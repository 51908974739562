/* © 2018 NauStud.io
 * @author Thanh
 * Shamelessly copied from https://github.com/marmelab/react-admin/blob/master/examples/demo/src/Login.js
 * TODO: implement two factor authentication
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { propTypes, reduxForm, Field } from 'redux-form';
import { connect } from 'react-redux';
import compose from 'recompose/compose';

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import LockIcon from '@material-ui/icons/Lock';

import { Notification, translate, userLogin } from 'react-admin';

import { loginWithPassword } from '../helper/authClient';
import config from '../config';

const styles = theme => ({
	main: {
		display: 'flex',
		flexDirection: 'column',
		minHeight: '100vh',
		alignItems: 'center',
		justifyContent: 'flex-start',
		background: 'url(https://source.unsplash.com/1600x900/?vietnam)',
		backgroundRepeat: 'no-repeat',
		backgroundSize: 'cover',
	},
	card: {
		minWidth: 300,
		marginTop: '6em',
	},
	avatar: {
		margin: '1em',
		display: 'flex',
		justifyContent: 'center',
	},
	icon: {
		backgroundColor: theme.palette.secondary.main,
	},
	hint: {
		marginTop: '1em',
		display: 'flex',
		justifyContent: 'center',
		color: theme.palette.grey[500],
	},
	form: {
		padding: '0 1em 1em 1em',
	},
	input: {
		marginTop: '1em',
	},
	actions: {
		padding: '0 1em 1em 1em',
	},
});

// see http://redux-form.com/6.4.3/examples/material-ui/
const renderInput = ({ meta: { touched, error } = {}, input: { ...inputProps }, ...props }) => (
	<TextField error={!!(touched && error)} helperText={touched && error} {...inputProps} {...props} fullWidth />
);

renderInput.propTypes = {
	meta: PropTypes.shape({}),
	input: PropTypes.shape({}),
};

renderInput.defaultProps = {
	meta: {},
	input: {},
};

class Login extends Component {
	static propTypes = {
		...propTypes,
		// authProvider: PropTypes.func,
		classes: PropTypes.object.isRequired,
		// previousRoute: PropTypes.string,
		translate: PropTypes.func.isRequired,
		userLogin: PropTypes.func.isRequired,
	};

	static defaultProps = {
		// previousRoute: '',
		// authProvider: () => {},
	};

	state = {
		step: 1,
		processing: false,
		loginError: '',
	};

	loginWithOTP = auth =>
		this.props.userLogin(auth, this.props.location.state ? this.props.location.state.nextPathname : '/');
	loginWithoutOTP = auth =>
		this.props.userLogin(auth, this.props.location.state ? this.props.location.state.nextPathname : '/');

	loginWithPassword = ({ email, password }) => {
		loginWithPassword(`${config.apiHost}/api/users/login-with-password`, { email, password })
			.then(() => {
				this.setState({ step: 2, processing: false, loginError: '' });
			})
			.catch(() => {
				this.setState({ processing: false, loginError: this.props.translate('login.emailPassword.failed') });
			});
		this.setState({ processing: true });
	};

	render() {
		const { classes, handleSubmit, isLoading, translate } = this.props;
		const { processing, loginError = '' } = this.state;
		// TODO:  We login with OTP in development environment, I don't know how add new env var to react
		const loginWithPassword = this.loginWithoutOTP;

		return (
			<div className={classes.main}>
				<Card className={classes.card}>
					<div className={classes.avatar}>
						<Avatar className={classes.icon}>
							<LockIcon />
						</Avatar>
					</div>
					{this.state.step === 1 && (
						<form onSubmit={handleSubmit(loginWithPassword)} name="step-1">
							{/* <div className={classes.hint}>Hint: demo / demo</div> */}
							<div className={classes.form}>
								<div className={classes.input}>
									<Field
										name="email"
										component={renderInput}
										label={translate('login.email')}
										disabled={isLoading}
									/>
								</div>
								<div className={classes.input}>
									<Field
										name="password"
										component={renderInput}
										label={translate('ra.auth.password')}
										type="password"
										disabled={isLoading}
									/>
								</div>
							</div>
							<CardActions className={classes.actions}>
								<Button
									variant="raised"
									type="submit"
									color="primary"
									disabled={isLoading || processing}
									className={classes.button}
									fullWidth
								>
									{(isLoading || processing) && <CircularProgress size={25} thickness={2} />}
									{translate('ra.auth.sign_in')}
								</Button>
							</CardActions>
						</form>
					)}
					<div className="login-error" style={{ color: 'red' }}>
						{loginError}
					</div>
					{this.state.step === 2 && (
						<form onSubmit={handleSubmit(this.loginWithOTP)} name="step-2">
							{/* <div className={classes.hint}>Hint: demo / demo</div> */}
							<div className={classes.form}>
								<div className={classes.input}>
									<Field
										name="otpToken"
										component={renderInput}
										label={translate('login.otpToken')}
										disabled={isLoading}
									/>
								</div>
							</div>
							<CardActions className={classes.actions}>
								<Button
									variant="raised"
									type="submit"
									color="primary"
									disabled={isLoading}
									className={classes.button}
									fullWidth
								>
									{isLoading && <CircularProgress size={25} thickness={2} />}
									{translate('ra.auth.sign_in')}
								</Button>
							</CardActions>
						</form>
					)}
				</Card>
				<Notification />
			</div>
		);
	}
}

const mapStateToProps = state => ({ isLoading: state.admin.loading > 0 });

const enhance = compose(
	translate,
	reduxForm({
		form: 'signIn',
		validate: (values, props) => {
			const errors = {};
			const { translate } = props;
			if (!values.email) {
				errors.email = translate('ra.validation.required');
			}
			if (!values.password) {
				errors.password = translate('ra.validation.required');
			}

			return errors;
		},
	}),
	connect(mapStateToProps, { userLogin }),
	withStyles(styles)
);

export default enhance(Login);
