/* © 2019 NauStud.io
 * @author Eric Tran
 */

import React, { Component } from 'react';
import { Edit, SimpleForm, TextInput, required } from 'react-admin';

import MultiLanguageInput from '../MultiLanguageInput';
import LocationInput from '../LocationInput';

class AirportEdit extends Component {
	render() {
		return (
			<Edit {...this.props} undoable={false}>
				<SimpleForm redirect="list">
					<TextInput source="code" label="Airport IATA Code" validate={required()} />
					<MultiLanguageInput source="name" label="Airport Name" validate={required()} />
					<LocationInput source="locationId" label="Location" validate={required()} />
				</SimpleForm>
			</Edit>
		);
	}
}

export default AirportEdit;
