/* © 2019 NauStud.io
 * @author Hieu Le
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import format from 'date-fns/format';
import differenceInDays from 'date-fns/differenceInDays';
import formatDistance from 'date-fns/formatDistance';
import {
	Datagrid,
	List,
	Responsive,
	SimpleList,
	TextField,
	Filter,
	TextInput,
	// AutocompleteInput,
	// ReferenceInput,
	EditButton,
	DeleteButton,
} from 'react-admin';

import { dataGridStyle } from '../../helper/constants';

const StoryFilter = props => (
	<Filter {...props}>
		<TextInput label="Search by position" source="position" alwaysOn />
	</Filter>
);

const style = {
	imgWrapper: {
		width: '80px',
		height: '80px',
		position: 'relative',
		margin: '5px 0',
		overflow: 'hidden',
	},
	img: {
		position: 'absolute',
		top: '0',
		left: '50%',
		transform: 'translate(-50%, 0)',
		height: '100%',
	},
};

const ImageCustomField = ({ record = {} }) => (
	<div style={style.imgWrapper}>
		<img style={style.img} src={record.image} alt="" />
	</div>
);

ImageCustomField.propTypes = {
	record: PropTypes.shape({
		image: PropTypes.string,
	}),
};

ImageCustomField.defaultProps = { record: null };

const CreatedTimeField = ({ record = {} }) => {
	let createdAt = record.createdAt ? new Date(record.createdAt) : '';

	if (createdAt) {
		if (differenceInDays(new Date(), createdAt) >= 10) {
			createdAt = format(createdAt, 'dd/MM/yyyy');
		} else {
			createdAt = `${formatDistance(createdAt, new Date())} ago`;
		}
	}

	return <span>{createdAt}</span>;
};

CreatedTimeField.propTypes = {
	record: PropTypes.shape({
		createdAt: PropTypes.instanceOf(Date),
	}),
};

CreatedTimeField.defaultProps = { record: null };

class StoryList extends Component {

    getPrimaryText = record => record.name;

	getSecondaryText = record => record.content;

    getTertiaryText = record => new Date(record.createdAt).toLocaleDateString();

    render() {
        const { classes } = this.props;

        return (
	<List
		{...this.props}
		filters={<StoryFilter />}
		sort={{ field: 'updatedAt', order: 'DESC' }}
		bulkActionButtons={false}
	>
		<Responsive
			small={
				<SimpleList
					primaryText={this.getPrimaryText}
					secondaryText={this.getSecondaryText}
					tertiaryText={this.getTertiaryText}
				/>
					}
			medium={
				<Datagrid styles={dataGridStyle}>
					<ImageCustomField source="image" sortable={false} />
					<TextField source="name" className={classes.maxWidthField} sortable={false} />
					<TextField source="content" className={classes.nowrap} label="Content" sortable={false} />
					<EditButton />
					<DeleteButton />
				</Datagrid>
					}
		/>
	</List>
        );
    }
}

const styles = {
	...dataGridStyle,
};

StoryList.propTypes = {
	classes: PropTypes.shape({}),
};

StoryList.defaultProps = {
	classes: {},
};

export default withStyles(styles)(StoryList);