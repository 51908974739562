/* © 2018 NauStud.io
 * @author Eric Tran
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
	Datagrid,
	List,
	TextField,
	ReferenceField,
	EditButton,
	NumberField,
	DeleteButton,
	Filter,
	TextInput,
} from 'react-admin';

import { dataGridStyle } from '../../helper/constants';

const style = {
	imgWrapper: {
		width: '80px',
		height: '56px',
		position: 'relative',
		margin: '5px 0',
	},
	img: {
		position: 'absolute',
		top: '0',
		left: '0',
		width: '100%',
		height: '100%',
	},
};

const ImageCustomField = ({ record = {} }) => (
	<div style={style.imgWrapper}>
		<img style={style.img} src={record.image} alt="" />
	</div>
);

ImageCustomField.propTypes = {
	record: PropTypes.shape({
		image: PropTypes.string,
	}),
};

ImageCustomField.defaultProps = { record: null };

const ProductFilter = props => (
	<Filter {...props}>
		<TextInput label="Search by name" source="name" alwaysOn />
	</Filter>
);

class ProductList extends Component {
	static propTypes = {
		classes: PropTypes.shape({}),
	};

	static defaultProps = {
		classes: {},
	};

	render() {
		const { classes } = this.props;

		return (
			<List filters={<ProductFilter />} {...this.props} bulkActionButtons={false}>
				<Datagrid classes={classes}>
					<ImageCustomField sortable={false} source="image" />
					<TextField source="name.en" label="Name" sortable={false} />
					<ReferenceField label="Category" source="categoryId" reference="product-categories">
						<TextField source="name.en" />
					</ReferenceField>
					<TextField source="description.en" label="Description" sortable={false} />
					<NumberField source="remain" sortable={false} />
					<EditButton />
					<DeleteButton />
				</Datagrid>
			</List>
		);
	}
}

const styles = {
	...dataGridStyle,
};

export default withStyles(styles)(ProductList);
