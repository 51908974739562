/* © 2019 NauStud.io
 * @author Eric Tran
 */

import React, { Component, Fragment } from 'react';
import format from 'date-fns/format';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { Field, FieldArray } from 'redux-form';
import { Button } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import { fetchJson } from '../../helper/restClient';

import config from '../../config';

class PromotionCodesTable extends Component {
	static propTypes = {
		formData: PropTypes.object.isRequired,
	};

	state = {
		showDialog: false,
	};

	onAddPromotionCode = () => {
		this.generatePromotionCode().then(generatedCode => {
			const newCode = {
				code: generatedCode,
				createdDate: new Date(),
				dateApplied: null,
				bookingCode: null,
				bookingModel: null,
				used: 0,
			};
			this.unshiftPromotionCode(newCode);
		});
	};

	onDeleteRow = code => {
		this.selectedCode = code;
		this.setState({ showDialog: true });
	};

	generatePromotionCode = async () => {
		const codes = this.props.formData.promotionCodes || [];
		const codeList = codes.map(code => code.code);
		let promotionCode = await fetchJson(`${config.apiHost}/api/promotions/generate-code`).then(res => {
			if (res && res.status < 400) {
				return res.json;
			}

			return null;
		});

		if (!promotionCode || codeList.indexOf(promotionCode) > -1) {
			promotionCode = await this.generatePromotionCode();
		}

		return promotionCode;
	};

	handleDelete = () => {
		if (this.selectedCode) {
			const codes = this.props.formData.promotionCodes;
			const foundIndex = codes.findIndex(code => code.code === this.selectedCode);
			if (foundIndex > -1) {
				this.removePromotionCode(foundIndex);
			}
		}
		this.handleCloseClick();
	};

	handleCloseClick = () => {
		this.setState({ showDialog: false });
	};

	Input = ({ input: { name, value, onChange, ...inputProps } }) => <input hidden {...inputProps} />;

	renderPromotionCodes = ({ fields }) => {
		this.removePromotionCode = fields.remove;
		this.unshiftPromotionCode = fields.unshift;
		const fieldsName = ['code', 'createdDate', 'dateApplied', 'bookingCode', 'bookingModel', 'used'];

		return fields.map((promotionCode, index) => (
			<Fragment key={index.toString()}>
				{fieldsName.map(name => (
					<Field key={name} name={`${promotionCode}.${name}`} component={this.Input} hidden />
				))}
			</Fragment>
		));
	};

	render() {
		const {
			formData: { promotionCodes = [] },
		} = this.props;
		const { showDialog } = this.state;

		return (
			<>
				<div style={{ padding: '30px 0' }}>
					<Button variant="contained" color="primary" onClick={this.onAddPromotionCode}>
						Create Promotion Code
					</Button>
				</div>
				<FieldArray name="promotionCodes" component={this.renderPromotionCodes} />
				<Dialog fullWidth open={showDialog} onClose={this.handleCloseClick} aria-label="Are you sure?">
					<DialogTitle>Are you sure you want to delete?</DialogTitle>
					<DialogActions>
						<Button onClick={this.handleDelete} key="button">
							Delete
						</Button>
						<Button onClick={this.handleCloseClick}>Cancel</Button>
					</DialogActions>
				</Dialog>
				<Table>
					<TableHead>
						<TableRow>
							<TableCell>Promotion Code</TableCell>
							<TableCell>Created Date</TableCell>
							<TableCell>Code used</TableCell>
							<TableCell>Date Applied</TableCell>
							<TableCell>Booking Code</TableCell>
							<TableCell>Delete</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{promotionCodes.map(promotionCode => (
							<TableRow key={promotionCode.code}>
								<TableCell>{promotionCode.code}</TableCell>
								<TableCell>{format(new Date(promotionCode.createdDate), 'dd/MM/yyyy HH:mm')}</TableCell>
								<TableCell>{promotionCode.bookingCode ? 'Used' : 'Available'}</TableCell>
								<TableCell>{format(new Date(promotionCode.dateApplied), 'dd/MM/yyyy HH:mm')}</TableCell>
								<TableCell>{promotionCode.bookingCode}</TableCell>
								<TableCell>
									{!promotionCode.bookingCode ? (
										<Button
											variant="contained"
											color="secondary"
											onClick={() => {
												this.onDeleteRow(promotionCode.code);
											}}
										>
											Delete
										</Button>
									) : (
										''
									)}
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</>
		);
	}
}

export default PromotionCodesTable;
