/* © 2018 NauStud.io
 * @author Thanh
 */
import React from 'react';
import { Layout } from 'react-admin';

import AppBar from './AppBar';
import Menu from '../menu';

// The default react-admin layout
const VILayout = props => <Layout {...props} appBar={AppBar} menu={Menu} />;

export default VILayout;
