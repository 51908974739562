/* © 2018 NauStud.io
 * @author Tu Nguyen
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';

class Blockquote extends Component {
	state = {
		cite: '',
		content: '',
	};

	_disableOKButton = () => !(this.state.content && this.state.cite);

	_handleOnChange = e => {
		this.setState({
			[e.target.name]: e.target.value,
		});
	};

	_onSubmit = () => {
		this.props.onSubmit({
			content: this.state.content,
			cite: this.state.cite,
		});
	};

	render() {
		return (
			<div className="blockquote-tooltip">
				<div>
					<div className="flex-column">
						<textarea onChange={this._handleOnChange} type="text" name="content" placeholder="content" />
						<input onChange={this._handleOnChange} type="text" name="cite" placeholder="cite" />
					</div>
					<div className="flex">
						<button
							className="rte__tooltip-button"
							type="button"
							onClick={this._onSubmit}
							disabled={this._disableOKButton()}
						>
							OK
						</button>
						<button type="button" className="rte__tooltip-button" onClick={this.props.closeTooltip}>
							Cancel
						</button>
					</div>
				</div>
			</div>
		);
	}
}

Blockquote.propTypes = {
	onSubmit: PropTypes.func,
	closeTooltip: PropTypes.func,
};

Blockquote.defaultProps = {
	onSubmit: () => {},
	closeTooltip: () => {},
};

export default Blockquote;
