/* © 2018 NauStud.io
 * @author Eric Tran
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
	DisabledInput,
	Edit,
	LongTextInput,
	BooleanInput,
	SimpleForm,
	TextInput,
	minLength,
	Toolbar,
	SaveButton,
} from 'react-admin';
import { slugify, isDuplicateRecord } from '../../helper/utils';
import ImageInput from '../ImageInput';

const ConditionalTagNameField = ({ record, ...rest }) =>
	record && record.isPredefined ? (
		<DisabledInput source="name" record={record} {...rest} />
	) : (
		<TextInput source="name" record={record} {...rest} />
	);

ConditionalTagNameField.propTypes = {
	record: PropTypes.shape({}),
};

ConditionalTagNameField.defaultProps = {
	record: {},
};

const TagSaveToolbar = props => {
	if (props.record.isPredefined) {
		return (
			<Toolbar {...props}>
				<SaveButton />
			</Toolbar>
		);
	} else {
		return <Toolbar {...props} />;
	}
};

class TagEdit extends Component {
	static propTypes = {
		match: PropTypes.shape({
			params: PropTypes.shape({
				id: PropTypes.string,
			}),
		}),
	};

	static defaultProps = {
		match: {},
	};

	state = {
		errorState: '',
	};

	checkDuplicateRecord = values => {
		const id = slugify(values.name);

		return new Promise((resolve, reject) => {
			if (id && id !== this.props.match.params.id) {
				isDuplicateRecord(id, 'id', 'tags')
					.then(res => {
						if (res.data.length > 0) {
							return reject({
								name: ['Duplicated record'],
							});
						}

						return resolve({});
					})
					.catch(e => {
						console.log(e);
					});
			} else {
				resolve({});
			}
		});
	};

	render() {
		return (
			<Edit {...this.props} undoable={false}>
				<SimpleForm toolbar={<TagSaveToolbar />} asyncValidate={this.checkDuplicateRecord} asyncBlurFields={['name']}>
					<DisabledInput source="id" label="URL" />
					<ConditionalTagNameField />

					<BooleanInput source="hidden" />
					<LongTextInput source="description" validate={minLength(10)} />
					<LongTextInput source="longDescription" validate={minLength(10)} />
					<ImageInput
						source="image"
						dropzone={{
							uniqueId: 'tagEditDrop',
							width: 1400,
							height: 450,
						}}
					/>
					<TextInput source="metaTitle" label="Meta Title" />
					<TextInput source="metaDescription" label="Meta Description" />
					<DisabledInput label="Number of posts" source="count.posts" />
				</SimpleForm>
			</Edit>
		);
	}
}

export default TagEdit;
