/* © 2019 NauStud.io
 * @author Eric Tran
 */

import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
	Datagrid,
	List,
	TextField,
	EditButton,
	ReferenceField,
	ReferenceArrayField,
	SingleFieldList,
	ChipField,
	SelectField,
} from 'react-admin';

import { dataGridStyle, noWrapStyle } from '../../helper/constants';
import DeleteButtonWithConfirmation from '../DeleteButtonWithConfirmation';

class ServiceList extends Component {
	render() {
		return (
			<List {...this.props} bulkActionButtons={false}>
				<Datagrid styles={dataGridStyle}>
					<TextField source="name.en" label="Name" />
					<SelectField
						source="serviceType"
						label="Service type"
						choices={[
							{ id: 'private-trip', name: 'Private Short Tour' },
							{ id: 'public-trip', name: 'Public Short Tour' },
							{ id: 'show', name: 'Ticket / Show' },
							{ id: 'free-walking-tour', name: 'Free walking tour' },
						]}
					/>
					<ReferenceArrayField label="Interests" reference="service-interests" source="interestIds">
						<SingleFieldList>
							<ChipField source="name.en" />
						</SingleFieldList>
					</ReferenceArrayField>
					<ReferenceArrayField label="Tags" reference="service-tags" source="tagIds">
						<SingleFieldList>
							<ChipField source="name.en" />
						</SingleFieldList>
					</ReferenceArrayField>
					<ReferenceField label="Location" source="locationId" reference="locations">
						<TextField source="title.en" />
					</ReferenceField>
					<EditButton />
					<DeleteButtonWithConfirmation />
				</Datagrid>
			</List>
		);
	}
}

const styles = {
	...noWrapStyle,
};

export default withStyles(styles)(ServiceList);
