/* © 2018 NauStud.io
 * @author Eric Tran
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
	Datagrid,
	List,
	Responsive,
	SimpleList,
	TextField,
	BooleanField,
	ImageField,
	EditButton,
	// DeleteButton,
} from 'react-admin';

import CustomTagDeleteButton from '../CustomTagDeleteButton';

import { dataGridStyle } from '../../helper/constants';

class TagList extends Component {
	static propTypes = {
		classes: PropTypes.shape({}),
	};

	static defaultProps = {
		classes: {},
	};

	getPrimaryText = record => record.name;

	getSecondaryText = record => record.description;

	getTertiaryText = record => new Date(record.createdAt).toLocaleDateString();

	render() {
		const { classes } = this.props;

		return (
			<List {...this.props} bulkActionButtons={false}>
				<Responsive
					small={
						<SimpleList
							primaryText={this.getPrimaryText}
							secondaryText={this.getSecondaryText}
							tertiaryText={this.getTertiaryText}
						/>
					}
					medium={
						<Datagrid classes={classes}>
							<TextField source="id" label="URL" sortable={false} />
							<TextField source="name" sortable={false} />
							<BooleanField source="hidden" />
							<TextField source="description" sortable={false} />
							<TextField source="longDescription" sortable={false} />
							<ImageField source="image" sortable={false} />
							<TextField label="Posts" source="count.posts" className={classes.postsField} />
							<EditButton />
							<CustomTagDeleteButton />
						</Datagrid>
					}
				/>
			</List>
		);
	}
}

const styles = {
	postsField: { textAlign: 'center', display: 'block' },
	...dataGridStyle,
};

export default withStyles(styles)(TagList);
