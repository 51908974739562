/* © 2018 NauStud.io
 * @author Eric Tran
 */

import React, { Component } from 'react';
import {
	Edit,
	TabbedForm,
	FormTab,
	TextInput,
	LongTextInput,
	ReferenceInput,
	NumberInput,
	ImageField,
	ArrayInput,
	SimpleFormIterator,
	SelectInput,
	FormDataConsumer,
	Labeled,
	TextField,
	GET_LIST,
	AutocompleteInput,
} from 'react-admin';
import { DateInput, DateTimeInput } from 'react-admin-date-inputs';
import Snackbar from '@material-ui/core/Snackbar';

import SearchUserInput from '../SearchUserInput';
import restClient from '../../helper/restClient';
import config from '../../config';

import MultiLanguageInput from '../MultiLanguageInput';

class BookingEdit extends Component {
	state = {
		tripPrice: 0,
		success: false,
	};

	componentDidMount() {
		restClient(GET_LIST, 'bookings', {
			pagination: {
				perPage: 1,
			},
			sort: {},
			filter: {
				id: this.props.id,
			},
		}).then(res => {
			if (res && res.data && res.data.length) {
				this.booking = res.data[0];
				if (this.booking.trip) {
					this.setState({ tripPrice: parseInt(this.booking.trip.price, 10) });
				}
			}
		});
	}

	componentWillReceiveProps() {
		const pushedSuccess = localStorage.getItem('pushedSuccess');
		if (pushedSuccess) {
			this.setState({ success: true });
			localStorage.removeItem('pushedSuccess');
		}
	}

	onFormChange = e => {
		clearTimeout(this.formChangeTimeout);
		if (e.additionalProducts) {
			this.formChangeTimeout = setTimeout(async () => {
				const customerCount = e.customers.length || 0;
				const productIds = e.additionalProducts.map(ap => ap.id);
				const productsFromServer = await restClient(GET_LIST, 'products', {
					pagination: {
						perPage: productIds.length,
					},
					sort: {},
					filter: {
						id: { inq: productIds },
					},
				}).then(res => res.data);

				// eslint-disable-next-line no-param-reassign
				e.additionalProducts = e.additionalProducts.map(ap => {
					const foundProduct = productsFromServer.find(p => p.id === ap.id);
					if (foundProduct && !ap.price) {
						return {
							id: ap.id,
							name: foundProduct.name.en,
							description: foundProduct.description.en,
							price: this.getProductPriceBaseOnNumberOfGuest(foundProduct, customerCount),
						};
					}

					return ap;
				});
				this.calculateTotalPrice(e);
			}, 400);
		} else if (e.id) {
			this.calculateTotalPrice(e);
		}
	};

	getProductPriceBaseOnNumberOfGuest = (product, numberOfGuests) => {
		const ranges = product.priceByRange || [];
		if (!ranges.length || !product) {
			return 0;
		}

		let lastResult = 0;

		for (let index = 0; index < ranges.length; index++) {
			const range = ranges[index];
			lastResult = range.price;
			if (numberOfGuests <= range.max) {
				return range.price;
			}
		}

		return lastResult;
	};

	calculateTotalPrice = formData => {
		let sum = (formData.tourBasePrice ? parseInt(formData.tourBasePrice.price, 10) : this.state.tripPrice) || 0;
		sum += formData.additionalFees.reduce((total, fee) => total + fee.price, 0);
		sum += formData.additionalProducts.reduce((total, p) => total + p.price, 0);
		// eslint-disable-next-line no-param-reassign
		formData.totalPrice = sum * (formData.customers.length || 0);
	};

	closePopup = () => {
		this.setState({ success: false });
	}

	render() {
		const { resource } = this.props;
		const tourReference = resource === 'custom-tour-bookings' ? 'custom-tours' : 'tours';

		return (
			<>
				<Edit {...this.props} undoable={false}>
					<TabbedForm onChange={this.onFormChange} redirect="edit">
						<FormTab label="summary">
							<TextField source="id" label="Booking ID" />
							<FormDataConsumer>
								{({ formData }) => {
									if (formData.tourId) {
										const href = `#/${tourReference}/${formData.tourId}`;

										return (
											<Labeled label="Tour Id">
												<a href={href}>{formData.tourId}</a>
											</Labeled>
										);
									}

									return '';
								}}
							</FormDataConsumer>
							<FormDataConsumer>
								{({ formData }) => {
									if (formData.tripId) {
										const href = `#/trips/${formData.tripId}`;

										return (
											<Labeled label="Guarantee Tour Trip Id">
												<a href={href}>{formData.tripId}</a>
											</Labeled>
										);
									}

									return '';
								}}
							</FormDataConsumer>
							<SearchUserInput source="userId" />
							<DateInput source="departureDate" options={{ format: 'dd/MM/yyyy' }} label="Departure Date" />
							<NumberInput source="totalPrice" />
							<SelectInput
								source="status"
								choices={[
									{ id: 'pending', name: 'Waiting For Payment' },
									{ id: 'pending-bank-transfer', name: 'Waiting For Bank Transfer' },
									{ id: 'deposited', name: 'Deposited' },
									{ id: 'paid', name: 'Paid' },
									{ id: 'inquiry', name: 'Inquiry' },
									{ id: 'canceled', name: 'Canceled' },
									{ id: 'pushed', name: 'Pushed to E-tour' },
									{ id: 'credit', name: 'Paid using credit' },
								]}
							/>
						</FormTab>
						<FormTab label="Contact Info">
							<SelectInput
								source="contactInfo.contactTitle"
								label="Title"
								choices={[{ id: 'mr', name: 'Mr' }, { id: 'mrs', name: 'Mrs' }, { id: 'ms', name: 'Ms' }]}
							/>
							<TextInput source="contactInfo.contactFirstName" label="Contact First Name" />
							<TextInput source="contactInfo.contactLastName" label="Contact Last Name" />
							<SelectInput
								source="contactInfo.contactGender"
								label="Gender"
								choices={[{ id: 'male', name: 'Male' }, { id: 'female', name: 'Female' }, { id: 'other', name: 'Other' }]}
							/>
							<TextInput source="contactInfo.contactEmail" label="Contact Email" />
							<TextInput source="contactInfo.contactPhone" label="Contact Phone" />
							<TextInput source="contactInfo.contactCountry" label="Contact Country" />
							<TextInput source="contactInfo.contactNote" label="Contact Note" />
						</FormTab>
						<FormTab label="Tour Info">
							<TextInput options={{ disabled: true }} source="tourInfo.tourTitle" label="Tour Title" />
							<ImageField options={{ disabled: true }} source="tourInfo.tourThumbnail" label="Tour Thumbnail" />
							<TextInput options={{ disabled: true }} source="tourInfo.tourType" label="Tour Type" />
							<TextInput options={{ disabled: true }} source="tourInfo.tourDuration" label="Tour Duration" />
							<FormDataConsumer>
								{({ formData }) =>
									formData.tourInfo.tourType === 'fixed' && formData.tourBasePrice ? (
										<TextInput source="tourBasePrice.name" label="Selected Base Price Name" />
									) : (
										''
									)
								}
							</FormDataConsumer>
							<FormDataConsumer>
								{({ formData }) =>
									formData.tourInfo.tourType === 'fixed' && formData.tourBasePrice ? (
										<TextInput source="tourBasePrice.price" label="Selected Base Price" />
									) : (
										''
									)
								}
							</FormDataConsumer>
							<FormDataConsumer>
								{({ formData }) =>
									formData.tourInfo.tourType === 'guarantee' && this.state.tripPrice ? (
										<Labeled label="Trip Price">
											<span>{this.state.tripPrice}</span>
										</Labeled>
									) : (
										''
									)
								}
							</FormDataConsumer>
							<ArrayInput source="additionalFees">
								<SimpleFormIterator>
									<TextInput source="name" />
									<NumberInput source="price" />
								</SimpleFormIterator>
							</ArrayInput>
							<FormDataConsumer>
								{({ formData }) =>
									formData.tourInfo.tourType === 'fixed' ? (
										<ArrayInput source="additionalProducts">
											<SimpleFormIterator>
												<ReferenceInput
													label="Product"
													source="id"
													reference="products"
													filterToQuery={searchText => ({
														name: { like: searchText },
													})}
													allowEmpty
												>
													<AutocompleteInput optionText="name.en" />
												</ReferenceInput>
												<TextInput source="name" />,
												<TextInput source="description" />,
												<NumberInput source="price" />
											</SimpleFormIterator>
										</ArrayInput>
									) : (
										''
									)
								}
							</FormDataConsumer>
						</FormTab>
						<FormTab label="Customers">
							<ArrayInput source="customers">
								<SimpleFormIterator>
									<TextInput source="firstName" label="First Name" />
									<TextInput source="lastName" label="Last Name" />
									<SelectInput
										source="gender"
										label="Gender"
										choices={[{ id: 'male', name: 'Male' }, { id: 'female', name: 'Female' }, { id: 'other', name: 'Other' }]}
									/>
									<DateInput label="Birthday" source="birthday" options={{ format: 'dd/MM/yyyy' }} />
									<SelectInput
										source="customerType"
										label="Customer Type"
										choices={[
											{ id: 'adult', name: 'Adult' },
											{ id: 'children', name: 'Children' },
											{ id: 'baby', name: 'Baby' },
										]}
									/>
									<TextInput source="passportNumber" label="Passport Number" />
									<DateInput
										source="passportExpiryDate"
										options={{ format: 'dd/MM/yyyy' }}
										label="Passport Expiry Date"
									/>
									<TextInput source="passportIssuingCountry" label="Passport Issuing Country" />
								</SimpleFormIterator>
							</ArrayInput>
						</FormTab>
						<FormTab label="Payment Logs">
							<FormDataConsumer>
								{({ formData }) => {
									if (formData.id && (formData.status === 'inquiry' || formData.status === 'pending')) {
										const href = `${config.websiteHost}/checkout/${formData.id}`;

										return (
											<Labeled label="Checkout URL">
												<a href={href}>{href}</a>
											</Labeled>
										);
									}

									return '';
								}}
							</FormDataConsumer>
							<ArrayInput source="paymentLogs">
								<SimpleFormIterator disableAdd disableRemove>
									<TextInput source="code" options={{ disabled: true }} label="Response code from gateway" />
									<MultiLanguageInput source="error" options={{ disabled: true }} label="Error messages" />
									<TextInput source="reference" options={{ disabled: true }} label="Gateway transaction ID" />
									<TextInput source="paymentMethod" options={{ disabled: true }} label="Payment using" />
									<DateTimeInput source="createdAt" options={{ disabled: true }} label="Created At" />
								</SimpleFormIterator>
							</ArrayInput>
						</FormTab>
						<FormTab label="Push Etour Logs">
							<ArrayInput source="pushEtourLogs">
								<SimpleFormIterator disableAdd disableRemove>
									<TextInput source="success" options={{ disabled: true }} label="Success" />
									<TextInput source="code" options={{ disabled: true }} label="Response code" />
									<LongTextInput source="error" options={{ disabled: true }} label="Error messages" />
									<DateTimeInput source="createdAt" options={{ disabled: true }} label="Created At" />
									<ReferenceInput
										label="User"
										source="userId"
										reference="users"
										options={{ disabled: true }}
									>
										<AutocompleteInput optionText="email" />
									</ReferenceInput>
								</SimpleFormIterator>
							</ArrayInput>
						</FormTab>
					</TabbedForm>
				</Edit>

				<Snackbar
					open={this.state.success}
					message={'Pushed E-tour SuccessFully'}
					onClose={this.closePopup}
					anchorOrigin={{vertical: 'top', horizontal: 'center'}}
				/>
			</>
		);
	}
}

export default BookingEdit;
