/* © 2018 NauStud.io
 * @author Thanh
 */
import React from 'react';
import ReactDOM from 'react-dom';

import { Resource, Admin } from 'react-admin';

import englishMessages from 'ra-language-english';
import vietnameseMessages from 'ra-language-vietnamese';

import config from './config';
import VInboundTheme from './components/VInboundTheme';
import VILayout from './components/layout';
import LoginPage from './components/LoginPage';
import Dashboard from './components/dashboard';
// import Menu from './components/menu'; // deprecated
import addUploadFeature from './helper/addUploadFeature';

import { TourList, TourCreate, TourEdit, TourIcon } from './components/tours';
import { TripList, TripCreate, TripEdit, TripIcon } from './components/trips';
import { ProductList, ProductCreate, ProductEdit, ProductIcon } from './components/products';
import {
	ProductCategoryList,
	ProductCategoryCreate,
	ProductCategoryEdit,
	ProductCategoryIcon,
} from './components/product-categories';
import { TravelStyleList, TravelStyleCreate, TravelStyleEdit, TravelStyleIcon } from './components/travel-styles';
import { TourReviewIcon, TourReviewList, TourReviewEdit } from './components/reviews';
import { ProductReviewIcon, ProductReviewList, ProductReviewEdit } from './components/product-reviews';
import { PostIcon, PostList, PostCreate, PostEdit } from './components/posts';
import { PageIcon, PageList, PageCreate, PageEdit } from './components/pages';
// import { CommentIcon, CommentList, CommentEdit } from './components/comments';
import { SubscriberList, SubscriberIcon } from './components/subscribers';
import { InquiryTourList, InquiryTourIcon, InquiryTourShow } from './components/inquiry-tours';
import { ContactList, ContactIcon, ContactShow } from './components/contacts';
import {
	PostCategoryList,
	PostCategoryIcon,
	/*PostCategoryCreate, */ PostCategoryEdit,
} from './components/post-categories';
import { TagList, TagEdit, TagCreate, TagIcon } from './components/tags';
import { /*MediaList,*/ MediaCreate, MediaEdit, MediaIcon } from './components/media';
import { /*AlbumsList,*/ AlbumsCreate, AlbumsEdit, AlbumsIcon } from './components/albums';
import { UserList, UserCreate, UserEdit, UserIcon } from './components/users';
import { StoryIcon, StoryList, StoryCreate, StoryEdit } from './components/stories';
import { LocationList, LocationCreate, LocationEdit, LocationIcon } from './components/locations';
import { CountryList, CountryCreate, CountryEdit, CountryIcon } from './components/countries';
import { CurrencyList, CurrencyCreate, CurrencyEdit, CurrencyIcon } from './components/currencies';
import { CustomTourList, CustomTourEdit, CustomTourIcon } from './components/custom-tours';
import { MarketList, MarketCreate, MarketEdit, MarketIcon } from './components/markets';
import {
	CustomTourFeeList,
	CustomTourFeeCreate,
	CustomTourFeeEdit,
	CustomTourFeeIcon,
} from './components/custom-tour-fees';

import { FooterList, FooterCreate, FooterEdit, FooterIcon } from './components/footers';
import { FaqList, FaqCreate, FaqEdit, FaqIcon } from './components/faqs';

import { AgencyGroupList, AgencyGroupCreate, AgencyGroupEdit, AgencyGroupIcon } from './components/agency-groups';
import {
	HighlightPostList,
	HighlightPostEdit,
	HighlightPostCreate,
	HighlightPostIcon,
} from './components/highlight-post';
import { BookingList, /*BookingCreate,*/ BookingEdit, BookingIcon } from './components/bookings';
import { CustomTourBookingList, CustomTourBookingEdit, CustomTourBookingIcon } from './components/custom-tour-bookings';
import { ProductBookingList, ProductBookingEdit, ProductBookingIcon } from './components/product-bookings';
import { FwtBookingList, FwtBookingEdit, FwtBookingIcon } from './components/fwt-bookings';
import { InvoiceList, InvoiceCreate, InvoiceEdit, InvoiceIcon } from './components/invoices';
import { TourGuideList, TourGuideCreate, TourGuideEdit, TourGuideIcon } from './components/tour-guides';
import { AirportList, AirportCreate, AirportEdit, AirportIcon } from './components/airports';
import { CarTypeList, CarTypeCreate, CarTypeEdit, CarTypeIcon } from './components/car-types';
import { CarAllotmentList, CarAllotmentCreate, CarAllotmentEdit, CarAllotmentIcon } from './components/car-allotments';
import {
	GuideAllotmentList,
	GuideAllotmentCreate,
	GuideAllotmentEdit,
	GuideAllotmentIcon,
} from './components/guide-allotments';
import { GuideLanguageList, GuideLanguageCreate, GuideLanguageIcon } from './components/guide-languages';
import {
	AirportTransferList,
	AirportTransferCreate,
	AirportTransferEdit,
	AirportTransferIcon,
} from './components/airport-transfers';
import { CityCarList, CityCarCreate, CityCarEdit, CityCarIcon } from './components/city-cars';
import { IntercityCarList, IntercityCarCreate, IntercityCarEdit, IntercityCarIcon } from './components/intercity-cars';
import { OpenBusRouteList, OpenBusRouteCreate, OpenBusRouteEdit, OpenBusRouteIcon } from './components/open-bus-routes';
import { OpenBusEdit, OpenBusList, OpenBusIcon } from './components/open-buses';
import { OpenBusFolderList, OpenBusFolderIcon, OpenBusFolderEdit } from './components/open-bus-folders';
import { ServiceList, ServiceCreate, ServiceEdit, ServiceIcon } from './components/services';
import { ServiceTagList, ServiceTagCreate, ServiceTagEdit, ServiceTagIcon } from './components/service-tags';
import {
	ServiceInterestList,
	ServiceInterestCreate,
	ServiceInterestEdit,
	ServiceInterestIcon,
} from './components/service-interests';
import { PromotionList, PromotionCreate, PromotionEdit, PromotionIcon } from './components/promotions';
import { CommissionList, CommissionCreate, CommissionEdit, CommissionIcon } from './components/commissions';

import Settings from './components/settings';
import HomePageSettings from './components/home-page-settings';
import InquiryTourQuestion from './components/inquiry-tour-question';
import LogoutButton from './components/LogoutButton';
import { CareerIcon, CareerCreate, CareerEdit, CareerList } from './components/careers';

import restClient from './helper/restClient';
import { loginWithoutOTP, loginWithOTP } from './helper/authClient';
// import authClient from './helper/authClient';
import customRoutes from './customRoutes';

import * as customMessages from './i18n';

const messages = {
	vi: { ...vietnameseMessages, ...customMessages.vi },
	en: { ...englishMessages, ...customMessages.en },
};
const i18nProvider = locale => messages[locale];

const authProvider = loginWithoutOTP(`${config.apiHost}/api/users/login-with-password`);

// TODO: re-login doesn't update the Resource list based on new permission. Need FIX.
ReactDOM.render(
	<Admin
		authProvider={authProvider}
		dataProvider={addUploadFeature(restClient)}
		title="Vietravel Inbound Backoffice"
		locale="en"
		i18nProvider={i18nProvider}
		theme={VInboundTheme}
		appLayout={VILayout}
		dashboard={Dashboard}
		loginPage={LoginPage}
		logoutButton={LogoutButton}
		customRoutes={customRoutes}
	>
		{permissions => [
			permissions !== 'tour-manager' ? (
				<Resource name="posts" list={PostList} edit={PostEdit} create={PostCreate} icon={PostIcon} />
			) : null,
			permissions !== 'tour-manager' ? (
				<Resource
					name="highlights"
					list={HighlightPostList}
					create={HighlightPostCreate}
					edit={HighlightPostEdit}
					icon={HighlightPostIcon}
				/>
			) : null,
			permissions !== 'tour-manager' ? (
				<Resource name="tags" list={TagList} create={TagCreate} edit={TagEdit} icon={TagIcon} />
			) : null,
			permissions !== 'tour-manager' ? (
				<Resource
					name="categories"
					list={permissions === 'admin' ? PostCategoryList : null}
					edit={permissions === 'admin' ? PostCategoryEdit : null}
					icon={PostCategoryIcon}
				/>
			) : null,
			// permissions === 'admin' || permissions === 'tour-manager' ? (
			// 	<Resource name="tours" list={TourList} icon={TourIcon} create={TourCreate} edit={TourEdit} />
			// ) : null,
			// permissions === 'admin' || permissions === 'tour-manager' ? (
			// 	<Resource name="tour-booking-permissions" />
			// ) : null,
			// permissions === 'admin' || permissions === 'tour-manager' ? <Resource name="tour-commissions" /> : null,
			// permissions === 'admin' || permissions === 'tour-manager' ? (
			// 	<Resource name="trips" list={TripList} icon={TripIcon} create={TripCreate} edit={TripEdit} />
			// ) : null,
			// permissions === 'admin' || permissions === 'tour-manager' ? (
			// 	<Resource
			// 		name="products"
			// 		list={ProductList}
			// 		icon={ProductIcon}
			// 		edit={ProductEdit}
			// 		create={ProductCreate}
			// 	/>
			// ) : null,
			// permissions === 'admin' || permissions === 'tour-manager' ? (
			// 	<Resource
			// 		name="product-categories"
			// 		options={{ label: 'Product Categories' }}
			// 		list={ProductCategoryList}
			// 		icon={ProductCategoryIcon}
			// 		edit={ProductCategoryEdit}
			// 		create={ProductCategoryCreate}
			// 	/>
			// ) : null,
			// permissions === 'admin' || permissions === 'tour-manager' ? (
			// 	<Resource
			// 		name="travel-styles"
			// 		options={{ label: 'Travel Styles' }}
			// 		list={TravelStyleList}
			// 		icon={TravelStyleIcon}
			// 		edit={TravelStyleEdit}
			// 		create={TravelStyleCreate}
			// 	/>
			// ) : null,
			// permissions === 'admin' || permissions === 'tour-manager' ? <Resource name="customers-list" /> : null,
			// permissions === 'admin' ? (
			// 	<Resource name="reviews" list={TourReviewList} icon={TourReviewIcon} edit={TourReviewEdit} />
			// ) : null,
			// permissions === 'admin' ? (
			// 	<Resource
			// 		name="bookings"
			// 		list={BookingList}
			// 		icon={BookingIcon}
			// 		/*create={BookingCreate}*/ edit={BookingEdit}
			// 	/>
			// ) : null,
			// permissions === 'admin' ? (
			// 	<Resource
			// 		name="invoices"
			// 		list={InvoiceList}
			// 		icon={InvoiceIcon}
			// 		create={InvoiceCreate}
			// 		edit={InvoiceEdit}
			// 	/>
			// ) : null,
			// permissions === 'admin' || permissions === 'tour-manager' ? (
			// 	<Resource
			// 		name="custom-tour-fees"
			// 		options={{ label: 'Custom Tour Fees' }}
			// 		list={CustomTourFeeList}
			// 		icon={CustomTourFeeIcon}
			// 		edit={CustomTourFeeEdit}
			// 		create={CustomTourFeeCreate}
			// 	/>
			// ) : null,
			// permissions === 'admin' || permissions === 'tour-manager' ? (
			// 	<Resource
			// 		name="custom-tours"
			// 		options={{ label: 'Custom Tours' }}
			// 		list={CustomTourList}
			// 		icon={CustomTourIcon}
			// 		edit={CustomTourEdit}
			// 	/>
			// ) : null,
			// permissions === 'admin' ? (
			// 	<Resource
			// 		name="custom-tour-bookings"
			// 		list={CustomTourBookingList}
			// 		icon={CustomTourBookingIcon}
			// 		edit={CustomTourBookingEdit}
			// 	/>
			// ) : null,
			// permissions === 'admin' ? (
			// 	<Resource
			// 		name="custom-tour-invoices"
			// 		list={InvoiceList}
			// 		icon={InvoiceIcon}
			// 		create={InvoiceCreate}
			// 		edit={InvoiceEdit}
			// 	/>
			// ) : null,
			// permissions === 'admin' || permissions === 'tour-manager' ? (
			// 	<Resource name="inquiry-tours" show={InquiryTourShow} list={InquiryTourList} icon={InquiryTourIcon} />
			// ) : null,
			// permissions === 'admin' || permissions === 'tour-manager' ? (
			// 	<Resource
			// 		name="airports"
			// 		create={AirportCreate}
			// 		edit={AirportEdit}
			// 		list={AirportList}
			// 		icon={AirportIcon}
			// 	/>
			// ) : null,
			// permissions === 'admin' || permissions === 'tour-manager' ? (
			// 	<Resource
			// 		name="car-types"
			// 		create={CarTypeCreate}
			// 		edit={CarTypeEdit}
			// 		list={CarTypeList}
			// 		icon={CarTypeIcon}
			// 	/>
			// ) : null,
			// permissions === 'admin' || permissions === 'tour-manager' ? (
			// 	<Resource
			// 		name="car-allotments"
			// 		create={CarAllotmentCreate}
			// 		edit={CarAllotmentEdit}
			// 		list={CarAllotmentList}
			// 		icon={CarAllotmentIcon}
			// 	/>
			// ) : null,
			// permissions === 'admin' || permissions === 'tour-manager' ? (
			// 	<Resource
			// 		name="guide-languages"
			// 		create={GuideLanguageCreate}
			// 		list={GuideLanguageList}
			// 		icon={GuideLanguageIcon}
			// 	/>
			// ) : null,

			// permissions === 'admin' || permissions === 'tour-manager' ? (
			// 	<Resource
			// 		name="guide-allotments"
			// 		create={GuideAllotmentCreate}
			// 		edit={GuideAllotmentEdit}
			// 		list={GuideAllotmentList}
			// 		icon={GuideAllotmentIcon}
			// 	/>
			// ) : null,
			// permissions === 'admin' || permissions === 'tour-manager' ? (
			// 	<Resource
			// 		name="airport-transfers"
			// 		create={AirportTransferCreate}
			// 		edit={AirportTransferEdit}
			// 		list={AirportTransferList}
			// 		icon={AirportTransferIcon}
			// 	/>
			// ) : null,
			// permissions === 'admin' || permissions === 'tour-manager' ? (
			// 	<Resource
			// 		name="city-cars"
			// 		create={CityCarCreate}
			// 		edit={CityCarEdit}
			// 		list={CityCarList}
			// 		icon={CityCarIcon}
			// 	/>
			// ) : null,
			// permissions === 'admin' || permissions === 'tour-manager' ? (
			// 	<Resource
			// 		name="intercity-cars"
			// 		create={IntercityCarCreate}
			// 		edit={IntercityCarEdit}
			// 		list={IntercityCarList}
			// 		icon={IntercityCarIcon}
			// 	/>
			// ) : null,
			// permissions === 'admin' || permissions === 'tour-manager' ? (
			// 	<Resource
			// 		name="open-bus-routes"
			// 		create={OpenBusRouteCreate}
			// 		edit={OpenBusRouteEdit}
			// 		list={OpenBusRouteList}
			// 		icon={OpenBusRouteIcon}
			// 	/>
			// ) : null,
			// permissions === 'admin' || permissions === 'tour-manager' ? (
			// 	<Resource name="open-buses" list={OpenBusList} edit={OpenBusEdit} icon={OpenBusIcon} />
			// ) : null,
			// permissions === 'admin' || permissions === 'tour-manager' ? (
			// 	<Resource
			// 		options={{ label: 'Open buses' }}
			// 		name="open-bus-folders"
			// 		list={OpenBusFolderList}
			// 		icon={OpenBusFolderIcon}
			// 		edit={OpenBusFolderEdit}
			// 	/>
			// ) : null,
			// permissions === 'admin' || permissions === 'tour-manager' ? <Resource name="open-buses/generate" /> : null,
			// permissions === 'admin' || permissions === 'tour-manager' ? (
			// 	<Resource
			// 		name="tour-guides"
			// 		list={TourGuideList}
			// 		icon={TourGuideIcon}
			// 		edit={TourGuideEdit}
			// 		create={TourGuideCreate}
			// 	/>
			// ) : null,
			// permissions === 'admin' || permissions === 'tour-manager' ? (
			// 	<Resource
			// 		name="service-tags"
			// 		list={ServiceTagList}
			// 		icon={ServiceTagIcon}
			// 		edit={ServiceTagEdit}
			// 		create={ServiceTagCreate}
			// 	/>
			// ) : null,
			// permissions === 'admin' || permissions === 'tour-manager' ? (
			// 	<Resource
			// 		name="service-interests"
			// 		list={ServiceInterestList}
			// 		icon={ServiceInterestIcon}
			// 		edit={ServiceInterestEdit}
			// 		create={ServiceInterestCreate}
			// 	/>
			// ) : null,
			// permissions === 'admin' || permissions === 'tour-manager' ? (
			// 	<Resource
			// 		name="services"
			// 		list={ServiceList}
			// 		icon={ServiceIcon}
			// 		edit={ServiceEdit}
			// 		create={ServiceCreate}
			// 	/>
			// ) : null,
			// permissions === 'admin' ? (
			// 	<Resource
			// 		name="product-reviews"
			// 		list={ProductReviewList}
			// 		icon={ProductReviewIcon}
			// 		edit={ProductReviewEdit}
			// 	/>
			// ) : null,
			// permissions === 'admin' || permissions === 'tour-manager' ? (
			// 	<Resource
			// 		name="product-bookings"
			// 		list={ProductBookingList}
			// 		icon={ProductBookingIcon}
			// 		edit={ProductBookingEdit}
			// 	/>
			// ) : null,
			// permissions === 'admin' || permissions === 'tour-manager' ? (
			// 	<Resource
			// 		name="product-invoices"
			// 		list={InvoiceList}
			// 		icon={InvoiceIcon}
			// 		create={InvoiceCreate}
			// 		edit={InvoiceEdit}
			// 	/>
			// ) : null,
			// permissions === 'admin' || permissions === 'tour-manager' ? (
			// 	<Resource
			// 		name="promotions"
			// 		list={PromotionList}
			// 		icon={PromotionIcon}
			// 		create={PromotionCreate}
			// 		edit={PromotionEdit}
			// 	/>
			// ) : null,
			// permissions === 'admin' || permissions === 'tour-manager' ? (
			// 	<Resource
			// 		options={{ label: 'FWT Bookings' }}
			// 		name="fwt-bookings"
			// 		list={FwtBookingList}
			// 		icon={FwtBookingIcon}
			// 		edit={FwtBookingEdit}
			// 	/>
			// ) : null,

			// <Resource name="comments" list={CommentList} edit={CommentEdit} icon={CommentIcon} />
			<Resource
				name="countries"
				list={permissions === 'admin' ? CountryList : null}
				create={permissions === 'admin' ? CountryCreate : null}
				edit={permissions === 'admin' ? CountryEdit : null}
				icon={CountryIcon}
			/>,
			<Resource
				name="currencies"
				list={permissions === 'admin' ? CurrencyList : null}
				create={permissions === 'admin' ? CurrencyCreate : null}
				edit={permissions === 'admin' ? CurrencyEdit : null}
				icon={CurrencyIcon}
			/>,
			<Resource
				name="careers"
				list={permissions === 'admin' ? CareerList : null}
				create={permissions === 'admin' ? CareerCreate : null}
				edit={permissions === 'admin' ? CareerEdit : null}
				icon={CareerIcon}
			/>,
			<Resource
				name="stories"
				list={permissions === 'admin' ? StoryList : null}
				create={permissions === 'admin' ? StoryCreate : null}
				edit={permissions === 'admin' ? StoryEdit : null}
				icon={StoryIcon}
			/>,
			<Resource
				name="locations"
				list={LocationList}
				create={LocationCreate}
				edit={LocationEdit}
				icon={LocationIcon}
			/>,
			<Resource
				name="users"
				list={permissions === 'admin' ? UserList : null}
				create={permissions === 'admin' ? UserCreate : null}
				edit={permissions === 'admin' ? UserEdit : null}
				icon={UserIcon}
			/>,
			<Resource
				name="media"
				// list={permissions === 'admin' ? MediaList : null}
				create={permissions === 'admin' ? MediaCreate : null}
				edit={permissions === 'admin' ? MediaEdit : null}
				icon={MediaIcon}
			/>,
			<Resource
				name="albums"
				// list={permissions === 'admin' ? AlbumsList : null}
				create={permissions === 'admin' ? AlbumsCreate : null}
				edit={permissions === 'admin' ? AlbumsEdit : null}
				icon={AlbumsIcon}
			/>,
			// permissions === 'admin' ? (
			// 	<Resource
			// 		name="commissions"
			// 		label="Default Commission"
			// 		list={CommissionList}
			// 		icon={CommissionIcon}
			// 		create={CommissionCreate}
			// 		edit={CommissionEdit}
			// 	/>
			// ) : null,
			// permissions === 'admin' ? (
			// 	<Resource
			// 		name="agency-groups"
			// 		options={{ label: 'Agency Group' }}
			// 		list={AgencyGroupList}
			// 		create={AgencyGroupCreate}
			// 		edit={AgencyGroupEdit}
			// 		icon={AgencyGroupIcon}
			// 	/>
			// ) : null,
			// permissions === 'admin' ? (
			// 	<Resource
			// 		name="markets"
			// 		options={{ label: 'Agency Market' }}
			// 		list={MarketList}
			// 		create={MarketCreate}
			// 		edit={MarketEdit}
			// 		icon={MarketIcon}
			// 	/>
			// ) : null,
			// permissions === 'admin' ? (
			// 	<Resource name="footers" list={FooterList} create={FooterCreate} edit={FooterEdit} icon={FooterIcon} />
			// ) : null,
			// permissions === 'admin' ? (
			// 	<Resource name="faqs" list={FaqList} create={FaqCreate} edit={FaqEdit} icon={FaqIcon} />
			// ) : null,
			<Resource name="settings" list={permissions === 'admin' ? Settings : null} />,
			// <Resource name="home-page-settings" list={permissions === 'admin' ? HomePageSettings : null} />,
			permissions === 'admin' ? (
				<Resource
					name="inquiry-tour-questions"
					options={{ label: 'Inquiry Tour Setting' }}
					list={InquiryTourQuestion}
				/>
			) : null,
			permissions === 'admin' ? (
				<Resource name="pages" list={PageList} create={PageCreate} edit={PageEdit} icon={PageIcon} />
			) : null,
			// permissions === 'admin' ? (
			// 	<Resource name="contacts" show={ContactShow} list={ContactList} icon={ContactIcon} />
			// ) : null,
			// permissions === 'admin' ? (
			// 	<Resource name="subscribers" list={SubscriberList} icon={SubscriberIcon} />
			// ) : null,
		]}
	</Admin>,
	document.getElementById('root')
);
