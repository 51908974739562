/* © 2019 NauStud.io
 * @author Eric Tran
 */

import React, { Component } from 'react';
import { Create, SimpleForm, required } from 'react-admin';

import MultiLanguageInput from '../MultiLanguageInput';

class ServiceTagCreate extends Component {
	render() {
		return (
			<Create {...this.props} undoable={false}>
				<SimpleForm redirect="list">
					<MultiLanguageInput source="name" validate={required()} />
				</SimpleForm>
			</Create>
		);
	}
}

export default ServiceTagCreate;
