/* © 2018 NauStud.io
 * @author Hieu Le
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { AutocompleteArrayInput, AutocompleteInput, ReferenceInput, ReferenceArrayInput } from 'react-admin';

class LocationInput extends Component {
	static propTypes = {
		source: PropTypes.string.isRequired,
		label: PropTypes.string,
		validate: PropTypes.func,
		isMultiple: PropTypes.bool,
	};

	static defaultProps = {
		label: 'Location',
		validate: undefined,
		isMultiple: false,
	};

	_renderLocationWithCountry = data => {
		if (data.country && !this.props.isMultiple) {
			return `${data.country.name} - ${data.title.en}`;
		}

		return data.title.en;
	};

	render() {
		const { source, label, validate, isMultiple, ...rest } = this.props;
		let Reference = ReferenceInput;
		let Input = AutocompleteInput;
		if (isMultiple) {
			Reference = ReferenceArrayInput;
			Input = AutocompleteArrayInput;
		}

		return (
			<Reference
				{...rest}
				label={label}
				source={source}
				reference="locations"
				filter={{ include: ['country'] }}
				sort={{ field: 'countryId', order: 'DESC' }}
				validate={validate}
				filterToQuery={searchText => ({ search: searchText, include: ['country'] })}
			>
				<Input optionText={this._renderLocationWithCountry} />
			</Reference>
		);
	}
}

export default LocationInput;
