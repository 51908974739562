/* © 2018 NauStud.io
 * @author Quy Tran
 */

import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
	Datagrid,
	List,
	Responsive,
	SimpleList,
	TextField,
	Filter,
	TextInput,
	EditButton,
	DeleteButton,
} from 'react-admin';
// import { green, amber } from '@material-ui/core/colors';
import { dataGridStyle, noWrapStyle } from '../../helper/constants';

const CountryFilter = props => (
	<Filter {...props}>
		<TextInput label="Search by name" source="name" alwaysOn />
	</Filter>
);

class CountryList extends Component {
	getPrimaryText = record => record.title;

	getTertiaryText = record => new Date(record.publishedAt).toLocaleDateString();

	render() {
		return (
			<List
				{...this.props}
				filters={<CountryFilter />}
				sort={{ field: 'updatedAt', order: 'DESC' }}
				bulkActionButtons={false}
			>
				<Responsive
					small={<SimpleList primaryText={this.getPrimaryText} tertiaryText={this.getTertiaryText} />}
					medium={
						<Datagrid styles={dataGridStyle}>
							<TextField source="id" sortable={false} />
							<TextField source="name" />
							<EditButton />
							<DeleteButton />
						</Datagrid>
					}
				/>
			</List>
		);
	}
}

const styles = {
	// centerField: { textAlign: 'center', display: 'block' },
	// maxWidthField: { maxWidth: '200px', display: 'block' },
	...noWrapStyle,
};

export default withStyles(styles)(CountryList);
