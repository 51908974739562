/* © 2019 NauStud.io
 * @author Eric Tran
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import format from 'date-fns/format';
import { PRODUCT_TYPE_NAME_MAPPING } from '../../helper/constants';

class OpenBusBooking extends Component {
	static propTypes = {
		style: PropTypes.object.isRequired,
		product: PropTypes.object.isRequired,
		itemRenderer: PropTypes.func.isRequired,
		renderMainPassenger: PropTypes.func.isRequired,
	};

	calculateHour = minutes => {
		const hour = Math.floor(minutes / 60);
		const minutesLeft = minutes % 60;
		if (hour) {
			return `${hour} h ${minutesLeft} m`;
		}

		return `${minutes} m`;
	};

	render() {
		const { style, product, itemRenderer, renderMainPassenger } = this.props;

		const openBusUrl = `/#/open-buses/${product.openBus.id}`;
		const totalSeats = product.numberOfTravellers.adult + product.numberOfTravellers.child;

		return (
			<>
				<h5 style={style.header}>{PRODUCT_TYPE_NAME_MAPPING[product.category]}</h5>
				{itemRenderer('Departure', format(new Date(product.departureDate), 'dd/MM/yyyy | HH:mm'))}
				{itemRenderer('Route', <a href={openBusUrl}>{product.openBus.name}</a>)}
				{itemRenderer('Pickup Point', product.openBus.pickupPoint)}
				{itemRenderer('Duration', this.calculateHour(product.openBus.duration))}
				{itemRenderer('Distance', `${product.openBus.distance} km`)}
				{itemRenderer(
					'Travellers',
					`${product.numberOfTravellers.adult} Adult(s), ${product.numberOfTravellers.child} Children, ${product.numberOfTravellers.infant} Infant(s)`,
					`${product.openBus.price} x ${totalSeats} = ${product.openBus.price * totalSeats}`
				)}
				{product.isRoundtrip && (
					<>
						{itemRenderer('Return', format(new Date(product.returnDate), 'dd/MM/yyyy | HH:mm'))}
						{itemRenderer('Route', <a href={openBusUrl}>{product.returnOpenBus.name}</a>)}
						{itemRenderer('Pickup Point', '')}
						{itemRenderer('Duration', this.calculateHour(product.returnOpenBus.duration))}
						{itemRenderer('Distance', `${product.returnOpenBus.distance} km`)}
						{itemRenderer(
							'Travellers',
							`${product.numberOfTravellers.adult} Adult(s), ${product.numberOfTravellers.child} Children, ${product.numberOfTravellers.infant} Infant(s)`,
							`${product.returnOpenBus.price} x ${totalSeats} = ${product.returnOpenBus.price *
								totalSeats}`
						)}
					</>
				)}
				{itemRenderer('Main Passenger', renderMainPassenger(product.mainPassenger))}
			</>
		);
	}
}

export default OpenBusBooking;
