/* © 2018 NauStud.io
 * @author Quy Tran
 */

import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Datagrid, List, TextField, Filter, TextInput, EditButton, DeleteButton, NumberField } from 'react-admin';
import { dataGridStyle, noWrapStyle } from '../../helper/constants';

const CurrencyFilter = props => (
	<Filter {...props}>
		<TextInput label="Search by code" source="code" alwaysOn />
	</Filter>
);

class CurrencyList extends Component {
	getPrimaryText = record => record.title;

	getTertiaryText = record => new Date(record.publishedAt).toLocaleDateString();

	render() {
		return (
			<List {...this.props} filters={<CurrencyFilter />} bulkActionButtons={false}>
				<Datagrid styles={dataGridStyle}>
					<TextField source="code" sortable={false} />
					<TextField source="name" />
					<NumberField source="exchangeRate" label="Exchange Rate (1$ = ?VND)" />
					<EditButton />
					<DeleteButton />
				</Datagrid>
			</List>
		);
	}
}

const styles = {
	...noWrapStyle,
};

export default withStyles(styles)(CurrencyList);
