/* © 2018 NauStud.io
 * @author Quy Tran
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import {
	Create,
	TabbedForm,
	FormTab,
	TextInput,
	SelectInput,
	ReferenceInput,
	AutocompleteInput,
	// ReferenceArrayInput,
	// SelectArrayInput,
	FormDataConsumer,
	required,
} from 'react-admin';

import { slugify, isDuplicateRecord } from '../../helper/utils';
import { listStyle } from '../../helper/constants';
import RichTextEditor from '../RichTextEditor';
import PostImageInput from '../PostImageInput';
import LocationInput from '../LocationInput';
// import ImageWithCropInput from '../ImageWithCropInput';
import storage from '../../helper/storage';

const regexValidSlug = /([^a-zA-Z0-9-])/g;

class HighlightPostCreate extends Component {
	static propTypes = {
		sidebarOpen: PropTypes.bool,
		permissions: PropTypes.string.isRequired,
	};

	static defaultProps = {
		sidebarOpen: false,
	};

	state = {
		isContentEmpty: true,
	};

	authorOptionRender = choice => {
		if (choice.name) {
			return `${choice.name.first} ${choice.name.last}`;
		}

		return 'No name';
	};

	searchName = searchText => ({ name: { regexp: searchText } });
	searchTagName = searchText => ({
		name: { regexp: searchText },
		hidden: false,
	});

	searchUsers = searchText => ({
		or: [{ 'name.first': { regexp: searchText } }, { 'name.last': { regexp: searchText } }],
	});

	checkDuplicateRecord = values => {
		const slug = values.slug ? slugify(values.slug) : '';

		return new Promise((resolve, reject) => {
			if (slug) {
				if (regexValidSlug.test(values.slug)) {
					return reject({
						slug: ['Invalid slug'],
					});
				}

				isDuplicateRecord(slug, 'slug', 'posts')
					.then(res => {
						if (res.data.length > 0) {
							return reject({
								slug: ['Duplicated record'],
							});
						}

						return resolve({});
					})
					.catch(e => {
						console.log(e);
					});
			} else {
				resolve({});
			}

			return 0;
		});
	};

	validateContent = innerHtml => {
		if (innerHtml === '<p><br></p>' || innerHtml === undefined) {
			this.setState({
				isContentEmpty: true,
			});
		} else {
			this.setState({
				isContentEmpty: false,
			});
		}
	};

	checkTypeNotCookBook = record => {
		if (record && record.type !== 'cookbook') {
			return true;
		}

		return false;
	};

	render() {
		const currentUser = storage.load('currentUser') || {};
		const role = this.props.permissions;
		const fullname = currentUser.fullname || '';
		const { isContentEmpty } = this.state;

		return (
			<Create {...this.props} undoable={false}>
				<TabbedForm redirect="list" asyncValidate={this.checkDuplicateRecord} asyncBlurFields={['slug']}>
					<FormTab label="summary">
						<TextInput
							source="slug"
							options={{
								placeholder: 'Leave it blank for auto generation',
							}}
						/>
						<TextInput source="title" validate={required()} />
						<TextInput source="description" validate={required()} />
						{/* <ImageWithCropInput
							source="portraitImage"
							ratio={9 / 16}
							label="Portrait Image"
							dropzone={{
								uniqueId: 'postImagePortrait',
								width: 700,
								height: 200,
							}}
						/> */}
						<PostImageInput
							source="image"
							thumbnailSource="thumbnail"
							label="Image *"
							dropzone={{
								uniqueId: 'postImage',
								width: 700,
								height: 200,
							}}
						/>
						{/* <ReferenceArrayInput
							label="Tags"
							source="tagIds"
							reference="tags"
							allowEmpty
							filter={{ hidden: false }}
							filterToQuery={this.searchTagName}
							validate={required()}
						>
							<SelectArrayInput
								optionText="name"
								options={{
									listStyle,
								}}
							/>
						</ReferenceArrayInput> */}
						<FormDataConsumer>
							{({ formData, ...rest }) =>
								formData.type === 'show' && (
									<TextInput
										source="videoUrl"
										label="Video URL (Youtube)"
										validate={required()}
										{...rest}
									/>
								)
							}
						</FormDataConsumer>

						<FormDataConsumer>
							{({ formData, ...rest }) => (
								<LocationInput label="Location" source="locationId" validate={required()} {...rest} />
							)}
						</FormDataConsumer>
						{role !== 'author' && (
							<ReferenceInput
								label="Author (Leave it blank for current user)"
								source="authorId"
								reference="users"
								allowEmpty
								filterToQuery={this.searchUsers}
							>
								<AutocompleteInput
									optionText={this.authorOptionRender}
									options={{
										searchText: fullname,
										floatingLabelStyle: { whiteSpace: 'nowrap' },
										listStyle,
									}}
								/>
							</ReferenceInput>
						)}
						<SelectInput
							source="language"
							choices={[
								{ id: 'vi', name: 'Vietnamese' },
								{ id: 'en', name: 'English' },
							]}
							validate={required()}
						/>
					</FormTab>
					<FormTab label="Content *">
						{isContentEmpty && (
							<TextInput
								source="textCloned"
								validate={required()}
								style={{
									display: 'none',
								}}
							/>
						)}
						<RichTextEditor
							isCreate
							source="text"
							validation={{ required: true }}
							sidebarOpen={this.props.sidebarOpen}
							validate={this.validateContent}
						/>
					</FormTab>
					<FormTab label="miscellaneous">
						<TextInput source="metaTitle" label="Meta Title" />
						<TextInput source="metaDescription" label="Meta Description" />
					</FormTab>
				</TabbedForm>
			</Create>
		);
	}
}

const mapStateToProps = state => ({
	sidebarOpen: state.admin.ui.sidebarOpen,
});

export default compose(connect(mapStateToProps))(HighlightPostCreate);
