/* © 2019 NauStud.io
 * @author Eric Tran
 */

import React, { Component } from 'react';
import addYears from 'date-fns/addYears';
import {
	Create,
	TabbedForm,
	FormTab,
	TextInput,
	LongTextInput,
	BooleanInput,
	SelectInput,
	NumberInput,
	FormDataConsumer,
	ArrayInput,
	SimpleFormIterator,
	ReferenceInput,
	required,
	minValue,
	maxValue,
} from 'react-admin';

import { DateInput } from 'react-admin-date-inputs';
import LocationInput from '../LocationInput';
import ProductCostTable from '../ProductCostTable';

class OpenBusRouteCreate extends Component {
	render() {
		return (
			<Create {...this.props} undoable={false}>
				<TabbedForm redirect="list">
					<FormTab label="General">
						<TextInput source="name" label="Name" validate={required()} />
						<LongTextInput source="description" label="Description" />
						<LongTextInput source="pickupPoint" label="Pickup Point Address" />
						<LocationInput source="fromLocationId" label="From City" validate={required()} />
						<LocationInput source="toLocationId" label="To City" validate={required()} />
						<NumberInput source="distance" label="Distance (KM)" validate={required()} />
						<NumberInput source="duration" label="Duration (Minutes)" validate={required()} />
						<BooleanInput source="active" label="Active" defaultValue />
					</FormTab>
					<FormTab label="Cost Information">
						<DateInput
							source="supplyTerm.from"
							label="Supply Term (From)"
							validate={required()}
							options={{ format: 'dd/MM/yyyy' }}
							defaultValue={new Date()}
						/>
						<DateInput
							source="supplyTerm.to"
							label="Supply Term (To)"
							validate={required()}
							options={{ format: 'dd/MM/yyyy' }}
							defaultValue={addYears(new Date(), 1)}
						/>
						<ArrayInput source="costInfo" label="Cost Information Array">
							<SimpleFormIterator>
								<ReferenceInput source="carTypeId" label="Car Type" reference="car-types">
									<SelectInput optionText="name.en" />
								</ReferenceInput>
								<NumberInput source="cost" label="Cost (VND)" />
							</SimpleFormIterator>
						</ArrayInput>
						<SelectInput
							source="markupType"
							defaultValue="fix"
							choices={[{ id: 'fix', name: 'Fix' }, { id: 'percentage', name: '%' }]}
						/>
						<FormDataConsumer>
							{({ formData }) => {
								if (formData.markupType === 'percentage') {
									return (
										<NumberInput
											source="markupAmount"
											inputProps={{ min: 0, max: 100 }}
											validate={[minValue(0), maxValue(100)]}
											defaultValue={0}
										/>
									);
								}

								return (
									<NumberInput
										source="markupAmount"
										inputProps={{ min: 0 }}
										validate={minValue(0)}
										defaultValue={0}
									/>
								);
							}}
						</FormDataConsumer>
						<BooleanInput source="fillTheTable" label="Fill the table" />
						<FormDataConsumer>
							{({ formData }) => {
								if (formData.fillTheTable) {
									return <ProductCostTable formData={formData} />;
								}

								return '';
							}}
						</FormDataConsumer>
					</FormTab>
				</TabbedForm>
			</Create>
		);
	}
}

export default OpenBusRouteCreate;
