/* © 2018 NauStud.io
 * @author Eric Tran
 */

const storage = {
	save: (key, value) => {
		if (typeof Storage === 'undefined') {
			return false;
		}
		const record = { value };
		localStorage.setItem(key, JSON.stringify(record));

		return value;
	},
	load: key => {
		if (typeof Storage === 'undefined') {
			return false;
		}
		try {
			const record = JSON.parse(localStorage.getItem(key));
			if (!record) {
				return false;
			}

			return record.value;
		} catch (e) {
			return false;
		}
	},
	remove: key => {
		if (typeof Storage === 'undefined') {
			return false;
		}
		localStorage.removeItem(key);

		return true;
	},
};

export default storage;
