/* © 2019 NauStud.io
 * @author Eric Tran
 */

import React from 'react';
import PropTypes from 'prop-types';
import fm from 'date-fns/format';

const ListDateField = ({ record = {}, source, format }) => {
	if (!record[source]) {
		return '';
	}

	let date = new Date(record[source]);
	date = fm(date, format);

	return <span>{date}</span>;
};

ListDateField.propTypes = {
	source: PropTypes.string,
	format: PropTypes.string,
	record: PropTypes.shape({
		updatedAt: PropTypes.instanceOf(Date),
	}),
};

ListDateField.defaultProps = {
	record: null,
	source: 'date',
	format: 'dd/MM/yyyy',
};

export default ListDateField;
