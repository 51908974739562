/* © 2018 NauStud.io
 * @author Thanh
 */
export const messages = {
	common: {
		settings: 'Cài Đặt',
		profile: 'Tài khoản',
		logout: 'Đăng xuất'
	},
	login: {
		email: 'Email',
		otpToken: 'Mật mã OTP',
		emailPassword: {
			failed: 'Nhập sai email hoặc mật khẩu.'
		}
	},
	dashboard: {
		welcome: {
			title: 'Chào mừng đến với trang Quản Lý Vietravel Inbound',
			subtitle: 'Đây là nơi Admin, Editor hoặc Author có thể điều khiển mọi thứ trong Vietravel Inbound',
		},
		draft: 'Bài blog nháp',
		postSummary: {
			draft: 'Tổng bài blog nháp',
			published: 'Tổng bài blog đã đăng',
			post: 'Tổng bài blog',
		},
		mediaSummary: {
			media: 'Phương tiện Truyền thông',
			mediaAlbum: 'Album',
		},
		userSummary: {
			user: 'Người dùng',
			newUser: 'Người dùng mới',
		},
	},
};

export default messages;
