/* © 2018 NauStud.io
 * @author Eric Tran
 */

import React, { Component } from 'react';
import { Create, TabbedForm, FormTab, TextInput, required } from 'react-admin';
import { slugify, isDuplicateRecord } from '../../helper/utils';

import MultiLanguageInput from '../MultiLanguageInput';
import RichTextEditor from '../RichTextEditor';

const regexValidSlug = /([^a-zA-Z0-9-])/g;

class PageCreate extends Component {
	componentWillMount() {
		this.availableLanguages = JSON.parse(localStorage.getItem('languages') || []);
	}

	checkDuplicateRecord = values => {
		const slug = values.slug ? slugify(values.slug) : '';

		return new Promise((resolve, reject) => {
			if (slug) {
				if (regexValidSlug.test(values.slug)) {
					return reject({
						slug: ['Invalid slug'],
					});
				}

				isDuplicateRecord(slug, 'slug', 'pages')
					.then(res => {
						if (res.data.length > 0) {
							return reject({
								slug: ['Duplicated record'],
							});
						}

						return resolve({});
					})
					.catch(e => {
						console.log(e);
					});
			} else {
				resolve({});
			}

			return 0;
		});
	};

	render() {
		return (
			<Create title="Static Page Create" {...this.props} undoable={false}>
				<TabbedForm redirect="list" asyncValidate={this.checkDuplicateRecord} asyncBlurFields={['slug']}>
					<FormTab label="summary">
						<TextInput source="slug" validate={required()} label="Url" />
						<MultiLanguageInput source="title" label="Title" validate={required()} />
					</FormTab>
					{this.availableLanguages.map(lang => (
						<FormTab label={lang.name}>
							<RichTextEditor
								source={`text.${lang.code}`}
								toolbarOptions={[
									[{ header: [2, 3, 4, false] }],
									['bold', 'italic', 'underline'],
									[{ list: 'bullet' }],
									[{ indent: '-1' }, { indent: '+1' }],
									[{ align: [] }],
									[{ color: [] }, { background: [] }],
									['clean'], // remove formatting button
									['link'],
								]}
							/>
						</FormTab>
					))}
					<FormTab label="miscellaneous">
						<TextInput source="metaTitle" label="Meta Title" />
						<TextInput source="metaDescription" label="Meta Description" />
					</FormTab>
				</TabbedForm>
			</Create>
		);
	}
}

export default PageCreate;
