/* © 2019 NauStud.io
 * @author Eric Tran
 */

import React, { Component } from 'react';
import {
	Edit,
	TextInput,
	TabbedForm,
	FormTab,
	GET_LIST,
	Toolbar,
	SaveButton,
	ArrayInput,
	SimpleFormIterator,
	FormDataConsumer,
} from 'react-admin';

import restClient from '../../helper/restClient';
import ImageWithCropInput from '../../components/ImageWithCropInput';
import SearchTourInput from '../../components/SearchTourInput';
import SearchActivityInput from '../../components/SearchActivityInput';

const EditToolbar = props => (
	<Toolbar {...props}>
		<SaveButton />
	</Toolbar>
);

class HomePageSetting extends Component {
	state = {
		settingId: '',
	};

	componentDidMount() {
		restClient(GET_LIST, 'home-page-settings', {
			pagination: {
				perPage: 1,
			},
			sort: {},
			filter: {},
		})
			.then(rs => {
				if (rs.data && rs.data.length) {
					this.setState({ settingId: rs.data[0].id });
				}
			})
			.catch(err => {
				console.log(err);
			});
	}

	render() {
		const { settingId } = this.state;

		if (!settingId) {
			return <div />;
		}
		const match = {
			params: { id: settingId },
			url: `/home-page-settings/${settingId}`,
			isExact: true,
		};

		return (
			<div>
				<Edit
					undoable={false}
					{...this.props}
					resource="home-page-settings"
					match={match}
					id={settingId}
					title="Home Page Setting"
					actions={null}
				>
					<TabbedForm toolbar={<EditToolbar />}>
						<FormTab label="Our Destinations">
							<TextInput source="destinationTitle1" label="Promotion Block Title 1" />
							<ImageWithCropInput
								source="destinationImage1"
								label="Promotion Block Image 1"
								ratio={534 / 321}
								dropzone={{ width: 534, height: 321 }}
							/>
							<TextInput source="destinationUrl1" label="Promotion Block Url 1" />
							<TextInput source="destinationTitle2" label="Promotion Block Title 2" />
							<ImageWithCropInput
								source="destinationImage2"
								label="Promotion Block Image 2"
								ratio={534 / 321}
								dropzone={{ width: 534, height: 321 }}
							/>
							<TextInput source="destinationUrl2" label="Promotioni Block Url 2" />
						</FormTab>
						<FormTab label="Hot deal">
							<FormDataConsumer>
								{({ formData }) => (
									<ArrayInput source="hotDealTourIds">
										<SimpleFormIterator
											disableAdd={formData.hotDealTourIds && formData.hotDealTourIds.length >= 4}
										>
											<SearchTourInput hasTrip />
										</SimpleFormIterator>
									</ArrayInput>
								)}
							</FormDataConsumer>
						</FormTab>
						<FormTab label="Popular Activities">
							<FormDataConsumer>
								{({ formData }) => (
									<ArrayInput source="popularServiceIds">
										<SimpleFormIterator
											disableAdd={
												formData.popularServiceIds && formData.popularServiceIds.length >= 4
											}
										>
											<SearchActivityInput />
										</SimpleFormIterator>
									</ArrayInput>
								)}
							</FormDataConsumer>
						</FormTab>
						<FormTab label="Popular Tours">
							<FormDataConsumer>
								{({ formData }) => (
									<ArrayInput source="popularTourIds">
										<SimpleFormIterator
											disableAdd={formData.popularTourIds && formData.popularTourIds.length >= 4}
										>
											<SearchTourInput hasTrip />
										</SimpleFormIterator>
									</ArrayInput>
								)}
							</FormDataConsumer>
						</FormTab>
						<FormTab label="Trending">
							<FormDataConsumer>
								{({ formData }) => (
									<ArrayInput source="trendingTourIds">
										<SimpleFormIterator
											disableAdd={
												formData.trendingTourIds && formData.trendingTourIds.length >= 4
											}
										>
											<SearchTourInput hasTrip />
										</SimpleFormIterator>
									</ArrayInput>
								)}
							</FormDataConsumer>
						</FormTab>
					</TabbedForm>
				</Edit>
			</div>
		);
	}
}

export default HomePageSetting;
