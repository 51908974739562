/* © 2018 NauStud.io
 * @author Eric Tran
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import format from 'date-fns/format';
import { withStyles } from '@material-ui/core/styles';
import { Datagrid, List, TextField, ShowButton } from 'react-admin';
import { dataGridStyle } from '../../helper/constants';

const CustomDateField = ({ record = {}, source }) => {
	let date = record[source] ? new Date(record[source]) : '';

	date = format(date, 'dd/MM/yyyy');

	return <span>{date}</span>;
};

CustomDateField.propTypes = {
	record: PropTypes.shape({
		departureDate: PropTypes.instanceOf(Date),
	}),
	source: PropTypes.string,
};

CustomDateField.defaultProps = { record: null, source: '' };

class ContactList extends Component {
	static propTypes = {
		classes: PropTypes.shape({}),
	};

	static defaultProps = {
		classes: {},
	};

	render() {
		const { classes } = this.props;

		return (
			<List {...this.props} bulkActionButtons={false} sort={{ field: 'createdAt', order: 'DESC' }}>
				<Datagrid classes={classes}>
					<TextField source="name" />
					<TextField source="email" />
					<TextField source="subject" />
					<CustomDateField source="createdAt" />
					<ShowButton />
				</Datagrid>
			</List>
		);
	}
}

const styles = {
	validField: { textAlign: 'left', marginLeft: '10px' },
	dataGridStyle,
};

export default withStyles(styles)(ContactList);
