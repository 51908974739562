/* © 2018 NauStud.io
 * @author Eric Tran
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import {
	Create,
	TabbedForm,
	FormTab,
	TextInput,
	ArrayInput,
	NumberInput,
	BooleanInput,
	SimpleFormIterator,
	SelectInput,
	ReferenceArrayInput,
	SelectArrayInput,
	FormDataConsumer,
	required,
	LongTextInput,
	AutocompleteArrayInput,
	CheckboxGroupInput,
} from 'react-admin';
import { DateInput } from 'react-admin-date-inputs';

import { slugify, isDuplicateRecord } from '../../helper/utils';
import ImageWithCropInput from '../ImageWithCropInput';
import ImageWithCropListInput from '../ImageWithCropListInput';
import MultiLanguageInput from '../MultiLanguageInput';
import MultiLanguageRichTextInput from '../MultiLanguageRichTextInput';
import LocationInput from '../LocationInput';

const regexValidSlug = /([^a-zA-Z0-9-])/g;

class TourCreate extends Component {
	static propTypes = {
		sidebarOpen: PropTypes.bool,
	};

	static defaultProps = {
		sidebarOpen: false,
	};

	state = {
		isContentEmpty: true,
	};

	validateMaxValue = (val, formData) => {
		const prices = formData.basePrice || [];
		for (let i = 0; i < prices.length; i++) {
			const item = prices[i];
			const ranges = item.ranges || [];

			const maxValueRanges = ranges.map(range => range.max);
			let lastValue = 0;

			for (let index = 0; index < maxValueRanges.length; index++) {
				const value = maxValueRanges[index];
				if (!value) {
					return 'Các trường giá trị max đều bắt buộc';
				}
				if (value <= lastValue) {
					return 'Các giá trị max phải theo thứ tự từ nhỏ đến lớn';
				}
				lastValue = value;
			}
		}

		return undefined;
	};

	checkDuplicateRecord = values => {
		const slug = values.slug ? slugify(values.slug) : '';

		return new Promise((resolve, reject) => {
			const promises = [];
			if (slug) {
				if (regexValidSlug.test(values.slug)) {
					reject({
						slug: ['Invalid slug'],
					});

					return;
				}

				const checkSlug = isDuplicateRecord(slug, 'slug', 'tours')
					.then(res => {
						if (res.data.length > 0) {
							return {
								slug: ['Duplicated record'],
							};
						}

						return {};
					})
					.catch(e => {
						console.log(e);

						return { slug: [e.message] };
					});

				promises.push(checkSlug);
			}

			Promise.all(promises).then(rs => {
				const result = rs.reduce((prev, curr) => ({ ...prev, ...curr }), {});
				if (Object.keys(result).length > 0) {
					reject(result);
				} else {
					resolve({});
				}
			});
		});
	};

	searchLocationName = searchText => ({
		title: { regexp: searchText },
		// hidden: false,
	});

	render() {
		return (
			<Create undoable={false} {...this.props}>
				<TabbedForm redirect="list" asyncValidate={this.checkDuplicateRecord} asyncBlurFields={['slug']}>
					<FormTab label="summary">
						<TextInput source="vtTourId" label="Vietravel Tour Code" validate={required()} />
						<MultiLanguageInput source="title" label="Title" validate={required()} />
						<TextInput
							source="slug"
							options={{
								placeholder: 'Leave it blank for auto generation',
							}}
						/>
						<SelectInput
							source="type"
							choices={[{ id: 'guarantee', name: 'Guarantee tour' }, { id: 'fixed', name: 'Fixed tour' }]}
							validate={required()}
						/>
						<FormDataConsumer>
							{({ formData }) =>
								formData.type === 'fixed' && (
									<BooleanInput
										source="fixedTourInquiryOnly"
										label="Is this fixed tour still need confirmation? (not allow user to pay)"
									/>
								)
							}
						</FormDataConsumer>
						<ReferenceArrayInput
							label="Travel Styles"
							source="travelStyleIds"
							reference="travel-styles"
							validate={required()}
						>
							<SelectArrayInput optionText="name" />
						</ReferenceArrayInput>
						<NumberInput source="duration" validate={required()} />
						<LongTextInput source="noteForOperator" label="Note for Operator" />
						<SelectInput
							source="language"
							choices={[{ id: 'vi', name: 'Vietnamese' }, { id: 'en', name: 'English' }]}
							validate={required()}
						/>
					</FormTab>
					<FormTab label="Locations">
						<ArrayInput source="locationIds" validate={required()}>
							<SimpleFormIterator>
								<LocationInput label="Location" validate={required()} />
							</SimpleFormIterator>
						</ArrayInput>
					</FormTab>
					<FormTab label="Images">
						<ImageWithCropInput
							source="thumbnail"
							ratio={1}
							label="Thumbnail"
							dropzone={{
								uniqueId: 'thumbnailImage',
								width: 300,
								height: 300,
							}}
						/>
						<ArrayInput source="images">
							<SimpleFormIterator>
								<ImageWithCropListInput
									label="Image"
									ratio={1170 / 480}
									dropzone={{
										uniqueId: 'imageList',
										width: 350,
										height: 196,
									}}
								/>
							</SimpleFormIterator>
						</ArrayInput>
					</FormTab>
					<FormTab label="Highlight">
						<ArrayInput source="highlightList">
							<SimpleFormIterator>
								<MultiLanguageInput label="Highlight list item" />
							</SimpleFormIterator>
						</ArrayInput>
						<ImageWithCropInput
							source="highlightImage"
							ratio={650 / 400}
							label="Highlight map image"
							dropzone={{
								uniqueId: 'highlightImage',
								width: 325,
								height: 200,
							}}
						/>
						<MultiLanguageInput source="highlightAgeRange" label="Highlight Age Range" />
						<MultiLanguageInput source="highlightMaxGroupSize" label="Highlight Max Group Size" />
						<MultiLanguageInput source="highlightTravelStyle" label="Highlight Travel Style" />
						<MultiLanguageInput source="highlightAccommodation" label="Highlight Accommodation" />
						<MultiLanguageInput source="highlightTransportation" label="Highlight Transportation" />
						<TextInput source="highlightLanguages" label="Highlight Languages" />
						<MultiLanguageInput source="highlightDescription" />
					</FormTab>
					<FormTab label="Itinerary">
						<MultiLanguageInput source="itineraryIntroduction" />
						<TextInput source="itineraryDownloadLink" />
						<ArrayInput source="itinerary">
							<SimpleFormIterator>
								<MultiLanguageInput source="title" />
								<MultiLanguageRichTextInput source="description" />
								<ImageWithCropInput source="image" ratio={16 / 9} />
							</SimpleFormIterator>
						</ArrayInput>
						<MultiLanguageInput source="travelGuideDescription" />
						<ImageWithCropInput
							source="travelGuideImage"
							ratio={1.6}
							label="Travel guide image"
							dropzone={{
								uniqueId: 'travelGuideImage',
								width: 300,
								height: 187,
							}}
						/>
						<MultiLanguageInput source="travelGuideSmallText" />
						<TextInput source="travelGuideLink" />
					</FormTab>
					<FormTab label="What's included">
						<ArrayInput source="included" label="Included list">
							<SimpleFormIterator>
								<MultiLanguageInput label="Included list item" />
							</SimpleFormIterator>
						</ArrayInput>
						<ArrayInput source="notIncluded" label="Not included list">
							<SimpleFormIterator>
								<MultiLanguageInput label="Not included list item" />
							</SimpleFormIterator>
						</ArrayInput>
					</FormTab>
					<FormTab label="Video">
						<TextInput source="video" label="Youtube video url" />
					</FormTab>
					<FormTab label="miscellaneous">
						<MultiLanguageInput source="metaTitle" label="Meta Title" />
						<MultiLanguageInput source="metaDescription" label="Meta Description" />
					</FormTab>
					<FormTab label="settings">
						<NumberInput source="babyAge" label="Baby Age (<)" defaultValue={2} validate={required()} />
						<NumberInput
							source="childrenAge"
							label="Children Age (<)"
							defaultValue={12}
							validate={required()}
						/>
						<NumberInput
							source="babyPricePercent"
							label="Baby price percent (%)"
							defaultValue={0}
							validate={required()}
						/>
						<NumberInput
							source="childrenPricePercent"
							label="Children price percent (%)"
							validate={required()}
						/>
						<FormDataConsumer>
							{({ formData }) =>
								formData.type === 'guarantee' && (
									<ArrayInput source="additionalFees" label="Additional Fees">
										<SimpleFormIterator>
											<MultiLanguageInput source="name" label="Name" validate={required()} />
											<NumberInput
												source="price"
												label="Price per person"
												validate={required()}
											/>
										</SimpleFormIterator>
									</ArrayInput>
								)
							}
						</FormDataConsumer>
						<FormDataConsumer>
							{({ formData }) =>
								formData.type === 'fixed' && (
									<ArrayInput style={{ width: '50%' }} source="availableDays" label="Available days">
										<SimpleFormIterator>
											<DateInput label="From" source="from" options={{ format: 'dd/MM/yyyy' }} />
											<DateInput label="To" source="to" options={{ format: 'dd/MM/yyyy' }} />
											<CheckboxGroupInput
												source="weekdays"
												label="Available Weekdays"
												defaultValue={[0, 1, 2, 3, 4, 5, 6]}
												choices={[
													{ id: '1', name: 'Monday' },
													{ id: '2', name: 'Tuesday' },
													{ id: '3', name: 'Wednesday' },
													{ id: '4', name: 'Thursday' },
													{ id: '5', name: 'Friday' },
													{ id: '6', name: 'Saturday' },
													{ id: '0', name: 'Sunday' },
												]}
											/>
										</SimpleFormIterator>
									</ArrayInput>
								)
							}
						</FormDataConsumer>
						<FormDataConsumer>
							{({ formData }) =>
								formData.type === 'fixed' && (
									<ReferenceArrayInput
										source="productIds"
										label="Additional Products"
										reference="products"
										filterToQuery={searchText => ({
											name: { like: searchText },
										})}
										allowEmpty
									>
										<AutocompleteArrayInput optionText="name.en" />
									</ReferenceArrayInput>
								)
							}
						</FormDataConsumer>
					</FormTab>
					<FormTab label="Fixed tour price">
						<FormDataConsumer>
							{({ formData }) =>
								formData.type === 'fixed' && (
									<NumberInput
										source="fixedTourPrice"
										label="Fixed tour price (for display)"
										validate={required()}
									/>
								)
							}
						</FormDataConsumer>
						<FormDataConsumer>
							{({ formData }) =>
								formData.type === 'fixed' && (
									<ArrayInput style={{ width: '50%' }} source="basePrice" label="Base Price">
										<SimpleFormIterator>
											<MultiLanguageInput source="name" label="Name" validate={required()} />
											<ArrayInput source="ranges" label="Range">
												<SimpleFormIterator>
													<NumberInput
														source="max"
														label="Range Max Guests Number"
														validate={this.validateMaxValue}
													/>
													<NumberInput source="price" label="Price" />
												</SimpleFormIterator>
											</ArrayInput>
										</SimpleFormIterator>
									</ArrayInput>
								)
							}
						</FormDataConsumer>
					</FormTab>
				</TabbedForm>
			</Create>
		);
	}
}

const mapStateToProps = state => ({
	sidebarOpen: state.admin.ui.sidebarOpen,
});

export default compose(connect(mapStateToProps))(TourCreate);
