/* © 2019 NauStud.io
 * @author Eric Tran
 */

import React, { Component } from 'react';
import {
	Edit,
	TabbedForm,
	FormTab,
	TextInput,
	LongTextInput,
	BooleanInput,
	SelectInput,
	NumberInput,
	ArrayInput,
	SimpleFormIterator,
	ReferenceInput,
	required,
} from 'react-admin';

import { DateTimeInput } from 'react-admin-date-inputs';

class OpenBusEdit extends Component {
	render() {
		return (
			<Edit {...this.props} undoable={false}>
				<TabbedForm redirect="list">
					<FormTab label="General">
						<TextInput source="name" label="Name" validate={required()} />
						<LongTextInput source="description" label="Description" />
						<NumberInput source="release" label="Release" />
						<DateTimeInput
							source="departureDateTime"
							label="Departure Date"
							options={{ format: 'dd/MM/yyyy HH:mm' }}
						/>
						<ArrayInput source="routes" label="Routes">
							<SimpleFormIterator>
								<ReferenceInput source="id" label="Open Bus Route Id" reference="open-bus-routes">
									<SelectInput optionText="name" />
								</ReferenceInput>
								<DateTimeInput
									source="departureDateTime"
									label="Departure Date"
									options={{ format: 'dd/MM/yyyy HH:mm', disabled: true }}
								/>
								<NumberInput source="stopTime" label="Stop Time (Minutes)" />
							</SimpleFormIterator>
						</ArrayInput>
						<BooleanInput source="active" label="Active" />
					</FormTab>
				</TabbedForm>
			</Edit>
		);
	}
}

export default OpenBusEdit;
