/* © 2019 NauStud.io
 * @author Eric Tran
 */

import React, { Component } from 'react';
import { Edit, SimpleForm, required } from 'react-admin';

import MultiLanguageInput from '../MultiLanguageInput';

class ServiceTagEdit extends Component {
	render() {
		return (
			<Edit {...this.props} undoable={false}>
				<SimpleForm redirect="list">
					<MultiLanguageInput source="name" validate={required()} />
				</SimpleForm>
			</Edit>
		);
	}
}

export default ServiceTagEdit;
