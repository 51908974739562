/* © 2018 NauStud.io
 * @author Hai Luu
 */

import React, { Component } from 'react';
import {
	Edit,
	TextInput,
	SelectInput,
	LongTextInput,
	FormDataConsumer,
	ReferenceInput,
	NumberInput,
	TabbedForm,
	FormTab,
	required,
	ArrayInput,
	SimpleFormIterator,
} from 'react-admin';
import { DateInput } from 'react-admin-date-inputs';

import ImageWithCropInput from '../ImageWithCropInput';
import storage from '../../helper/storage';

class UserEdit extends Component {
	render() {
		const role = storage.load('currentUser').role;
		const isAdmin = role === 'admin';

		// <ImageInput
		// 	source="cover"
		// 	label="Cover"
		// 	dropzone={{
		// 		uniqueId: 'userAvatarCover',
		// 		width: 700,
		// 		height: 200,
		// 	}}
		// />

		return (
			<Edit {...this.props} undoable={false}>
				<TabbedForm>
					<FormTab label="General Info">
						<TextInput source="id" label="id" options={{ disabled: true }} />
						<ImageWithCropInput
							source="image"
							label="Avatar"
							ratio={1}
							dropzone={{
								uniqueId: 'userAvatar',
								width: 700,
								height: 200,
							}}
						/>
						<TextInput source="name.first" label="First name" />
						<TextInput source="name.last" label="Last name" />
						<TextInput options={{ disabled: true }} source="username" label="Username" />
						<TextInput options={{ disabled: true }} source="email" label="Email" />
						<LongTextInput source="bio" />
						<TextInput source="website" label="Website" />
						<SelectInput
							source="language"
							choices={[{ id: 'vi_VN', name: 'Vietnamese' }, { id: 'en_US', name: 'English' }]}
						/>
						{isAdmin ? (
							<SelectInput
								source="role"
								choices={[
									{ id: 'user', name: 'User' },
									{ id: 'editor', name: 'Editor' },
									{ id: 'author', name: 'Author' },
									{ id: 'admin', name: 'Admin' },
									{ id: 'agency', name: 'Agency' },
									{ id: 'tour-manager', name: 'Tour Manager' },
								]}
							/>
						) : null}
						{isAdmin ? (
							<TextInput source="password" label="Password (Optional)" options={{ type: 'password' }} />
						) : null}
					</FormTab>
					{isAdmin ? (
						<FormTab label="Agency Info">
							<FormDataConsumer>
								{({ formData }) =>
									formData.role === 'agency' && (
										<ReferenceInput
											label="Agency Group"
											source="agencyGroupId"
											reference="agency-groups"
											validate={required()}
										>
											<SelectInput optionText="name" />
										</ReferenceInput>
									)
								}
							</FormDataConsumer>
							<FormDataConsumer>
								{({ formData }) =>
									formData.role === 'agency' && (
										<ReferenceInput label="Market" source="marketId" reference="markets">
											<SelectInput optionText="name" />
										</ReferenceInput>
									)
								}
							</FormDataConsumer>
							<FormDataConsumer>
								{({ formData }) =>
									formData.role === 'agency' && (
										<NumberInput source="availableCredit" validate={required()} />
									)
								}
							</FormDataConsumer>
							<ArrayInput source="creditChangeHistory" label="Credit Change History">
								<SimpleFormIterator disableRemove disableAdd>
									<ReferenceInput
										source="userId"
										label="Action by User"
										reference="users"
										options={{ disabled: true }}
									>
										<SelectInput optionText="fullname" />
									</ReferenceInput>
									<DateInput
										source="createdAt"
										label="Date"
										options={{ disabled: true, format: 'dd/MM/yyyy HH:mm' }}
									/>
									<NumberInput source="newValue" label="New Value" options={{ disabled: true }} />
									<NumberInput source="newValue" label="New Value" options={{ disabled: true }} />
								</SimpleFormIterator>
							</ArrayInput>
						</FormTab>
					) : (
						''
					)}
				</TabbedForm>
			</Edit>
		);
	}
}

export default UserEdit;
