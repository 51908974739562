/* © 2018 NauStud.io
 * @author Eric Tran
 */

import React, { Component } from 'react';
import { Create, LongTextInput, SimpleForm, TextInput, minLength, required } from 'react-admin';

import ImageInput from '../ImageInput';

class PostCategoryCreate extends Component {
	render() {
		return (
			<Create {...this.props} undoable={false}>
				<SimpleForm>
					<TextInput validate={required()} source="id" />
					<TextInput source="name" />
					<LongTextInput source="description" validate={minLength(10)} />
					<ImageInput
						source="image"
						dropzone={{
							uniqueId: 'categoryEditDrop',
							width: 1400,
							height: 450,
						}}
					/>
					<TextInput source="metaTitle" label="Meta-title" />
					<TextInput source="metaDescription" label="Meta-description" />
				</SimpleForm>
			</Create>
		);
	}
}

export default PostCategoryCreate;
