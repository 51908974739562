/* © 2018 NauStud.io
 * @author Eric Tran
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import format from 'date-fns/format';
import differenceInDays from 'date-fns/differenceInDays';
import formatDistance from 'date-fns/formatDistance';

import CustomEditButton from '../CustomEditButton';

const styles = {
	table: {
		margin: 0,
		borderBottom: 0,
	},
	tableHeader: {
		border: 0,
	},
	tableHeaderRow: {
		border: 0,
	},
	headerDraftListItem: {
		textTransform: 'uppercase',
		color: 'rgba(0, 0, 0, 0.87)',
		verticalAlign: 'middle',
		fontSize: '14px',
		borderBottom: 0,
	},
};

class DraftList extends Component {
	static propTypes = {
		data: PropTypes.arrayOf(
			PropTypes.shape({
				thumbnail: PropTypes.string,
				title: PropTypes.string,
				computedAuthor: PropTypes.object,
				updatedAt: PropTypes.string,
			})
		),
		history: PropTypes.shape({
			push: PropTypes.func,
		}).isRequired,
	};

	static defaultProps = {
		data: [],
	};

	handleTitleClick = e => {
		const id = e.currentTarget.dataset.id;
		this.props.history.push(`/posts/${id}`);
	};

	render() {
		return (
			<Table style={styles.table}>
				<TableHead displaySelectAll={false} adjustForCheckbox={false} style={styles.tableHeader}>
					<TableRow style={styles.tableHeaderRow}>
						<TableCell style={styles.headerDraftListItem}>Thumbnail</TableCell>
						<TableCell style={styles.headerDraftListItem}>Title</TableCell>
						<TableCell style={styles.headerDraftListItem}>Author</TableCell>
						<TableCell style={styles.headerDraftListItem}>Last Edit</TableCell>
						<TableCell style={styles.headerDraftListItem}>Actions</TableCell>
					</TableRow>
				</TableHead>
				<TableBody displayRowCheckbox={false}>
					{this.props.data
						.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt))
						.map(record => {
							let lastEdit = record.updatedAt ? new Date(record.updatedAt) : '';
							if (lastEdit) {
								if (differenceInDays(new Date(), lastEdit) >= 10) {
									lastEdit = format(lastEdit, 'dd/MM/yyyy');
								} else {
									lastEdit = `${formatDistance(lastEdit, new Date())} ago`;
								}
							}

							return (
								<TableRow key={record.id}>
									<TableCell>
										<img src={record.thumbnail} alt={record.thumbnail} style={{ maxWidth: '120px' }} />
									</TableCell>
									<TableCell style={{ textOverflow: 'wrap', whiteSpace: 'normal' }}>{record.title}</TableCell>
									<TableCell>{record.computedAuthor ? record.computedAuthor.fullname : ''}</TableCell>
									<TableCell>{lastEdit}</TableCell>
									<TableCell>
										<CustomEditButton record={record} basePath="posts" />
									</TableCell>
								</TableRow>
							);
						})}
				</TableBody>
			</Table>
		);
	}
}

export default DraftList;
