/* © 2018 NauStud.io
 * @author Eric Tran
 */

import React, { Component } from 'react';
import {
	Datagrid,
	EditButton,
	// DeleteButton,
	List,
	Responsive,
	SimpleList,
	ImageField,
	TextField,
} from 'react-admin';

import { dataGridStyle } from '../../helper/constants';

class PostCategoryList extends Component {
	getPrimaryText = record => record.name;

	getSecondaryText = record => record.description;

	render() {
		return (
			<List {...this.props} bulkActionButtons={false}>
				<Responsive
					small={<SimpleList primaryText={this.getPrimaryText} secondaryText={this.getSecondaryText} />}
					medium={
						<Datagrid styles={dataGridStyle}>
							<TextField source="id" />
							<TextField source="name" />
							<TextField source="description" />
							<ImageField source="image" sortable={false} />
							<EditButton />
							{/* <DeleteButton /> */}
						</Datagrid>
					}
				/>
			</List>
		);
	}
}

export default PostCategoryList;
