/* © 2018 NauStud.io
 * @author Hieu Le
 */

import React, { Component } from 'react';
import {
    Create,
    SimpleForm,
	TextInput,
    required,
} from 'react-admin';
import ImageWithCropInput from '../ImageWithCropInput';

class StoryCreate extends Component {
render() {
    return (
	<Create {...this.props} undoable={false}>
		<SimpleForm redirect="list">
			<TextInput source="name" label="Name" validate={required()} />
			<ImageWithCropInput
				source="image"
				label="Image *"
				ratio={343/280}
				dropzone={{
								uniqueId: 'storyImage',
								width: 700,
								height: 200,
							}}
			/>
			<TextInput source="content" label="Content" />
		</SimpleForm>
	</Create>
    );
}
}

export default StoryCreate;
