/* © 2018 NauStud.io
 * @author Quy Tran
 */

import React, { Component } from 'react';
import { Create, SimpleForm, TextInput } from 'react-admin';
import { isDuplicateRecord } from '../../helper/utils';


const validateCountryCode = (values) => {
	const {id = ''} = values || {};
	const errors = {};

	if (id.length !== 2) {
			errors.id = ['The id must be 2 chars'];
	}

	return errors
}

class CountryCreate extends Component {
	checkDuplicateRecord = values => {
		const id = values.id;

		return new Promise((resolve, reject) => {
			if (id) {
				isDuplicateRecord(id, 'id', 'countries')
					.then(res => {
						if (res.data.length > 0) {
							return reject({
								name: ['Duplicated record'],
							});
						}

						return resolve({});
					})
					.catch(e => {
						console.log(e);
					});
			} else {
				resolve({});
			}
		});
	};

	render() {
		return (
			<Create {...this.props} undoable={false}>
				<SimpleForm asyncValidate={this.checkDuplicateRecord} asyncBlurFields={['id']} validate={validateCountryCode}>
					<TextInput source="id" label="ISO Country Code (ex: vn, us)"  />
					<a href="https://en.wikipedia.org/wiki/List_of_ISO_3166_country_codes" target="blank">* ISO country code list</a>
					<TextInput source="name" />
				</SimpleForm>
			</Create>
		);
	}
}

export default CountryCreate;
