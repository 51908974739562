/* © 2018 NauStud.io
 * @author Eric Tran
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import format from 'date-fns/format';
import differenceInDays from 'date-fns/differenceInDays';
import formatDistance from 'date-fns/formatDistance';
import {
	Datagrid,
	List,
	Responsive,
	SimpleList,
	SelectField,
	TextField,
	Filter,
	TextInput,
	EditButton,
	ReferenceArrayField,
	SingleFieldList,
	ChipField,
	CloneButton,
} from 'react-admin';
import { green, amber } from '@material-ui/core/colors';
import { dataGridStyle, noWrapStyle } from '../../helper/constants';

import DeleteButtonWithConfirmation from '../DeleteButtonWithConfirmation';

const style = {
	imgWrapper: {
		width: '80px',
		height: '80px',
		position: 'relative',
		margin: '5px 0',
	},
	img: {
		position: 'absolute',
		top: '0',
		left: '0',
		width: '100%',
		height: '100%',
	},
};

const ImageCustomField = ({ record = {} }) => (
	<div style={style.imgWrapper}>
		<img style={style.img} src={record.thumbnail} alt="" />
	</div>
);

ImageCustomField.propTypes = {
	record: PropTypes.shape({
		thumbnail: PropTypes.string,
	}),
};

ImageCustomField.defaultProps = { record: null };

const StatusField = ({ record = {} }) => (
	<span style={{ color: record.status === 'published' ? green.A700 : amber['900'] }}>{record.status}</span>
);

StatusField.propTypes = {
	record: PropTypes.shape({
		title: PropTypes.string,
	}),
};

StatusField.defaultProps = { record: null };

const PublishedField = ({ record = {} }) => {
	let publishedAt = record.status === 'published' && record.publishedAt ? new Date(record.publishedAt) : '';

	if (publishedAt) {
		if (differenceInDays(new Date(), publishedAt) >= 10) {
			publishedAt = format(publishedAt, 'dd/MM/yyyy');
		} else {
			publishedAt = `${formatDistance(publishedAt, new Date())} ago`;
		}
	}

	return <span>{publishedAt}</span>;
};

PublishedField.propTypes = {
	record: PropTypes.shape({
		updatedAt: PropTypes.instanceOf(Date),
	}),
};

PublishedField.defaultProps = { record: null };

const LastEditField = ({ record = {} }) => {
	let lastEdit = record.updatedAt ? new Date(record.updatedAt) : '';
	if (lastEdit) {
		if (differenceInDays(new Date(), lastEdit) >= 10) {
			lastEdit = format(lastEdit, 'dd/MM/yyyy');
		} else {
			lastEdit = `${formatDistance(lastEdit, new Date())} ago`;
		}
	}

	return <span>{lastEdit}</span>;
};

LastEditField.propTypes = {
	record: PropTypes.shape({
		updatedAt: PropTypes.instanceOf(Date),
	}),
};

LastEditField.defaultProps = { record: null };

const TourFilter = props => (
	<Filter {...props}>
		<TextInput label="Search by title" source="search" alwaysOn />
		<TextInput label="Search by ID" source="id" alwaysOn />
	</Filter>
);

class PostList extends Component {
	getPrimaryText = record => record.title;

	getSecondaryText = record => (record.count ? `${record.count.views} views` : '0');

	getTertiaryText = record => new Date(record.publishedAt).toLocaleDateString();

	render() {
		const { classes } = this.props;

		return (
			<List
				{...this.props}
				filters={<TourFilter />}
				sort={{ field: 'updatedAt', order: 'DESC' }}
				bulkActionButtons={false}
			>
				<Responsive
					small={
						<SimpleList
							primaryText={this.getPrimaryText}
							secondaryText={this.getSecondaryText}
							tertiaryText={this.getTertiaryText}
						/>
					}
					medium={
						<Datagrid styles={dataGridStyle}>
							<TextField source="id" label="Tour Id" sortable={false} />
							<TextField source="slug" sortable={false} />
							<ImageCustomField source="thumbnail" sortable={false} />
							<TextField
								source="title.en"
								label="Title"
								className={classes.maxWidthField}
								sortable={false}
							/>
							<SelectField
								source="type"
								sortable
								choices={[
									{ id: 'fixed', name: 'Fixed tour' },
									{ id: 'guarantee', name: 'Guarantee tour' },
								]}
							/>
							<ReferenceArrayField label="Locations" reference="locations" source="locationIds">
								<SingleFieldList>
									<ChipField source="title.en" />
								</SingleFieldList>
							</ReferenceArrayField>
							<StatusField source="status" />
							<PublishedField label="Published at" source="publishedAt" />
							<LastEditField label="Last updated" source="updatedAt" />
							<CloneButton />
							<EditButton />
							<DeleteButtonWithConfirmation undoable={false} />
						</Datagrid>
					}
				/>
			</List>
		);
	}
}

PostList.propTypes = {
	classes: PropTypes.shape({}),
};

PostList.defaultProps = {
	classes: {},
};

const styles = {
	...noWrapStyle,
};

export default withStyles(styles)(PostList);
