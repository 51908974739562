/* © 2018 NauStud.io
 * @author Eric Tran
 */

import React, { Component } from 'react';
import {
	Edit,
	TabbedForm,
	FormTab,
	TextInput,
	LongTextInput,
	ReferenceInput,
	NumberInput,
	ArrayInput,
	SimpleFormIterator,
	SelectInput,
	FormDataConsumer,
	TextField,
	AutocompleteInput,
} from 'react-admin';
import { DateTimeInput } from 'react-admin-date-inputs';
import Snackbar from '@material-ui/core/Snackbar';

import SearchUserInput from '../SearchUserInput';

import MultiLanguageInput from '../MultiLanguageInput';
import { PRODUCT_TYPE } from '../../helper/constants';
import {
	calculateAirportTransferTotalPrice,
	calculateCarRentalTotalPrice,
	calculateOpenBusTotalPrice,
	calculateServicePrice,
	calculateGuidePrice,
} from '../../helper/utils';
import AirportTransportationBooking from './AirportTransportationBooking';
import CarRentalBooking from './CarRentalBooking';
import PrivateIntercityTransportationBooking from './PrivateIntercityTransportationBooking';
import OpenBusBooking from './OpenBusBooking';
import ServiceBooking from './ServiceBooking';
import HotelBooking from './HotelBooking';
import GuideBooking from './GuideBooking';

const style = {
	header: { fontFamily: 'Muli', margin: '10px 0', letterSpacing: 'normal' },
	roundtripTag: {
		fontFamily: 'Muli',
		display: 'inline-block',
		borderRadius: '8px',
		background: '#0064b6',
		color: 'white',
		padding: '5px 20px',
		marginBottom: '20px',
		fontSize: '14px',
	},
	item: {
		fontFamily: 'Muli',
		marginBottom: '15px',
	},
	key: {
		fontSize: '14px',
		fontWeight: 600,
		color: '#8f8f8f',
		marginRight: '15px',
	},
	value: {
		fontSize: '14px',
	},
	price: {
		fontSize: '14px',
		color: 'red',
	},
	discount: {
		fontSize: '14px',
		color: 'green',
	},
	separator: {
		marginTop: '40px',
		marginBottom: '40px',
		borderBottom: '1px solid gray',
	},
};

class BookingEdit extends Component {
	state = {
		success: false,
	};

	closePopup = () => {
		this.setState({ success: false });
	};

	itemRenderer = (key, value, price) => (
		<div style={style.item}>
			{key && <span style={style.key}>{key}</span>}
			{value && <span style={style.value}>{value}</span>}
			{price !== undefined && price !== null && price !== '' ? (
				<span style={isNaN(price) || Number(price) > 0 ? style.price : style.discount}>
					&nbsp;&nbsp;&nbsp;=&gt; {price} VND
				</span>
			) : (
				''
			)}
		</div>
	);

	calculateEachItem = item => {
		switch (item.category) {
			case PRODUCT_TYPE.AIRPORT_TRANSPORTATION:
				return calculateAirportTransferTotalPrice(item);
			case PRODUCT_TYPE.CAR_RENTAL:
			case PRODUCT_TYPE.PRIVATE_INTERCITY_TRANSPORTATION:
				return calculateCarRentalTotalPrice(item);
			case PRODUCT_TYPE.OPEN_BUS:
				return calculateOpenBusTotalPrice(item);
			case PRODUCT_TYPE.TOUR_GUIDE:
				return calculateGuidePrice(item);
			default:
				return calculateServicePrice(item);
		}
	};

	renderMainPassenger = mainPassenger =>
		`${mainPassenger.firstName} ${mainPassenger.lastName}, ${mainPassenger.country}, ${mainPassenger.phone}, ${mainPassenger.email}`;

	renderProduct = product => {
		const commonProps = {
			itemRenderer: this.itemRenderer,
			renderMainPassenger: this.renderMainPassenger,
			product,
			style,
		};

		switch (product.category) {
			case PRODUCT_TYPE.AIRPORT_TRANSPORTATION:
				return <AirportTransportationBooking {...commonProps} />;
			case PRODUCT_TYPE.CAR_RENTAL:
				return <CarRentalBooking {...commonProps} />;
			case PRODUCT_TYPE.PRIVATE_INTERCITY_TRANSPORTATION:
				return <PrivateIntercityTransportationBooking {...commonProps} />;
			case PRODUCT_TYPE.OPEN_BUS:
				return <OpenBusBooking {...commonProps} />;
			case PRODUCT_TYPE.HOTEL:
				return <HotelBooking {...commonProps} />;
			case PRODUCT_TYPE.TOUR_GUIDE:
				return <GuideBooking {...commonProps} />;
			default:
				return <ServiceBooking {...commonProps} />;
		}
	};

	render() {
		return (
			<>
				<Edit {...this.props} undoable={false}>
					<TabbedForm onChange={this.onFormChange} redirect="edit">
						<FormTab label="summary">
							<TextField source="id" label="Booking ID" />
							<SearchUserInput source="userId" />
							<NumberInput source="subtotalPrice" />
							<NumberInput source="discount" />
							<ArrayInput source="additionalFees">
								<SimpleFormIterator>
									<TextInput source="name" label="Fee name" />
									<NumberInput source="price" label="Fee" />
								</SimpleFormIterator>
							</ArrayInput>
							<NumberInput source="totalPrice" />
							<SelectInput
								source="status"
								choices={[
									{ id: 'pending', name: 'Waiting For Payment' },
									{ id: 'pending-bank-transfer', name: 'Waiting For Bank Transfer' },
									{ id: 'deposited', name: 'Deposited' },
									{ id: 'paid', name: 'Paid' },
									{ id: 'inquiry', name: 'Inquiry' },
									{ id: 'canceled', name: 'Canceled' },
									{ id: 'pushed', name: 'Pushed to E-tour' },
									{ id: 'credit', name: 'Paid using credit' },
								]}
							/>
						</FormTab>
						<FormTab label="Contact Info">
							<TextInput source="contactInfo.firstName" label="Contact First Name" />
							<TextInput source="contactInfo.lastName" label="Contact Last Name" />
							{/* <SelectInput
								source="contactInfo.contactGender"
								label="Gender"
								choices={[{ id: 'male', name: 'Male' }, { id: 'female', name: 'Female' }, { id: 'other', name: 'Other' }]}
							/> */}
							<TextInput source="contactInfo.email" label="Contact Email" />
							<TextInput source="contactInfo.phone" label="Contact Phone" />
							<TextInput source="contactInfo.country" label="Contact Country" />
						</FormTab>
						<FormTab label="Products">
							<FormDataConsumer>
								{({ formData }) =>
									formData.products.map(product => {
										const { discount, commission } = this.calculateEachItem(product);

										return (
											<>
												{this.renderProduct(product)}
												{product.validPromotionCode
													? this.itemRenderer('Discount', null, -discount)
													: ''}
												{commission > 0
													? this.itemRenderer('Commission', null, -commission)
													: ''}
												<div style={style.separator} />
											</>
										);
									})
								}
							</FormDataConsumer>
						</FormTab>
						<FormTab label="Payment Logs">
							<ArrayInput source="paymentLogs">
								<SimpleFormIterator disableAdd disableRemove>
									<TextInput
										source="code"
										options={{ disabled: true }}
										label="Response code from gateway"
									/>
									<MultiLanguageInput
										source="error"
										options={{ disabled: true }}
										label="Error messages"
									/>
									<TextInput
										source="reference"
										options={{ disabled: true }}
										label="Gateway transaction ID"
									/>
									<TextInput
										source="paymentMethod"
										options={{ disabled: true }}
										label="Payment using"
									/>
									<DateTimeInput source="createdAt" options={{ disabled: true }} label="Created At" />
								</SimpleFormIterator>
							</ArrayInput>
						</FormTab>
						<FormTab label="Push Etour Logs">
							<ArrayInput source="pushEtourLogs">
								<SimpleFormIterator disableAdd disableRemove>
									<TextInput source="success" options={{ disabled: true }} label="Success" />
									<TextInput source="code" options={{ disabled: true }} label="Response code" />
									<LongTextInput source="error" options={{ disabled: true }} label="Error messages" />
									<DateTimeInput source="createdAt" options={{ disabled: true }} label="Created At" />
									<ReferenceInput
										label="User"
										source="userId"
										reference="users"
										options={{ disabled: true }}
									>
										<AutocompleteInput optionText="email" />
									</ReferenceInput>
								</SimpleFormIterator>
							</ArrayInput>
						</FormTab>
					</TabbedForm>
				</Edit>

				<Snackbar
					open={this.state.success}
					message={'Pushed E-tour SuccessFully'}
					onClose={this.closePopup}
					anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
				/>
			</>
		);
	}
}

export default BookingEdit;
