/* © 2018 NauStud.io
 * @author Eric Tran
 */
import React, { Component } from 'react';
import { Field } from 'redux-form';
import { Labeled, addField } from 'react-admin';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Cropper from 'react-cropper';
import Snackbar from '@material-ui/core/Snackbar';
import 'cropperjs/dist/cropper.css';
import ImageDropZone from '../components/ImageDropZone';

const styles = {
	inputLabel: {
		cursor: 'pointer',
		display: 'inline-block',
		height: 40,
		lineHeight: '40px',
		width: 200,
		textAlign: 'center',
		background: '#edecec',
		// float: 'left',
		marginRight: '3rem',
	},
	container: {
		display: 'block',
		margin: '4rem',
		marginLeft: '0',
		marginRight: '0',
	},
	inputImage: {
		display: 'inline-block',
		float: 'left',
		clear: 'left',
		maxWidth: 400,
		maxHeight: 400,
		marginBottom: 20,
	},
	removeButton: {
		cursor: 'pointer',
		float: 'left',
		background: 'red',
		outline: 'none',
		border: '0',
		marginTop: 20,
		marginLeft: 20,
		height: 40,
		lineHeight: '37px',
		color: 'white',
		fontSize: 14,
		fontWeight: 600,
		padding: '0 15px',
	},
};

const limitFileSize = 5;

class ImageWithCropListInput extends Component {
	static propTypes = {
		label: PropTypes.string,
		record: PropTypes.shape({}),
		source: PropTypes.string.isRequired,
		ratio: PropTypes.number,
		dropzone: PropTypes.shape(),
	};

	static defaultProps = {
		label: 'Image',
		ratio: 16 / 9,
		dropzone: {},
		record: {},
	};

	constructor(props) {
		super(props);
		if (props.input && props.input.value && (typeof props.input.value === 'string' || props.input.value.base64)) {
			this.state = {
				imageValue: props.input.value.base64 || props.input.value,
			};
		} else {
			this.state = {};
		}
	}

	componentWillReceiveProps(nextProps) {
		if (
			nextProps.input &&
			nextProps.input.value &&
			(typeof nextProps.input.value === 'string' || nextProps.input.value.base64) &&
			!this.state.imageValue &&
			this.state.imageValue !== 'null'
		) {
			this.setState({
				imageValue: nextProps.input.value.base64 || nextProps.input.value,
			});
		}
	}

	onChange = e => {
		const input = e.target;
		this.dummyInput = input;
		if (input.files && input.files.length) {
			this.imageProcess(input.files[0]);
		}
	};

	imageProcess = file => {
		const fileSize = file.size / 1000000;
		if (fileSize > limitFileSize) {
			this.setState({ isImageError: true });
		} else {
			this.setState({ isImageError: false });
			const source = this.props.source;

			this[`${source}.nameOnChange`](file.name);
			this[`${source}.typeOnChange`](file.type);
			this._createBlob(file, rs => {
				this.value = rs;
				this.toggleCropDialog();
			});
		}

		if (this.dummyInput) {
			this.dummyInput.value = null;
		}
	};

	_createBlob = (file, callback) => {
		const reader = new FileReader();
		reader.onload = function() {
			callback(reader.result);
		};
		reader.readAsDataURL(file);
	};

	_refImage = input => {
		this.cropperImage = input;
	};

	_refThumbnail = input => {
		this.cropperThumbnail = input;
	};

	cancelCrop = () => {
		this.toggleCropDialog();
		this.value = '';
	};

	cropImage = () => {
		const { source } = this.props;
		this.toggleCropDialog();
		this.setState(
			{
				imageValue: this.cropperImage.getCroppedCanvas().toDataURL(),
			},
			() => {
				this[`${source}.base64OnChange`](this.state.imageValue);
			}
		);
	};

	removeImage = () => {
		const { source } = this.props;
		this.value = '';
		this.setState(
			{
				imageValue: this.props.record[source] || 'null',
			},
			() => {
				this[`${source}.base64OnChange`](this.state.imageValue);
			}
		);
	};

	removeAllImage = () => {
		const { source } = this.props;
		this.value = '';
		this.setState(
			{
				imageValue: 'null',
			},
			() => {
				this[`${source}.base64OnChange`]('null');
			}
		);
	};

	toggleCropDialog = () => {
		this.setState({ cropDialogActive: !this.state.cropDialogActive });
	};

	Input = ({ input: { name, value, onChange, onBlur, ...inputProps }, meta: omitMeta, ...props }) => {
		this[`${name}OnChange`] = onChange;

		return (
			<span>
				<input id={name} type="text" {...inputProps} {...props} hidden />
			</span>
		);
	};

	closePopup = () => {
		this.setState({
			isImageError: false,
		});
	};

	render() {
		const { label, source, ratio, dropzone } = this.props;
		const { imageValue, imageFileName, imageFileType, cropDialogActive } = this.state;

		return (
			<Labeled label={label}>
				<span>
					<Field hidden name={`${source}.base64`} component={this.Input} type="text" value={imageValue} />
					<Field hidden name={`${source}.name`} component={this.Input} type="text" value={imageFileName} />
					<Field hidden name={`${source}.type`} component={this.Input} type="text" value={imageFileType} />
					<span style={styles.container}>
						<span style={{ display: 'block' }}>
							<input id={`dummy-input-${source}`} accept="image/*" onChange={this.onChange} type="file" hidden />
							<label htmlFor={`dummy-input-${source}`} style={styles.inputLabel}>
								Select file to upload
							</label>
							<ImageDropZone label=" " imageProcess={this.imageProcess} {...dropzone} />
						</span>
						<button
							type="button"
							style={{
								...styles.removeButton,
								display: (imageValue && imageValue !== 'null') ? 'inline-block' : 'none',
							}}
							onClick={this.removeImage}
						>
							REMOVE
						</button>
						<button
							type="button"
							style={{
								...styles.removeButton,
								display: !this.value && (!imageValue || imageValue === 'null') ? 'none' : 'inline-block',
							}}
							onClick={this.removeAllImage}
						>
							NO IMAGE
						</button>
					</span>

					{imageValue && imageValue !== 'null' ? (
						<img
							src={imageValue.indexOf('base64') > -1 ? imageValue : `${imageValue}?time=${Date.now()}`}
							style={styles.inputImage}
							alt=""
						/>
					) : (
						''
					)}

					<Dialog open={cropDialogActive || false} onClose={this.cancelCrop}>
						<DialogContent>
							<Cropper
								ref={this._refImage}
								src={this.value}
								style={{ height: 500, width: '100%' }}
								// Cropper.js options
								autoCropArea={1}
								aspectRatio={ratio}
								guides
							/>
						</DialogContent>
						<DialogActions>
							<Button variant="contained" onClick={this.cancelCrop}>
								Cancel
							</Button>
							<Button variant="contained" color="primary" onClick={this.cropImage}>
								Crop
							</Button>
						</DialogActions>
					</Dialog>

					{this.state.isImageError && (
						<Snackbar
							open={this.state.isImageError}
							message={'File size is larger than 5MB'}
							onClose={this.closePopup}
						/>
					)}
				</span>
			</Labeled>
		);
	}
}

export default addField(ImageWithCropListInput);
