/* © 2019 NauStud.io
 * @author Eric Tran
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { withStyles } from '@material-ui/core/styles';
import { Datagrid, List, TextField, EditButton } from 'react-admin';

import { dataGridStyle, noWrapStyle } from '../../helper/constants';

const CustomLinkButton = ({ record }) => (
	<Link to={`/open-buses?filter={"folderId":"${encodeURIComponent(record.id)}"}`}>Buses List</Link>
);

CustomLinkButton.propTypes = {
	record: PropTypes.object,
};

CustomLinkButton.defaultProps = {
	record: {},
};

class OpenBusFolderList extends Component {
	render() {
		return (
			<List {...this.props} bulkActionButtons={false} sort={{ field: 'departureDateTime', order: 'ASC' }}>
				<Datagrid styles={dataGridStyle}>
					<TextField source="name" label="Name" />
					<CustomLinkButton label="Buses List" />
					<EditButton />
				</Datagrid>
			</List>
		);
	}
}

const styles = {
	...noWrapStyle,
};

export default withStyles(styles)(OpenBusFolderList);
