/* © 2018 NauStud.io
 * @author Eric Tran
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import {
	Create,
	TabbedForm,
	FormTab,
	TextInput,
	NumberInput,
	SelectInput,
	ReferenceInput,
	BooleanInput,
	FormDataConsumer,
	required,
} from 'react-admin';

import MultiLanguageInput from '../MultiLanguageInput';
import { slugify, isDuplicateRecord } from '../../helper/utils';
import RichTextEditor from '../RichTextEditor';
import PostImageInput from '../PostImageInput';
// import ImageWithCropInput from '../ImageWithCropInput';

const regexValidSlug = /([^a-zA-Z0-9-])/g;

class LocationCreate extends Component {
	static propTypes = {
		sidebarOpen: PropTypes.bool,
	};

	static defaultProps = {
		sidebarOpen: false,
	};

	state = {
		isContentEmpty: true,
	};

	authorOptionRender = choice => {
		if (choice.name) {
			return `${choice.name.first} ${choice.name.last}`;
		}

		return 'No name';
	};

	checkDuplicateRecord = values => {
		const slug = values.slug ? slugify(values.slug) : '';

		return new Promise((resolve, reject) => {
			if (slug) {
				if (regexValidSlug.test(values.slug)) {
					return reject({
						slug: ['Invalid slug'],
					});
				}

				isDuplicateRecord(slug, 'slug', 'locations')
					.then(res => {
						if (res.data.length > 0) {
							return reject({
								slug: ['Duplicated record'],
							});
						}

						return resolve({});
					})
					.catch(e => {
						console.log(e);
					});
			} else {
				resolve({});
			}

			return 0;
		});
	};

	validateContent = innerHtml => {
		if (innerHtml === '<p><br></p>' || innerHtml === undefined) {
			this.setState({
				isContentEmpty: true,
			});
		} else {
			this.setState({
				isContentEmpty: false,
			});
		}
	};

	render() {
		const { isContentEmpty } = this.state;

		return (
			<Create undoable={false} {...this.props}>
				<TabbedForm redirect="list" asyncValidate={this.checkDuplicateRecord} asyncBlurFields={['slug']}>
					<FormTab label="summary">
						<TextInput
							source="slug"
							options={{
								placeholder: 'Leave it blank for auto generation',
							}}
						/>
						<MultiLanguageInput source="title" validate={required()} />
						<TextInput source="alias" validate={required()} />
						<TextInput source="description" validate={required()} />
						{/* <ImageWithCropInput
							source="portraitImage"
							ratio={9 / 16}
							label="Portrait Image"
							dropzone={{
								uniqueId: 'locationImagePortrait',
								width: 700,
								height: 200,
							}}
						/> */}
						<PostImageInput
							source="image"
							thumbnailSource="thumbnail"
							label="Image *"
							dropzone={{
								uniqueId: 'locationImage',
								width: 700,
								height: 200,
							}}
						/>
						<FormDataConsumer>
							{({ formData, ...rest }) =>
								formData.type === 'show' && (
									<TextInput
										source="videoUrl"
										label="Video URL (Youtube)"
										validate={required()}
										{...rest}
									/>
								)
							}
						</FormDataConsumer>

						<FormDataConsumer>
							{({ formData, ...rest }) => (
								<ReferenceInput
									label="Country"
									source="countryId"
									reference="countries"
									validate={required()}
									{...rest}
								>
									<SelectInput />
								</ReferenceInput>
							)}
						</FormDataConsumer>

						<SelectInput
							source="language"
							choices={[
								{ id: 'vi_VN', name: 'Vietnamese' },
								{ id: 'en_EN', name: 'English' },
							]}
							validate={required()}
						/>
					</FormTab>
					<FormTab label="Content *">
						{isContentEmpty && (
							<TextInput
								source="textCloned"
								validate={required()}
								style={{
									display: 'none',
								}}
							/>
						)}
						<RichTextEditor
							isCreate
							source="text"
							validation={{ required: true }}
							sidebarOpen={this.props.sidebarOpen}
							validate={this.validateContent}
						/>
					</FormTab>
					<FormTab label="miscellaneous">
						<TextInput source="metaTitle" label="Meta Title" />
						<TextInput source="metaDescription" label="Meta Description" />
						<NumberInput source="order" label="Order in Popular Places section (Blog)" />
						<BooleanInput source="showInHome" label="Show in Experiences section (Home)" />
						<NumberInput source="mapPosition.lat" label="Latitude" />
						<NumberInput source="mapPosition.lng" label="Longitude" />
					</FormTab>
				</TabbedForm>
			</Create>
		);
	}
}

const mapStateToProps = state => ({
	sidebarOpen: state.admin.ui.sidebarOpen,
});

export default compose(connect(mapStateToProps))(LocationCreate);
