/* © 2018 NauStud.io
 * @author Eric Tran
 */

/* eslint-disable react/forbid-prop-types */

import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import shouldUpdate from 'recompose/shouldUpdate';
import compose from 'recompose/compose';
import Button from '@material-ui/core/Button';
import ContentCreate from '@material-ui/icons/Create';

import storage from '../helper/storage';

const EditButton = ({ basePath = '', label = 'Edit', record = {} }) => {
	const currentUser = storage.load('currentUser');
	const userId = currentUser.id;
	const role = currentUser.role;
	if (role === 'author' && userId !== record.authorId) {
		return <div />;
	}

	return (
		<Link to={`${basePath}/${encodeURIComponent(record.id)}`}>
			<Button
				primary
				style={{ overflow: 'inherit' }}
			>
				<ContentCreate />
				{label}
			</Button>
		</Link>
	);
};

EditButton.propTypes = {
	basePath: PropTypes.string,
	label: PropTypes.string,
	record: PropTypes.object,
};

EditButton.defaultProps = {
	record: {},
	basePath: '',
	label: '',
};

const enhance = compose(
	shouldUpdate(
		(props, nextProps) =>
			(props.record && props.record.id !== nextProps.record.id) ||
			props.basePath !== nextProps.basePath ||
			(props.record == null && nextProps.record != null)
	)
);

export default enhance(EditButton);
