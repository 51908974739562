/* © 2018 NauStud.io
 * @author Hai Luu
 */

import React, { Component, Fragment } from 'react';
import {
	Datagrid,
	EditButton,
	List,
	Responsive,
	SimpleList,
	TextField,
	ImageField,
	Filter,
	TextInput,
} from 'react-admin';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';

import { dataGridStyle } from '../../helper/constants';

// const UserFilter = props => (
// 	<Filter {...props}>
// 		<TextInput label="Search for user" source="search" alwaysOn />
// 	</Filter>
// );

const filterStyles = {
	status: { width: 150 },
};

const UserFilter = withStyles(filterStyles)(({ classes, ...props }) => (
	<Filter {...props}>
		<TextInput label="Search for user" source="search" alwaysOn />
	</Filter>
));

const datagridStyles = {
	total: { fontWeight: 'bold' },
};

class TabbedDatagrid extends Component {
	state = { normal: [], editor: [] };

	static getDerivedStateFromProps(props, state) {
		if (props.ids !== state[props.filterValues.role]) {
			return { ...state, [props.filterValues.role]: props.ids };
		}

		return null;
	}

	getPrimaryText = record => record.name;

	getSecondaryText = record => record.description;

	handleChange = (event, value) => {
		const { filterValues, setFilters } = this.props;

		setFilters({ ...filterValues, role: value });
	};

	tabs = [
		{ id: 'user', name: 'normal' },
		{ id: 'agency', name: 'agency' },
		{ id: 'tour-manager', name: 'tour manager' },
		{ id: 'editor', name: 'editor' },
		{ id: 'author', name: 'author' },
		{ id: 'admin', name: 'admin' },
	];

	renderUserList = (props, ids) => (
		<Datagrid styles={dataGridStyle}
			{...props}
			ids={ids}
		>
			<ImageField source="image" />
			<TextField source="username" />
			<TextField source="email" />
			<TextField source="role" />
			<TextField source="name.first" label="First name" />
			<TextField source="name.last" label="Last name" />
			<EditButton />
		</Datagrid>
	)

	render() {
		const { filterValues } = this.props;

		return (
			<Fragment>
				<Tabs
					fullWidth
					centered
					value={filterValues.role}
					indicatorColor="primary"
					onChange={this.handleChange}
				>
					{this.tabs.map(choice => (
						<Tab
							key={choice.id}
							label={choice.name}
							value={choice.id}
						/>
					))}
				</Tabs>
				<Responsive
					small={<SimpleList primaryText={this.getPrimaryText} secondaryText={this.getSecondaryText} />}
					medium={
						<div>
							{filterValues.role === 'user' && (
								this.renderUserList(this.props, this.state.user)
							)}
							{filterValues.role === 'agency' && (
								this.renderUserList(this.props, this.state.agency)
							)}
							{filterValues.role === 'tour-manager' && (
								this.renderUserList(this.props, this.state['tour-manager'])
							)}
							{filterValues.role === 'editor' && (
								this.renderUserList(this.props, this.state.editor)
							)}
							{filterValues.role === 'author' && (
								this.renderUserList(this.props, this.state.author)
							)}
							{filterValues.role === 'admin' && (
								this.renderUserList(this.props, this.state.admin)
							)}
						</div>
					}
				/>
			</Fragment>
		)
	};
}

TabbedDatagrid.propTypes = {
	filterValues: PropTypes.shape({
		role: PropTypes.string,
	}),
	setFilters: PropTypes.func,
};

TabbedDatagrid.defaultProps = {filterValues: {role: 'user'}, setFilters: () => {}};

const StyledTabbedDatagrid = withStyles(datagridStyles)(TabbedDatagrid);

const UserList = ({ classes, ...props }) => (
	<List
		{...props}
		filterDefaultValues={{ role: 'user' }}
		sort={{ field: 'updatedAt', order: 'DESC' }}
		filters={<UserFilter />}
	>
		<StyledTabbedDatagrid />
	</List>
);

export default UserList;
