/* © 2018 NauStud.io
 * @author Tu Nguyen
 */

import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import Quill from 'quill/dist/quill';
import Blockquote from '../tooltips/Blockquote';
import ImageTooltip from '../tooltips/ImageTooltip';
import VideoTooltip from '../tooltips/VideoTooltip';
import LinkTooltip from '../tooltips/LinkTooltip';
import { saveImageToServer } from '../../../helper/rte';
import { getYoutubeId } from '../../../helper/utils';

const TOOLTIP_MAPPING = [<Blockquote />, <ImageTooltip />, <VideoTooltip />, <ImageTooltip url />, <LinkTooltip />];

class Toolbar extends Component {
	constructor(props) {
		super(props);

		this.state = {
			currentTooltip: null,
			isSideMenuOpen: false,
		};
	}
	componentDidMount() {
		this._initOnSelectChange();
		this._initLinkHandler();
	}

	_onListBtnClick = () => {
		const range = this.props.quill.getSelection(true);
		this.props.quill.insertText(range.index, '\n', Quill.sources.USER);
		this.props.quill.insertEmbed(range.index + 1, 'customListItem', true, Quill.sources.USER);
		this.props.quill.setSelection(range.index + 4, Quill.sources.SILENT);
		this._hideSideMenu();
	};

	_initOnSelectChange = () => {
		const range = this.props.quill.getSelection(true);

		if (range) {
			this.setState({
				toolbarStyle: {
					top: this.props.quill.getBounds(range.index).bottom + 22,
				},
			});
		}
		this.props.quill.on('selection-change', range => {
			if (range) {
				if (range.length === 0) {
					// console.log('asdasd', this.props.quill.getBounds(range.index));
					this.setState({
						toolbarStyle: {
							top: this.props.quill.getBounds(range.index).bottom + 22,
						},
					});
				}
			}
		});
	};

	_openToolTip = index => {
		this.setState({
			isSideMenuOpen: false,
			currentTooltip: index,
		});
	};

	_initLinkHandler = () => {
		const toolbar = this.props.quill.getModule('toolbar');

		toolbar.addHandler('link', value => {
			if (value === true) {
				const range = this.props.quill.getSelection(true);
				if (range) {
					this._openToolTip(4);
				} else {
					this.props.showWarning('Please select text you want to add link');
				}
			} else {
				this.props.quill.format('link', value, Quill.sources.USER);
			}
		});
	};

	_onDividerBtnClick = () => {
		const range = this.props.quill.getSelection(true);
		this.props.quill.insertText(range.index, '\n', Quill.sources.USER);
		this.props.quill.insertEmbed(range.index + 1, 'divider', true, Quill.sources.USER);
		this.props.quill.setSelection(range.index + 2, Quill.sources.SILENT);
		this._hideSideMenu();
	};

	_hideSideMenu = () => {
		this.setState({
			currentTooltip: null,
			isSideMenuOpen: false,
		});
	};

	_spreadHandlers = () => ({
		onSubmit: this._handleSubmit,
		closeTooltip: this._handleClose,
	});

	_handleSubmit = values => {
		switch (this.state.currentTooltip) {
			case 0:
				this._insertBlockquote(values);
				break;
			case 1:
				this._handleAddImage(values);
				break;
			case 2:
				this._handleAddVideo(values);
				break;
			case 3:
				this._handleAddImageUrl(values);
				break;
			case 4:
				this._handleLinkTooltip(values);
				break;

			default:
				break;
		}

		this.setState({
			currentTooltip: null,
		});
	};

	_handleClose = () => {
		this.setState({
			currentTooltip: null,
		});
	};

	_insertBlockquote = e => {
		const range = this.props.quill.getSelection(true);
		this.props.quill.insertText(range.index, '\n', Quill.sources.USER);
		this.props.quill.insertEmbed(range.index + 1, 'customBlockquote', e, Quill.sources.USER);
		this.props.quill.setSelection(range.index + 2, Quill.sources.SILENT);
	};

	_insertImageToEditor = (fileUrl, altText, caption, hasContainer) => {
		const range = this.props.quill.getSelection(true);
		this.props.quill.insertText(range.index, '\n', Quill.sources.USER);

		if (range) {
			this.props.quill.insertEmbed(range.index + 1, hasContainer ? 'fullTextImage' : 'fullContentImage', {
				url: fileUrl,
				alt: altText,
				caption,
			});
		}
		// this.onTextChange();
	};

	_handleAddImage = values => {
		saveImageToServer(values.file, fileUrl => {
			this._insertImageToEditor(fileUrl, values.altText, values.caption, values.hasContainer);
		});
	};

	_handleAddImageUrl = values => {
		this._insertImageToEditor(values.url, values.altText, values.caption, values.hasContainer);
	};

	_handleAddVideo = values => {
		const range = this.props.quill.getSelection(true);
		this.props.quill.insertText(range.index, '\n', Quill.sources.USER);

		if (range) {
			const range = this.props.quill.getSelection(true);
			const url = getYoutubeId(values.url);
			if (url) {
				this.props.quill.insertEmbed(
					range.index,
					'video',
					{ url: `https://www.youtube.com/embed/${url}?showinfo=0`, caption: values.caption },
					Quill.sources.USER
				);
			} else {
				this.props.showWarning('This is not a valid youtube url');
			}
		}
	};

	_handleLinkTooltip = ({ url, openLinkType }) => {
		const type = {
			current: '_self',
			newt: '_blank',
		};

		if (url && openLinkType) {
			this.props.quill.format('link', { url, openLinkType: type[openLinkType] }, Quill.sources.USER);
		}
	};

	_renderToolTip = () => React.cloneElement(TOOLTIP_MAPPING[this.state.currentTooltip], this._spreadHandlers());

	_onSideMenuClick = () => {
		const range = this.props.quill.getSelection();
		if (range) {
			if (range.length === 0) {
				this.setState({
					isSideMenuOpen: !this.state.isSideMenuOpen,
				});
			}
		} else {
			this.setState({
				isSideMenuOpen: !this.state.isSideMenuOpen,
			});
		}
	};

	_setWidgetRef = node => {
		if (node) {
			this.toolWidget = node;
		}
	};

	_renderSideToolbar = () => (
		<div className="side-toolbar">
			<button
				className="rte__tooltip-button"
				data-tooltip="blockquotes"
				type="button"
				onClick={() => {
					this._openToolTip(0);
				}}
			>
				<i className="icon icon--quotes" />
			</button>
			<button
				className="rte__tooltip-button"
				data-tooltip="Image File"
				type="button"
				onClick={() => {
					this._openToolTip(1);
				}}
			>
				<i className="icon icon--rte-image" />
			</button>
			<button
				className="rte__tooltip-button"
				data-tooltip="Image with URL"
				type="button"
				onClick={() => {
					this._openToolTip(3);
				}}
			>
				<i className="icon icon--rte-image-url" />
			</button>

			<button
				type="button"
				className="rte__tooltip-button"
				data-tooltip="Youtube Embed"
				onClick={() => {
					this._openToolTip(2);
				}}
			>
				<i className="icon icon--youtube" />
			</button>

			<button
				type="button"
				className="rte__tooltip-button"
				id="list-button"
				onClick={this._onListBtnClick}
				data-tooltip="List"
			>
				<i className="icon icon--rte-list-item" />
			</button>

			<button
				type="button"
				className="rte__tooltip-button"
				id="divider-button"
				onClick={this._onDividerBtnClick}
				data-tooltip="Separator Line"
			>
				<i className="icon icon--rte-line-separator" />
			</button>
		</div>
	);

	render() {
		const { isSideMenuOpen, toolbarStyle } = this.state;

		return (
			<div className="tool-widget" ref={this._setWidgetRef} style={toolbarStyle}>
				{TOOLTIP_MAPPING[this.state.currentTooltip] && (
					<div className="tooltip-wrap">
						<div className="tooltip-content">{this._renderToolTip()}</div>
					</div>
				)}

				<button
					id="show-controls"
					type="button"
					className="rte__side-menu__btn rte__side-menu__toggle"
					onClick={this._onSideMenuClick}
					// onBlur={() => {
					// 	setTimeout(() => {
					// 		this.setState({
					// 			isSideMenuOpen: false,
					// 		});
					// 	}, 150);
					// }}
				/>

				{isSideMenuOpen && <Fragment>{this._renderSideToolbar()}</Fragment>}
			</div>
		);
	}
}

Toolbar.propTypes = {
	quill: PropTypes.func.isRequired,
	showWarning: PropTypes.func,
};

Toolbar.defaultProps = {
	showWarning: () => {},
};

export default Toolbar;
