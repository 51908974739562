/* © 2018 NauStud.io
 * @author Quy Tran
 */

/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import {DeleteButton} from 'react-admin';

const TagDeleteButton = ({record, ...rest }) => {
	if (record && record.isPredefined) {
		return <div />;
	}

	return (
		<DeleteButton
			record={record}
			{...rest}
		/>
	);
};

TagDeleteButton.propTypes = {
	basePath: PropTypes.string,
	label: PropTypes.string,
	record: PropTypes.object,
};

TagDeleteButton.defaultProps = {
	basePath: '',
	label: '',
	record: {},
};

export default TagDeleteButton;
