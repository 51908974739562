/* © 2019 NauStud.io
 * @author Eric Tran
 */

import React, { Component } from 'react';
import { Create, SimpleForm, required, NumberInput, BooleanInput, ArrayInput, SimpleFormIterator } from 'react-admin';

import MultiLanguageInput from '../MultiLanguageInput';
import ImageWithCropListInput from '../ImageWithCropListInput';

class CarTypeCreate extends Component {
	render() {
		return (
			<Create {...this.props} undoable={false}>
				<SimpleForm redirect="list">
					<MultiLanguageInput source="name" label="Car Type Name" validate={required()} />
					<NumberInput source="ranking" label="Ranking" validate={required()} />
					<NumberInput source="numberOfPeople" label="Number of People (Max)" validate={required()} />
					<NumberInput source="numberOfLuggage" label="Number of Luggage (Max)" validate={required()} />
					<BooleanInput source="active" label="Active" />
					<ArrayInput source="images">
						<SimpleFormIterator>
							<ImageWithCropListInput
								label="Image"
								ratio={16 / 9}
								dropzone={{
									uniqueId: 'imageList',
									width: 350,
									height: 196,
								}}
							/>
						</SimpleFormIterator>
					</ArrayInput>
				</SimpleForm>
			</Create>
		);
	}
}

export default CarTypeCreate;
