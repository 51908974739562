/* © 2018 NauStud.io
 * @author Eric Tran
 */
import React, { Component } from 'react';
import { Field } from 'redux-form';
import { Labeled, addField } from 'react-admin';
import PropTypes from 'prop-types';
import Snackbar from '@material-ui/core/Snackbar';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import ImageDropZone from '../components/ImageDropZone';

const styles = {
	inputLabel: {
		cursor: 'pointer',
		display: 'inline-block',
		height: 40,
		lineHeight: '40px',
		width: 200,
		textAlign: 'center',
		background: '#edecec',
		marginTop: 20,
		marginBottom: 20,
		marginRight: '3rem',
		// float: 'left',
	},
	container: {
		display: 'block',
		margin: '4rem',
		marginLeft: '0',
	},
	inputImage: {
		display: 'inline-block',
		float: 'left',
		clear: 'left',
		maxWidth: 400,
		maxHeight: 400,
		marginBottom: 20,
	},
	removeButton: {
		cursor: 'pointer',
		float: 'left',
		background: 'red',
		outline: 'none',
		border: '0',
		margin: 20,
		height: 40,
		lineHeight: '37px',
		color: 'white',
		fontSize: 14,
		fontWeight: 600,
		padding: '0 15px',
	},
};

const limitFileSize = 5;

class PostImageInput extends Component {
	static propTypes = {
		label: PropTypes.string,
		record: PropTypes.shape({}),
		source: PropTypes.string,
		thumbnailSource: PropTypes.string,
		thumbnailRatio: PropTypes.number,
		dropzone: PropTypes.shape(),
	};

	static defaultProps = {
		label: 'Image',
		record: {},
		source: 'image',
		thumbnailSource: 'thumbnail',
		thumbnailRatio: 1,
		dropzone: {},
	};

	state = {
		isImageError: false,
	};

	onChange = e => {
		const input = e.target;
		this.dummyInput = input;
		if (input.files && input.files.length) {
			this.imageProcess(input.files[0]);
		}
	};

	imageProcess = file => {
		const fileSize = file.size / 1000000;
		if (fileSize > limitFileSize) {
			this.setState({ isImageError: true });
		} else {
			this.imageNameOnChange(file.name);
			this.imageTypeOnChange(file.type);
			this._createBlob(file, rs => {
				this.value = rs;
				this.toggleCropDialog();
			});
		}

		if (this.dummyInput) {
			this.dummyInput.value = null;
		}
	};

	_createBlob = (file, callback) => {
		const reader = new FileReader();
		reader.onload = function() {
			callback(reader.result);
		};
		reader.readAsDataURL(file);
	};

	_refImage = input => {
		this.cropperImage = input;
	};

	_refThumbnail = input => {
		this.cropperThumbnail = input;
	};

	cancelCrop = () => {
		this.toggleCropDialog();
		this.value = '';
	};

	cropImage = () => {
		this.toggleCropDialog();
		this.setState(
			{
				imageValue: this.cropperImage.getCroppedCanvas().toDataURL(),
				thumbnailValue: this.cropperThumbnail.getCroppedCanvas().toDataURL(),
				imageDropZoneShow: !this.state.imageDropZoneShow,
			},
			() => {
				this.imageOnChange(this.state.imageValue);
				this.thumbnailOnChange(this.state.thumbnailValue);
			}
		);
	};

	removeImage = () => {
		this.value = '';
		this.setState(
			{
				imageValue: this.props.record[this.props.source],
				thumbnailValue: this.props.record[this.props.thumbnailSource],
				imageDropZoneShow: !this.state.imageDropZoneShow,
			},
			() => {
				this.imageOnChange(this.state.imageValue);
				this.thumbnailOnChange(this.state.thumbnailValue);
			}
		);
	};

	toggleCropDialog = () => {
		this.setState({ cropDialogActive: !this.state.cropDialogActive });
	};

	closePopup = () => {
		this.setState({
			isImageError: false,
		});
	};

	Input = ({ input: { name, value, onChange, ...inputProps }, meta: omitMeta, ...props }) => {
		this[`${name}OnChange`] = onChange;
		if (name === this.props.source && value !== this.state.imageValue) {
			this.setState({ imageValue: value });
		}
		if (name === this.props.thumbnailSource && value !== this.state.imageValue) {
			this.setState({ thumbnailValue: value });
		}

		return (
			<span>
				<input id={name} defaultValue={value} type="text" {...inputProps} {...props} hidden />
			</span>
		);
	};

	render() {
		const { label, source, thumbnailSource, thumbnailRatio, dropzone } = this.props;
		const { imageValue, thumbnailValue, cropDialogActive } = this.state;

		return (
			<Labeled label={label}>
				<span>
					<Field hidden name={source} component={this.Input} type="text" />
					<Field hidden name={thumbnailSource} component={this.Input} type="text" />
					<Field hidden name={`${source}Name`} component={this.Input} type="text" />
					<Field hidden name={`${source}Type`} component={this.Input} type="text" />
					<span style={styles.container}>
						<span style={styles.container}>
							<input
								id="post-image-dummy-input"
								accept="image/*"
								onChange={this.onChange}
								type="file"
								hidden
							/>

							<label htmlFor="post-image-dummy-input" style={styles.inputLabel}>
								Select file to upload
							</label>
							<ImageDropZone label=" " imageProcess={this.imageProcess} {...dropzone} />
						</span>
						<button
							type="button"
							style={{
								...styles.removeButton,
								display: this.value ? 'inline-block' : 'none',
							}}
							onClick={this.removeImage}
						>
							REMOVE
						</button>
					</span>

					<Dialog open={cropDialogActive || false} onClose={this.cancelCrop}>
						<DialogContent>
							<Cropper
								ref={this._refImage}
								src={this.value}
								style={{ height: 300, overflow: 'hidden', width: '45%', float: 'left' }}
								// Cropper.js options
								autoCropArea={1}
								aspectRatio={16 / 9}
								guides
							/>
							<Cropper
								ref={this._refThumbnail}
								src={this.value}
								style={{ height: 300, overflow: 'hidden', width: '45%', float: 'right' }}
								// Cropper.js options
								autoCropArea={1}
								aspectRatio={thumbnailRatio}
								guides
							/>
						</DialogContent>

						<DialogActions>
							<Button variant="contained" onClick={this.cancelCrop}>
								Cancel
							</Button>
							<Button variant="contained" color="primary" onClick={this.cropImage}>
								Crop
							</Button>
						</DialogActions>
					</Dialog>

					{imageValue ? <img src={imageValue} style={styles.inputImage} alt="" /> : ''}
					{thumbnailValue ? <img src={thumbnailValue} style={styles.inputImage} alt="" /> : ''}

					{this.state.isImageError && (
						<Snackbar
							open={this.state.isImageError}
							message={'File size is larger than 5MB'}
							onClose={this.closePopup}
						/>
					)}
				</span>
			</Labeled>
		);
	}
}

export default addField(PostImageInput);
