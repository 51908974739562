/* © 2018 NauStud.io
 * @author Eric Tran
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import ContentSave from '@material-ui/icons/Save';
import CircularProgress from '@material-ui/core/CircularProgress';

class CustomSaveButton extends Component {
	static propTypes = {
		label: PropTypes.string,
		raised: PropTypes.bool,
		saving: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
		submitOnEnter: PropTypes.bool,
		handleSubmitWithRedirect: PropTypes.func,
		redirect: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
		onClick: PropTypes.func,
	};

	static defaultProps = {
		handleSubmitWithRedirect: () => () => {},
		label: '',
		raised: false,
		redirect: '',
		submitOnEnter: false,
		saving: false,
		onClick: null,
	};

	handleClick = e => {
		if (this.props.saving) {
			// prevent double submission
			e.preventDefault();
		} else {
			if (this.props.onClick) {
				this.props.onClick();
			}
			// always submit form explicitly regardless of button type
			const { handleSubmitWithRedirect, redirect } = this.props;
			if (e) {
				e.preventDefault();
			}
			setTimeout(() => {
				handleSubmitWithRedirect(redirect)();
			}, 0);
		}
	};

	render() {
		const { saving, label = 'Save', raised = true, submitOnEnter, redirect } = this.props;
		const type = submitOnEnter ? 'submit' : 'button';

		return (
			<Button
				variant={raised ? 'contained' : 'text'}
				type={type}
				icon={saving && saving.redirect === redirect ? <CircularProgress size={25} thickness={2} /> : <ContentSave />}
				onClick={this.handleClick}
				color={!saving ? 'primary' : ''}
				style={{
					margin: '10px 24px',
					position: 'relative',
				}}
			>
				{label}
			</Button>
		);
	}
}

const mapStateToProps = state => ({
	saving: state.admin.saving,
});

export default connect(mapStateToProps)(CustomSaveButton);
