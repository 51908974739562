/* © 2018 NauStud.io
 * @author Quy Tran
 */

import React, { Component } from 'react';
import { Create, SimpleForm, TextInput, NumberInput } from 'react-admin';
import { isDuplicateRecord } from '../../helper/utils';

const validateCountryCode = values => {
	const { code = '' } = values || {};
	const errors = {};

	if (code.length !== 3) {
		errors.code = ['The currency code must be 3 chars'];
	}

	return errors;
};

class CurrencyCreate extends Component {
	checkDuplicateRecord = values => {
		const code = values.code;

		return new Promise((resolve, reject) => {
			if (code) {
				isDuplicateRecord(code, 'code', 'currencies')
					.then(res => {
						if (res.data.length > 0) {
							return reject({
								code: ['Duplicated record'],
							});
						}

						return resolve({});
					})
					.catch(e => {
						console.log(e);
					});
			} else {
				resolve({});
			}
		});
	};

	render() {
		return (
			<Create undoable={false} {...this.props}>
				<SimpleForm
					redirect="list"
					asyncValidate={this.checkDuplicateRecord}
					asyncBlurFields={['code']}
					validate={validateCountryCode}
				>
					<TextInput source="code" label="ISO Currency Code (ex: USD, EUR)" />
					<TextInput source="name" />
					<NumberInput source="exchangeRate" label="Exchange Rate (1$ = ?VND)" />
				</SimpleForm>
			</Create>
		);
	}
}

export default CurrencyCreate;
