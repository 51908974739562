/* © 2018 NauStud.io
 * @author Eric Tran
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import format from 'date-fns/format';
import { Datagrid, List, TextField, NumberField, ReferenceField, Filter, EditButton } from 'react-admin';
import { DateInput } from 'react-admin-date-inputs';

import { dataGridStyle } from '../../helper/constants';

const CustomDateField = ({ record = {}, source }) => {
	let date = record[source] ? new Date(record[source]) : '';

	date = format(date, 'dd/MM/yyyy');

	return <span>{date}</span>;
};

CustomDateField.propTypes = {
	record: PropTypes.shape({
		departureDate: PropTypes.instanceOf(Date),
	}),
	source: PropTypes.string,
};

CustomDateField.defaultProps = { record: null, source: '' };

const InvoicesFilter = props => (
	<Filter {...props}>
		<DateInput label="Issue Date From" source="issueDate.gte" alwaysOn options={{ format: 'dd/MM/yyyy' }} />
		<DateInput label="Issue Date To" source="issueDate.lte" alwaysOn options={{ format: 'dd/MM/yyyy' }} />
	</Filter>
);

class InvoiceList extends Component {
	static propTypes = {
		classes: PropTypes.shape({}),
	};

	static defaultProps = {
		classes: {},
	};

	render() {
		const { classes, resource } = this.props;
		let bookingReference = 'bookings';
		switch (resource) {
			case 'custom-tour-invoices':
				bookingReference = 'custom-tour-bookings';
				break;
			case 'product-invoices':
				bookingReference = 'product-bookings';
				break;
			default:
				bookingReference = 'bookings';
				break;
		}

		return (
			<List {...this.props} bulkActionButtons={false} filters={<InvoicesFilter />}>
				<Datagrid classes={classes}>
					<TextField source="id" label="Invoice #" />
					<ReferenceField source="userId" label="User" reference="users">
						<TextField source="fullname" />
					</ReferenceField>
					<ReferenceField source="bookingId" label="Booking" reference={bookingReference}>
						<TextField source="id" />
					</ReferenceField>
					<NumberField source="totalPrice" />
					<TextField source="billingInfo.firstName" label="First Name" />
					<TextField source="billingInfo.email" label="Email" />
					<TextField source="billingInfo.phone" label="Phone" />
					<CustomDateField source="issueDate" label="Issue Date" />
					<EditButton />
				</Datagrid>
			</List>
		);
	}
}

const styles = {
	postsField: { textAlign: 'center', display: 'block' },
	...dataGridStyle,
};

export default withStyles(styles)(InvoiceList);
