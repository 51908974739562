/* © 2019 NauStud.io
 * @author Eric Tran
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import format from 'date-fns/format';

class GuideBooking extends Component {
	static propTypes = {
		style: PropTypes.object.isRequired,
		product: PropTypes.object.isRequired,
		itemRenderer: PropTypes.func.isRequired,
		renderMainPassenger: PropTypes.func.isRequired,
	};

	render() {
		const { style, product, itemRenderer, renderMainPassenger } = this.props;

		let duration = 'Half-day';
		if (product.duration === 'full') {
			duration = 'Full day';
		} else if (product.duration === 'multiple') {
			duration = `${product.numberOfDays} days`;
		}

		return (
			<>
				<h5 style={style.header}>{product.name}</h5>
				{itemRenderer('Date', format(new Date(product.date), 'dd/MM/yyyy | HH:mm'))}
				{itemRenderer('Location', product.location)}
				{itemRenderer('Duration', duration)}
				{itemRenderer('Total Price', '', product.price || product.totalPrice)}
				{itemRenderer('Main Passenger', renderMainPassenger(product.mainPassenger))}
			</>
		);
	}
}

export default GuideBooking;
