/* © 2018 NauStud.io
 * @author Eric Tran
 */

import React, { Component } from 'react';
import {
	Edit,
	FormDataConsumer,
	TabbedForm,
	FormTab,
	ReferenceInput,
	SelectInput,
	NumberInput,
	ArrayInput,
	BooleanInput,
	SimpleFormIterator,
	required,
} from 'react-admin';
import { DateInput } from 'react-admin-date-inputs';

import ImageWithCropInput from '../ImageWithCropInput';
import MultiLanguageInput from '../MultiLanguageInput';
import LocationInput from '../LocationInput';

class ProductEdit extends Component {
	validateMaxValue = (val, formData) => {
		const ranges = formData.priceByRange || [];
		const maxValueRanges = ranges.map(range => range.max);
		let lastValue = 0;

		for (let index = 0; index < maxValueRanges.length; index++) {
			const value = maxValueRanges[index];
			if (!value) {
				return 'Các trường giá trị max đều bắt buộc';
			}
			if (value <= lastValue) {
				return 'Các giá trị max phải theo thứ tự từ nhỏ đến lớn';
			}
			lastValue = value;
		}

		return undefined;
	};

	render() {
		return (
			<Edit undoable={false} {...this.props}>
				<TabbedForm redirect="list">
					<FormTab label="Summary">
						<MultiLanguageInput source="name" label="Name" validate={required()} />
						<MultiLanguageInput source="description" label="Description" />
						<ImageWithCropInput
							source="image"
							ratio={1}
							label="Image"
							dropzone={{
								uniqueId: 'productImage',
								width: 300,
								height: 300,
							}}
						/>
						<FormDataConsumer>
							{({ formData, ...rest }) => (
								<ReferenceInput
									label="Category"
									source="categoryId"
									reference="product-categories"
									validate={required()}
									{...rest}
								>
									<SelectInput optionText="name.en" />
								</ReferenceInput>
							)}
						</FormDataConsumer>
						<NumberInput source="babyAge" label="Baby Age (<)" defaultValue={2} validate={required()} />
						<NumberInput source="childrenAge" label="Children Age (<)" defaultValue={12} validate={required()} />
						<NumberInput
							source="babyPricePercent"
							label="Baby price percent (%)"
							defaultValue={0}
							validate={required()}
						/>
						<NumberInput source="childrenPricePercent" label="Children price percent (%)" validate={required()} />
						<NumberInput
							source="remain"
							label="Number of remain product (use -1 for unlimited)"
							validate={required()}
						/>
						<LocationInput label="Available Locations" source="locationIds" isMultiple />
						<MultiLanguageInput source="unit" label="Unit" validate={required()} />
						<BooleanInput
							source="availableToUser"
							label="Is this product avaiable for customer to choose in custom tour?"
						/>
					</FormTab>
					<FormTab label="Prices by range">
						<ArrayInput source="priceByRange" label="Prices">
							<SimpleFormIterator>
								<NumberInput source="max" label="Range max value" validate={this.validateMaxValue} />
								<NumberInput source="price" label="Price per person" validate={required()} />
							</SimpleFormIterator>
						</ArrayInput>
					</FormTab>
					<FormTab label="Available days">
						<ArrayInput source="availableDays" label="Available days">
							<SimpleFormIterator>
								<DateInput source="from" label="From" validate={required()} options={{ format: 'dd/MM/yyyy' }} />
								<DateInput source="to" label="To" validate={required()} options={{ format: 'dd/MM/yyyy' }} />
							</SimpleFormIterator>
						</ArrayInput>
					</FormTab>
				</TabbedForm>
			</Edit>
		);
	}
}

export default ProductEdit;
