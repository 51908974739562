/* © 2019 NauStud.io
 * @author Eric Tran
 */

import React, { Component } from 'react';
import {
	Edit,
	TabbedForm,
	FormTab,
	BooleanInput,
	SelectInput,
	CheckboxGroupInput,
	NumberInput,
	FormDataConsumer,
	ArrayInput,
	SimpleFormIterator,
	LongTextInput,
	ReferenceInput,
	required,
	minValue,
	maxValue,
	FileInput,
	FileField,
	TextInput,
	ReferenceArrayInput,
	SelectArrayInput,
} from 'react-admin';
import { DateInput, TimeInput } from 'react-admin-date-inputs';

import LocationInput from '../LocationInput';
import MultiLanguageRichTextInput from '../MultiLanguageRichTextInput';
import MultiLanguageInput from '../MultiLanguageInput';
import ImageWithCropInput from '../ImageWithCropInput';
import ImageWithCropListInput from '../ImageWithCropListInput';
import ProductCostTable from '../ProductCostTable';
import { slugify, isDuplicateRecord } from '../../helper/utils';

const regexValidSlug = /([^a-zA-Z0-9-])/g;

class ServiceEdit extends Component {
	validateMaxValue = (val, formData) => {
		const prices = formData.basePrice || [];
		for (let i = 0; i < prices.length; i++) {
			const item = prices[i];
			const ranges = item.ranges || [];

			const maxValueRanges = ranges.map(range => range.max);
			let lastValue = 0;

			for (let index = 0; index < maxValueRanges.length; index++) {
				const value = maxValueRanges[index];
				if (!value) {
					return 'Các trường giá trị max đều bắt buộc';
				}
				if (value <= lastValue) {
					return 'Các giá trị max phải theo thứ tự từ nhỏ đến lớn';
				}
				lastValue = value;
			}
		}

		return undefined;
	};

	checkDuplicateRecord = values => {
		const slug = values.slug ? slugify(values.slug) : '';

		return new Promise((resolve, reject) => {
			const promises = [];
			if (slug) {
				if (regexValidSlug.test(values.slug)) {
					reject({
						slug: ['Invalid slug'],
					});

					return;
				}

				const checkSlug = isDuplicateRecord(slug, 'slug', 'services', values.id)
					.then(res => {
						if (res.data.length > 0) {
							return {
								slug: ['Duplicated record'],
							};
						}

						return {};
					})
					.catch(e => {
						console.log(e);

						return { slug: [e.message] };
					});

				promises.push(checkSlug);
			}

			Promise.all(promises).then(rs => {
				const result = rs.reduce((prev, curr) => ({ ...prev, ...curr }), {});
				if (Object.keys(result).length > 0) {
					reject(result);
				} else {
					resolve({});
				}
			});
		});
	};

	renderShortTour = (source, label) => (
		<FormDataConsumer>
			{({ formData }) =>
				formData.serviceType && formData.serviceType.indexOf('trip') > -1 ? (
					<MultiLanguageInput source={source} label={label} />
				) : (
					''
				)
			}
		</FormDataConsumer>
	);

	renderFreeWalkingTour = (source, label) => (
		<FormDataConsumer>
			{({ formData }) =>
				formData.serviceType && formData.serviceType.indexOf('free-walking-tour') > -1 ? (
					<MultiLanguageInput source={source} label={label} />
				) : (
					''
				)
			}
		</FormDataConsumer>
	);

	render() {
		return (
			<Edit {...this.props} undoable={false}>
				<TabbedForm redirect="list" asyncValidate={this.checkDuplicateRecord}>
					<FormTab label="General">
						<TextInput source="slug" label="Slug" validate={required()} />
						<TextInput source="vtCode" label="Vietravel Code" validate={required()} />
						<MultiLanguageInput source="name" label="Name" validate={required()} />
						<MultiLanguageInput source="description" label="Description" />
						<LocationInput source="locationId" label="Location" validate={required()} />
						<SelectInput
							source="serviceType"
							label="Service type"
							choices={[
								{ id: 'private-trip', name: 'Private Short Tour' },
								{ id: 'public-trip', name: 'Public Short Tour' },
								{ id: 'show', name: 'Ticket / Show' },
								{ id: 'free-walking-tour', name: 'Free walking tour' },
							]}
						/>
						<ReferenceArrayInput label="Interests" source="interestIds" reference="service-interests">
							<SelectArrayInput optionText="name.en" />
						</ReferenceArrayInput>
						<ReferenceArrayInput label="Tags" source="tagIds" reference="service-tags">
							<SelectArrayInput optionText="name.en" />
						</ReferenceArrayInput>
						<NumberInput source="minPax" label="Minimum Pax per Booking" defaultValue={1} />
						<NumberInput source="maxPax" label="Maximum Pax per Booking" defaultValue={-1} />
						<NumberInput source="release" label="Release (Hour)" validate={required()} />
						<NumberInput source="duration" label="Duration (Minutes)" />
						<ArrayInput source="startTimes">
							<SimpleFormIterator>
								<TimeInput />
							</SimpleFormIterator>
						</ArrayInput>
						<BooleanInput source="active" label="Active" />
					</FormTab>
					<FormTab label="Highlights">
						<ImageWithCropInput
							source="highlightImage"
							ratio={650 / 400}
							label="Highlight Image"
							dropzone={{
								uniqueId: 'highlightImage',
								width: 325,
								height: 200,
							}}
						/>
						<ArrayInput source="highlightList">
							<SimpleFormIterator>
								<MultiLanguageInput label="Highlight list item" />
							</SimpleFormIterator>
						</ArrayInput>
						{this.renderShortTour('ageRange', 'Age Range')}
						{this.renderShortTour('maxGroupSize', 'Max Group Size')}
						{this.renderShortTour('travelStyle', 'Travel Style')}
						{this.renderShortTour('transportation', 'Transportation')}
						<MultiLanguageRichTextInput source="highlightDescription" label="Highlight Description" />
						{this.renderFreeWalkingTour('transportation', 'Transportation')}
						{/* {this.renderFreeWalkingTour('depart', 'Depart')} */}
						{this.renderFreeWalkingTour('tourGuide', 'Tour Guide')}
						{this.renderFreeWalkingTour('costText', 'Cost')}
					</FormTab>
					<FormTab label="Service Images">
						<ArrayInput source="images">
							<SimpleFormIterator>
								<ImageWithCropListInput
									label="Image"
									ratio={16 / 9}
									dropzone={{
										uniqueId: 'imageList',
										width: 350,
										height: 196,
									}}
								/>
							</SimpleFormIterator>
						</ArrayInput>
					</FormTab>
					<FormTab label="Content Blocks">
						<FormDataConsumer>
							{({ formData }) => {
								if (formData.serviceType === 'free-walking-tour') {
									return (
										<ArrayInput source="freeWalkingTourPoints" label="Free Walking Tour Points">
											<SimpleFormIterator>
												<TextInput source="googleSearchString" label="Google Search String" />
												<MultiLanguageInput source="title" label="Title" />
												<MultiLanguageRichTextInput source="content" label="Content" />
												<FileInput source="audio" label="Audio file">
													<FileField source="src" title="File" />
												</FileInput>
											</SimpleFormIterator>
										</ArrayInput>
									);
								}

								return '';
							}}
						</FormDataConsumer>
						<ArrayInput source="blocks">
							<SimpleFormIterator>
								<MultiLanguageInput source="title" />
								<MultiLanguageRichTextInput source="content" label="Content" />
							</SimpleFormIterator>
						</ArrayInput>
					</FormTab>
					<FormTab label="Cost Information">
						<ArrayInput style={{ width: '50%' }} source="availableDays" label="Available days">
							<SimpleFormIterator>
								<DateInput label="From" source="from" options={{ format: 'dd/MM/yyyy' }} />
								<DateInput label="To" source="to" options={{ format: 'dd/MM/yyyy' }} />
								<CheckboxGroupInput
									source="weekdays"
									label="Available Weekdays"
									defaultValue={[0, 1, 2, 3, 4, 5, 6]}
									choices={[
										{ id: '1', name: 'Monday' },
										{ id: '2', name: 'Tuesday' },
										{ id: '3', name: 'Wednesday' },
										{ id: '4', name: 'Thursday' },
										{ id: '5', name: 'Friday' },
										{ id: '6', name: 'Saturday' },
										{ id: '0', name: 'Sunday' },
									]}
								/>
							</SimpleFormIterator>
						</ArrayInput>
						<ArrayInput source="availableGuides" label="Guides (optional)">
							<SimpleFormIterator>
								<ReferenceInput source="language" label="Language" reference="guide-languages">
									<SelectInput optionsText="name" />
								</ReferenceInput>
								<NumberInput source="price" label="Price (VND)" />
							</SimpleFormIterator>
						</ArrayInput>
						<ArrayInput source="costInfo" label="Cost">
							<SimpleFormIterator>
								<NumberInput source="max" label="Range (Max value)" validate={this.validateMaxValue} />
								<NumberInput source="cost" label="Cost (VND)" />
							</SimpleFormIterator>
						</ArrayInput>
						<SelectInput
							source="markupType"
							defaultValue="fix"
							choices={[
								{ id: 'fix', name: 'Fix' },
								{ id: 'percentage', name: '%' },
							]}
						/>
						<FormDataConsumer>
							{({ formData }) => {
								if (formData.markupType === 'percentage') {
									return (
										<NumberInput
											source="markupAmount"
											inputProps={{ min: 0, max: 100 }}
											validate={[minValue(0), maxValue(100)]}
											defaultValue={0}
										/>
									);
								}

								return (
									<NumberInput
										source="markupAmount"
										inputProps={{ min: 0 }}
										validate={minValue(0)}
										defaultValue={0}
									/>
								);
							}}
						</FormDataConsumer>
						<BooleanInput source="fillTheTable" label="Fill the table" />
						<FormDataConsumer>
							{({ formData }) => {
								if (formData.fillTheTable) {
									const lookupData = (formData.costInfo || [])
										.filter(range => !!range.max)
										.map(range => ({ id: range.max, name: `${range.max}` }));

									return (
										<ProductCostTable
											formData={formData}
											lookupValue="max"
											lookupData={lookupData}
											changeValues={['cost']}
										/>
									);
								}

								return '';
							}}
						</FormDataConsumer>
					</FormTab>
					<FormTab label="miscellaneous">
						<LongTextInput source="metaTitle" label="Meta Title" />
						<LongTextInput source="metaDescription" label="Meta Description" />
					</FormTab>
				</TabbedForm>
			</Edit>
		);
	}
}

export default ServiceEdit;
