/* © 2018 NauStud.io
 * @author Quy Tran
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import {
	Edit,
	TabbedForm,
	FormTab,
	TextInput,
	SelectInput,
	ReferenceInput,
	AutocompleteInput,
	// ReferenceArrayInput,
	// SelectArrayInput,
	TextField,
	required,
	SaveButton,
	Toolbar,
	FormDataConsumer,
} from 'react-admin';

import RichTextEditor from '../RichTextEditor';
import DateField from '../DateField';
import PostImageInput from '../PostImageInput';
import LocationInput from '../LocationInput';
// import ImageWithCropInput from '../ImageWithCropInput';
import CustomSaveButton from '../CustomSaveButton';
import { slugify, isDuplicateRecord } from '../../helper/utils';
import { listStyle } from '../../helper/constants';

const regexValidSlug = /([^a-zA-Z0-9-])/g;

const HighlightPostTitle = ({ record }) => <span>HighlightPost {record ? `"${record.title}"` : ''}</span>;

HighlightPostTitle.propTypes = {
	record: PropTypes.shape({
		title: PropTypes.string,
	}),
};

HighlightPostTitle.defaultProps = {
	record: null,
};

const HighlightPostSaveToolbar = props => {
	if (props.role === 'author') {
		return (
			<Toolbar {...props}>
				<SaveButton label="Update" redirect="list" submitOnEnter={false} />
			</Toolbar>
		);
	}

	if (props.record && props.record.status && props.record.status === 'published') {
		return (
			<Toolbar {...props}>
				<SaveButton label="Update" redirect="list" submitOnEnter={false} />
				<CustomSaveButton
					onClick={() => props.toggleStatus(props.record.status)}
					label="Unpublish"
					redirect="list"
					submitOnEnter={false}
				/>
			</Toolbar>
		);
	}

	return (
		<Toolbar {...props}>
			<SaveButton label="Save Draft" redirect="list" submitOnEnter={false} />
			<CustomSaveButton
				onClick={() => props.toggleStatus(props.record.status)}
				label="Save and Publish"
				redirect="list"
				submitOnEnter={false}
			/>
		</Toolbar>
	);
};

HighlightPostSaveToolbar.propTypes = {
	status: PropTypes.string,
	toggleStatus: PropTypes.func,
	role: PropTypes.string.isRequired,
	record: PropTypes.object.isRequired,
};

HighlightPostSaveToolbar.defaultProps = {
	status: 'draft',
	toggleStatus: null,
};

class HighlightPostEdit extends Component {
	static propTypes = {
		match: PropTypes.shape({
			params: PropTypes.shape({
				slug: PropTypes.string,
				id: PropTypes.string,
			}),
		}),
		sidebarOpen: PropTypes.bool,
		permissions: PropTypes.string.isRequired,
	};

	static defaultProps = {
		match: {},
		sidebarOpen: false,
	};

	state = {
		isContentEmpty: true,
	};

	authorOptionRender = choice => {
		if (choice.name) {
			return `${choice.name.first} ${choice.name.last}`;
		}

		return 'No name';
	};

	searchName = searchText => ({ name: { regexp: searchText } });
	searchTagName = searchText => ({
		name: { regexp: searchText },
		hidden: false,
	});

	searchUsers = searchText => ({
		or: [{ 'name.first': { regexp: searchText } }, { 'name.last': { regexp: searchText } }],
	});

	toggleStatus = status => {
		if (status === 'published') {
			this.onChangeStatus('draft');
		} else {
			this.onChangeStatus('published');
		}
	};

	StatusInput = ({ input: { value, onChange, onBlur, ...inputProps } }) => {
		this.onChangeStatus = onChange;

		return (
			<span>
				<input onChange={onChange} type="text" {...inputProps} hidden />
			</span>
		);
	};

	validateInput = values => {
		const slug = slugify(values.slug);

		return new Promise(resolve => {
			if (slug) {
				if (regexValidSlug.test(values.slug)) {
					return resolve({
						slug: ['Invalid slug'],
					});
				}
				isDuplicateRecord(slug, 'slug', 'posts', values.id)
					.then(res => {
						if (res.data.length > 0) {
							resolve({
								slug: ['Duplicated record'],
							});

							return;
						}

						resolve({});
					})
					.catch(e => {
						console.log(e);
					});
			} else {
				resolve({});
			}

			return 0;
		});
	};

	validateContent = innerHtml => {
		if (innerHtml === '<p><br></p>' || innerHtml === undefined) {
			this.setState({
				isContentEmpty: true,
			});
		} else {
			this.setState({
				isContentEmpty: false,
			});
		}
	};

	render() {
		const role = this.props.permissions;
		const { isContentEmpty } = this.state;

		return (
			<Edit title={<HighlightPostTitle />} undoable={false} {...this.props}>
				<TabbedForm
					asyncValidate={this.validateInput}
					asyncBlurFields={['slug']}
					toolbar={<HighlightPostSaveToolbar toggleStatus={this.toggleStatus} role={role} />}
				>
					<FormTab label="summary">
						<TextInput source="slug" options={{ placeholder: 'Leave it blank for auto generation' }} />
						<TextInput source="title" validate={required()} />
						<TextInput source="description" validate={required()} />
						{/* <ImageWithCropInput
							source="portraitImage"
							ratio={9 / 16}
							label="Portrait Image"
							dropzone={{ uniqueId: 'postImagePortrait', width: 700, height: 200 }}
						/> */}
						<PostImageInput
							source="image"
							thumbnailSource="thumbnail"
							label="Image *"
							dropzone={{ uniqueId: 'postImage', width: 700, height: 200 }}
						/>
						{/* <ReferenceArrayInput
							label="Tags *"
							source="tagIds"
							reference="tags"
							allowEmpty
							filter={{ hidden: false }}
							filterToQuery={this.searchTagName}
						>
							<SelectArrayInput optionText="name" options={{ listStyle }} />
						</ReferenceArrayInput> */}
						<SelectInput
							source="language"
							choices={[
								{ id: 'vi_VN', name: 'Vietnamese' },
								{ id: 'en_EN', name: 'English' },
							]}
							validate={required()}
						/>
						<FormDataConsumer>
							{({ formData, ...rest }) => (
								<LocationInput label="Location" source="locationId" validate={required()} {...rest} />
							)}
						</FormDataConsumer>
						{role !== 'author' && (
							<ReferenceInput
								label="Author"
								source="authorId"
								reference="users"
								allowEmpty
								filterToQuery={this.searchUsers}
								validate={required()}
							>
								<AutocompleteInput optionText={this.authorOptionRender} options={{ listStyle }} />
							</ReferenceInput>
						)}
						<TextField source="status" label="Status" />
						<DateField source="publishedAt" label="Last published at" />
						<TextField source="count.views" label="Views" />
						<Field name="status" component={this.StatusInput} />
					</FormTab>
					<FormTab label="Content *">
						{isContentEmpty && (
							<TextInput
								source="textCloned"
								validate={required()}
								style={{
									display: 'none',
								}}
							/>
						)}
						<RichTextEditor
							source="text"
							validation={{ required: true }}
							sidebarOpen={this.props.sidebarOpen}
							validate={this.validateContent}
						/>
					</FormTab>
					<FormTab label="miscellaneous">
						<TextInput source="metaTitle" label="Meta Title" />
						<TextInput source="metaDescription" label="Meta Description" />
					</FormTab>
				</TabbedForm>
			</Edit>
		);
	}
}

const mapStateToProps = state => ({
	sidebarOpen: state.admin.ui.sidebarOpen,
});

export default compose(connect(mapStateToProps))(HighlightPostEdit);
