/* © 2018 NauStud.io
 * @author Eric Tran
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Datagrid, TextField, List, Filter, TextInput, NumberField } from 'react-admin';
import { Link } from 'react-router-dom';

const CommissionHistoryFilter = props => (
	<Filter {...props}>
		<TextInput label="Agency" source="search" alwaysOn />
		<TextInput label="Market" source="searchMarket" alwaysOn />
		<TextInput label="Agency Group" source="searchAgencyGroup" alwaysOn />
	</Filter>
);

const CustomLinkButton = ({ record }) => (
	<Link to={`/bookings/?filter={"userId":"${encodeURIComponent(record.id)}"}`}>Bookings List</Link>
);

CustomLinkButton.propTypes = {
	record: PropTypes.object,
};

CustomLinkButton.defaultProps = {
	record: {},
	basePath: '',
};

class CommissionHistory extends Component {
	render() {
		return (
			<List
				{...this.props}
				resource="users"
				basePath="users"
				filters={<CommissionHistoryFilter />}
				bulkActionButtons={false}
				filter={{
					role: 'agency',
				}}
				hasCreate={false}
				hasEdit={false}
				hasShow={false}
				hasList
			>
				<Datagrid>
					<TextField source="fullname" label="Agency" />
					<TextField source="market.name" label="Market" />
					<TextField source="agencyGroup.name" label="Agency Group" />
					<TextField source="bookings.length" label="Total bookings" />
					<NumberField source="totalPrice" label="Total price" />
					<CustomLinkButton label="Bookings" />
				</Datagrid>
			</List>
		);
	}
}

export default CommissionHistory;
