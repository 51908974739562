/* © 2018 NauStud.io
 * @author Eric Tran
 * FIXME: deprecated, use custom layout instead
 */

/* eslint-disable
	react/no-unused-prop-types,
	react/prop-types
*/

import React from 'react';
import { connect } from 'react-redux';
import { MenuItemLink, getResources, DashboardMenuItem } from 'react-admin';
import { withRouter } from 'react-router-dom';
import DefaultIcon from '@material-ui/icons/ViewList';
import { OpenBusIcon } from '../open-buses';
import storage from '../../helper/storage';

const capitalize = str => `${str.charAt(0).toUpperCase()}${str.substring(1)}`;

const MyMenu = ({ resources, onMenuClick, dense, hasDashboard }) => {
	const currentUser = storage.load('currentUser');

	return (
		<div>
			{hasDashboard && <DashboardMenuItem onClick={onMenuClick} />}
			{resources
				.filter(r => ['posts', 'pages', 'locations', 'countries'].includes(r.name))
				.map((resource, index) => {
					if (resource.name === 'open-buses') {
						return null;
					}
					const item = (
						<MenuItemLink
							key={resource.name}
							to={`/${resource.name}`}
							leftIcon={resource.icon ? <resource.icon /> : <DefaultIcon />}
							primaryText={resource.options.label || capitalize(resource.name.split('-').join(' '))}
							dense={dense}
							onClick={onMenuClick}
						/>
					);

					// if (
					// 	currentUser &&
					// 	currentUser.role === 'admin' &&
					// 	(resource.name === 'tours' || resource.name === 'countries')
					// ) {
					// 	// eslint-disable-next-line react/no-array-index-key
					// 	return [<hr style={{ width: '70%', opacity: '0.4' }} key={index} />, item];
					// }

					// if (
					// 	currentUser &&
					// 	(currentUser.role === 'admin' || currentUser.role === 'tour-manager') &&
					// 	resource.name === 'airports'
					// ) {
					// 	// eslint-disable-next-line react/no-array-index-key
					// 	return [<hr style={{ width: '70%', opacity: '0.4' }} key={index} />, item];
					// }

					if (currentUser && currentUser.role === 'admin' && resource.name === 'agency-groups') {
						return [
							<MenuItemLink
								key={'commission history'}
								to={`/commission-history`}
								leftIcon={<DefaultIcon />}
								primaryText="Commission History"
								dense={dense}
								onClick={onMenuClick}
							/>,
							<MenuItemLink
								key={'agency report'}
								to={`/report`}
								leftIcon={<DefaultIcon />}
								primaryText="Agency Report"
								dense={dense}
								onClick={onMenuClick}
							/>,
							item,
						];
					}

					if (resource.name === 'open-bus-routes') {
						return [
							<MenuItemLink
								key="open-bus-generate"
								to={`/open-bus-generate`}
								leftIcon={<OpenBusIcon />}
								primaryText="Open bus generator"
								dense={dense}
								onClick={onMenuClick}
							/>,
							item,
						];
					}

					return item;
				})}
		</div>
	);
};

const mapStateToProps = state => ({
	resources: getResources(state),
});

export default withRouter(connect(mapStateToProps)(MyMenu));
