/* © 2019 NauStud.io
 * @author Eric Tran
 */

import React, { Component } from 'react';
import {
	Create,
	TabbedForm,
	FormTab,
	TextInput,
	LongTextInput,
	BooleanInput,
	SelectInput,
	SelectArrayInput,
	NumberInput,
	FormDataConsumer,
	ReferenceInput,
	AutocompleteArrayInput,
	required,
	minValue,
	maxValue,
} from 'react-admin';

import { DateInput, TimeInput } from 'react-admin-date-inputs';
import { PROMOTION_TYPE, PRODUCT_TYPE } from '../../helper/constants';
import PromotionCodesTable from './PromotionCodesTable';

class PromotionCreate extends Component {
	render() {
		return (
			<Create {...this.props} undoable={false}>
				<TabbedForm redirect="list">
					<FormTab label="General Information">
						<TextInput source="name" label="Name" validate={required()} />
						<LongTextInput source="description" label="Description" />
						<LongTextInput source="comment" label="Comment" />
						<SelectInput
							validate={required()}
							source="type"
							label="Type"
							choices={[
								{ id: PROMOTION_TYPE.GENERATE_DISCOUNT, name: 'General Discount' },
								{ id: PROMOTION_TYPE.CAMPAIGN, name: 'Campaign' },
								{ id: PROMOTION_TYPE.PROMOTION, name: 'Promotion' },
							]}
						/>
						<SelectInput
							validate={required()}
							source="discountType"
							defaultValue="fix"
							choices={[
								{ id: 'fix', name: 'Fix' },
								{ id: 'percentage', name: '%' },
							]}
						/>
						<FormDataConsumer>
							{({ formData }) => {
								if (formData.discountType === 'percentage') {
									return (
										<NumberInput
											source="discountAmount"
											inputProps={{ min: 0, max: 100 }}
											validate={[minValue(0), maxValue(100), required()]}
											defaultValue={0}
										/>
									);
								}

								return (
									<NumberInput
										source="discountAmount"
										inputProps={{ min: 0 }}
										validate={[required(), minValue(0)]}
										defaultValue={0}
									/>
								);
							}}
						</FormDataConsumer>
						<NumberInput source="maxTime" label="Maximum Time Allowed" />
						<NumberInput source="maxDiscountAmount" label="Maximum Discount Amount (VND)" />
						<BooleanInput source="active" label="Active" defaultValue />
						<BooleanInput source="cummulative" label="Cummulative" />
					</FormTab>
					<FormTab label="Discount Conditions">
						<DateInput
							options={{ format: 'dd/MM/yyyy', clearable: true }}
							label="Booking Date (From)"
							source="bookingDate.from"
							allowEmpty
						/>
						<DateInput
							options={{ format: 'dd/MM/yyyy', clearable: true }}
							label="Booking Date (To)"
							source="bookingDate.to"
							allowEmpty
						/>
						<TimeInput label="Booking Time (From)" source="bookingTime.from" allowEmpty />
						<TimeInput label="Booking Time (To)" source="bookingTime.to" allowEmpty />
						<DateInput
							options={{ format: 'dd/MM/yyyy', clearable: true }}
							label="Service Start (From)"
							source="serviceStartDate.from"
							allowEmpty
						/>
						<DateInput
							options={{ format: 'dd/MM/yyyy', clearable: true }}
							label="Service Start (To)"
							source="serviceStartDate.to"
							allowEmpty
						/>
						<NumberInput label="Minimum Price Required (VND)" source="minAmountRequired" allowEmpty />
						<NumberInput label="Maximum Price Required (VND)" source="maxAmountRequired" allowEmpty />
						<NumberInput label="Days in Advance (before Service Start)" source="daysInAdvance" allowEmpty />
						<ReferenceInput
							label="Location"
							source="locationIds"
							reference="locations"
							filterToQuery={searchText => ({
								search: searchText,
							})}
							allowEmpty
						>
							<AutocompleteArrayInput optionText="title.en" />
						</ReferenceInput>
						<SelectArrayInput
							source="productTypes"
							label="Product Types"
							choices={[
								{ id: PRODUCT_TYPE.AIRPORT_TRANSPORTATION, name: 'Airport Transportaion' },
								{ id: PRODUCT_TYPE.PRIVATE_INTERCITY_TRANSPORTATION, name: 'Private Bus' },
								{ id: PRODUCT_TYPE.OPEN_BUS, name: 'Open Bus' },
								{ id: PRODUCT_TYPE.CAR_RENTAL, name: 'Car Rental' },
								{ id: PRODUCT_TYPE.PRIVATE_TRIP, name: 'Private Trip' },
								{ id: PRODUCT_TYPE.PUBLIC_TRIP, name: 'Public Trip' },
								{ id: PRODUCT_TYPE.SHOW, name: 'Show' },
								{ id: PRODUCT_TYPE.FREE_WALKING_TOUR, name: 'Free Walking Tour' },
								{ id: PRODUCT_TYPE.HOTEL, name: 'Hotel' },
								{ id: PRODUCT_TYPE.TOUR_GUIDE, name: 'Tour Guide' },
								{ id: PRODUCT_TYPE.PRIVATE_TOUR_PACKAGE, name: 'Private Tour Package' },
								{ id: PRODUCT_TYPE.GUARANTEE_TOUR_PACKAGE, name: 'Guarantee Tour Package' },
							]}
						/>
						<ReferenceInput
							label="Agency Group"
							source="agencyGroupIds"
							reference="agency-groups"
							allowEmpty
						>
							<AutocompleteArrayInput optionText="name" />
						</ReferenceInput>
						<ReferenceInput label="Agency Market" source="marketIds" reference="markets" allowEmpty>
							<AutocompleteArrayInput optionText="name" />
						</ReferenceInput>
					</FormTab>
					<FormTab label="Promotion Codes">
						<FormDataConsumer>
							{({ formData }) => {
								if (formData.type === PROMOTION_TYPE.CAMPAIGN) {
									return <TextInput source="promotionCode" label="Promotion Code" />;
								} else if (formData.type === PROMOTION_TYPE.PROMOTION) {
									return <PromotionCodesTable formData={formData} />;
								}

								return '';
							}}
						</FormDataConsumer>
					</FormTab>
				</TabbedForm>
			</Create>
		);
	}
}

export default PromotionCreate;
