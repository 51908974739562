/* © 2018 NauStud.io
 * @author Eric Tran
 */

import React, { Component } from 'react';
import {
	Edit,
	TabbedForm,
	FormTab,
	TextInput,
	LongTextInput,
	ReferenceInput,
	ArrayInput,
	SimpleFormIterator,
	SelectInput,
	FormDataConsumer,
	Labeled,
	TextField,
	AutocompleteInput,
} from 'react-admin';
import { DateInput, DateTimeInput } from 'react-admin-date-inputs';
import Snackbar from '@material-ui/core/Snackbar';

import SearchUserInput from '../SearchUserInput';

import MultiLanguageInput from '../MultiLanguageInput';

class FwtBookingEdit extends Component {
	state = {
		tripPrice: 0,
		success: false,
	};

	componentWillReceiveProps() {
		const pushedSuccess = localStorage.getItem('pushedSuccess');
		if (pushedSuccess) {
			this.setState({ success: true });
			localStorage.removeItem('pushedSuccess');
		}
	}

	closePopup = () => {
		this.setState({ success: false });
	};

	render() {
		return (
			<>
				<Edit {...this.props} undoable={false}>
					<TabbedForm redirect="edit">
						<FormTab label="summary">
							<TextField source="id" label="Booking ID" />
							<FormDataConsumer>
								{({ formData }) => {
									if (formData.productId) {
										const href = `#/services/${formData.productId}`;

										return (
											<Labeled label="Tour">
												<a href={href}>{formData.productId}</a>
											</Labeled>
										);
									}

									return '';
								}}
							</FormDataConsumer>
							<SearchUserInput source="userId" />
							<DateInput
								source="departureDate"
								options={{ format: 'dd/MM/yyyy' }}
								label="Departure Date"
							/>
							<SelectInput
								source="status"
								choices={[
									{ id: 'pending', name: 'Waiting For Confirmation' },
									{ id: 'confirmed', name: 'Confirmed' },
									{ id: 'canceled', name: 'Canceled' },
								]}
							/>
						</FormTab>
						<FormTab label="Contact Info">
							<TextInput source="contactInfo.firstName" label="Contact First Name" />
							<TextInput source="contactInfo.lastName" label="Contact Last Name" />
							<TextInput source="contactInfo.email" label="Contact Email" />
							<TextInput source="contactInfo.phone" label="Contact Phone" />
							<TextInput source="contactInfo.country" label="Contact Country" />
						</FormTab>
						<FormTab label="Payment Logs">
							<ArrayInput source="paymentLogs">
								<SimpleFormIterator disableAdd disableRemove>
									<TextInput
										source="code"
										options={{ disabled: true }}
										label="Response code from gateway"
									/>
									<MultiLanguageInput
										source="error"
										options={{ disabled: true }}
										label="Error messages"
									/>
									<TextInput
										source="reference"
										options={{ disabled: true }}
										label="Gateway transaction ID"
									/>
									<TextInput
										source="paymentMethod"
										options={{ disabled: true }}
										label="Payment using"
									/>
									<DateTimeInput source="createdAt" options={{ disabled: true }} label="Created At" />
								</SimpleFormIterator>
							</ArrayInput>
						</FormTab>
						<FormTab label="Push Etour Logs">
							<ArrayInput source="pushEtourLogs">
								<SimpleFormIterator disableAdd disableRemove>
									<TextInput source="success" options={{ disabled: true }} label="Success" />
									<TextInput source="code" options={{ disabled: true }} label="Response code" />
									<LongTextInput source="error" options={{ disabled: true }} label="Error messages" />
									<DateTimeInput source="createdAt" options={{ disabled: true }} label="Created At" />
									<ReferenceInput
										label="User"
										source="userId"
										reference="users"
										options={{ disabled: true }}
									>
										<AutocompleteInput optionText="email" />
									</ReferenceInput>
								</SimpleFormIterator>
							</ArrayInput>
						</FormTab>
					</TabbedForm>
				</Edit>

				<Snackbar
					open={this.state.success}
					message={'Pushed E-tour SuccessFully'}
					onClose={this.closePopup}
					anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
				/>
			</>
		);
	}
}

export default FwtBookingEdit;
