/* © 2018 NauStud.io
 * @author Thanh
 */
import React from 'react';
import PropTypes from 'prop-types';
import { AppBar, UserMenu, MenuItemLink, translate, GET_LIST } from 'react-admin';
import { withStyles } from '@material-ui/core/styles';
import SettingsIcon from '@material-ui/icons/Settings';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import Avatar from '@material-ui/core/Avatar';
import Select from '@material-ui/core/Select';

import storage from '../../helper/storage';
import restClient from '../../helper/restClient';

/**
 * Some customization on the layout and appbar
 * Refer to: https://marmelab.com/react-admin/Theming.html#using-a-custom-appbar
 */

const profileIconStyle = {
	avatar: {
		height: 30,
		width: 30,
	},
};

// TODO: use correct user's profile image
const ProfileIcon = withStyles(profileIconStyle)(({ classes, src }) => <Avatar className={classes.avatar} src={src} />);

// The dropdown menu at User profile icon

// The top AppBar
class VIAppBar extends React.Component {
	static propTypes = {
		translate: PropTypes.func,
		logout: PropTypes.shape(),
	};

	static defaultProps = {
		translate: () => {},
		logout: {},
	};

	state = {
		currentUser: {
			image: '',
		},
	};
	static getDerivedStateFromProps(/* nextProps, prevState */) {
		const currentUser = storage.load('currentUser');

		return {
			currentUser,
		};
	}

	componentDidMount = () => {
		restClient(GET_LIST, 'settings', {
			pagination: {
				perPage: 1,
			},
			sort: {},
			filter: {},
		});
	};

	onSelectMarket = e => {
		localStorage.setItem('market', e.target.value);
		window.location.reload();
	};

	getUserIcon() {
		const { currentUser } = this.state;
		if (currentUser && currentUser.image) {
			return <ProfileIcon src={`${currentUser.image}?time=${Date.now()}`} />;
		}

		return <AccountCircleIcon />;
	}

	render() {
		const { translate, logout } = this.props;
		const clonedProps = Object.assign({}, this.props);

		delete clonedProps.translate;
		const market = localStorage.getItem('market') || 'VN';

		return (
			<AppBar
				title=""
				{...this.clonedProps}
				color="primary"
				userMenu={
					<UserMenu icon={this.getUserIcon()}>
						<MenuItemLink
							to="/profile"
							primaryText={translate('common.profile')}
							leftIcon={<SettingsIcon />}
						/>
						{logout}
					</UserMenu>
				}
			>
				<Typography
					style={{ flex: 1, textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}
					variant="title"
					color="inherit"
					id="react-admin-title"
				/>
				<Select style={{ color: 'white' }} value={market} onChange={this.onSelectMarket}>
					<MenuItem value="VN">Vietnam</MenuItem>
					<MenuItem value="CN">China</MenuItem>
					<MenuItem value="TH">Thailand</MenuItem>
				</Select>
			</AppBar>
		);
	}
}

export default translate(VIAppBar);
