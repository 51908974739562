/* © 2018 NauStud.io
 * @author Hieu Le
 */

import React, { Component } from 'react';
import {
    Edit,
    SimpleForm,
	TextInput,
    required,
} from 'react-admin';
import PostImageInput from '../PostImageInput';
import { isDuplicateRecord } from '../../helper/utils';
 
class StoryEdit extends Component {
    checkDuplicateRecord = values => {
		const id = values.id || '';

		return new Promise((resolve, reject) => {
			const promises = [];
			if (id) {
				const checkStoriesId= isDuplicateRecord(id, 'id', 'stories', values.id)
				.then(res => {
                    console.log(res);
					if (res.data.length > 0) {
						return {
							id: ['Duplicated record'],
						};
					}

					return {};
				})
				.catch(e => {
					console.log(e);

					return { id: [e.message] };
				});

				promises.push(checkStoriesId);
			}

			Promise.all(promises)
			.then((rs) => {
				const result = rs.reduce((prev, curr) => ({ ...prev, ...curr }), {});
				if (Object.keys(result).length > 0) {
					reject(result);
				} else {
					resolve({});
				}
			});
		});
    };
render() {
    return (
	<Edit {...this.props} undoable={false}>
		<SimpleForm asyncBlurFields={['id']}>
			<TextInput source="name" label="Name" validate={required()} />
			<PostImageInput
				source="image"
				thumbnailSource="thumbnail"
				label="Image *"
				dropzone={{ uniqueId: 'postImage', width: 700, height: 200 }}
			/>
			<TextInput source="content" label="Content" />
		</SimpleForm>
	</Edit>
    );
}
}

export default StoryEdit;
