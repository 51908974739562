/* © 2018 NauStud.io
 * @author Eric Tran
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { LongTextInput, FieldTitle, isRequired } from 'react-admin';
import { FormControl, InputLabel } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { capitalize } from '../helper/utils';

const styles = {
	input: {
		marginLeft: '30px',
	},
};

const CustomTextInput = withStyles(styles)(({ classes, ...props }) => (
	<LongTextInput className={classes.input} {...props} />
));

class MultiLanguageInput extends Component {
	static propTypes = {
		source: PropTypes.string.isRequired,
		label: PropTypes.string,
		resource: PropTypes.string,
		options: PropTypes.object,
		validate: PropTypes.oneOfType([PropTypes.func, PropTypes.arrayOf(PropTypes.func)]),
	};

	static defaultProps = {
		label: '',
		resource: '',
		validate: undefined,
		options: {},
	};

	componentWillMount = () => {
		this.availableLanguages = JSON.parse(localStorage.getItem('languages') || []);
	};

	render() {
		const { source, label, resource, validate, options } = this.props;

		if (!this.availableLanguages.length) {
			return <LongTextInput source={source} label={label || capitalize(source)} validate={validate} />;
		}

		return (
			<FormControl>
				<InputLabel htmlFor={source} shrink style={{ marginTop: 20 }}>
					<FieldTitle
						label={label || capitalize(source)}
						source={source}
						resource={resource}
						isRequired={isRequired(validate)}
					/>
				</InputLabel>
				<br />
				{this.availableLanguages.map(language => (
					<CustomTextInput
						key={language.code}
						source={`${source}.${language.code}`}
						label={language.name || language.code}
						validate={language.code === 'en' ? validate : undefined}
						options={options}
					/>
				))}
			</FormControl>
		);
	}
}

export default MultiLanguageInput;
