/* © 2018 NauStud.io
 * @author Hai Luu
 */

import React, { Component } from 'react';
import {
	Create,
	TextInput,
	LongTextInput,
	SelectInput,
	TabbedForm,
	FormTab,
	ReferenceInput,
	AutocompleteInput,
	required,
} from 'react-admin';

import ImageInput from '../ImageInput';

class MediaCreate extends Component {
	searchName = searchText => ({ name: { regexp: searchText } });

	render() {
		return (
			<Create {...this.props} undoable={false}>
				<TabbedForm>
					<FormTab label="summary">
						<TextInput source="name" validate={required()} />
						<TextInput source="url" validate={required()} />
						<SelectInput
							validate={required()}
							source="type"
							choices={[{ id: 'image', name: 'Image' }, { id: 'video', name: 'Video' }]}
						/>
						<ImageInput
							source="poster"
							dropzone={{
								uniqueId: 'mediaCreate',
								width: 700,
								height: 400,
							}}
						/>
						<ReferenceInput
							label="Album"
							source="albumId"
							reference="albums"
							filterToQuery={this.searchName}
							allowEmpty
						>
							<AutocompleteInput optionText="name" />
						</ReferenceInput>
					</FormTab>
					<FormTab label="content">
						<LongTextInput source="description" />
					</FormTab>
				</TabbedForm>
			</Create>
		);
	}
}

export default MediaCreate;
