/* © 2018 NauStud.io
 * @author Quy Tran
 */

import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import format from 'date-fns/format';
import { Datagrid, List, TextField, Filter, TextInput, EditButton } from 'react-admin';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import { dataGridStyle } from '../../helper/constants';

const CustomTourFilter = props => (
	<Filter {...props}>
		<TextInput label="Search by id" source="id" alwaysOn />
	</Filter>
);

const CustomDateField = ({ record = {}, source }) => {
	let date = record[source] ? new Date(record[source]) : '';

	date = format(date, 'dd/MM/yyyy');

	return <span>{date}</span>;
};

CustomDateField.propTypes = {
	record: PropTypes.shape({
		departureDate: PropTypes.instanceOf(Date),
	}),
	source: PropTypes.string,
};

CustomDateField.defaultProps = { record: null, source: '' };

class TabbedDatagrid extends Component {
	state = { temp: [], new: [], inquiry: [], ready: [], paid: [], expired: [] };

	static getDerivedStateFromProps(props, state) {
		if (props.ids !== state[props.filterValues.status]) {
			return { ...state, [props.filterValues.status]: props.ids };
		}

		return null;
	}

	handleChange = (event, value) => {
		const { filterValues, setFilters } = this.props;

		setFilters({ ...filterValues, status: value });
	};

	tabs = [
		{ id: 'temp', name: 'temporary' },
		{ id: 'new', name: 'new' },
		{ id: 'inquiry', name: 'inquiry' },
		{ id: 'ready', name: 'ready for payment' },
		{ id: 'paid', name: 'paid' },
		{ id: 'expired', name: 'expired' },
	];

	renderUserList = (props, ids) => (
		<Datagrid styles={dataGridStyle} {...props} ids={ids}>
			<TextField source="id" sortable={false} />
			<TextField source="title" />
			<CustomDateField source="createdAt" label="Created At" />
			<CustomDateField source="updatedAt" label="Updated At" />
			<EditButton />
		</Datagrid>
	);

	render() {
		const { filterValues } = this.props;

		return (
			<Fragment>
				<Tabs fullWidth centered value={filterValues.status} indicatorColor="primary" onChange={this.handleChange}>
					{this.tabs.map(choice => (
						<Tab key={choice.id} label={choice.name} value={choice.id} />
					))}
				</Tabs>
				<div>
					{filterValues.status === 'temp' && this.renderUserList(this.props, this.state.temp)}
					{filterValues.status === 'new' && this.renderUserList(this.props, this.state.new)}
					{filterValues.status === 'inquiry' && this.renderUserList(this.props, this.state.inquiry)}
					{filterValues.status === 'ready' && this.renderUserList(this.props, this.state.ready)}
					{filterValues.status === 'paid' && this.renderUserList(this.props, this.state.paid)}
					{filterValues.status === 'expired' && this.renderUserList(this.props, this.state.expired)}
				</div>
			</Fragment>
		);
	}
}

TabbedDatagrid.propTypes = {
	filterValues: PropTypes.shape({
		role: PropTypes.string,
	}),
	setFilters: PropTypes.func,
};

TabbedDatagrid.defaultProps = { filterValues: { status: 'new' }, setFilters: () => {} };

const CustomTourList = props => (
	<List
		{...props}
		filterDefaultValues={{ status: 'new' }}
		sort={{ field: 'updatedAt', order: 'DESC' }}
		filters={<CustomTourFilter />}
		bulkActionButtons={false}
	>
		<TabbedDatagrid />
	</List>
);

export default CustomTourList;
