/* © 2018 NauStud.io
 * @author Eric Tran
 */

import React, { Component } from 'react';
import { Edit, SimpleForm, minLength, required } from 'react-admin';

import MultiLanguageInput from '../MultiLanguageInput';

class ProductCategoryEdit extends Component {
	render() {
		return (
			<Edit {...this.props} undoable={false}>
				<SimpleForm redirect="list">
					<MultiLanguageInput source="name" validate={required()} />
					<MultiLanguageInput source="description" validate={minLength(10)} />
				</SimpleForm>
			</Edit>
		);
	}
}

export default ProductCategoryEdit;
