/* © 2018 NauStud.io
 * @author Eric Tran
 */

import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Datagrid, List, TextField, EditButton, DeleteButton } from 'react-admin';

import { dataGridStyle, noWrapStyle } from '../../helper/constants';

class CountryList extends Component {
	render() {
		return (
			<List {...this.props} bulkActionButtons={false}>
				<Datagrid styles={dataGridStyle}>
					<TextField source="name.en" label="Name" />
					<EditButton />
					<DeleteButton />
				</Datagrid>
			</List>
		);
	}
}

const styles = {
	...noWrapStyle,
};

export default withStyles(styles)(CountryList);
