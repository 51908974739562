/* © 2019 NauStud.io
 * @author Eric Tran
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import format from 'date-fns/format';
import { PRODUCT_TYPE_NAME_MAPPING } from '../../helper/constants';

class PrivateIntercityTransportationBooking extends Component {
	static propTypes = {
		style: PropTypes.object.isRequired,
		product: PropTypes.object.isRequired,
		itemRenderer: PropTypes.func.isRequired,
		renderMainPassenger: PropTypes.func.isRequired,
	};

	calculateHour = minutes => {
		const hour = Math.floor(minutes / 60);
		const minutesLeft = minutes % 60;
		if (hour) {
			return `${hour} h ${minutesLeft} m`;
		}

		return `${minutes} m`;
	};

	render() {
		const { style, product, itemRenderer, renderMainPassenger } = this.props;

		const intercityCarUrl = `/#/intercity-cars/${product.selectedIntercityCar.id}`;

		return (
			<>
				<h5 style={style.header}>{PRODUCT_TYPE_NAME_MAPPING[product.category]}</h5>
				{itemRenderer('Departure', format(new Date(product.departureDate), 'dd/MM/yyyy | HH:mm'))}
				{itemRenderer('Route', <a href={intercityCarUrl}>{product.selectedIntercityCar.name}</a>)}
				{itemRenderer('Pickup Point', product.pickupAddress)}
				{itemRenderer('Duration', this.calculateHour(product.selectedIntercityCar.duration))}
				{itemRenderer('Distance', `${product.selectedIntercityCar.distance} km`)}
				{itemRenderer(
					'Travellers',
					`${product.numberOfTravellers.adult} Adult(s), ${product.numberOfTravellers.child} Children, ${product.numberOfTravellers.infant} Infant(s)`
				)}
				{itemRenderer(
					'Car',
					`${product.selectedCar.name} (${product.numberOfCar} vehicle(s))`,
					product.selectedCar.price * product.numberOfCar
				)}
				{itemRenderer('Main Passenger', renderMainPassenger(product.mainPassenger))}
				{itemRenderer(
					'Guide',
					product.hireTravelGuide ? `${product.selectedGuide.name}` : 'No guide',
					product.hireTravelGuide ? product.selectedGuide.price : ''
				)}
			</>
		);
	}
}

export default PrivateIntercityTransportationBooking;
