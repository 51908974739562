/* © 2018 NauStud.io
 * @author Tu Nguyen
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';

class Blockquote extends Component {
	state = {
		url: '',
		caption: '',
	};

	_disableOKButton = () => !this.state.url;

	_handleOnChange = e => {
		this.setState({
			[e.target.name]: e.target.value,
		});
	};

	_onSubmit = () => {
		this.props.onSubmit({
			url: this.state.url,
			caption: this.state.caption,
		});
	};

	render() {
		return (
			<div className="blockquote-tooltip">
				<div>
					<div className="flex-column">
						<input
							onChange={this._handleOnChange}
							autoComplete="off"
							type="text"
							name="url"
							placeholder="Youtube URL *"
						/>
						<input
							onChange={this._handleOnChange}
							autoComplete="off"
							type="text"
							name="caption"
							placeholder="caption"
						/>
					</div>
					<div className="flex">
						<button
							type="button"
							className="rte__tooltip-button"
							onClick={this._onSubmit}
							disabled={this._disableOKButton()}
						>
							OK
						</button>
						<button type="button" className="rte__tooltip-button" onClick={this.props.closeTooltip}>
							Cancel
						</button>
					</div>
				</div>
			</div>
		);
	}
}

Blockquote.propTypes = {
	onSubmit: PropTypes.func,
	closeTooltip: PropTypes.func,
};

Blockquote.defaultProps = {
	onSubmit: () => {},
	closeTooltip: () => {},
};

export default Blockquote;
