/* © 2018 NauStud.io
 * @author Eric Tran
 */

import React, { Component } from 'react';
import { Create, SimpleForm, TextInput, NumberInput } from 'react-admin';

class AgencyGroupCreate extends Component {
	render() {
		return (
			<Create {...this.props} undoable={false}>
				<SimpleForm redirect="list">
					<TextInput source="name" />
					<NumberInput source="defaultCommission" label="Default commission (%)" />
				</SimpleForm>
			</Create>
		);
	}
}

export default AgencyGroupCreate;
