/* © 2018 NauStud.io
 * @author Tu, Eric Tran
 */
import Quill from 'quill/dist/quill';

const Block = Quill.import('blots/block');
const Parchment = Quill.import('parchment');
const Inline = Quill.import('blots/inline');
const Container = Quill.import('blots/container');
const TextBlot = Quill.import('blots/text');
// const BlockEmbed 	= Quill.import('blots/block/embed');
const Break = Quill.import('blots/break');

const checkPrev = (current, count = 0) => {
	let c = count;
	const prevElement = current.previousElementSibling;
	if (!prevElement || prevElement.tagName === 'H2' || prevElement.tagName === 'H3' || prevElement.tagName === 'HR') {
		return c;
	} else if (prevElement.className === 'post__list__item') {
		c += 1;
	}

	return checkPrev(prevElement, c);
};

export class BulletPlaceholder extends Parchment.Embed {
	static blotName = 'bulletPlaceholder';
	static className = 'bullet-placeholder';
	static tagName = 'span';

	static value() {
		return undefined;
	}

	insertInto(parent, ref) {
		/* eslint-disable no-use-before-define*/
		if (parent.children.length === 0) {
			const customListItem = parent.parent;
			const placeholderText = Parchment.create('text', `${checkPrev(customListItem.domNode, 1)}.`);
			placeholderText.insertInto(parent, ref);
		} else {
			this.remove();
		}
	}

	length() {
		return 0;
	}

	value() {
		return '';
	}
}

export class HeadingPlaceholder extends Block {
	static blotName = 'headingPlaceholder';
	static className = 'heading-placeholder';
	static tagName = 'span';

	static value() {
		return undefined;
	}

	insertInto(parent, ref) {
		if (parent.children.length === 0) {
			const placeholderText = Parchment.create('break');
			placeholderText.insertInto(parent, ref);
		} else {
			this.remove();
		}
	}

	length() {
		return 0;
	}

	value() {
		return '';
	}
}

export class ListPlaceholder extends Parchment.Embed {
	static blotName = 'listPlaceholder';
	static className = 'list-placeholder';
	static tagName = 'span';

	static value() {
		return undefined;
	}

	insertInto(parent, ref) {
		if (parent.children.length === 0) {
			const bullet = Parchment.create('customBullet');
			const heading = Parchment.create('customHeading');
			bullet.insertInto(parent, ref);
			heading.insertInto(parent, ref);
		} else {
			this.remove();
		}
	}

	length() {
		return 0;
	}

	value() {
		return '';
	}
}

export class Bullet extends Block {
	static blotName = 'customBullet';
	static tagName = 'SPAN';
	static className = 'post__list__bullet';
	static allowedChildren = [BulletPlaceholder, Block, TextBlot, Inline, Break];
	static defaultChild = 'bulletPlaceholder';

	static create(value) {
		const node = super.create(value);
		node.setAttribute('contenteditable', 'false');

		return node;
	}

	static formats(domNode) {
		if (domNode.tagName === 'SPAN' && domNode.className === 'post__list__bullet') {
			return true;
		}

		return undefined;
	}

	format(name, value) {
		if (name === 'customListItem' && !value) {
			this.replaceWith(Parchment.create(this.statics.scope));
		}
	}

	replaceWith(name, value) {
		this.parent.isolate(this.offset(this.parent), this.length());
		if (name === this.parent.statics.blotName) {
			this.parent.replaceWith(name, value);

			return this;
		}
		this.parent.unwrap();

		return super.replaceWith(name, value);
	}

	remove() {
		if (this.parent.statics.blotName === 'customListItem') {
			this.parent.remove();
		} else if (this.parent.children.length === 1) {
			super.replaceWith('text', '');
		} else {
			super.remove();
		}
	}

	insertAt(index, value) {
		if (value !== '\n') {
			super.insertAt(index, value);
		}
	}

	optimize(context) {
		super.optimize(context);
		if (this.parent.statics.blotName !== 'customListItem') {
			this.remove();
		}
	}
}

export class Heading extends Block {
	static blotName = 'customHeading';
	static tagName = 'SPAN';
	static className = 'post__list__heading';
	static allowedChildren = [HeadingPlaceholder, Block, TextBlot, Inline, Break];
	static defaultChild = 'headingPlaceholder';

	static formats(domNode) {
		if (domNode.tagName === 'SPAN' && domNode.className === 'post__list__heading') {
			return true;
		}

		return undefined;
	}

	format(name, value) {
		if (name === 'customListItem' && !value) {
			this.replaceWith(Parchment.create(this.statics.scope));
		}
	}

	replaceWith(name, value) {
		this.parent.isolate(this.offset(this.parent), this.length());
		if (name === this.parent.statics.blotName) {
			this.parent.replaceWith(name, value);

			return this;
		}
		this.parent.unwrap();

		return super.replaceWith(name, value);
	}

	insertAt(index, value) {
		if (value !== '\n') {
			super.insertAt(index, value);
		}
	}

	remove(child) {
		if (this.domNode.innerText.length <= 0) {
			super.remove(child);
		}
	}

	moveChildren() {}
}

export class CustomListItem extends Container {
	static blotName = 'customListItem';
	static tagName = 'DIV';
	static className = 'post__list__item';
	static scope = Parchment.Scope.BLOCK_BLOT;
	static allowedChildren = [TextBlot, Heading, Bullet, ListPlaceholder, Break];
	static defaultChild = 'listPlaceholder';
	static count = 0;

	static formats(domNode) {
		if (domNode.tagName === 'DIV' && domNode.className === 'post__list__item') {
			return true;
		}

		return undefined;
	}

	format(name, value) {
		if (this.children.length > 0) {
			this.children.tail.format(name, value);
		}
	}

	formats() {
		return { [this.statics.blotName]: this.statics.formats(this.domNode) };
	}

	insertBefore(blot, ref) {
		if (blot instanceof Bullet || blot instanceof Heading || blot instanceof ListPlaceholder) {
			super.insertBefore(blot, ref);
		} else {
			const index = ref == null ? this.length() : ref.offset(this);
			const after = this.split(index);
			after.parent.insertBefore(blot, after);
		}
	}

	optimize(context) {
		super.optimize(context);
		if (this.children.length === 0) {
			this.remove();

			return;
		}

		const next = this.next;
		if (
			next != null &&
			next.prev === this &&
			next.statics.blotName === this.statics.blotName &&
			next.domNode.tagName === this.domNode.tagName &&
			this.children.length < 2
		) {
			next.moveChildren(this);
			next.remove();
		}
	}

	replace(target) {
		console.log('customList', CustomListItem.count);
		if (target.statics.blotName !== this.statics.blotName) {
			if (CustomListItem.count % 2 === 0) {
				const bulletItem = Parchment.create('customBullet');
				bulletItem.appendChild(target.children.head);
				bulletItem.domNode.setAttribute('style', '');
				this.appendChild(bulletItem);
			} else {
				const headingItem = Parchment.create('customHeading');
				headingItem.appendChild(target.children.head);
				headingItem.domNode.setAttribute('style', '');
				this.appendChild(headingItem);
			}
			CustomListItem.count += 1;
		}
		super.replace(target);
	}
}
