/* © 2018 NauStud.io
 * @author Quy Tran
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { green, amber } from '@material-ui/core/colors';

import format from 'date-fns/format';
import differenceInDays from 'date-fns/differenceInDays';
import formatDistance from 'date-fns/formatDistance';
import {
	Datagrid,
	List,
	Responsive,
	SimpleList,
	TextField,
	Filter,
	TextInput,
	EditButton,
	DeleteButton,
	ReferenceField,
	NumberField,
} from 'react-admin';
// import { green, amber } from '@material-ui/core/colors';
import { dataGridStyle, noWrapStyle } from '../../helper/constants';

const style = {
	imgWrapper: {
		width: '80px',
		height: '80px',
		position: 'relative',
		margin: '5px 0',
		overflow: 'hidden',
	},
	img: {
		position: 'absolute',
		top: '0',
		left: '50%',
		transform: 'translate(-50%, 0)',
		height: '100%',
	},
};

const ImageCustomField = ({ record = {} }) => (
	<div style={style.imgWrapper}>
		<img style={style.img} src={record.thumbnail} alt="" />
	</div>
);

ImageCustomField.propTypes = {
	record: PropTypes.shape({
		thumbnail: PropTypes.string,
	}),
};

ImageCustomField.defaultProps = { record: null };

const StatusField = ({ record = {} }) => (
	<span style={{ color: record.status === 'published' ? green.A700 : amber['900'] }}>{record.status}</span>
);

StatusField.propTypes = {
	record: PropTypes.shape({
		title: PropTypes.string,
	}),
};

StatusField.defaultProps = { record: null };

const PublishedField = ({ record = {} }) => {
	let publishedAt = record.status === 'published' && record.publishedAt ? new Date(record.publishedAt) : '';

	if (publishedAt) {
		if (differenceInDays(new Date(), publishedAt) >= 10) {
			publishedAt = format(publishedAt, 'dd/MM/yyyy');
		} else {
			publishedAt = `${formatDistance(publishedAt, new Date())} ago`;
		}
	}

	return <span>{publishedAt}</span>;
};

PublishedField.propTypes = {
	record: PropTypes.shape({
		updatedAt: PropTypes.instanceOf(Date),
	}),
};

PublishedField.defaultProps = { record: null };

const LastEditField = ({ record = {} }) => {
	let lastEdit = record.updatedAt ? new Date(record.updatedAt) : '';
	if (lastEdit) {
		if (differenceInDays(new Date(), lastEdit) >= 10) {
			lastEdit = format(lastEdit, 'dd/MM/yyyy');
		} else {
			lastEdit = `${formatDistance(lastEdit, new Date())} ago`;
		}
	}

	return <span>{lastEdit}</span>;
};

LastEditField.propTypes = {
	record: PropTypes.shape({
		updatedAt: PropTypes.instanceOf(Date),
	}),
};

LastEditField.defaultProps = { record: null };

const LocationFilter = props => (
	<Filter {...props}>
		<TextInput label="Search by title, country" source="search" alwaysOn />
	</Filter>
);

class LocationList extends Component {
	static propTypes = {
		classes: PropTypes.shape({}),
	};

	static defaultProps = {
		classes: {},
	};

	getPrimaryText = record => record.title;

	getSecondaryText = record => (record.count ? `${record.count.views} views` : '0');

	getTertiaryText = record => new Date(record.publishedAt).toLocaleDateString();

	render() {
		const { classes } = this.props;

		return (
			<List
				{...this.props}
				filters={<LocationFilter />}
				sort={{ field: 'updatedAt', order: 'DESC' }}
				bulkActionButtons={false}
			>
				<Responsive
					small={
						<SimpleList
							primaryText={this.getPrimaryText}
							secondaryText={this.getSecondaryText}
							tertiaryText={this.getTertiaryText}
						/>
					}
					medium={
						<Datagrid styles={dataGridStyle}>
							<ImageCustomField source="thumbnail" sortable={false} />
							<TextField
								source="title.en"
								label="Title"
								className={classes.maxWidthField}
								sortable={false}
							/>
							<TextField source="alias" className={classes.maxWidthField} sortable={false} />
							{/* <TextField source="description" className={classes.maxWidthField} sortable={false} /> */}
							<ReferenceField label="Country" source="countryId" reference="countries">
								<TextField source="name" />
							</ReferenceField>
							<StatusField source="status" />
							<PublishedField label="Published At" source="publishedAt" />
							<LastEditField label="Last Updated" source="updatedAt" />
							<NumberField label="Order" source="order" />
							<EditButton />
							<DeleteButton />
						</Datagrid>
					}
				/>
			</List>
		);
	}
}

const styles = {
	// centerField: { textAlign: 'center', display: 'block' },
	// maxWidthField: { maxWidth: '200px', display: 'block' },
	...noWrapStyle,
};

export default withStyles(styles)(LocationList);
