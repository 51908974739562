/* © 2018 NauStud.io
 * @author Eric Tran
 */

import { GET_LIST } from 'react-admin';
import restClient from './restClient';

const isEmail = email => {
	const formatEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

	return formatEmail.test(email);
};

const slugify = input => {
	// Chuyển hết sang chữ thường
	let str = input.toLowerCase();

	// xóa dấu
	str = str.replace(/(à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ)/g, 'a');
	str = str.replace(/(è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ)/g, 'e');
	str = str.replace(/(ì|í|ị|ỉ|ĩ)/g, 'i');
	str = str.replace(/(ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ)/g, 'o');
	str = str.replace(/(ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ)/g, 'u');
	str = str.replace(/(ỳ|ý|ỵ|ỷ|ỹ)/g, 'y');
	str = str.replace(/(đ)/g, 'd');

	// Xóa ký tự đặc biệt
	str = str.replace(/([^0-9a-z-\s.])/g, '');

	// Xóa khoảng trắng thay bằng ký tự -
	str = str.replace(/([\s.]+)/g, '-');

	// Xóa ký tự - liên tiếp
	str = str.replace(/-+/g, '-');

	// xóa phần dự - ở đầu
	str = str.replace(/^-+/g, '');

	// xóa phần dư - ở cuối
	str = str.replace(/-+$/g, '');

	// return
	return str;
};

const capitalize = str => {
	if (!str || !str.length) {
		return '';
	}

	return `${str.charAt(0).toUpperCase()}${str.substring(1)}`;
};

const isDuplicateRecord = (value, field, model, editingId) =>
	new Promise(resolve => {
		const filter = {
			[field]: `${value}`,
		};

		if (editingId && field !== 'id') {
			filter.id = { nin: [editingId] };
		}

		restClient(GET_LIST, model, {
			pagination: {},
			sort: {},
			filter,
		}).then(res => {
			resolve({ data: res.data });
		});
	});

const getYoutubeId = url => {
	const regExp = /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
	const match = url.match(regExp);
	if (match && match[2].length === 11) {
		return match[2];
	}

	return null;
};

const getValueNestedObject = (object, propText) => {
	if (!propText || !object) {
		return object;
	}

	const firstDotIndex = propText.indexOf('.');
	const propFirstKey = firstDotIndex < 0 ? propText : propText.substring(0, firstDotIndex);
	const value = object[propFirstKey];

	return getValueNestedObject(value, firstDotIndex < 0 ? '' : propText.substring(firstDotIndex + 1));
};

const calculateDiscount = (validPromotionCode, total) => {
	if (!validPromotionCode || !total) {
		return 0;
	}

	let discount = 0;
	if (validPromotionCode.discountType === 'percentage') {
		discount = Math.ceil((total * validPromotionCode.discountAmount) / 100);
	} else {
		discount = validPromotionCode.discountAmount;
	}
	if (Number.isInteger(validPromotionCode.maxDiscountAmount) && discount > validPromotionCode.maxDiscountAmount) {
		discount = validPromotionCode.maxDiscountAmount;
	}

	return discount;
}

const calculateCommission = (commission, total, totalMarkup) => {
	if (!commission || !total) {
		return 0;
	}

	if (commission.type === 'profit') {
		return ((commission.percentage || 0) * totalMarkup) / 100;
	}

	return ((commission.percentage || 0) * total) / 100;
}

const calculateAirportTransferTotalPrice = (item) => {
	const {
		selectedCar,
		numberOfCar,
		isRoundtrip,
		returnSelectedCar,
		returnNumberOfCar,
		hireTravelGuide,
		selectedGuide,
		validPromotionCode,
	} = item;

	let subtotal = 0;
	let commission = 0;
	// car
	const afterDiscount = selectedCar.price - calculateDiscount(selectedCar.discount, selectedCar.price);
	const price = afterDiscount * (numberOfCar || 1);
	commission += calculateCommission(selectedCar.commission, price, 0);
	subtotal += price;

	if (isRoundtrip) {
		// return car
		const afterDiscount =
			returnSelectedCar.price - calculateDiscount(returnSelectedCar.discount, returnSelectedCar.price);
		const price = afterDiscount * (returnNumberOfCar || 1);
		commission += calculateCommission(returnSelectedCar.commission, price, 0);
		subtotal += price;
	}

	if (hireTravelGuide) {
		// guide
		const price = selectedGuide.price - calculateDiscount(selectedGuide.discount, selectedGuide.price);
		commission += calculateCommission(selectedGuide.commission, price, 0);
		subtotal += price;
	}

	const discount = calculateDiscount(validPromotionCode, subtotal);
	const totalPrice = subtotal - discount - commission;

	return {
		subtotal,
		discount,
		commission,
		totalPrice,
	};
}

const calculateCarRentalTotalPrice = (item) => {
	const { selectedCar, numberOfCar, hireTravelGuide, selectedGuide, validPromotionCode } = item;

	let subtotal = 0;
	let commission = 0;

	const afterDiscount = selectedCar.price - calculateDiscount(selectedCar.discount, selectedCar.price);
	const price = afterDiscount * (numberOfCar || 1);
	commission += calculateCommission(selectedCar.commission, price, 0);
	subtotal += price;

	if (hireTravelGuide) {
		const price = selectedGuide.price - calculateDiscount(selectedGuide.discount, selectedGuide.price);
		commission += calculateCommission(selectedGuide.commission, price, 0);
		subtotal += price;
	}

	const discount = calculateDiscount(validPromotionCode, subtotal);
	const totalPrice = subtotal - discount - commission;

	return {
		subtotal,
		discount,
		commission,
		totalPrice,
	};
}

const calculateGuidePrice = (item) => {
	const { validPromotionCode, commission } = item;
	const price = Number.isInteger(item.price) ? item.price : item.totalPrice;
	const subtotal = price - calculateDiscount(item.discount, price);
	const discount = calculateDiscount(validPromotionCode, subtotal);
	const com = calculateCommission(commission, subtotal, 0);
	const totalPrice = subtotal - discount - com;

	return {
		subtotal,
		discount,
		commission: com,
		totalPrice,
	};
}

const calculateServicePrice = (item) => {
	const { validPromotionCode, commission } = item;
	const subtotal = Number.isInteger(item.subtotal) ? item.subtotal : item.totalPrice;
	const discount = calculateDiscount(validPromotionCode, subtotal);
	const com = calculateCommission(commission, subtotal, 0);
	const totalPrice = subtotal - discount - com;

	return {
		subtotal,
		discount,
		commission: com,
		totalPrice,
	};
}

const calculateOpenBusTotalPrice = (item) => {
	const { openBus, isRoundtrip, returnOpenBus, numberOfTravellers, validPromotionCode } = item;
	const totalSeats = numberOfTravellers.adult + numberOfTravellers.child;

	let subtotal = 0;
	let commission = 0;
	const afterDiscount = openBus.price - calculateDiscount(openBus.discount, openBus.price);
	const price = afterDiscount * totalSeats;
	commission += calculateCommission(openBus.commission, price, 0);
	subtotal += price;
	if (isRoundtrip && returnOpenBus) {
		const afterDiscount = returnOpenBus.price - calculateDiscount(returnOpenBus.discount, returnOpenBus.price);
		const price = afterDiscount * totalSeats;
		commission += calculateCommission(returnOpenBus.commission, price, 0);
		subtotal += price;
	}

	const discount = calculateDiscount(validPromotionCode, subtotal);
	const totalPrice = subtotal - discount - commission;

	return {
		subtotal,
		discount,
		commission,
		totalPrice,
	};
}

export {
	isEmail,
	slugify,
	isDuplicateRecord,
	getYoutubeId,
	capitalize,
	getValueNestedObject,
	calculateDiscount,
	calculateAirportTransferTotalPrice,
	calculateCarRentalTotalPrice,
	calculateOpenBusTotalPrice,
	calculateGuidePrice,
	calculateServicePrice
};
