/* © 2018 NauStud.io
 * @author Eric Tran
 */

import React from 'react';
import { Edit, SimpleForm, ReferenceField, TextField, SelectInput, Toolbar, SaveButton } from 'react-admin';
import PropTypes from 'prop-types';
import { DateInput } from 'react-admin-date-inputs';
import StarRatingField from '../StarRatingField';

const TourReviewSaveToolbar = props => (
	<Toolbar {...props}>
		<SaveButton redirect="list" submitOnEnter={false} />
	</Toolbar>
);

const TourReviewEdit = ({ classes, ...props }) => (
	<Edit {...props} undoable={false}>
		<SimpleForm toolbar={<TourReviewSaveToolbar />}>
			<DateInput source="createdAt" />
			<ReferenceField source="serviceId" reference="services">
				<TextField source="name.en" />
			</ReferenceField>
			<StarRatingField source="rating" />
			<TextField source="text" />
			<SelectInput
				source="status"
				choices={[
					{ id: 'approved', name: 'Approved' },
					{ id: 'pending', name: 'Pending' },
					{ id: 'deleted', name: 'Deleted' },
				]}
			/>
		</SimpleForm>
	</Edit>
);

TourReviewEdit.propTypes = {
	classes: PropTypes.shape({}),
};

TourReviewEdit.defaultProps = {
	classes: {},
};

export default TourReviewEdit;
