/* © 2019 NauStud.io
 * @author Hieu Le
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import format from 'date-fns/format';
import differenceInDays from 'date-fns/differenceInDays';
import formatDistance from 'date-fns/formatDistance';
import {
	Datagrid,
	List,
	TextField,
	EditButton,
	DeleteButton,
	ReferenceArrayField,
	NumberField,
	Filter,
	TextInput,
	SingleFieldList,
	ChipField,
} from 'react-admin';
import { green, amber } from '@material-ui/core/colors';
import { dataGridStyle } from '../../helper/constants';

const CareerFilter = props => (
	<Filter {...props}>
		<TextInput label="Search by position" source="position" alwaysOn />
	</Filter>
);

const CreatedTimeField = ({ record = {} }) => {
	let createdAt = record.createdAt ? new Date(record.createdAt) : '';

	if (createdAt) {
		if (differenceInDays(new Date(), createdAt) >= 10) {
			createdAt = format(createdAt, 'dd/MM/yyyy');
		} else {
			createdAt = `${formatDistance(createdAt, new Date())} ago`;
		}
	}

	return <span>{createdAt}</span>;
};

CreatedTimeField.propTypes = {
	record: PropTypes.shape({
		createdAt: PropTypes.instanceOf(Date),
	}),
};

CreatedTimeField.defaultProps = { record: null };

const StatusField = ({ record = {} }) => (
	<span style={{ color: record.status === 'published' ? green.A700 : amber['900'] }}>{record.status}</span>
);

StatusField.propTypes = {
	record: PropTypes.shape({
		title: PropTypes.string,
	}),
};

StatusField.defaultProps = { record: null };

class CareerList extends Component {
	render() {
		const { classes } = this.props;

		return (
			<List filters={<CareerFilter />} {...this.props} bulkActionButtons={false}>
				<Datagrid classes={classes}>
					<TextField source="id" label="id" sortable={false} />
					<TextField source="position" label="Position" sortable={false} />
					<ReferenceArrayField label="Locations" reference="locations" source="locationIds">
						<SingleFieldList>
							<ChipField source="title.en" />
						</SingleFieldList>
					</ReferenceArrayField>
					<NumberField source="quantity" />
					<StatusField source="status" />
					<CreatedTimeField label="Created at" source="createdAt" />
					<EditButton />
					<DeleteButton />
				</Datagrid>
			</List>
		);
	}
}

const styles = {
	...dataGridStyle,
};

CareerList.propTypes = {
	classes: PropTypes.shape({}),
};

CareerList.defaultProps = {
	classes: {},
};

export default withStyles(styles)(CareerList);
