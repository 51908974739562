/* © 2019 NauStud.io
 * @author Eric Tran
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import format from 'date-fns/format';

class HotelBooking extends Component {
	static propTypes = {
		style: PropTypes.object.isRequired,
		product: PropTypes.object.isRequired,
		itemRenderer: PropTypes.func.isRequired,
		renderMainPassenger: PropTypes.func.isRequired,
	};

	render() {
		const { style, product, itemRenderer, renderMainPassenger } = this.props;

		const { reservation = {} } = product;

		return (
			<>
				<h5 style={style.header}>{product.name}</h5>
				{itemRenderer('Hotel Address', product.address)}
				{itemRenderer('Hotel Phone', product.phone)}
				{itemRenderer('From Date', format(new Date(product.from), 'dd/MM/yyyy'))}
				{itemRenderer('To Date', format(new Date(product.to), 'dd/MM/yyyy'))}
				{itemRenderer('Rooms', product.rooms.map(room => `${room.name} x ${room.quantity}`).join(', '))}
				{itemRenderer('Total Price', '', product.totalPrice)}
				{itemRenderer('Main Passenger', renderMainPassenger(product.mainPassenger))}
				{itemRenderer(
					'Reservation External Booking Reference',
					reservation.externalBookingReference || 'Not yet reserving'
				)}
			</>
		);
	}
}

export default HotelBooking;
