/* © 2018 NauStud.io
 * @author Eric Tran
 */
import React, { Component } from 'react';
import { Field } from 'redux-form';
import { Labeled } from 'react-admin';
import PropTypes from 'prop-types';
import Snackbar from '@material-ui/core/Snackbar';
import { red } from '@material-ui/core/colors';
import ImageDropZone from '../components/ImageDropZone';

const styles = {
	inputLabel: {
		display: 'inline-block',
		height: 40,
		lineHeight: '40px',
		width: 200,
		textAlign: 'center',
		background: '#edecec',
		marginTop: 20,
		marginBottom: 20,
		marginRight: '3rem',
	},
	container: {
		display: 'block',
		margin: '4rem',
		marginLeft: '0',
	},
	inputImage: {
		display: 'inline-block',
		float: 'left',
		clear: 'left',
		maxWidth: 400,
		maxHeight: 400,
		marginBottom: 20,
	},
	removeButton: {
		float: 'left',
		background: 'red',
		outline: 'none',
		border: '0',
		margin: 20,
		height: 40,
		lineHeight: '37px',
		color: 'white',
		fontSize: 14,
		fontWeight: 600,
		padding: '0 15px',
	},
};

const limitFileSize = 5;

class ImageInput extends Component {
	static propTypes = {
		label: PropTypes.string,
		record: PropTypes.shape({}),
		source: PropTypes.string,
		dropzone: PropTypes.shape(),
	};

	static defaultProps = {
		label: 'Image',
		record: {},
		source: 'image',
		dropzone: {},
	};

	constructor(props) {
		super(props);
		const state = {
			isImageError: false,
		};

		if (props.record && props.source) {
			state.value = props.record[props.source];
		}

		this.state = state;
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.record && nextProps.source && !this.state.value) {
			this.setState({ value: nextProps.record[nextProps.source] });
		}
	}

	onChange = e => {
		const input = e.target;
		if (input && input.files && input.files.length) {
			if (this.isFileSizeOk(input.files[0])) {
				this._createBlob(input.files[0], rs => {
					this.setState({
						value: rs,
					});
				});

				this[`uploadFiles-${this.props.source}OnChange`]([...input.files]);
			} else {
				this.setState({
					isImageError: true,
				});
			}
		}
	};

	isFileSizeOk = file => {
		const fileSize = file.size / 1000000;

		if (fileSize > limitFileSize) {
			return false;
		}

		return true;
	};

	imageProcess = file => {
		if (this.isFileSizeOk(file)) {
			this._createBlob(file, rs => {
				this.setState({
					value: rs,
				});

				this[`uploadFiles-${this.props.source}OnChange`]([file]);
			});
		} else {
			this.setState({
				isImageError: true,
			});
		}

		if (this.dummyInput) {
			this.dummyInput.value = '';
		}
	};

	removeImage = () => {
		this.setState({ value: this.props.record[this.props.source] }, () => {
			this[`${this.props.source}OnChange`](null);
		});

		if (this.dummyInput) {
			this.dummyInput.value = '';
		}
	};

	_createBlob = (file, callback) => {
		const reader = new FileReader();
		reader.onload = function() {
			callback(reader.result);
		};
		reader.readAsDataURL(file);
	};

	refDummyInput = input => {
		this.dummyInput = input;
	};

	closePopup = () => {
		this.setState({
			isImageError: false,
		});
	};

	FileInput = ({ input: { name, value, onChange, onBlur, ...inputProps }, meta: omitMeta, ...props }) => {
		this[`${name}OnChange`] = onChange;

		return (
			<span style={styles.container}>
				<span style={styles.container}>
					<input
						ref={this.refDummyInput}
						id={this.props.source}
						accept="image/*"
						onChange={this.onChange}
						type="file"
						{...inputProps}
						{...props}
						hidden
						value=""
					/>
					<label htmlFor={this.props.source} style={styles.inputLabel}>
						Select file to upload
					</label>
					<ImageDropZone label=" " imageProcess={this.imageProcess} {...this.props.dropzone} />
				</span>
				<button
					type="button"
					style={{
						...styles.removeButton,
						display: value ? 'inline-block' : 'none',
					}}
					onClick={this.removeImage}
				>
					REMOVE
				</button>
			</span>
		);
	};

	render() {
		const { label, source, record } = this.props;
		const { value } = this.state;

		return (
			<Labeled label={label}>
				<span>
					<Field hidden name={source} component="input" type="text" value={record[source]} />
					<Field name={`uploadFiles-${source}`} component={this.FileInput} hidden />
					{value ? <img src={value} style={styles.inputImage} alt="" /> : ''}

					<Snackbar
						open={this.state.isImageError}
						message={'File size is larger than 5MB'}
						onClose={this.closePopup}
						bodyStyle={{
							backgroundColor: red['700'],
						}}
					/>
				</span>
			</Labeled>
		);
	}
}

export default ImageInput;
