/* © 2018 NauStud.io
 * @author Eric Tran
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import format from 'date-fns/format';
import differenceInDays from 'date-fns/differenceInDays';
import formatDistance from 'date-fns/formatDistance';

import { Datagrid, List, TextField, EditButton, FormDataConsumer } from 'react-admin';

import { green, amber } from '@material-ui/core/colors';
import { dataGridStyle } from '../../helper/constants';

import DeleteButtonWithConfirmation from '../DeleteButtonWithConfirmation';

const StatusField = ({ record = {} }) => (
	<span style={{ color: record.status === 'published' ? green.A700 : amber['900'] }}>{record.status}</span>
);

StatusField.propTypes = {
	record: PropTypes.shape({
		title: PropTypes.string,
	}),
};

StatusField.defaultProps = { record: null };

const LastEditField = ({ record = {} }) => {
	let lastEdit = record.updatedAt ? new Date(record.updatedAt) : '';
	if (lastEdit) {
		if (differenceInDays(new Date(), lastEdit) >= 10) {
			lastEdit = format(lastEdit, 'dd/MM/yyyy');
		} else {
			lastEdit = `${formatDistance(lastEdit, new Date())} ago`;
		}
	}

	return <span>{lastEdit}</span>;
};

LastEditField.propTypes = {
	record: PropTypes.shape({
		updatedAt: PropTypes.instanceOf(Date),
	}),
};

LastEditField.defaultProps = { record: null };

class PageList extends Component {
	static propTypes = {
		classes: PropTypes.shape({}),
	};

	static defaultProps = {
		classes: {},
	};

	getPrimaryText = record => record.title;

	getTertiaryText = record => new Date(record.updatedAt).toLocaleDateString();

	render() {
		const { classes } = this.props;

		return (
			<List {...this.props} sort={{ field: 'updatedAt', order: 'DESC' }} bulkActionButtons={false}>
				<Datagrid styles={dataGridStyle}>
					<TextField source="slug" label="URL" />
					<TextField source="title.en" label="Title" className={classes.title} />
					<LastEditField label="last updated" source="updatedAt" />
					<EditButton />
					<FormDataConsumer>
						{({ record, ...rest }) => {
							if (record.slug === 'about') {
								return '';
							}

							return <DeleteButtonWithConfirmation undoable={false} record={record} {...rest} />;
						}}
					</FormDataConsumer>
				</Datagrid>
			</List>
		);
	}
}

const styles = {
	title: {
		maxWidth: '200px',
		display: 'block',
	},
	booleanField: { textAlign: 'left', marginLeft: '50px' },
};

export default withStyles(styles)(PageList);
