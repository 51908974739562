/* © 2018 NauStud.io
 * @author Eric Tran
 */

import React from 'react';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import { translate } from 'react-admin';
import { withRouter } from 'react-router';

const Summary = translate(({ value, translate, Icon, color, translateString, history, linkTo }) => {
	const subtitle = translateString ? translate(translateString) : '';
	const styles = {
		card: {
			borderLeft: `solid 4px ${color || '#000'}`,
			flex: '1',
			marginRight: '1em',
			marginBottom: '1em',
			cursor: 'pointer',
		},
		icon: { float: 'right', width: 64, height: 64, padding: 16, color: color || '#000' },
	};

	const navigate = () => {
		if (linkTo) {
			history.push(linkTo);
		}
	};

	return (
		<Card style={styles.card} onClick={navigate}>
			{Icon ? <Icon style={styles.icon} /> : ''}
			<CardHeader title={value || 0} subheader={subtitle} />
		</Card>
	);
});

export default withRouter(Summary);
