/* © 2018 NauStud.io
 * @author Thanh
 */
import Quill from 'quill/dist/quill';

const Parchment = Quill.import('parchment');
const BlockEmbed = Quill.import('blots/block/embed');
// const Embed = Quill.import('blots/embed');
const Container = Quill.import('blots/container');
const Block = Quill.import('blots/block');
// const Inline = Quill.import('blots/inline');
const TextBlot = Quill.import('blots/text');
// const Break = Quill.import('blots/break');

export class ImageCaption extends Block {
	static blotName = 'imageCaption';
	static tagName = 'figcaption';

	constructor(domNode, value) {
		super(domNode, value);
		if (value && value !== true) {
			const node = Parchment.create('text', value);
			this.appendChild(node);
		}
	}

	split() {}

	optimize(context) {
		super.optimize(context);
		if (this.children.head.statics.blotName === 'break') {
			this.remove();
		}
	}
}

export class FullContentImage extends Container {
	static blotName = 'fullContentImage';
	static tagName = 'FIGURE';
	static scope = Parchment.Scope.BLOCK_BLOT;
	static allowedChildren = [ImageCaption, BlockEmbed, TextBlot];

	constructor(domNode, value) {
		super(domNode, value);
		if (value && value.url) {
			const image = Parchment.create('image', value);
			this.appendChild(image);

			if (value.caption) {
				const caption = Parchment.create('imageCaption', value.caption);
				this.appendChild(caption);
			}
		}
	}

	static formats(domNode) {
		if (domNode.tagName === 'FIGURE') {
			return true;
		}

		return undefined;
	}

	format(name, value) {
		if (this.children.length > 0) {
			this.children.tail.format(name, value);
		}
	}

	formats() {
		return { [this.statics.blotName]: this.statics.formats(this.domNode) };
	}

	split() {}
}

export class FullTextImage extends Container {
	static blotName = 'fullTextImage';
	static tagName = 'FIGURE';
	static className = 'container';
	static scope = Parchment.Scope.BLOCK_BLOT;
	static allowedChildren = [ImageCaption, BlockEmbed, TextBlot];

	constructor(domNode, value) {
		super(domNode, value);
		if (value && value.url) {
			const image = Parchment.create('image', value);
			this.appendChild(image);

			if (value.caption) {
				const caption = Parchment.create('imageCaption', value.caption);
				this.appendChild(caption);
			}
		}
	}

	static formats(domNode) {
		if (domNode.tagName === 'FIGURE' && domNode.className === 'container') {
			return true;
		}

		return undefined;
	}

	format(name, value) {
		if (this.children.length > 0) {
			this.children.tail.format(name, value);
		}
	}

	formats() {
		return { [this.statics.blotName]: this.statics.formats(this.domNode) };
	}
}

export class SideCaptionImage extends Container {
	static blotName = 'sideCaptionImage';
	static tagName = 'FIGURE';
	static className = 'side-caption';
	static scope = Parchment.Scope.BLOCK_BLOT;
	static allowedChildren = [ImageCaption, BlockEmbed, TextBlot];

	constructor(domNode, value) {
		super(domNode, value);
		if (value && value.url) {
			const image = Parchment.create('image', value);
			this.appendChild(image);

			if (value.caption) {
				const caption = Parchment.create('imageCaption', value.caption);
				this.appendChild(caption);
			}
		}
	}

	static formats(domNode) {
		if (domNode.tagName === 'FIGURE' && domNode.className === 'side-caption') {
			return true;
		}

		return undefined;
	}

	format(name, value) {
		if (this.children.length > 0) {
			this.children.tail.format(name, value);
		}
	}

	formats() {
		return { [this.statics.blotName]: this.statics.formats(this.domNode) };
	}

	split() {}
}
