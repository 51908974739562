/* © 2018 NauStud.io
 * @author Hai Luu
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
	Datagrid,
	EditButton,
	DeleteButton,
	List,
	Responsive,
	SimpleList,
	TextField,
	ImageField,
	UrlField,
	ReferenceField,
	Filter,
	TextInput,
} from 'react-admin';

const DurationField = ({ record }) => {
	const duration = record.duration;
	const seconds = `0${parseInt((duration / 1000) % 60, 10)}`;
	const minutes = `0${parseInt((duration / (1000 * 60)) % 60, 10)}`;
	const hours = `0${parseInt((duration / (1000 * 60 * 60)) % 24, 10)}`;

	return (
		<span>
			{`${hours.substr(hours.length - 2)} : ${minutes.substr(minutes.length - 2)} : ${seconds.substr(
				seconds.length - 2
			)}`}
		</span>
	);
};

DurationField.propTypes = { record: PropTypes.shape({}) };

DurationField.defaultProps = { record: {} };

const MediaFilter = props => (
	<Filter {...props}>
		<TextInput label="Search" source="name.regexp" alwaysOn />
	</Filter>
);

class MediaList extends Component {
	getPrimaryText = record => record.name;

	getSecondaryText = record => record.description;

	render() {
		return (
			<List
				{...this.props} filters={<MediaFilter />}
				bulkActionButtons={false}
			>
				<Responsive
					small={<SimpleList primaryText={this.getPrimaryText} secondaryText={this.getSecondaryText} />}
					medium={
						<Datagrid>
							<TextField source="id" />
							<TextField
								source="name"
								style={{ maxWidth: '18em', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
							/>
							<UrlField source="url" />
							<ImageField source="poster" title="poster" />
							<TextField source="type" />
							<DurationField source="duration" />
							<ReferenceField label="Album" source="albumId" reference="albums">
								<TextField source="name" />
							</ReferenceField>
							{/* <BooleanField source="hidden" />  */}
							{/* <NumberField source="count.views" label="Views" /> */}
							{/* <TextField source="createBy" label="Created by" />
							<DateField source="createdAt" label="Created at" />
							<DateField source="updatedAt" label="Updated at" />
							<DateField source="updatedBy" label="Updated by" /> */}
							<EditButton />
							<DeleteButton />
						</Datagrid>
					}
				/>
			</List>
		);
	}
}

export default MediaList;
