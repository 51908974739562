/* © 2019 NauStud.io
 * @author Eric Tran
 */

import React, { Component } from 'react';
import {
	Edit,
	TabbedForm,
	FormTab,
	TextInput,
	LongTextInput,
	BooleanInput,
	SelectInput,
	ReferenceInput,
	NumberInput,
	FormDataConsumer,
	ArrayInput,
	SimpleFormIterator,
	required,
	minValue,
	maxValue,
} from 'react-admin';
import { DateInput } from 'react-admin-date-inputs';

import ProductCostTable from '../ProductCostTable';

class TourGuideEdit extends Component {
	render() {
		return (
			<Edit {...this.props} undoable={false}>
				<TabbedForm redirect="list">
					<FormTab label="General">
						<TextInput source="vtCode" label="Vietravel Code" validate={required()} />
						<TextInput source="name" label="Name" validate={required()} />
						<LongTextInput source="description" label="Description" />
						<ReferenceInput source="allotmentId" reference="guide-allotments" label="Guide Department">
							<SelectInput optionText="name" />
						</ReferenceInput>
						<NumberInput source="release" label="Release (Hour)" validate={required()} />
						<BooleanInput source="active" label="Active" />
					</FormTab>
					<FormTab label="Cost Information">
						<DateInput
							source="supplyTerm.from"
							label="Supply Term (From)"
							validate={required()}
							options={{ format: 'dd/MM/yyyy' }}
						/>
						<DateInput
							source="supplyTerm.to"
							label="Supply Term (To)"
							validate={required()}
							options={{ format: 'dd/MM/yyyy' }}
						/>
						<ArrayInput source="costInfo" label="Cost Information Array">
							<SimpleFormIterator>
								<ReferenceInput label="Language" source="language" reference="guide-languages">
									<SelectInput optionText="name" />
								</ReferenceInput>
								<NumberInput source="halfdayCost" label="Half day Cost (VND)" />
								<NumberInput source="fulldayCost" label="Full day Cost (VND)" />
								<NumberInput source="extraCost" label="Extra day Cost (VND)" />
							</SimpleFormIterator>
						</ArrayInput>
						<SelectInput
							source="markupType"
							defaultValue="fix"
							choices={[{ id: 'fix', name: 'Fix' }, { id: 'percentage', name: '%' }]}
						/>
						<FormDataConsumer>
							{({ formData }) => {
								if (formData.markupType === 'percentage') {
									return (
										<NumberInput
											source="markupAmount"
											inputProps={{ min: 0, max: 100 }}
											validate={[minValue(0), maxValue(100)]}
											defaultValue={0}
										/>
									);
								}

								return (
									<NumberInput
										source="markupAmount"
										inputProps={{ min: 0 }}
										validate={minValue(0)}
										defaultValue={0}
									/>
								);
							}}
						</FormDataConsumer>
						<BooleanInput source="fillTheTable" label="Fill the table" />
						<FormDataConsumer>
							{({ formData }) => {
								if (formData.fillTheTable) {
									return (
										<ProductCostTable
											formData={formData}
											lookupValue="language"
											changeValues={['halfdayCost', 'fulldayCost', 'extraCost']}
										/>
									);
								}

								return '';
							}}
						</FormDataConsumer>
					</FormTab>
				</TabbedForm>
			</Edit>
		);
	}
}

export default TourGuideEdit;
