/* © 2018 NauStud.io
 * @author  Tu Nguyen
 */
import React from 'react';
import TextField from '@material-ui/core/TextField';
import format from 'date-fns/format';
import PropTypes from 'prop-types';

const DateField = ({ source, record, label = {} }) => (
	<TextField label={label} disabled defaultValue={record[source] ? format(new Date(record[source]), 'dd/MM/yyyy') : 'N/A'} />
);

DateField.propTypes = {
	source: PropTypes.string,
	record: PropTypes.shape({}),
	label: PropTypes.string,
};

DateField.defaultProps = {
	record: {},
	source: '',
	label: '',
};

export default DateField;
