/* © 2019 NauStud.io
 * @author Eric Tran
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { AutocompleteArrayInput, AutocompleteInput, ReferenceInput, ReferenceArrayInput } from 'react-admin';

class SearchUserInput extends Component {
	static propTypes = {
		source: PropTypes.string.isRequired,
		label: PropTypes.string,
		validate: PropTypes.object,
		isMultiple: PropTypes.bool,
	};

	static defaultProps = {
		label: 'User',
		validate: undefined,
		isMultiple: false,
	};

	_renderUserSearchInput = data => {
		if (data.email && !this.props.isMultiple) {
			return `${data.fullname} - ${data.email}`;
		}

		return data.fullname;
	};

	render() {
		const { source, label, validate, isMultiple, ...rest } = this.props;
		let Reference = ReferenceInput;
		let Input = AutocompleteInput;
		if (isMultiple) {
			Reference = ReferenceArrayInput;
			Input = AutocompleteArrayInput;
		}

		return (
			<Reference
				{...rest}
				label={label}
				source={source}
				reference="users"
				sort={{ field: 'name.first', order: 'ASC' }}
				validate={validate}
				perPage={1000}
				filterToQuery={searchText => ({
					or: [
						{ 'name.first': { like: searchText } },
						{ 'name.last': { like: searchText } },
						{ email: { like: searchText } },
					],
				})}
			>
				<Input optionText={this._renderUserSearchInput} />
			</Reference>
		);
	}
}

export default SearchUserInput;
