/* © 2018 NauStud.io
 * @author Eric
 */

import Quill from 'quill/dist/quill';

const Embed = Quill.import('blots/embed');
const Parchment = Quill.import('parchment');
const Container = Quill.import('blots/container');
const Inline = Quill.import('blots/inline');
const TextBlot = Quill.import('blots/text');

export class Video extends Embed {
	static blotName = 'videoIframe';
	static tagName = 'iframe';

	static create(url) {
		const node = super.create();
		node.setAttribute('src', url);
		node.setAttribute('frameborder', '0');
		node.setAttribute('allowfullscreen', true);

		return node;
	}

	static value(node) {
		return node.getAttribute('src');
	}

	remove() {
		if (this.parent) {
			this.parent.remove();
		}
	}
}

export class VideoCaption extends Inline {
	static blotName = 'videoCaption';
	static className = 'container-full';
	static tagName = 'em';

	constructor(domNode, value) {
		super(domNode, value);
		if (value && value !== true) {
			const node = Parchment.create('text', value);
			this.appendChild(node);
		}
	}

	split() {}

	insertAt() {}
}

export class VideoBlot extends Container {
	static blotName = 'video';
	static tagName = 'DIV';
	static className = 'video';
	static scope = Parchment.Scope.BLOCK_BLOT;
	static allowedChildren = [Embed, Video, VideoCaption, TextBlot];

	constructor(domNode, value) {
		super(domNode, value);
		if (value && value.url) {
			const image = Parchment.create('videoIframe', value.url);
			this.appendChild(image);

			if (value.caption) {
				const caption = Parchment.create('videoCaption', value.caption);
				this.appendChild(caption);
			}
		}
	}

	static formats(domNode) {
		if (domNode.tagName === this.tagName && domNode.className === this.className) {
			return true;
		}

		return undefined;
	}

	format(name, value) {
		if (this.children.length > 0) {
			this.children.tail.format(name, value);
		}
	}

	formats() {
		return { [this.statics.blotName]: this.statics.formats(this.domNode) };
	}

	split() {}
}
