/* © 2019 NauStud.io
 * @author Hieu Le
 */

import React, { Component } from 'react';
// import CardActions from '@material-ui/core/CardActions';
import { unparse as convertToCSV } from 'papaparse/papaparse.min';
import {
	Datagrid,
	TextField,
	List,
	Filter,
	TextInput,
	NumberField,
	DateInput,
	ReferenceField,
	downloadCSV,
} from 'react-admin';
import config from '../../config';

const ReportFilter = props => (
	<Filter {...props}>
		<TextInput label="Agency" source="search" alwaysOn />
		<TextInput label="Market" source="searchMarket" alwaysOn />
		<TextInput label="Agency Group" source="searchAgencyGroup" alwaysOn />
		<DateInput label="Booking Date From" source="createdAt.gte" alwaysOn />
		<DateInput label="Booking Date To" source="createdAt.lte" alwaysOn />
	</Filter>
);

// const Action = (filterValues) => {
// 	console.log(filterValues);
// 	const _handleExport = () => {
// 		const request = new Request(`${config.apiHost}/api/export`, {
// 			method: 'GET',
// 			headers: new Headers({ 'Content-Type': 'application/json' }),
// 		});

// 		return fetch(request)
// 			.then(response => {
// 				console.log(response)
// 			})
// 	}

// 	return (
// 		<CardActions>
// 			<Button primary label="Export" onClick={_handleExport} />
// 			<ExportButton />
// 		</CardActions>
// 	);
// };

const exporter = (records, fetchRelatedRecords) => {
	fetchRelatedRecords(records, 'userId', 'users').then(users => {
		const data = records.map(record => ({
			...record,
			agency: `${users[record.userId].name.first} ${users[record.userId].name.last}`,
			agencyGroup: users[record.userId].agencyGroup.name,
			market: users[record.userId].market.name,
			totalPrice: users[record.userId].totalPrice,
			date: new Date(record.createdAt).toLocaleDateString(),
			linkToTour: `=HYPERLINK("http://${config.websiteHost}/tour/${record.tourInfo.tourSlug}","Click to view")`,
		}));
		const csv = convertToCSV({
			data,
			fields: ['id', 'agency', 'agencyGroup', 'market', 'totalPrice', 'date', 'linkToTour'],
		});
		downloadCSV(`\ufeff${csv}`, 'report');
	});
};

class Report extends Component {
	render() {
		return (
			<List
				{...this.props}
				resource="bookings"
				bulkActionButtons={false}
				hasCreate={false}
				hasEdit={false}
				hasShow={false}
				hasList
				basePath={'/'}
				filters={<ReportFilter />}
				exporter={exporter}
			>
				<Datagrid>
					<TextField source="id" label="Tour Id" sortable={false} />
					<TextField source="tourInfo.tourTitle" label="Tour name" sortable={false} />
					<ReferenceField source="userId" label="Agency" basePath="users" reference="users">
						<TextField source="fullname" />
					</ReferenceField>
					<ReferenceField source="userId" label="Agency Group" basePath="users" reference="users">
						<TextField source="agencyGroup.name" />
					</ReferenceField>
					<ReferenceField source="userId" label="Market" basePath="users" reference="users">
						<TextField source="market.name" />
					</ReferenceField>
					<NumberField source="totalPrice" label="Total price" />
				</Datagrid>
			</List>
		);
	}
}

export default Report;
