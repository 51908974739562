/* © 2018 NauStud.io
 * @author Thanh
 */
/* TODO: if we remove the toolbar, we need to switch import from quill/dist/quill.core */
import Quill from 'quill/dist/quill';
import CustomBlockquote from './blots/CustomBlockquote';
import { ImageCaption, FullTextImage, FullContentImage } from './blots/Image';
import { Video, VideoCaption, VideoBlot } from './blots/Video';
import {
	HeadingPlaceholder,
	BulletPlaceholder,
	ListPlaceholder,
	Bullet,
	Heading,
	CustomListItem,
} from './blots/ListItem';

const Inline = Quill.import('blots/inline');
const BlockEmbed = Quill.import('blots/block/embed');
const Block = Quill.import('blots/block');
const Parchment = Quill.import('parchment');
const Delta = Quill.import('delta');

// below are simple / inline blots, for complicated blots,
// please define in ./blots/*

class BoldBlot extends Inline {
	static blotName = 'bold';
	static tagName = 'STRONG';

	static create() {
		return super.create();
	}

	static formats() {
		return true;
	}

	optimize(context) {
		super.optimize(context);
		if (this.children.head && this.children.head.statics.blotName === 'dropCap') {
			this.children.head.moveChildren(this);
			this.replaceWith('dropCap');

			if (this.parent.domNode.nodeName === 'P' && this.parent.next.domNode.nodeName === 'P') {
				this.parent.next.moveChildren(this.parent);
				this.parent.next.remove();
			}
		}
	}
}

class ItalicBlot extends Inline {
	static blotName = 'italic';
	static tagName = 'em';
}

class LinkBlot extends Inline {
	static blotName = 'link';
	static tagName = 'a';

	static create({ url, openLinkType }) {
		const node = super.create();
		node.setAttribute('href', url);
		node.setAttribute('target', openLinkType);

		return node;
	}

	static formats(node) {
		return node.getAttribute('href');
	}
}

export class HeaderBlot extends Block {
	static blotName = 'header';
	static tagName = ['H1', 'H2', 'H3', 'H4', 'H5', 'H6'];

	static formats(node) {
		return HeaderBlot.tagName.indexOf(node.tagName) + 1;
	}
}

class DividerBlot extends BlockEmbed {
	static blotName = 'divider';
	static tagName = 'hr';
}

class ImageBlot extends BlockEmbed {
	static blotName = 'image';
	static tagName = 'img';

	static create(value) {
		const node = super.create();
		node.setAttribute('alt', value.alt);
		node.setAttribute('src', value.url);

		return node;
	}

	static value(node) {
		return {
			alt: node.getAttribute('alt'),
			url: node.getAttribute('src'),
		};
	}

	remove() {
		if (this.parent) {
			this.parent.remove();
		}
	}
}

// Image
Quill.register(ImageCaption);
Quill.register(FullTextImage);
Quill.register(FullContentImage);

Quill.register(BoldBlot);
Quill.register(ItalicBlot);
Quill.register(LinkBlot);
Quill.register(HeaderBlot);
Quill.register(DividerBlot);
Quill.register(ImageBlot);

// Video
Quill.register(Video);
Quill.register(VideoCaption);
Quill.register(VideoBlot);

//CustomBlockquote
Quill.register(CustomBlockquote);

// List item
Quill.register(BulletPlaceholder);
Quill.register(HeadingPlaceholder);
Quill.register(ListPlaceholder);
Quill.register(Bullet);
Quill.register(Heading);
Quill.register(CustomListItem);

export default Quill;

// exports for use in index.js, do not use in ./blots/*
export { Inline, Block, BlockEmbed, Delta, FullContentImage, FullTextImage, /* SideCaptionImage, */ Parchment };
