/* © 2018 NauStud.io
 * @author Hai Luu
 */

import React, { Component } from 'react';
import { Create, LongTextInput, SimpleForm, TextInput, minLength } from 'react-admin';

class AlbumsCreate extends Component {
	render() {
		return (
			<Create {...this.props} undoable={false}>
				<SimpleForm>
					<TextInput source="name" />
					<LongTextInput source="description" validate={minLength(10)} />
					<TextInput source="metaTitle" label="Meta Title" />
					<TextInput source="metaDescription" label="Meta Description" />
				</SimpleForm>
			</Create>
		);
	}
}

export default AlbumsCreate;
