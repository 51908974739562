/* © 2019 NauStud.io
 * @author Eric Tran
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { AutocompleteArrayInput, AutocompleteInput, ReferenceInput, ReferenceArrayInput } from 'react-admin';

class SearchActivityInput extends Component {
	static propTypes = {
		source: PropTypes.string.isRequired,
		label: PropTypes.string,
		validate: PropTypes.object,
		isMultiple: PropTypes.bool,
		customFilter: PropTypes.object,
	};

	static defaultProps = {
		label: 'Service',
		validate: undefined,
		isMultiple: false,
		customFilter: {},
	};

	_renderActivitySearchInput = data => {
		if (!this.props.isMultiple) {
			return `${data.name.en} - ${data.id}`;
		}

		return data.name.en;
	};

	_filterToQuery = searchText => {
		const { customFilter } = this.props;
		const filter = {
			or: [{ name: { like: searchText } }, { id: { like: searchText } }],
			...customFilter,
		};

		return filter;
	};

	render() {
		const { source, label, validate, isMultiple, customFilter, ...rest } = this.props;
		let Reference = ReferenceInput;
		let Input = AutocompleteInput;
		if (isMultiple) {
			Reference = ReferenceArrayInput;
			Input = AutocompleteArrayInput;
		}

		const filter = customFilter;

		return (
			<Reference
				{...rest}
				label={label}
				source={source}
				reference="services"
				sort={{ field: 'name.en', order: 'ASC' }}
				validate={validate}
				allowEmpty
				filter={filter}
				perPage={1000}
				filterToQuery={this._filterToQuery}
			>
				<Input optionText={this._renderActivitySearchInput} />
			</Reference>
		);
	}
}

export default SearchActivityInput;
