/* © 2018 NauStud.io
 * @author Eric Tran
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FieldTitle, isRequired } from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
import { FormControl, InputLabel } from '@material-ui/core';
import { capitalize } from '../helper/utils';

class MultiLanguageRichTextInput extends Component {
	static propTypes = {
		source: PropTypes.string.isRequired,
		label: PropTypes.string,
		resource: PropTypes.string,
		validate: PropTypes.oneOfType([PropTypes.func, PropTypes.arrayOf(PropTypes.func)]),
	};

	static defaultProps = {
		label: '',
		resource: '',
		validate: undefined,
	};

	componentWillMount = () => {
		this.availableLanguages = JSON.parse(localStorage.getItem('languages') || []);
	};

	render() {
		const { source, label, resource, validate } = this.props;

		if (!this.availableLanguages.length) {
			return <RichTextInput source={source} label={label || capitalize(source)} validate={validate} />;
		}

		return (
			<FormControl>
				<InputLabel htmlFor={source} shrink style={{ marginTop: 20 }}>
					<FieldTitle
						label={label || capitalize(source)}
						source={source}
						resource={resource}
						isRequired={isRequired(validate)}
					/>
				</InputLabel>
				<br />
				<br />
				{this.availableLanguages.map(language => (
					<div style={{ marginLeft: '30px' }}>
						<span style={{ fontFamily: 'Muli, Helvetica, Arial, sans-serif', marginTop: '15px', fontSize: '14px', display: 'block' }}>{language.name || language.code}</span>
						<RichTextInput
							key={language.code}
							source={`${source}.${language.code}`}
							label={language.name || language.code}
							validate={language.code === 'en' ? validate : undefined}
						/>
						<br />
					</div>
				))}
			</FormControl>
		);
	}
}

export default MultiLanguageRichTextInput;
