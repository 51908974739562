/* © 2019 NauStud.io
 * @author Eric Tran
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import format from 'date-fns/format';
import { PRODUCT_TYPE_NAME_MAPPING } from '../../helper/constants';

class ServiceBooking extends Component {
	static propTypes = {
		style: PropTypes.object.isRequired,
		product: PropTypes.object.isRequired,
		itemRenderer: PropTypes.func.isRequired,
		renderMainPassenger: PropTypes.func.isRequired,
	};

	render() {
		const { style, product, itemRenderer, renderMainPassenger } = this.props;

		const serviceUrl = `/#/services/${product.id}`;

		return (
			<>
				<h5 style={style.header}>{PRODUCT_TYPE_NAME_MAPPING[product.category] || product.name}</h5>
				{itemRenderer('Service Url', <a href={serviceUrl}>{product.id}</a>)}
				{itemRenderer('Departure', format(new Date(product.departureDate), 'dd/MM/yyyy | HH:mm'))}
				{itemRenderer('Pickup Point', product.pickupAddress)}
				{itemRenderer('Special Request', product.specialRequest)}
				{itemRenderer(
					'Travellers',
					`${product.numberOfTravellers.adult} Adult(s), ${product.numberOfTravellers.child} Children, ${product.numberOfTravellers.infant} Infant(s)`,
					product.subtotal
				)}
				{itemRenderer('Main Passenger', renderMainPassenger(product.mainPassenger))}
				{product.selectedGuide
					? itemRenderer('Guide', product.selectedGuide.name, product.selectedGuide.price)
					: ''}
			</>
		);
	}
}

export default ServiceBooking;
