/* © 2018 NauStud.io
 * @author Tu Nguyen
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';

class ImageTooltip extends Component {
	static propTypes = {
		isFormatting: PropTypes.bool,
		closeTooltip: PropTypes.func,
		onSubmit: PropTypes.func,
		url: PropTypes.bool,
		// eslint-disable-next-line react/no-unused-prop-types
		hasContainer: PropTypes.bool,
	};

	static defaultProps = {
		isFormatting: false,
		onSubmit: () => {},
		closeTooltip: () => {},
		url: false,
		hasContainer: false,
	};

	static getDerivedStateFromProps(props, state) {
		console.log(props);

		return {
			...state,
			hasContainer: props.hasContainer,
		};
	}

	constructor(props) {
		super(props);

		this.state = {
			altText: '',
			file: false,
			url: '',
			caption: '',
			hasContainer: false,
		};
	}

	_onSubmit = () => {
		const { url } = this.props;
		const { altText, file, caption, hasContainer } = this.state;

		const payload = {
			altText,
			caption,
			hasContainer,
		};

		if (url) {
			payload.url = this.state.url;
		} else {
			payload.file = file;
		}

		this.props.onSubmit(payload);
	};

	_handleOnChange = e => {
		if (e.target.name === 'file') {
			this.setState({
				[e.target.name]: e.target.files[0],
			});
		} else if (e.target.name === 'hasContainer') {
			this.setState({
				[e.target.name]: e.target.checked,
			});
		} else {
			this.setState({
				[e.target.name]: e.target.value,
			});
		}
	};

	_disableOKButton = () => {
		const { url } = this.props;

		if (url) {
			return !(!!this.state.altText && !!this.state.url);
		}

		return !(!!this.state.altText && !!this.state.file);
	};

	_renderInputImage = () => {
		const { url } = this.props;

		if (url) {
			return (
				<input
					onChange={this._handleOnChange}
					autoComplete="off"
					type="text"
					name="url"
					className="rte__tooltip-input"
					placeholder="Image URL *"
				/>
			);
		}

		return (
			<input onChange={this._handleOnChange} type="file" name="file" accept="image/*" className="rte__tooltip-input" />
		);
	};

	render() {
		const { isFormatting } = this.props;
		const { hasContainer } = this.state;

		return (
			<div className="flex-column ">
				<div className="rte__tooltip-input-container">
					{!isFormatting && this._renderInputImage()}
					{!isFormatting && (
						<>
							<input
								onChange={this._handleOnChange}
								type="text"
								name="altText"
								placeholder="Alt text *"
								className="rte__tooltip-input"
								autoComplete="off"
							/>
							<input
								onChange={this._handleOnChange}
								type="text"
								name="caption"
								placeholder="Caption"
								className="rte__tooltip-input"
								autoComplete="off"
							/>
						</>
					)}
					<div className="flex align-center tooltip-content__checkbox">
						<input
							type="checkbox"
							checked={hasContainer}
							id="isFullWidth"
							onChange={this._handleOnChange}
							name="hasContainer"
						/>
						<label htmlFor="isFullWidth">Has container</label>
					</div>
				</div>
				<div className="rte__tooltip__actions flex">
					<button
						className="rte__tooltip-button"
						disabled={!isFormatting && this._disableOKButton()}
						onClick={this._onSubmit}
					>
						OK
					</button>
					<button type="button" className="rte__tooltip-button" onClick={this.props.closeTooltip}>
						Cancel
					</button>
				</div>
			</div>
		);
	}
}

export default ImageTooltip;
