/* © 2018 NauStud.io
 * @author Eric Tran
 */

/* eslint-disable react/no-array-index-key */

import React from 'react';
import PropTypes from 'prop-types';
import Icon from '@material-ui/icons/Stars';

const style = { opacity: 0.87, width: 20, height: 20 };

const StarRatingField = ({ record, source }) => record && (
	<span>
		{Array(record[source])
			.fill(true)
			.map((_, i) => <Icon key={i} style={style} />)}
	</span>
);

StarRatingField.propTypes = {
	record: PropTypes.object,
	source: PropTypes.string,
}

StarRatingField.defaultProps = {
	record: null,
    source: 'rating',
};

export default StarRatingField;
