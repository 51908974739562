/* © 2018 NauStud.io
 * @author Eric Tran
 */

import React, { Component } from 'react';
import {
	Edit,
	TabbedForm,
	FormTab,
	TextInput,
	SelectInput,
	ReferenceArrayField,
	SingleFieldList,
	ChipField,
	ArrayInput,
	SimpleFormIterator,
	ReferenceInput,
	LongTextInput,
	NumberInput,
	FormDataConsumer,
	ReferenceField,
	TextField,
	Labeled,
	required,
} from 'react-admin';

import { DateInput, DateTimeInput } from 'react-admin-date-inputs';
import LocationInput from '../LocationInput';
import config from '../../config';

class CustomTourEdit extends Component {
	render() {
		return (
			<Edit undoable={false} {...this.props}>
				<TabbedForm>
					<FormTab label="summary">
						<FormDataConsumer>
							{({ formData }) => {
								const href = `${config.websiteHost || 'http://localhost:3002'}/plan-your-trip/${formData.slug}`;

								return (
									<Labeled label="Link to tour">
										<a href={href}>{href}</a>
									</Labeled>
								);
							}}
						</FormDataConsumer>
						<FormDataConsumer>
							{({ formData }) => {
								if (formData.status === 'ready') {
									return <TextInput source="vtTourId" validate={required()} label="Vietravel Tour Code" />
								}

								return <TextInput source="vtTourId" label="Vietravel Tour Code" />
							}}
						</FormDataConsumer>
						<TextInput source="title" />
						<ReferenceArrayField allowEmpty label="Travel Styles" reference="travel-styles" source="travelStyleIds">
							<SingleFieldList>
								<ChipField source="name" />
							</SingleFieldList>
						</ReferenceArrayField>
						<ReferenceField reference="users" source="userId">
							<TextField source="email" />
						</ReferenceField>
						<TextInput source="contactName" />
						<TextInput source="contactEmail" />
						<LongTextInput source="note" label="User's note" />
						<LongTextInput source="noteForOperator" label="Note for Operator" />
						<SelectInput
							source="status"
							choices={[
								{ id: 'new', name: 'New' },
								{ id: 'inquiry', name: 'Inquiry' },
								{ id: 'ready', name: 'Ready for user payment' },
								{ id: 'expired', name: 'Expired' },
								{ id: 'paid', name: 'Paid', disabled: true },
							]}
						/>
					</FormTab>
					<FormTab label="Route">
						<ArrayInput source="route">
							<SimpleFormIterator disableAdd disableRemove>
								<LocationInput label="Location" source="locationId" />
								<FormDataConsumer>
									{({ formData, scopedFormData, getSource, ...rest }) => {
										const { record } = rest;

										return (
											record.from && (
												<DateTimeInput
													source={getSource('from')}
													{...rest}
													label="From"
													options={{ format: 'dd/MM/yyyy, HH:mm' }}
												/>
											)
										);
									}}
								</FormDataConsumer>
								<FormDataConsumer>
									{({ formData, scopedFormData, getSource, ...rest }) => {
										const { record } = rest;

										return (
											record.to && (
												<DateTimeInput
													source={getSource('to')}
													{...rest}
													label="To"
													options={{ format: 'dd/MM/yyyy, HH:mm' }}
												/>
											)
										);
									}}
								</FormDataConsumer>
								<ArrayInput source="transits" label="Transits">
									<SimpleFormIterator>
										<TextInput source="title" label="Title" />
										<TextInput source="description" label="Description" />
										<DateTimeInput source="from" label="From" options={{ format: 'dd/MM/yyyy, HH:mm' }} />
										<DateTimeInput source="to" label="To" options={{ format: 'dd/MM/yyyy, HH:mm' }} />
									</SimpleFormIterator>
								</ArrayInput>
							</SimpleFormIterator>
						</ArrayInput>
					</FormTab>
					<FormTab label="Day By Day">
						<ArrayInput source="dayByDay">
							<SimpleFormIterator>
								<DateInput source="day" label="Day" options={{ format: 'dd/MM/yyyy' }} />
								<ArrayInput source="activities" label="Activities">
									<SimpleFormIterator>
										<TextInput disabled source="productId" label=" " />
										<TextInput disabled source="product.name" label="Product Name" />
										<FormDataConsumer>
											{({ formData, scopedFormData, getSource, ...rest }) =>
												scopedFormData &&
												scopedFormData.productId && (
													<DateTimeInput
														source={getSource('from')}
														{...rest}
														label="From"
														options={{ format: 'dd/MM/yyyy, HH:mm' }}
													/>
												)
											}
										</FormDataConsumer>
										<FormDataConsumer>
											{({ formData, scopedFormData, getSource, ...rest }) =>
												scopedFormData &&
												scopedFormData.productId && (
													<DateTimeInput
														source={getSource('to')}
														{...rest}
														label="To"
														options={{ format: 'dd/MM/yyyy, HH:mm' }}
													/>
												)
											}
										</FormDataConsumer>
										<TextInput source="note" label="Note" />
										<FormDataConsumer>
											{({ formData, scopedFormData, getSource, ...rest }) =>
												scopedFormData &&
												scopedFormData.productId && (
													<NumberInput {...rest} source={getSource('quantity')} label="Quantity" />
												)
											}
										</FormDataConsumer>
									</SimpleFormIterator>
								</ArrayInput>
							</SimpleFormIterator>
						</ArrayInput>
					</FormTab>
					<FormTab label="Payment Price">
						<ArrayInput source="paymentPrice">
							<SimpleFormIterator disableRemove disableAdd>
								<TextInput disabled source="productId" label="Product Id" />
								<TextInput disabled source="product.name" label="Product Name" />
								<NumberInput options={{ disabled: true }} source="quantity" label="Quantity" />
								<NumberInput source="price" label="Price" />
							</SimpleFormIterator>
						</ArrayInput>
						<NumberInput source="numberOfPeople" label="Number of Guests" />
						<ArrayInput source="additionalFees">
							<SimpleFormIterator>
								<ReferenceInput label="Fee" source="id" reference="custom-tour-fees" validate={required()}>
									<SelectInput optionText="name.en" />
								</ReferenceInput>
								<NumberInput source="price" label="Price" />
							</SimpleFormIterator>
						</ArrayInput>
						<FormDataConsumer>
							{({ formData }) => {
								let sum = (formData.paymentPrice || []).reduce((total, p) => total + p.price || 0, 0);
								sum += (formData.additionalFees || []).reduce((total, f) => total + f.price || 0, 0);

								return (
									<Labeled label="Total Price">
										<span>{sum}</span>
									</Labeled>
								);
							}}
						</FormDataConsumer>
					</FormTab>
				</TabbedForm>
			</Edit>
		);
	}
}

export default CustomTourEdit;
