/* © 2018 NauStud.io
 * @author Hieu Le
 */

import React, { Component } from 'react';
import { Create, SimpleForm, TextInput, NumberInput, required } from 'react-admin';

import RichTextInput from 'ra-input-rich-text';
import LocationInput from '../LocationInput';

class CareerCreate extends Component {
	_renderLocationWithCountry = data => {
		if (data.country) {
			return `${data.country.name} - ${data.title}`;
		}

		return data.title;
	};

	render() {
		return (
			<Create {...this.props} undoable={false}>
				<SimpleForm redirect="list">
					<TextInput source="position" label="Position" validate={required()} />
					<NumberInput source="quantity" label="Quantity" validate={required()} />
					<LocationInput label="Location" source="locationIds" validate={required()} isMultiple />
					<TextInput source="knowledge" label="Knowledge" validate={required()} />
					<RichTextInput source="skill" />
					<RichTextInput source="requirement" />
				</SimpleForm>
			</Create>
		);
	}
}

export default CareerCreate;
