/* © 2018 NauStud.io
 * @author Eric Tran
 */

import { CREATE } from 'react-admin';
import restClient from './restClient';

export const johnDoe = '';

export const saveImageToServer = (file, cb) => {
	const reader = new FileReader();
	reader.readAsDataURL(file);

	reader.onload = () => {
		// server upload
		restClient(CREATE, 'uploads', {
			data: {
				image: reader.result,
				filename: file.name,
			},
		}).then(res => {
			if (res.data && res.data.id) {
				cb(res.data.id);
			} else {
				console.log('error upload please check network tab');
			}
		});
		//*/
	};
};
