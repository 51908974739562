/* © 2018 NauStud.io
 * @author Eric Tran
 */

import React, { Component } from 'react';
import { Create, SimpleForm, TextInput } from 'react-admin';

class MarketCreate extends Component {
	render() {
		return (
			<Create {...this.props} undoable={false}>
				<SimpleForm redirect="list">
					<TextInput source="name" />
				</SimpleForm>
			</Create>
		);
	}
}

export default MarketCreate;
