/* © 2018 NauStud.io
 * @author Eric Tran
 */

import React from 'react';
import { Route } from 'react-router-dom';
import { UserProfile } from './components/users';
import CommissionHistory from './components/commission-history';
import Report from './components/reports';
import CustomersList from './components/customers-list';
import { OpenBusCreate } from './components/open-buses';

const routes = [
	<Route exact path="/profile" component={UserProfile} />,
	<Route exact path="/commission-history" component={CommissionHistory} />,
	<Route exact path="/report" component={Report} />,
	<Route exact path="/customers-list/:tripId" component={CustomersList} />,
	<Route exact path="/open-bus-generate" component={OpenBusCreate} />,
];

export default routes;
