/* © 2018 NauStud.io
 * @author Tu Nguyen
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
	Datagrid,
	DeleteButton,
	DateField,
	List,
	Responsive,
	SimpleList,
	TextField,
	BooleanField,
	// NumberField,
	Filter,
	TextInput,
} from 'react-admin';
import { dataGridStyle } from '../../helper/constants';

const SubscriberFilter = props => (
	<Filter {...props}>
		<TextInput label="Search" source="email.regexp" alwaysOn />
	</Filter>
);

class SubscriberList extends Component {
	static propTypes = {
		classes: PropTypes.shape({}),
	};

	static defaultProps = {
		classes: {},
	};
	getPrimaryText = record => record.name;

	getSecondaryText = record => record.description;

	render() {
		const { classes } = this.props;

		return (
			<List {...this.props} filters={<SubscriberFilter />}>
				<Responsive
					small={<SimpleList primaryText={this.getPrimaryText} secondaryText={this.getSecondaryText} />}
					medium={
						<Datagrid classes={classes}>
							<TextField source="email" label="Email" />
							<DateField source="updatedAt" label="Created at" />
							<BooleanField source="valid" className={classes.validField} />
							<DeleteButton />
						</Datagrid>
					}
				/>
			</List>
		);
	}
}

const styles = {
	validField: { textAlign: 'left', marginLeft: '10px' },
	dataGridStyle,
};

export default withStyles(styles)(SubscriberList);
