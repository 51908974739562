/* © 2018 NauStud.io
 * @author Eric Tran
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Datagrid, List, TextField, EditButton, ReferenceField } from 'react-admin';

import format from 'date-fns/format';
import differenceInDays from 'date-fns/differenceInDays';
import formatDistance from 'date-fns/formatDistance';

import { dataGridStyle } from '../../helper/constants';
import StarRatingField from '../StarRatingField';

const CustomDateField = ({ record = {}, source }) => {
	let date = new Date(record[source]);
	if (differenceInDays(new Date(), date) >= 10) {
		date = format(date, 'dd/MM/yyyy');
	} else {
		date = `${formatDistance(date, new Date())} ago`;
	}

	return <span>{date}</span>;
};

CustomDateField.propTypes = {
	record: PropTypes.shape({
		updatedAt: PropTypes.instanceOf(Date),
	}),
	source: PropTypes.string.isRequired,
};

CustomDateField.defaultProps = { record: null };

class TourReviewList extends Component {
	render() {
		const { classes } = this.props;

		return (
			<List {...this.props} bulkActionButtons={false}>
				<Datagrid classes={classes}>
					<CustomDateField source="createdAt" />
					<ReferenceField label="Tour" source="tourId" reference="tours">
						<TextField source="title.en" />
					</ReferenceField>
					<TextField source="text" sortable={false} />
					<StarRatingField source="rating" />
					<TextField source="status" />
					<EditButton />
				</Datagrid>
			</List>
		);
	}
}

TourReviewList.propTypes = {
	classes: PropTypes.shape({}),
};

TourReviewList.defaultProps = {
	classes: {},
};

const styles = {
	...dataGridStyle,
};

export default withStyles(styles)(TourReviewList);
