/* © 2018 NauStud.io
 * @author Tu Nguyen
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { red } from '@material-ui/core/colors';

class ImageDropZone extends Component {
	static propTypes = {
		uniqueId: PropTypes.string,
		width: PropTypes.number,
		height: PropTypes.number,
		imageProcess: PropTypes.func,
		placeholder: PropTypes.string,
		hidden: PropTypes.bool,
	};

	static defaultProps = {
		uniqueId: '',
		width: '300px',
		height: '300px',
		imageProcess: undefined,
		placeholder: 'Drop file here',
		hidden: false,
	};

	componentDidMount() {
		window.addEventListener('dragover', e => e.preventDefault());
		window.addEventListener('drop', e => e.preventDefault());
		if (this.imageDropZoneElement) {
			this.addDrop();
		}
	}

	componentWillUnmount() {
		if (this.removeDropEvent) {
			this.removeDropEvent();
		}
	}

	addDrop = () => {
		this.removeDropEvent = window.DragDrop(this.imageDropZoneElement, {
			onDrop: file => {
				if (this.props.imageProcess) {
					this.props.imageProcess(file[0]);
				}
			},
			onDragOver: () => {
				this.imageDropZoneElement.style.borderColor = red['600'];
				this.imageDropZoneElement.style.backgroundColor = 'rgba(0,0,0, 0.1)';
				this.imageDropZoneElement.style.borderWidth = '6px';
			},
			onDragLeave: () => {
				this.imageDropZoneElement.style.borderColor = 'black';
				this.imageDropZoneElement.style.backgroundColor = 'rgba(0,0,0,0)';
				this.imageDropZoneElement.style.borderWidth = '4px';
			},
		});
	};

	style = {
		width: `${this.props.width}px`,
		height: `${this.props.height}px`,
		border: '4px dashed black',
		maxWidth: '500px',
		maxHeight: '500px',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		padding: '0',
	};

	labelStyle = {
		fontSize: '3.5rem',
		textAlign: 'center',
		lineHeight: '1.3',
		paddingTop: '0',
		display: 'inline-block',
		width: 'auto',
	};

	refDragAndDropRef = (node) => {
		if (node) {
			this.imageDropZoneElement = node;
		}
	}

	render() {
		const { hidden, uniqueId } = this.props;

		if (!hidden) {
			return (
				<span
					style={{
						display: 'inline-block',
					}}
				>
					<div id={uniqueId} style={this.style} ref={this.refDragAndDropRef}>
						<span style={this.labelStyle}>{this.props.placeholder}</span>
					</div>
				</span>
			);
		}

		return null;
	}
}

export default ImageDropZone;
