/* © 2018 NauStud.io
 * @author Eric Tran
 */

import React, { Component } from 'react'
import PropTypes from 'prop-types';
import ExitIcon from '@material-ui/icons/PowerSettingsNew';
import { translate, MenuItemLink } from 'react-admin';

import config from '../config';
import { logout } from '../helper/authClient';

class LogoutButton extends Component {
	static propTypes = {
		translate: PropTypes.func.isRequired,
	}

	onLogout = () => {
		logout(`${config.apiHost}/api/users/logout`).then(() => {
			window.location.assign('/#/login');
		});
	}

	render() {
		const { translate } = this.props;

		return (
			<MenuItemLink onClick={this.onLogout} to="#" primaryText={translate('common.logout')} leftIcon={<ExitIcon />} />
		);
	}
}

export default translate(LogoutButton);

