/* © 2018 NauStud.io
 * @author Eric Tran
 */

import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
	Datagrid,
	List,
	TextField,
	NumberField,
	Filter,
	TextInput,
	EditButton,
	DeleteButton,
} from 'react-admin';
// import { green, amber } from '@material-ui/core/colors';
import { dataGridStyle, noWrapStyle } from '../../helper/constants';

const AgencyGroupFilter = props => (
	<Filter {...props}>
		<TextInput label="Search by name" source="name" alwaysOn />
	</Filter>
);

class AgencyGroupList extends Component {
	render() {
		return (
			<List
				{...this.props}
				filters={<AgencyGroupFilter />}
				bulkActionButtons={false}
			>
				<Datagrid styles={dataGridStyle}>
					<TextField source="name" />
					<NumberField source="defaultCommission" label="Default commission (%)" />
					<EditButton />
					<DeleteButton />
				</Datagrid>
			</List>
		);
	}
}

const styles = {
	...noWrapStyle,
};

export default withStyles(styles)(AgencyGroupList);
