/* © 2018 NauStud.io
 * @author Eric Tran
 */

import React from 'react';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import Avatar from '@material-ui/core/Avatar';
import FaceIcon from '@material-ui/icons/Face';
import { translate } from 'react-admin';
import storage from '../../helper/storage';

export default translate(({ style, translate }) => {
	const currentUser = storage.load('currentUser');

	return (
		<Card style={style}>
			<CardHeader
				avatar={
					<Avatar backgroundColor="#FFEB3B">
						<FaceIcon />
					</Avatar>
				}
				title={`Welcome ${currentUser.fullname}`}
				subheader={translate('dashboard.welcome.subtitle')}
			/>
		</Card>
	);
});
