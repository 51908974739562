/* © 2018 NauStud.io
 * @author Hieu Le
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { Edit, SimpleForm, NumberInput, TextInput, required, Toolbar, SaveButton, TextField } from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
import CustomSaveButton from '../CustomSaveButton';
import LocationInput from '../LocationInput';
// import { isDuplicateRecord } from '../../helper/utils';

const PostSaveToolbar = props => {
	if (props.role === 'author') {
		return (
			<Toolbar {...props}>
				<SaveButton label="Update" redirect="list" submitOnEnter={false} />
			</Toolbar>
		);
	}

	if (props.record && props.record.status && props.record.status === 'published') {
		return (
			<Toolbar {...props}>
				<SaveButton label="Update" redirect="list" submitOnEnter={false} />
				<CustomSaveButton
					onClick={() => props.toggleStatus(props.record.status)}
					label="Unpublish"
					redirect="list"
					submitOnEnter={false}
				/>
			</Toolbar>
		);
	}

	return (
		<Toolbar {...props}>
			<SaveButton label="Save Draft" redirect="list" submitOnEnter={false} />
			<CustomSaveButton
				onClick={() => props.toggleStatus(props.record.status)}
				label="Save and Publish"
				redirect="list"
				submitOnEnter={false}
			/>
		</Toolbar>
	);
};

PostSaveToolbar.propTypes = {
	status: PropTypes.string,
	toggleStatus: PropTypes.func,
	role: PropTypes.string.isRequired,
};

PostSaveToolbar.defaultProps = {
	status: 'draft',
	toggleStatus: null,
};

class CareerEdit extends Component {
	static propTypes = {
		permissions: PropTypes.string.isRequired,
	};
	toggleStatus = status => {
		if (status === 'published') {
			this.onChangeStatus('draft');
		} else {
			this.onChangeStatus('published');
		}
	};

	StatusInput = ({ input: { value, onChange, onBlur, ...inputProps } }) => {
		this.onChangeStatus = onChange;

		return (
			<span>
				<input onChange={onChange} type="text" {...inputProps} hidden />
			</span>
		);
	};

	render() {
		const role = this.props.permissions;

		return (
			<Edit {...this.props} undoable={false}>
				<SimpleForm asyncBlurFields={['id']} toolbar={<PostSaveToolbar toggleStatus={this.toggleStatus} role={role} />}>
					<TextInput source="position" label="Position" validate={required()} />
					<NumberInput source="quantity" validate={required()} />
					<LocationInput label="Location" source="locationIds" validate={required()} isMultiple />
					<TextInput source="knowledge" label="Knowledge" validate={required()} />
					<RichTextInput source="skill" />
					<RichTextInput source="requirement" />
					<TextField source="status" label="Status" />
					<Field name="status" component={this.StatusInput} />
				</SimpleForm>
			</Edit>
		);
	}
}

export default CareerEdit;
