/* © 2018 NauStud.io
 * @author Eric Tran
 */

import React, { Component } from 'react';
import {
	Edit,
	TabbedForm,
	FormTab,
	TextInput,
	NumberInput,
	ArrayInput,
	SimpleFormIterator,
	SelectInput,
	ReferenceField,
	LongTextInput,
	ReferenceInput,
	AutocompleteInput,
	TextField,
} from 'react-admin';
import { DateInput, DateTimeInput } from 'react-admin-date-inputs';
import Snackbar from '@material-ui/core/Snackbar';

import SearchUserInput from '../SearchUserInput';
import MultiLanguageInput from '../MultiLanguageInput';

class CustomTourBookingEdit extends Component {
	state = {
		success: false,
	}

	componentWillReceiveProps() {
		const pushedSuccess = localStorage.getItem('pushedSuccess');
		if (pushedSuccess) {
			this.setState({ success: true });
			localStorage.removeItem('pushedSuccess');
		}
	}

	closePopup = () => {
		this.setState({ success: false });
	}

	render() {
		return (
			<>
				<Edit {...this.props} undoable={false}>
					<TabbedForm redirect="edit">
						<FormTab label="summary">
							<ReferenceField source="tourId" label="Tour ID" reference="custom-tours">
								<TextField source="id" />
							</ReferenceField>
							<SearchUserInput source="userId" />
							<DateInput source="departureDate" options={{ format: 'dd/MM/yyyy' }} label="Departure Date" />
							<NumberInput source="totalPrice" disabled />
							<SelectInput
								source="status"
								choices={[
									{ id: 'pending', name: 'Pending' },
									{ id: 'paid', name: 'Paid' },
									{ id: 'pushed', name: 'Pushed to E-tour' },
								]}
							/>
						</FormTab>
						<FormTab label="Contact Info">
							<SelectInput
								source="contactInfo.contactTitle"
								label="Title"
								choices={[{ id: 'mr', name: 'Mr' }, { id: 'mrs', name: 'Mrs' }, { id: 'ms', name: 'Ms' }]}
							/>
							<TextInput source="contactInfo.contactFirstName" label="Contact First Name" />
							<TextInput source="contactInfo.contactLastName" label="Contact Last Name" />
							<SelectInput
								source="contactInfo.contactGender"
								label="Gender"
								choices={[{ id: 'male', name: 'Male' }, { id: 'female', name: 'Female' }, { id: 'other', name: 'Other' }]}
							/>
							<TextInput source="contactInfo.contactEmail" label="Contact Email" />
							<TextInput source="contactInfo.contactPhone" label="Contact Phone" />
							<TextInput source="contactInfo.contactCountry" label="Contact Country" />
							<TextInput source="contactInfo.contactNote" label="Contact Note" />
						</FormTab>
						<FormTab label="Tour Info">
							<TextInput source="tourInfo.tourTitle" label="Tour Title" />
							<ArrayInput source="products">
								<SimpleFormIterator disableRemove disableAdd>
									<TextInput source="id" disabled />
									<TextInput source="name" disabled />
									<TextInput source="description" disabled />
									<NumberInput source="price" disabled />
								</SimpleFormIterator>
							</ArrayInput>
							<ArrayInput source="additionalFees">
								<SimpleFormIterator disableRemove disableAdd>
									<TextInput source="name" disabled />
									<NumberInput source="price" disabled />
								</SimpleFormIterator>
							</ArrayInput>
						</FormTab>
						<FormTab label="Customers">
							<ArrayInput source="customers">
								<SimpleFormIterator>
									<TextInput source="firstName" label="First Name" />
									<TextInput source="lastName" label="Last Name" />
									<SelectInput
										source="gender"
										label="Gender"
										choices={[{ id: 'male', name: 'Male' }, { id: 'female', name: 'Female' }, { id: 'other', name: 'Other' }]}
									/>
									<DateInput label="Birthday" source="birthday" options={{ format: 'dd/MM/yyyy' }} />
									<SelectInput
										source="customerType"
										label="Customer Type"
										choices={[
											{ id: 'adult', name: 'Adult' },
											{ id: 'children', name: 'Children' },
											{ id: 'baby', name: 'Baby' },
										]}
									/>
									<TextInput source="passportNumber" label="Passport Number" />
									<DateInput
										source="passportExpiryDate"
										options={{ format: 'dd/MM/yyyy' }}
										label="Passport Expiry Date"
									/>
									<TextInput source="passportIssuingCountry" label="Passport Issuing Country" />
								</SimpleFormIterator>
							</ArrayInput>
						</FormTab>
						<FormTab label="Payment Logs">
							<ArrayInput source="paymentLogs">
								<SimpleFormIterator disableAdd disableRemove>
									<TextInput source="code" options={{ disabled: true }} label="Response code from gateway" />
									<MultiLanguageInput source="error" options={{ disabled: true }} label="Error messages" />
									<TextInput source="reference" options={{ disabled: true }} label="Gateway transaction ID" />
									<TextInput source="paymentMethod" options={{ disabled: true }} label="Payment using" />
									<DateTimeInput source="createdAt" options={{ disabled: true }} label="Created At" />
								</SimpleFormIterator>
							</ArrayInput>
						</FormTab>
						<FormTab label="Push Etour Logs">
							<ArrayInput source="pushEtourLogs">
								<SimpleFormIterator disableAdd disableRemove>
									<TextInput source="success" options={{ disabled: true }} label="Success" />
									<TextInput source="code" options={{ disabled: true }} label="Response code" />
									<LongTextInput source="error" options={{ disabled: true }} label="Error messages" />
									<DateTimeInput source="createdAt" options={{ disabled: true }} label="Created At" />
									<ReferenceInput
										label="User"
										source="userId"
										reference="users"
										options={{ disabled: true }}
									>
										<AutocompleteInput optionText="email" />
									</ReferenceInput>
								</SimpleFormIterator>
							</ArrayInput>
						</FormTab>
					</TabbedForm>
				</Edit>
				<Snackbar
					open={this.state.success}
					message={'Pushed E-tour SuccessFully'}
					onClose={this.closePopup}
					anchorOrigin={{vertical: 'top', horizontal: 'center'}}
				/>
			</>
		);
	}
}

export default CustomTourBookingEdit;
