/* © 2019 NauStud.io
 * @author Eric Tran
 */

import React, { Component } from 'react';
import { Create, SimpleForm, required, AutocompleteInput } from 'react-admin';
import { languages, countries } from '../../helper/constants';

class GuideLanguageCreate extends Component {
	render() {
		return (
			<Create {...this.props} undoable={false}>
				<SimpleForm redirect="list">
					<AutocompleteInput
						source="id"
						label="Language"
						validate={required()}
						choices={
							languages.map((lang) => ({
								id: lang.code,
								name: lang.name
							}))
						}
					/>
					<AutocompleteInput
						source="countryFlag"
						label="Using Country Flag"
						validate={required()}
						choices={
							countries.map((lang) => ({
								id: lang.shortisocode,
								name: lang.name
							}))
						}
					/>
				</SimpleForm>
			</Create>
		);
	}
}

export default GuideLanguageCreate;
