/* © 2019 NauStud.io
 * @author Eric Tran
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import format from 'date-fns/format';
import { PRODUCT_TYPE_NAME_MAPPING } from '../../helper/constants';

class AirportTransportationBooking extends Component {
	static propTypes = {
		style: PropTypes.object.isRequired,
		product: PropTypes.object.isRequired,
		itemRenderer: PropTypes.func.isRequired,
		renderMainPassenger: PropTypes.func.isRequired,
	};

	render() {
		const { style, product, itemRenderer, renderMainPassenger } = this.props;

		return (
			<>
				<h5 style={style.header}>{PRODUCT_TYPE_NAME_MAPPING[product.category]}</h5>
				{product.isRoundtrip ? (
					<span style={style.roundtripTag}>Roundtrip</span>
				) : (
					<span style={style.roundtripTag}>One way</span>
				)}
				{product.isFromAirport ? (
					<>
						{itemRenderer('From', `${product.airport.name} (${product.airport.code})`)}
						{itemRenderer('To', product.hotelAddress)}
					</>
				) : (
					<>
						{itemRenderer('From', product.hotelAddress)}
						{itemRenderer('To', `${product.airport.name} (${product.airport.code})`)}
					</>
				)}
				{itemRenderer(
					'Travellers',
					`${product.numberOfTravellers.adult} Adult(s), ${product.numberOfTravellers.child} Children, ${product.numberOfTravellers.infant} Infant(s)`
				)}
				{itemRenderer('Departure', format(new Date(product.departureDate), 'dd/MM/yyyy | HH:mm'))}
				{itemRenderer(
					'Departure Car',
					`${product.selectedCar.name} (${product.numberOfCar} vehicle(s))`,
					product.selectedCar.price * product.numberOfCar
				)}
				{product.returnDate &&
					itemRenderer('Return', format(new Date(product.returnDate), 'dd/MM/yyyy | HH:mm'))}
				{product.returnSelectedCar &&
					itemRenderer(
						'Return Car',
						`${product.returnSelectedCar.name} (${product.returnNumberOfCar} vehicle(s))`,
						product.returnSelectedCar.price * product.returnNumberOfCar
					)}
				{itemRenderer('Main Passenger', renderMainPassenger(product.mainPassenger))}
				{itemRenderer(
					'Flight Information',
					`${product.flightInfo.airline}, ${product.flightInfo.flightNumber || ''}`
				)}
				{itemRenderer('Name on Welcome board or Note', product.flightInfo.nameOnBoard)}
				{itemRenderer('Driver Note', product.flightInfo.driverNote)}
				{itemRenderer(
					'Guide',
					product.hireTravelGuide ? `${product.selectedGuide.name}` : 'No guide',
					product.hireTravelGuide ? product.selectedGuide.price : ''
				)}
			</>
		);
	}
}

export default AirportTransportationBooking;
