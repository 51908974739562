/* © 2018 NauStud.io
 * @author Eric Tran
 */

import React, { Component } from 'react';
import {
	Create,
	TabbedForm,
	FormTab,
	TextInput,
	ReferenceInput,
	NumberInput,
	ImageField,
	ArrayInput,
	SimpleFormIterator,
	SelectInput,
	AutocompleteInput,
} from 'react-admin';

import { DateInput } from 'react-admin-date-inputs';
import SearchUserInput from '../SearchUserInput';

class BookingCreate extends Component {
	render() {
		return (
			<Create {...this.props} undoable={false}>
				<TabbedForm>
					<FormTab label="summary">
						<SearchUserInput source="userId" />
						<DateInput label="Departure Date" source="departureDate" options={{ format: 'dd/MM/yyyy' }} />
						<NumberInput source="totalPrice" />
						<SelectInput
							source="status"
							choices={[
								{ id: 'pending', name: 'Waiting For Payment' },
								{ id: 'pending-bank-transfer', name: 'Waiting For Bank Transfer' },
								{ id: 'deposited', name: 'Deposited' },
								{ id: 'paid', name: 'Paid' },
								{ id: 'inquiry', name: 'Inquiry' },
								{ id: 'canceled', name: 'Canceled' },
								{ id: 'pushed', name: 'Pushed to E-tour' },
								{ id: 'credit', name: 'Paid using credit' },
							]}
						/>
					</FormTab>
					<FormTab label="Contact Info">
						<SelectInput
							source="contactInfo.contactTitle"
							label="Title"
							choices={[{ id: 'mr', name: 'Mr' }, { id: 'mrs', name: 'Mrs' }, { id: 'ms', name: 'Ms' }]}
						/>
						<TextInput source="contactInfo.contactFirstName" label="Contact First Name" />
						<TextInput source="contactInfo.contactLastName" label="Contact Last Name" />
						<SelectInput
							source="contactInfo.contactGender"
							label="Gender"
							choices={[{ id: 'male', name: 'Male' }, { id: 'female', name: 'Female' }, { id: 'other', name: 'Other' }]}
						/>
						<TextInput source="contactInfo.contactEmail" label="Contact Email" />
						<TextInput source="contactInfo.contactPhone" label="Contact Phone" />
						{/* <DateInput source="contactInfo.contactBirthday" label="Contact Birthday" /> */}
						<TextInput source="contactInfo.contactCountry" label="Contact Country" />
						<TextInput source="contactInfo.contactNote" label="Contact Note" />
					</FormTab>
					<FormTab label="Tour Info">
						<TextInput source="tourInfo.tourTitle" label="Tour Title" />
						<ImageField source="tourInfo.tourThumbnail" label="Tour Thumbnail" />
						<TextInput source="tourInfo.tourType" label="Tour Type" />
						<TextInput source="tourInfo.tourDuration" label="Tour Duration" />
						<ArrayInput source="additionalFees">
							<SimpleFormIterator>
								<TextInput source="name" />
								<NumberInput source="price" />
							</SimpleFormIterator>
						</ArrayInput>
						<ArrayInput source="additionalProducts">
							<SimpleFormIterator>
								<ReferenceInput
									label="Product"
									source="id"
									reference="products"
									filterToQuery={searchText => ({
										name: { like: searchText },
									})}
									allowEmpty
								>
									<AutocompleteInput optionText="name.en" />
								</ReferenceInput>
							</SimpleFormIterator>
						</ArrayInput>
					</FormTab>
					<FormTab label="Customers">
						<ArrayInput source="customers">
							<SimpleFormIterator>
								<TextInput source="firstName" label="First Name" />
								<TextInput source="lastName" label="Last Name" />
								<SelectInput
									source="gender"
									label="Gender"
									choices={[{ id: 'male', name: 'Male' }, { id: 'female', name: 'Female' }, { id: 'other', name: 'Other' }]}
								/>
								<DateInput label="Birthday" source="birthday" options={{ format: 'dd/MM/yyyy' }} />
								<SelectInput
									source="customerType"
									label="Customer Type"
									choices={[
										{ id: 'adult', name: 'Adult' },
										{ id: 'children', name: 'Children' },
										{ id: 'baby', name: 'Baby' },
									]}
								/>
								<TextInput source="passportNumber" label="Passport Number" />
								<DateInput
									source="passportExpiryDate"
									options={{ format: 'dd/MM/yyyy' }}
									label="Passport Expiry Date"
								/>
								<TextInput source="passportIssuingCountry" label="Passport Issuing Country" />
							</SimpleFormIterator>
						</ArrayInput>
					</FormTab>
				</TabbedForm>
			</Create>
		);
	}
}

export default BookingCreate;
