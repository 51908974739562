/* © 2018 NauStud.io
 * @author Eric Tran
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import format from 'date-fns/format';
import { Datagrid, List, TextField, ReferenceField, Filter, EditButton } from 'react-admin';
import { DateInput } from 'react-admin-date-inputs';

import { dataGridStyle } from '../../helper/constants';

const CustomDateField = ({ record = {}, source }) => {
	let date = record[source] ? new Date(record[source]) : '';

	date = format(date, 'dd/MM/yyyy HH:mm');

	return <span>{date}</span>;
};

CustomDateField.propTypes = {
	record: PropTypes.shape({
		departureDate: PropTypes.instanceOf(Date),
	}),
	source: PropTypes.string,
};

CustomDateField.defaultProps = { record: null, source: '' };

const BookingsFilter = props => (
	<Filter {...props}>
		<DateInput label="Booking Date From" source="createdAt.gte" alwaysOn options={{ format: 'dd/MM/yyyy' }} />
		<DateInput label="Booking Date To" source="createdAt.lte" alwaysOn options={{ format: 'dd/MM/yyyy' }} />
	</Filter>
);

class BookingList extends Component {
	static propTypes = {
		classes: PropTypes.shape({}),
	};

	static defaultProps = {
		classes: {},
	};

	render() {
		const { classes } = this.props;

		return (
			<List
				{...this.props}
				bulkActionButtons={false}
				filters={<BookingsFilter />}
				sort={{ field: 'createdAt', order: 'DESC' }}
			>
				<Datagrid classes={classes}>
					<TextField source="id" label="Booking ID" sortable={false} />
					<ReferenceField source="productId" label="Tour" reference="services">
						<TextField source="name.en" />
					</ReferenceField>
					<CustomDateField label="Departure Date" source="departureDate" />
					<CustomDateField label="Booking Date" source="createdAt" />
					<TextField source="status" label="Status" />
					<TextField source="numberOfPeople" label="Number of guests" />
					<EditButton />
				</Datagrid>
			</List>
		);
	}
}

const styles = {
	postsField: { textAlign: 'center', display: 'block' },
	...dataGridStyle,
};

export default withStyles(styles)(BookingList);
