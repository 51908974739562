/* © 2018 NauStud.io
 * @author Eric
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';

class LinkTooltip extends Component {
	static propTypes = {
		onSubmit: PropTypes.func,
		closeTooltip: PropTypes.func,
	};

	static defaultProps = {
		onSubmit: null,
		closeTooltip: null,
	};

	state = {
		inputValue: '',
		radioValue: 'current',
	};

	onOkClick = () => {
		this.props.onSubmit({
			url: this.state.inputValue,
			openLinkType: this.state.radioValue,
		});
	};

	onCancelClick = () => {
		this.props.closeTooltip();
	};

	handleTooltipInputChange = e => {
		const value = e.target.value;
		this.setState({ inputValue: value });
	};

	handleTooltipRadioChange = e => {
		const value = e.target.value;
		this.setState({ radioValue: value });
	};

	_disableOKButton = () => !this.state.inputValue;

	render() {
		return (
			<div>
				<div className="rte__toolti-input-container">
					<input
						type="text"
						placeholder="Please enter a URL *"
						onChange={this.handleTooltipInputChange}
						value={this.state.inputValue}
						className="rte__tooltip-input"
					/>
					<div className="rte__tooltip-radio-container">
						<input
							name="link-radio"
							type="radio"
							value="current"
							checked={this.state.radioValue === 'current'}
							onChange={this.handleTooltipRadioChange}
						/>{' '}
						Current Tab <br />
						<input
							name="link-radio"
							type="radio"
							value="newt"
							checked={this.state.radioValue === 'newt'}
							onChange={this.handleTooltipRadioChange}
						/>{' '}
						New Tab
					</div>
				</div>
				<div className="rte__tooltip-button-container">
					<button
						type="button"
						className="rte__tooltip-button"
						disabled={this._disableOKButton()}
						onClick={this.onOkClick}
					>
						OK
					</button>
					<button type="button" className="rte__tooltip-button" onClick={this.onCancelClick}>
						Cancel
					</button>
				</div>
			</div>
		);
	}
}

export default LinkTooltip;
