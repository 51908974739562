/* © 2018 NauStud.io
 * @author Eric Tran
 */

import React, { Component } from 'react';
import {
	Datagrid,
	EditButton,
	DeleteButton,
	List,
	ImageField,
	TextField,
} from 'react-admin';

import { dataGridStyle } from '../../helper/constants';

class TravelStyleList extends Component {

	render() {
		return (
			<List {...this.props} bulkActionButtons={false}>
				<Datagrid styles={dataGridStyle}>
					<TextField source="name" />
					<TextField source="description" />
					<ImageField source="image" sortable={false} />
					<EditButton />
					<DeleteButton />
				</Datagrid>
			</List>
		);
	}
}

export default TravelStyleList;
