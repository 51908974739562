/* © 2018 NauStud.io
 * @author Hai Luu
 */

import React, { Component } from 'react';
import {
	Create,
	TextInput,
	SimpleForm,
	LongTextInput,
	SelectInput,
	FormDataConsumer,
	ReferenceInput,
	required,
	email,
	NumberInput,
} from 'react-admin';

import storage from '../../helper/storage';
import { isDuplicateRecord } from '../../helper/utils';

class UserCreate extends Component {
	checkDuplicateRecord = values => {
		const email = values.email.trim();

		return new Promise((resolve, reject) => {
			if (email) {
				isDuplicateRecord(email, 'email', 'users')
					.then(res => {
						if (res.data.length > 0) {
							return reject({
								email: ['Duplicated record'],
							});
						}

						return resolve({});
					})
					.catch(e => {
						console.log(e);
					});
			} else {
				resolve({});
			}
		});
	};

	render() {
		const role = storage.load('currentUser').role;
		const isAdmin = role === 'admin';

		return (
			<Create {...this.props} undoable={false}>
				<SimpleForm asyncValidate={this.checkDuplicateRecord} asyncBlurFields={['email']}>
					<TextInput source="email" label="Email" validate={[email(), required()]} />
					<TextInput source="name.first" label="First name" validate={[required()]} />
					<TextInput source="name.last" label="Last name" validate={[required()]} />
					<LongTextInput source="bio" />
					<TextInput source="website" label="Website" />
					<SelectInput
						source="language"
						choices={[{ id: 'vi_VN', name: 'Vietnamese' }, { id: 'en_US', name: 'English' }]}
					/>
					{isAdmin ? (
						<SelectInput
							source="role"
							validate={[required()]}
							defaultValue={'user'}
							choices={[
								{ id: 'user', name: 'User' },
								{ id: 'editor', name: 'Editor' },
								{ id: 'author', name: 'Author' },
								{ id: 'admin', name: 'Admin' },
								{ id: 'agency', name: 'Agency' },
								{ id: 'tour-manager', name: 'Tour Manager' },
							]}
						/>
					) : null}
					{isAdmin ? (
						<FormDataConsumer>
							{({ formData }) =>
								formData.role === 'agency' && (
									<ReferenceInput
										label="Agency Group"
										source="agencyGroupId"
										reference="agency-groups"
										validate={required()}
									>
										<SelectInput optionText="name" />
									</ReferenceInput>
								)
							}
						</FormDataConsumer>
					) : null}
					{isAdmin ? (
						<FormDataConsumer>
							{({ formData }) =>
								formData.role === 'agency' && (
									<ReferenceInput label="Market" source="marketId" reference="markets">
										<SelectInput optionText="name" />
									</ReferenceInput>
								)
							}
						</FormDataConsumer>
					) : null}
					{isAdmin ? (
						<FormDataConsumer>
							{({ formData }) =>
								formData.role === 'agency' && (
									<NumberInput source="availableCredit" label="Agency Available Credit" />
								)
							}
						</FormDataConsumer>
					) : null}
				</SimpleForm>
			</Create>
		);
	}
}

export default UserCreate;
