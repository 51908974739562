/* © 2018 NauStud.io
 * @author Eric Tran
 */

import React, { Component } from 'react';
import { Edit, LongTextInput, SimpleForm, TextInput, required } from 'react-admin';

import ImageWithCropInput from '../ImageWithCropInput';

class TravelStyleEdit extends Component {
	render() {
		return (
			<Edit {...this.props} undoable={false}>
				<SimpleForm>
					<TextInput source="name" validate={required()} />
					<LongTextInput source="description" />
					<ImageWithCropInput
						source="image"
						ratio={125 / 83}
						dropzone={{
							uniqueId: 'travelStyleDrop',
							width: 450,
							height: 250,
						}}
					/>
				</SimpleForm>
			</Edit>
		);
	}
}

export default TravelStyleEdit;
