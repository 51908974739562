/* © 2018 NauStud.io
 * @author Eric Tran
 */

import jsonRestLoopBack from 'ra-loopback';
import config from '../config';
import storage from './storage';

class HttpError extends Error {
	constructor(message, status) {
		super(message);
		this.message = message;
		this.status = status;
		this.name = this.constructor.name;
		if (typeof Error.captureStackTrace === 'function') {
			Error.captureStackTrace(this, this.constructor);
		} else {
			this.stack = new Error(message).stack;
		}
		this.stack = new Error().stack;
	}
}

export const fetchJson = (u, options = {}) => {
	const url = u;
	const requestHeaders =
		options.headers ||
		new Headers({
			Accept: 'application/json',
			iscms: true,
			market: localStorage.getItem('market') || 'VN',
		});

	if (!(options && options.body && options.body instanceof FormData)) {
		requestHeaders.set('Content-Type', 'application/json');
	}

	// passing access_token through HTTP Header instead of GET param
	let token = storage.load('lbtoken');
	const userId = (token && token.userId) || '';
	token = (token && token.id) || '';
	if (token) {
		requestHeaders.set('Authorization', token);
	}

	return fetch(url, { ...options, headers: requestHeaders })
		.then(response =>
			response.text().then(text => ({
				status: response.status,
				statusText: response.statusText,
				headers: response.headers,
				body: text,
			}))
		)
		.then(({ status, statusText, headers, body }) => {
			let json;
			try {
				json = JSON.parse(body);
			} catch (e) {
				// not json, no big deal
			}

			if (status === 401) {
				// storage.remove('lbtoken');
				// storage.remove('currentUser');
				// window.location.assign('/#/login');
				console.log('u here');
			} else if (status === 413) {
				let errorText = statusText;

				if (errorText === 'Payload Too Large') {
					errorText = 'Maximum file size is 5MB';
				}

				return Promise.reject(
					new HttpError(
						(json && json.message) || (json && json.error && json.error.message) || errorText,
						status
					)
				);
			} else if (status < 200 || status >= 400) {
				return Promise.reject(
					new HttpError(
						(json && json.message) || (json && json.error && json.error.message) || statusText,
						status
					)
				);
			}

			if (userId && url.indexOf(`/api/users/${userId}`) > -1 && options.method === 'PATCH' && json) {
				const currentUser = JSON.parse(localStorage.getItem('currentUser'));
				if (currentUser) {
					currentUser.value = {
						...currentUser.value,
						...json,
					};
					localStorage.setItem('currentUser', JSON.stringify(currentUser));
				}
			}

			if (url.indexOf(`/api/settings`) > -1) {
				const setting = Array.isArray(json) ? json[0] : json;

				if (setting && setting.availableLanguages) {
					localStorage.setItem('languages', JSON.stringify(setting.availableLanguages));
				}
			}

			if (
				(url.indexOf(`/api/bookings`) > -1 || url.indexOf('api/custom-tour-bookings') > -1) &&
				options.method === 'PATCH' &&
				json &&
				!Array.isArray(json) &&
				json.pushedSuccess
			) {
				localStorage.setItem('pushedSuccess', true);
			}

			return { status, headers, body, json };
		});
};

export default jsonRestLoopBack(`${config.apiHost}/api`, fetchJson);
