/* © 2018 NauStud.io
 * @author Quy Tran
 */

import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { change, submit, isSubmitting } from 'redux-form';
import {
	fetchEnd,
	fetchStart,
	required,
	showNotification,
	crudGetMatching,
	Button,
	SaveButton,
	SimpleForm,
	ReferenceInput,
	SelectInput,
	UPDATE,
	REDUX_FORM_NAME,
	refreshView,
} from 'react-admin';
import IconContentEdit from '@material-ui/icons/Edit';
import IconCancel from '@material-ui/icons/Cancel';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

import dataProvider from '../helper/restClient';

const ACCESS_TYPES = [
	{id: 'allowed', name: 'Allowed'},
	{id: 'denied', name: 'Denied'},
	{id: 'only-allowed', name: 'Only Allowed (normal users are not allowed)'},
];

class BookingPermissionEditButton extends Component {
	state = {
		error: false,
		showDialog: false,
	};

	handleClick = () => {
		this.setState({ showDialog: true });
	};

	handleCloseClick = () => {
		this.setState({ showDialog: false });
	};

	handleSaveClick = () => {
		const { submit } = this.props;

		// Trigger a submit of our custom quick create form
		// This is needed because our modal action buttons are oustide the form
		submit('booking-permission-edit');
	};

	handleSubmit = values => {
		const {
			change,
			crudGetMatching,
			fetchStart,
			fetchEnd,
			showNotification,
			refreshView,
			record,
		} = this.props;

		// FIXME: check why values contains unexpected fields such as babyPricePercent, learn more the way framework pass record from parent to child.
		const formData = {
			accessType: values.accessType,
			agencyGroupId: values.agencyGroupId,
			marketId: values.marketId,
			agencyId: values.agencyId,
			tourId: record.tourId,
		}

		// Dispatch an action letting react-admin know a API call is ongoing
		fetchStart();

		// As we want to know when the new post has been created in order to close the modal, we use the
		// dataProvider directly
		dataProvider(UPDATE, `tour-booking-permissions`, { data: formData, id: values.id })
			.then(({ data }) => {
				// Refresh the choices of the ReferenceInput to ensure our newly created tour-booking-permissions
				// always appear, even after selecting another post
				crudGetMatching('tour-booking-permissions', 'tour-booking-permissions@tourId', { page: 1, perPage: 25 }, { field: 'id', order: 'DESC' }, {});

				// Update the main react-admin form (in this case, the comments creation form)
				change(REDUX_FORM_NAME, 'tourId', data.tourId);
				this.setState({ showDialog: false });
				setTimeout(refreshView, 500);
			})
			.catch(error => {
				showNotification(error.message, 'error');
			})
			.finally(() => {
				// Dispatch an action letting react-admin know a API call has ended
				fetchEnd();
			});
	};

	render() {
		const { showDialog } = this.state;
		const { isSubmitting, record } = this.props;

		return (
			<Fragment>
				<Button onClick={this.handleClick} label="ra.action.edit">
					<IconContentEdit />
				</Button>
				<Dialog fullWidth open={showDialog} onClose={this.handleCloseClick} aria-label="Edit booking permission">
					<DialogTitle>Edit tour booking permission</DialogTitle>
					<DialogContent>
						<SimpleForm
							// We override the redux-form name to avoid collision with the react-admin main form
							form="booking-permission-edit"
							// resource="tour-booking-permissions"
							record={record}
							redirect={false}
							// We override the redux-form onSubmit prop to handle the submission ourselves
							onSubmit={this.handleSubmit}
							// We want no toolbar at all as we have our modal actions
							toolbar={null}
						>
							{/* FIXME: I don't know to how to utilize ReferenceField */}
							<ReferenceInput
								source="tourId"
								label="Tour"
								reference="tours"
							>
								<SelectInput style={{width: "100%"}} disabled optionText="title.en" />
							</ReferenceInput>
							<ReferenceInput
								source="marketId"
								label="Market"
								reference="markets"
								filter={{injectValueOfAll: true }}
							>
								<SelectInput  style={{width: "100%"}} optionText="name" />
							</ReferenceInput>
							<ReferenceInput
								source="agencyGroupId"
								label="Agency Group"
								reference="agency-groups"
								filter={{injectValueOfAll: true }}
							>
								<SelectInput style={{width: "100%"}} optionText="name" />
							</ReferenceInput>
							<ReferenceInput
								source="agencyId"
								label="Agency"
								reference="users"
								filter={{ role: 'agency', injectValueOfAll: true }}
							>
								<SelectInput style={{width: "100%"}} optionText="name.first" optionValue="id" />
							</ReferenceInput>
							<SelectInput style={{width: "100%"}} source="accessType" validate={required()} choices={ACCESS_TYPES} optionText="name" />
						</SimpleForm>
					</DialogContent>
					<DialogActions>
						<SaveButton saving={isSubmitting} onClick={this.handleSaveClick} />
						<Button label="ra.action.cancel" onClick={this.handleCloseClick}>
							<IconCancel />
						</Button>
					</DialogActions>
				</Dialog>
			</Fragment>
		);
	}
}

BookingPermissionEditButton.propTypes = {
	change: PropTypes.func.isRequired,
	crudGetMatching: PropTypes.func.isRequired,
	fetchStart: PropTypes.func.isRequired,
	fetchEnd: PropTypes.func.isRequired,
	showNotification: PropTypes.func.isRequired,
	submit: PropTypes.func.isRequired,
	isSubmitting: PropTypes.bool.isRequired,
	record: PropTypes.object.isRequired,
	refreshView: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
	isSubmitting: isSubmitting('booking-permission-edit')(state),
});

const mapDispatchToProps = {
	change,
	crudGetMatching,
	fetchEnd,
	fetchStart,
	showNotification,
	submit,
	refreshView,
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(BookingPermissionEditButton);
