/* © 2019 NauStud.io
 * @author Eric Tran
 */

import React, { Component } from 'react';
import { Edit, SimpleForm, TextInput, required } from 'react-admin';

import MultiLanguageRichTextInput from '../MultiLanguageRichTextInput';

class OpenBusFolderEdit extends Component {
	render() {
		return (
			<Edit {...this.props} undoable={false}>
				<SimpleForm redirect="list">
					<TextInput source="name" label="Name" validate={required()} />
					<MultiLanguageRichTextInput source="routeInformation" label="Route Information" />
				</SimpleForm>
			</Edit>
		);
	}
}

export default OpenBusFolderEdit;
