/* © 2018 NauStud.io
 * @author Thanh
 */
import { createMuiTheme } from '@material-ui/core/styles';
import red from '@material-ui/core/colors/red';

const VTInboundTheme = createMuiTheme({
	palette: {
		primary: {
			main: '#0064B6',
		},
		secondary: {
			main: '#F01335',
		},
		error: red,
	},
	typography: {
		// Use the system font instead of the default Roboto font.
		fontFamily: 'Muli, Helvetica, Arial, sans-serif',
	},
	// How to override: Look into component's generated styles object and override the exposed properties
	overrides: {
		MuiFormControl: {
			root: {
				minWidth: '50%'
			}
		}
		// MuiAppBar: {
		// 	// react-admin AppBar use secondary color by default (we don't want to change it)
		// 	colorSecondary: {
		// 		backgroundColor: '#0064B6',
		// 	},
		// },
	},
});

export default VTInboundTheme;
