/* © 2018 NauStud.io
 * @author Quy Tran
 */

import React, { Component } from 'react';
import {
	Edit,
	TabbedForm,
	FormTab,
	TextInput,
	NumberInput,
	ArrayInput,
	SimpleFormIterator,
	ReferenceInput,
	AutocompleteInput,
} from 'react-admin';
import { DateTimeInput } from 'react-admin-date-inputs';
import { isDuplicateRecord } from '../../helper/utils';

const validateCountryCode = values => {
	const { code = '' } = values || {};
	const errors = {};

	if (code.length !== 3) {
		errors.code = ['The currency code must be 3 chars'];
	}

	return errors;
};

class CurrencyEdit extends Component {
	checkDuplicateRecord = values => {
		const code = values.code;

		return new Promise((resolve, reject) => {
			if (code) {
				isDuplicateRecord(code, 'code', 'currencies', values.id)
					.then(res => {
						if (res.data.length > 0) {
							return reject({
								code: ['Duplicated record'],
							});
						}

						return resolve({});
					})
					.catch(e => {
						console.log(e);
					});
			} else {
				resolve({});
			}
		});
	};

	render() {
		return (
			<Edit undoable={false} {...this.props}>
				<TabbedForm
					redirect="list"
					asyncValidate={this.checkDuplicateRecord}
					asyncBlurFields={['code']}
					validate={validateCountryCode}
				>
					<FormTab label="Currency Info">
						<TextInput source="code" label="ISO Currency Code (ex: USD, EUR)" />
						<TextInput source="name" />
						<NumberInput source="exchangeRate" label="Exchange Rate (1$ = ?VND)" />
					</FormTab>
					<FormTab label="Change History">
						<ArrayInput source="history" label="History (latest first)">
							<SimpleFormIterator disableAdd disableRemove>
								<ReferenceInput source="userId" label="User" reference="users">
									<AutocompleteInput options={{ disabled: true }} optionText="fullname" />
								</ReferenceInput>
								<NumberInput disabled source="lastExchangeRate" label="Last Exchange Rate" />
								<DateTimeInput
									options={{ format: 'dd/MM/yyyy, HH:mm', disabled: true }}
									source="updatedDate"
									label="Updated Date"
								/>
							</SimpleFormIterator>
						</ArrayInput>
					</FormTab>
				</TabbedForm>
			</Edit>
		);
	}
}

export default CurrencyEdit;
