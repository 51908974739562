/* © 2018 NauStud.io
 * @author Eric Tran
 */

import React, { Component } from 'react';
import { Edit, TextInput, SimpleForm, SelectInput, LongTextInput, required, Toolbar, SaveButton } from 'react-admin';

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
// import ImageInput from '../ImageInput';
import ImageWithCropInput from '../ImageWithCropInput';
import storage from '../../helper/storage';
import config from '../../config';

const styles = {
	passwordChange: {
		background: 'white',
		marginTop: 30,
		padding: '0 1em 1em',
	},
	input: {
		display: 'block',
		width: 400,
	},
	changePasswordButton: {
		marginTop: 20,
	},
};

const ProfileSaveToolbar = props => (
	<Toolbar {...props}>
		<SaveButton />
	</Toolbar>
);

class UserProfile extends Component {
	state = {
		open: false,
		message: '',
		oldPassword: '',
		newPassword: '',
		newPasswordVerify: '',
	};

	componentWillMount() {
		const lbToken = storage.load('lbtoken');
		this.userId = lbToken.userId;
		this.token = lbToken.id;
	}

	onChangePasswordSubmit = e => {
		e.preventDefault();
		this.validationFlag = true;
		const { oldRequired, newRequired, newVerifyRequired, newVerifyNotMatch } = this.getErrors();
		if (oldRequired || newRequired || newVerifyRequired || newVerifyNotMatch) {
			this.forceUpdate();

			return;
		}
		const changePasswordUrl = `${config.apiHost}/api/users/change-password`;
		const oldPassword = this.state.oldPassword;
		const newPassword = this.state.newPassword;
		fetch(changePasswordUrl, {
			method: 'post',
			headers: {
				Accept: 'application/json, application/xml, text/plain, text/html, *.*',
				'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8',
				authorization: this.token,
			},
			body: `oldPassword=${oldPassword}&newPassword=${newPassword}`,
		}).then(rs => {
			if (rs.status === 400) {
				this.setState({ open: true, message: 'The old password is not correct!' });
			} else if (rs.status === 422) {
				// go deeper to the json response
				rs.json().then(json => {
					this.setState({
						message: json.error ? json.error.message : 'New password is too simple.',
						open: true,
					});
				});
			} else if (rs.status === 200 || rs.status === 204) {
				this.validationFlag = false;
				this.setState({
					open: true,
					message: 'Change password successfully!',
					oldPassword: '',
					newPassword: '',
					newPasswordVerify: '',
				});
			}
		});
	};

	onChangePasswordInput = e => {
		const name = e.target.name;
		const value = e.target.value;
		this.setState({
			[name]: value,
		});
	};

	getErrors = () => {
		const { oldPassword, newPassword, newPasswordVerify } = this.state;

		return {
			oldRequired: this.validationFlag && !oldPassword,
			newRequired: this.validationFlag && !newPassword,
			newVerifyRequired: this.validationFlag && !newPasswordVerify,
			newVerifyNotMatch: this.validationFlag && newPassword !== newPasswordVerify,
		};
	};

	handleRequestClose = () => {
		this.setState({ open: false });
	};

	validationFlag = false;

	render() {
		const { open, message, oldPassword, newPassword, newPasswordVerify } = this.state;
		const { oldRequired, newRequired, newVerifyRequired, newVerifyNotMatch } = this.getErrors();
		// <ImageInput
		// 	source="cover"
		// 	label="Cover"
		// 	dropzone={{
		// 		uniqueId: 'userAvatarCover',
		// 		width: 700,
		// 		height: 200,
		// 	}}
		// />
		if (!this.userId) {
			return <div />;
		}

		const match = {
			params: { id: this.userId },
			url: `/users/${this.userId}`,
			isExact: true,
		};

		return (
			<div>
				<Edit
					{...this.props}
					resource="users"
					basePath="users"
					match={match}
					id={this.userId}
					title="Your Profile"
					actions={null}
				>
					<SimpleForm toolbar={<ProfileSaveToolbar />}>
						<ImageWithCropInput
							source="image"
							label="Avatar"
							ratio={1}
							dropzone={{
								uniqueId: 'userAvatar',
								width: 700,
								height: 200,
							}}
						/>
						<TextInput source="name.first" label="First name" validate={required()} />
						<TextInput source="name.last" label="Last name" validate={required()} />
						<TextInput options={{ disabled: true }} source="username" label="Username" />
						<TextInput options={{ disabled: true }} source="email" label="Email" />
						<LongTextInput source="bio" />
						<TextInput source="website" label="Website" />
						<SelectInput
							source="language"
							choices={[{ id: 'vi_VN', name: 'Vietnamese' }, { id: 'en_US', name: 'English' }]}
						/>
						{/* <TextInput source="metaDescription" label="Meta Description" /> */}
						{/* <TextInput source="metaTitle" label="Meta Title" /> */}
					</SimpleForm>
				</Edit>

				<div style={styles.passwordChange}>
					<form onSubmit={this.onChangePasswordSubmit}>
						<TextField
							name="oldPassword"
							style={styles.input}
							label="Old Password *"
							type="password"
							onChange={this.onChangePasswordInput}
							error={oldRequired}
							helperText={oldRequired ? 'This field is required' : ''}
							value={oldPassword}
						/>
						<TextField
							name="newPassword"
							style={styles.input}
							label="New Password *"
							type="password"
							onChange={this.onChangePasswordInput}
							error={newRequired}
							helperText={newRequired ? 'This field is required' : ''}
							value={newPassword}
						/>
						<TextField
							name="newPasswordVerify"
							style={styles.input}
							label="Verify Password *"
							type="password"
							onChange={this.onChangePasswordInput}
							error={newVerifyRequired || newVerifyNotMatch}
							helperText={
								(newVerifyRequired && 'This field is required') ||
								(newVerifyNotMatch && 'Does not match the new password')
							}
							value={newPasswordVerify}
						/>
						<Button variant="contained" secondary="true" style={styles.changePasswordButton} type="submit">
							Change Password
						</Button>
					</form>
				</div>

				<Snackbar open={open} message={message} onClose={this.handleRequestClose} />
			</div>
		);
	}
}

export default UserProfile;
