/* © 2018 NauStud.io
 * @author Eric Tran
 */

import React, { Component } from 'react';
import { Edit, SimpleForm, required, ArrayInput, SimpleFormIterator, TextInput, NumberInput } from 'react-admin';

import MultiLanguageInput from '../MultiLanguageInput';

class FooterEdit extends Component {
	render() {
		return (
			<Edit {...this.props} undoable={false}>
				<SimpleForm redirect="list">
					<NumberInput source="order" />
					<MultiLanguageInput source="headerText" label="Header Text" validate={required()} />
					<ArrayInput source="links">
						<SimpleFormIterator>
							<MultiLanguageInput source="text" label="Text" validate={required()} />
							<TextInput source="url" label="URL" />
						</SimpleFormIterator>
					</ArrayInput>
				</SimpleForm>
			</Edit>
		);
	}
}

export default FooterEdit;
