/* © 2018 NauStud.io
 * @author Hai Luu
 */

import React, { Component } from 'react';
import {
	Datagrid,
	EditButton,
	DeleteButton,
	List,
	Responsive,
	SimpleList,
	TextField,
	DateField,
	// ReferenceArrayField,
	// SingleFieldList,
	// ChipField,
} from 'react-admin';

class AlbumsList extends Component {
	getPrimaryText = record => record.name;

	getSecondaryText = record => record.description;

	render() {
		return (
			<List {...this.props}>
				<Responsive
					small={<SimpleList primaryText={this.getPrimaryText} secondaryText={this.getSecondaryText} />}
					medium={
						<Datagrid>
							<TextField source="name" />
							<TextField source="description" />
							<DateField source="createdAt" label="created at" />
							<EditButton />
							<DeleteButton />
						</Datagrid>
					}
				/>
			</List>
		);
	}
}

export default AlbumsList;
