/* © 2018 NauStud.io
 * @author Eric Tran
 */

import React, { Component } from 'react';
import { Create, SimpleForm, NumberInput, ArrayInput, SimpleFormIterator, required, SelectInput } from 'react-admin';
import { DateInput } from 'react-admin-date-inputs';
import SearchTourInput from '../SearchTourInput';
import MultiLanguageInput from '../MultiLanguageInput';

class TripCreate extends Component {
	render() {
		return (
			<Create {...this.props} undoable={false}>
				<SimpleForm redirect="list">
					<SearchTourInput source="tourId" customFilter={{ type: 'guarantee' }} validate={required()} />
					<DateInput
						label="Departure Date"
						source="departureDate"
						options={{ format: 'dd/MM/yyyy' }}
						validate={required()}
					/>
					<NumberInput source="totalSeats" validate={required()} />
					<NumberInput source="price" validate={required()} />
					<SelectInput
						source="status"
						defaultValue="new"
						choices={[
							{ id: 'new', name: 'New' },
							{ id: 'ongoing', name: 'Ongoing' },
							{ id: 'completed', name: 'Completed' },
							{ id: 'canceled', name: 'Canceled' },
						]}
					/>
					<ArrayInput source="additionalFees" label="Additional Fees">
						<SimpleFormIterator>
							<MultiLanguageInput source="name" label="Name" validate={required()} />
							<NumberInput source="price" label="Price per person" validate={required()} />
						</SimpleFormIterator>
					</ArrayInput>
				</SimpleForm>
			</Create>
		);
	}
}

export default TripCreate;
