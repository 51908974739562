/* © 2018 NauStud.io
 * @author Quy Tran
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import format from 'date-fns/format';
import differenceInDays from 'date-fns/differenceInDays';
import formatDistance from 'date-fns/formatDistance';
import {
	Datagrid,
	List,
	Responsive,
	SimpleList,
	TextField,
	Filter,
	ReferenceField,
	TextInput,
	// AutocompleteInput,
	// ReferenceInput,
	EditButton,
	DeleteButton,
} from 'react-admin';
import { green, amber } from '@material-ui/core/colors';
// import { slugify } from '../../helper/utils';
import { dataGridStyle, noWrapStyle } from '../../helper/constants';

const style = {
	imgWrapper: {
		width: '80px',
		height: '80px',
		position: 'relative',
		margin: '5px 0',
		overflow: 'hidden',
	},
	img: {
		position: 'absolute',
		top: '0',
		left: '50%',
		transform: 'translate(-50%, 0)',
		height: '100%',
	},
};

const ImageCustomField = ({ record = {} }) => (
	<div style={style.imgWrapper}>
		<img style={style.img} src={record.thumbnail} alt="" />
	</div>
);

ImageCustomField.propTypes = {
	record: PropTypes.shape({
		thumbnail: PropTypes.string,
	}),
};

ImageCustomField.defaultProps = { record: null };

const StatusField = ({ record = {} }) => (
	<span style={{ color: record.status === 'published' ? green.A700 : amber['900'] }}>{record.status}</span>
);

StatusField.propTypes = {
	record: PropTypes.shape({
		title: PropTypes.string,
	}),
};

StatusField.defaultProps = { record: null };

const PublishedField = ({ record = {} }) => {
	let publishedAt = record.status === 'published' && record.publishedAt ? new Date(record.publishedAt) : '';

	if (publishedAt) {
		if (differenceInDays(new Date(), publishedAt) >= 10) {
			publishedAt = format(publishedAt, 'dd/MM/yyyy');
		} else {
			publishedAt = `${formatDistance(publishedAt, new Date())} ago`;
		}
	}

	return <span>{publishedAt}</span>;
};

PublishedField.propTypes = {
	record: PropTypes.shape({
		updatedAt: PropTypes.instanceOf(Date),
	}),
};

PublishedField.defaultProps = { record: null };

const LastEditField = ({ record = {} }) => {
	let lastEdit = record.updatedAt ? new Date(record.updatedAt) : '';
	if (lastEdit) {
		if (differenceInDays(new Date(), lastEdit) >= 10) {
			lastEdit = format(lastEdit, 'dd/MM/yyyy');
		} else {
			lastEdit = `${formatDistance(lastEdit, new Date())} ago`;
		}
	}

	return <span>{lastEdit}</span>;
};

LastEditField.propTypes = {
	record: PropTypes.shape({
		updatedAt: PropTypes.instanceOf(Date),
	}),
};

LastEditField.defaultProps = { record: null };
/*
const searchTag = searchText => {
	if (searchText) {
		return { name: slugify(searchText) };
	}

	return {};
}; */

const HighlightPostFilter = props => (
	<Filter {...props}>
		{/* <ReferenceInput label="Search by tag" source="tagIds" reference="tags" alwaysOn filterToQuery={searchTag}>
			<AutocompleteInput
				optionText="name"
				options={{
					listStyle,
				}}
			/>
		</ReferenceInput> */}
		<TextInput label="Search for title, location" source="search" alwaysOn />
	</Filter>
);

class HighlightPostList extends Component {
	static propTypes = {
		classes: PropTypes.shape(),
	};

	static defaultProps = {
		classes: {},
	};

	getPrimaryText = record => record.title;

	getSecondaryText = record => (record.count ? `${record.count.views} views` : '0');

	getTertiaryText = record => new Date(record.publishedAt).toLocaleDateString();

	render() {
		const { classes } = this.props;

		return (
			<List
				{...this.props}
				filters={<HighlightPostFilter />}
				sort={{ field: 'updatedAt', order: 'DESC' }}
				bulkActionButtons={false}
			>
				<Responsive
					small={
						<SimpleList
							primaryText={this.getPrimaryText}
							secondaryText={this.getSecondaryText}
							tertiaryText={this.getTertiaryText}
						/>
					}
					medium={
						<Datagrid styles={dataGridStyle}>
							<ImageCustomField source="thumbnail" sortable={false} />
							<TextField source="title" className={classes.maxWidthField} sortable={false} />
							<TextField
								source="computedAuthor.fullname"
								className={classes.nowrap}
								label="Author"
								sortable={false}
							/>
							<ReferenceField label="Location" source="locationId" reference="locations">
								<TextField source="title.en" />
							</ReferenceField>
							<StatusField source="status" />
							<PublishedField label="published at" source="PublishedAt" />
							<LastEditField label="last updated" source="UpdatedAt" />
							<TextField source="count.views" label="Views" className={classes.centerField} />
							<TextField source="count.likes" label="Likes" className={classes.centerField} />
							<EditButton />
							<DeleteButton />
						</Datagrid>
					}
				/>
			</List>
		);
	}
}

const styles = {
	// centerField: { textAlign: 'center', display: 'block' },
	// maxWidthField: { maxWidth: '200px', display: 'block' },
	...noWrapStyle,
};

export default withStyles(styles)(HighlightPostList);
