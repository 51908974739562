/* © 2018 NauStud.io
 * @author Thanh
 */
export const messages = {
	common: {
		settings: 'Settings',
		profile: 'Profile',
		logout: 'Logout'
	},
	login: {
		email: 'Email',
		otpToken: 'OTP Token',
		emailPassword: {
			failed: 'Wrong email or password.'
		}
	},
	dashboard: {
		welcome: {
			title: 'Welcome to Vietravel Inbound Backoffice',
			subtitle: 'This is where Admin, Editor or Author can control things in Vietravel Inbound website',
		},
		draft: 'Drafts',
		postSummary: {
			draft: 'Total draft Posts',
			published: 'Total published Posts',
			post: 'Total Posts',
		},
		mediaSummary: {
			media: 'Media',
			mediaAlbum: 'Media Albums',
		},
		userSummary: {
			user: 'Users',
		},
	},
};

export default messages;
