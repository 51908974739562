/* © 2018 NauStud.io
 * @author Eric Tran
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
	Datagrid,
	List,
	TextField,
	DateField,
	EditButton,
	ReferenceField,
	NumberField,
	Filter,
	TextInput,
} from 'react-admin';
import { Link } from 'react-router-dom';
import { dataGridStyle } from '../../helper/constants';
import DeleteButtonWithConfirmation from '../DeleteButtonWithConfirmation';

const TripFilter = props => (
	<Filter {...props}>
		<TextInput label="Search by trip ID" source="id" alwaysOn />
		<TextInput label="Search by tour ID" source="tourId" alwaysOn />
	</Filter>
);

const CustomLinkButton = ({ record }) => (
	<Link to={`/customers-list/${encodeURIComponent(record.id)}`}>Customer List</Link>
);

CustomLinkButton.propTypes = {
	record: PropTypes.object,
};

CustomLinkButton.defaultProps = {
	record: {},
};

class TripList extends Component {
	render() {
		const { classes } = this.props;

		return (
			<List filters={<TripFilter />} {...this.props} bulkActionButtons={false}>
				<Datagrid classes={classes}>
					<TextField source="id" label="Trip Id" sortable={false} />
					<ReferenceField label="Tour" source="tourId" reference="tours">
						<TextField source="title.en" />
					</ReferenceField>
					<DateField
						source="departureDate"
						options={{ weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }}
					/>
					<NumberField source="totalSeats" />
					<NumberField source="seatsLeft" label="Seats Left" />
					<NumberField source="price" />
					<CustomLinkButton label="Customers" />
					<EditButton />
					<DeleteButtonWithConfirmation undoable={false} />
				</Datagrid>
			</List>
		);
	}
}

const styles = {
	...dataGridStyle,
};

TripList.propTypes = {
	classes: PropTypes.shape({}),
};

TripList.defaultProps = {
	classes: {},
};

export default withStyles(styles)(TripList);
