/* © 2018 NauStud.io
 * @author Eric Tran
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import withWidth from '@material-ui/core/withWidth';
import { GET_LIST, translate } from 'react-admin';
import { connect } from 'react-redux';
import compose from 'recompose/compose';

import { PostIcon } from '../posts/index';
import { UserIcon } from '../users/index';

import Welcome from './Welcome';
import Summary from './Summary';
import DraftList from './DraftList';

import storage from '../../helper/storage';
import restClient from '../../helper/restClient';

const styles = {
	welcome: { marginBottom: '2em' },
	flex: { display: 'flex' },
	col: { flex: 1 },
	rightCol: { flex: 1, marginLeft: '1em' },
	singleCol: { marginTop: '2em' },
};

class Dashboard extends Component {
	static propTypes = {
		history: PropTypes.shape({
			push: PropTypes.func,
		}),
		admin: PropTypes.shape({
			settings: PropTypes.object,
		}),
		translate: PropTypes.func.isRequired,
		permissions: PropTypes.string.isRequired,
	};

	static defaultProps = {
		history: null,
		admin: null,
	};

	state = {
		draftPosts: [],
		draftPostsLength: 0,
		publishedPostsLength: 0,
		media: [],
		mediaAlbum: [],
		users: [],
		nbNewUsers: 0,
		nbNewPosts: 0,
	};

	componentDidMount() {
		const currentUser = storage.load('currentUser');
		const userId = currentUser.id;
		this.role = this.props.permissions;
		const lastDBCheck = localStorage.getItem('lastDBCheck');
		let newFilter = {};
		if (lastDBCheck) {
			newFilter = {
				createdAt: { gte: lastDBCheck },
			};
		}

		restClient('UPDATE', 'users', {
			id: userId,
			data: {
				lastDBCheck: new Date(),
			},
		}).then(res => {
			if (res.data && res.data.lastDBCheck) {
				localStorage.setItem('lastDBCheck', res.data.lastDBCheck);
			}
		});

		restClient(GET_LIST, 'posts', {
			pagination: {
				perPage: 10,
			},
			sort: {
				field: 'createdAt',
				order: 'DESC',
			},
			filter: {
				status: 'draft',
			},
		}).then(res => {
			this.setState({
				draftPosts: res.data,
				draftPostsLength: res.total,
			});
		});

		restClient(GET_LIST, 'posts', {
			pagination: {},
			sort: {},
			perPage: 10,
			filter: {
				status: 'published',
			},
		}).then(res => {
			this.setState({
				publishedPostsLength: res.total,
			});
		});

		restClient(GET_LIST, 'media', {
			pagination: {},
			sort: {},
			filter: {},
		}).then(res => {
			this.setState({ media: res.data });
		});

		restClient(GET_LIST, 'albums', {
			pagination: {},
			sort: {},
			filter: {},
		}).then(res => {
			this.setState({ mediaAlbum: res.data });
		});

		restClient(GET_LIST, 'posts', {
			pagination: {},
			sort: {},
			filter: newFilter,
		}).then(res => {
			this.setState({ nbNewPosts: res.total });
		});
	}

	componentWillReceiveProps = nextProps => {
		if (nextProps.permissions && nextProps.permissions !== this.props.permissions) {
			this.role = nextProps.permissions;

			if (this.role === 'admin') {
				const lastDBCheck = localStorage.getItem('lastDBCheck');
				let newFilter = {};
				if (lastDBCheck) {
					newFilter = {
						createdAt: { gte: lastDBCheck },
					};
				}
				restClient(GET_LIST, 'users', {
					pagination: {},
					sort: {},
					filter: {},
				}).then(res => {
					this.setState({ users: res.data });
				});

				restClient(GET_LIST, 'users', {
					pagination: {},
					sort: {},
					filter: newFilter,
				}).then(res => {
					this.setState({ nbNewUsers: res.total });
				});
			}
		}
	};

	navigateTo = e => {
		const to = e.currentTarget.dataset.to;
		this.props.history.push(to);
	};

	render() {
		const { draftPosts, draftPostsLength, publishedPostsLength, users, nbNewUsers, nbNewPosts } = this.state;

		const totalPost = draftPostsLength + publishedPostsLength;

		if (this.role === 'tour-manager') {
			return (
				<div>
					<Welcome style={styles.welcome} />
				</div>
			);
		}

		return (
			<div>
				<Welcome style={styles.welcome} />
				<div style={styles.flex}>
					<div style={styles.col}>
						<div style={styles.flex}>
							<Summary
								linkTo={'posts?filter={"status":"draft"}&order=DESC&page=1&perPage=10&sort=updatedAt'}
								value={draftPostsLength}
								Icon={PostIcon}
								color="#31708f"
								translateString="dashboard.postSummary.draft"
							/>
							<Summary
								linkTo={'posts?filter={"status":"published"}&order=DESC&page=1&perPage=10&sort=updatedAt'}
								value={publishedPostsLength}
								Icon={PostIcon}
								color="#4caf50"
								translateString="dashboard.postSummary.published"
							/>
							{/* <Summary value={media.length} Icon={MediaIcon} color="#ff9800" translateString="dashboard.mediaSummary.media" />
							<Summary value={mediaAlbum.length} Icon={AlbumsIcon} color="#f44336" translateString="dashboard.mediaSummary.mediaAlbum" /> */}
						</div>
						<div style={styles.flex}>
							<Summary
								linkTo="posts"
								value={`${totalPost} (${nbNewPosts} new)`}
								Icon={PostIcon}
								color="#000"
								translateString="dashboard.postSummary.post"
							/>
							{this.role === 'admin' ? (
								<Summary
									linkTo="users"
									value={`${users.length} (${nbNewUsers} new)`}
									Icon={UserIcon}
									color="#BF55EC"
									translateString="dashboard.userSummary.user"
								/>
							) : (
								''
							)}
						</div>
					</div>
				</div>
				<h5 style={{ fontFamily: 'Roboto' }}>{this.props.translate('dashboard.draft')}</h5>
				<DraftList data={draftPosts} history={this.props.history} />
			</div>
		);
	}
}

const mapStateToProps = state => ({
	admin: state.admin,
});

const enhance = compose(connect(mapStateToProps));

export default enhance(withWidth()(translate(Dashboard)));
