/* © 2019 NauStud.io
 * @author Eric Tran
 */

import React, { Component } from 'react';
import {
	Create,
	SimpleForm,
	TextInput,
	LongTextInput,
	BooleanInput,
	SelectInput,
	NumberInput,
	ArrayInput,
	SimpleFormIterator,
	ReferenceInput,
	required,
	CheckboxGroupInput,
} from 'react-admin';

import { DateInput, TimeInput } from 'react-admin-date-inputs';
import MultiLanguageRichTextInput from '../MultiLanguageRichTextInput';

class OpenBusCreate extends Component {
	render() {
		const props = {
			resource: 'open-buses/generate',
			basePath: '/open-bus-folders',
			location: {
				hash: '',
				pathname: '/open-bus-generate',
				search: '',
				state: undefined,
			},
			match: {
				isExact: true,
				params: {},
				path: '/open-bus-generate',
				url: '/open-bus-generate',
			},
		};

		return (
			<Create {...props} undoable={false}>
				<SimpleForm>
					<TextInput source="data.name" label="Name" validate={required()} />
					<LongTextInput source="data.description" label="Description" />
					<NumberInput source="data.release" label="Release" />
					<ArrayInput style={{ width: '50%' }} source="data.availableDays" label="Departure dates">
						<SimpleFormIterator>
							<DateInput label="From" source="from" options={{ format: 'dd/MM/yyyy' }} />
							<DateInput label="To" source="to" options={{ format: 'dd/MM/yyyy' }} />
							<CheckboxGroupInput
								source="weekdays"
								label="Weekdays"
								defaultValue={[0, 1, 2, 3, 4, 5, 6]}
								choices={[
									{ id: '1', name: 'Monday' },
									{ id: '2', name: 'Tuesday' },
									{ id: '3', name: 'Wednesday' },
									{ id: '4', name: 'Thursday' },
									{ id: '5', name: 'Friday' },
									{ id: '6', name: 'Saturday' },
									{ id: '0', name: 'Sunday' },
								]}
							/>
						</SimpleFormIterator>
					</ArrayInput>
					<ArrayInput source="data.times" label="Departure times">
						<SimpleFormIterator>
							<TimeInput options={{ format: 'HH:mm' }} />
						</SimpleFormIterator>
					</ArrayInput>
					<ArrayInput source="data.routes" label="Routes">
						<SimpleFormIterator>
							<ReferenceInput source="id" label="Open Bus Route Id" reference="open-bus-routes">
								<SelectInput optionText="name" />
							</ReferenceInput>
							<NumberInput source="stopTime" label="Stop Time (Minutes)" />
						</SimpleFormIterator>
					</ArrayInput>
					<MultiLanguageRichTextInput source="data.routeInformation" label="Route Information" />
					<BooleanInput source="data.active" label="Active" defaultValue />
				</SimpleForm>
			</Create>
		);
	}
}

export default OpenBusCreate;
