/* © 2018 NauStud.io
 * @author Eric Tran
 */

import React, { Component } from 'react';
import {
	Edit,
	SimpleForm,
	TextInput,
	NumberInput,
	required,
	ReferenceInput,
	AutocompleteInput,
	TextField,
} from 'react-admin';
import { DateInput } from 'react-admin-date-inputs';
import SearchUserInput from '../SearchUserInput';

class InvoiceEdit extends Component {
	render() {
		const { resource } = this.props;
		let bookingReference = 'bookings';
		switch (resource) {
			case 'custom-tour-invoices':
				bookingReference = 'custom-tour-bookings';
				break;
			case 'product-invoices':
				bookingReference = 'product-bookings';
				break;
			default:
				bookingReference = 'bookings';
				break;
		}

		return (
			<Edit {...this.props} undoable={false}>
				<SimpleForm>
					<TextField source="id" label="Invoice #" />
					<SearchUserInput source="userId" />
					<ReferenceInput
						source="bookingId"
						reference={bookingReference}
						label="Booking ID"
						filterToQuery={searchText => ({ id: { like: searchText } })}
					>
						<AutocompleteInput optionText="id" />
					</ReferenceInput>
					<TextInput source="description" />
					<NumberInput source="subtotal" validate={required()} />
					<NumberInput source="discount" defaultValue={0} label="Discount (VND)" />
					<NumberInput source="tax" defaultValue={0} label="Tax (%)" />
					<NumberInput source="fee" defaultValue={0} label="Fee (VND)" />
					<NumberInput source="totalPrice" validate={required()} />
					<TextField source="paymentMethod" label="Payment Method" />
					<TextField source="paymentReference" label="Transaction ID" />
					<TextInput source="billingInfo.firstName" label="Billing Info First Name" />
					<TextInput source="billingInfo.lastName" label="Billing Info Last Name" />
					<TextInput source="billingInfo.email" label="Billing Info Email" />
					<TextInput source="billingInfo.phone" label="Billing Info Phone" />
					<TextInput source="billingInfo.addressLine" label="Billing Info Address Line 1" />
					<TextInput source="billingInfo.city" label="Billing Info City" />
					<TextInput source="billingInfo.state" label="Billing Info State" />
					<TextInput source="billingInfo.country" label="Billing Info Country Code" />
					<a href="https://en.wikipedia.org/wiki/List_of_ISO_3166_country_codes" target="blank">
						* ISO country code list
					</a>
					<TextInput source="billingInfo.postalCode" label="Billing Info Postal Code" />
					<DateInput
						source="issueDate"
						label="Issue Date"
						validate={required()}
						options={{ format: 'dd/MM/yyyy' }}
					/>
					<DateInput source="dueDate" label="Due Date" options={{ format: 'dd/MM/yyyy' }} />
				</SimpleForm>
			</Edit>
		);
	}
}

export default InvoiceEdit;
