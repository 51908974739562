/* © 2018 NauStud.io
 * @author Quy Tran
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field } from 'redux-form';
import compose from 'recompose/compose';

import {
	Edit,
	TabbedForm,
	FormTab,
	TextInput,
	NumberInput,
	SelectInput,
	BooleanInput,
	required,
	SaveButton,
	Toolbar,
	FormDataConsumer,
	TextField,
	ReferenceInput,
	EditButton,
	Datagrid,
	ReferenceManyField,
} from 'react-admin';

import MultiLanguageInput from '../MultiLanguageInput';
import RichTextEditor from '../RichTextEditor';
import DateField from '../DateField';
import PostImageInput from '../PostImageInput';
import CustomSaveButton from '../CustomSaveButton';
import { slugify, isDuplicateRecord } from '../../helper/utils';

const regexValidSlug = /([^a-zA-Z0-9-])/g;

const LocationTitle = ({ record }) => <span>Location {record ? `"${record.title}"` : ''}</span>;

LocationTitle.propTypes = {
	record: PropTypes.shape({
		title: PropTypes.string,
	}),
};

LocationTitle.defaultProps = {
	record: null,
};

const LocationSaveToolbar = props => {
	if (props.role === 'author') {
		return (
			<Toolbar {...props}>
				<SaveButton label="Update" redirect="list" submitOnEnter={false} />
			</Toolbar>
		);
	}

	if (props.record && props.record.status && props.record.status === 'published') {
		return (
			<Toolbar {...props}>
				<SaveButton label="Update" redirect="list" submitOnEnter={false} />
				<CustomSaveButton
					onClick={() => props.toggleStatus(props.record.status)}
					label="Unpublish"
					redirect="list"
					submitOnEnter={false}
				/>
			</Toolbar>
		);
	}

	return (
		<Toolbar {...props}>
			<SaveButton label="Save Draft" redirect="list" submitOnEnter={false} />
			<CustomSaveButton
				onClick={() => props.toggleStatus(props.record.status)}
				label="Save and Publish"
				redirect="list"
				submitOnEnter={false}
			/>
		</Toolbar>
	);
};

LocationSaveToolbar.propTypes = {
	status: PropTypes.string,
	toggleStatus: PropTypes.func,
	role: PropTypes.string.isRequired,
	record: PropTypes.object.isRequired,
};

LocationSaveToolbar.defaultProps = {
	status: 'draft',
	toggleStatus: null,
};

class LocationEdit extends Component {
	static propTypes = {
		match: PropTypes.shape({
			params: PropTypes.shape({
				slug: PropTypes.string,
				id: PropTypes.string,
			}),
		}),
		sidebarOpen: PropTypes.bool,
		permissions: PropTypes.string.isRequired,
	};

	static defaultProps = {
		match: {},
		sidebarOpen: false,
	};

	state = {
		isContentEmpty: true,
	};

	authorOptionRender = choice => {
		if (choice.name) {
			return `${choice.name.first} ${choice.name.last}`;
		}

		return 'No name';
	};

	searchName = searchText => ({ name: { regexp: searchText } });
	searchTagName = searchText => ({
		name: { regexp: searchText },
		hidden: false,
	});

	searchUsers = searchText => ({
		or: [{ 'name.first': { regexp: searchText } }, { 'name.last': { regexp: searchText } }],
	});

	toggleStatus = status => {
		if (status === 'published') {
			this.onChangeStatus('draft');
		} else {
			this.onChangeStatus('published');
		}
	};

	StatusInput = ({ input: { value, onChange, onBlur, ...inputProps } }) => {
		this.onChangeStatus = onChange;

		return (
			<span>
				<input onChange={onChange} type="text" {...inputProps} hidden />
			</span>
		);
	};

	validateInput = values => {
		const slug = slugify(values.slug);

		return new Promise(resolve => {
			if (slug) {
				if (regexValidSlug.test(values.slug)) {
					return resolve({
						slug: ['Invalid slug'],
					});
				}

				isDuplicateRecord(slug, 'slug', 'posts', values.id)
					.then(res => {
						if (res.data.length > 0) {
							resolve({
								slug: ['Duplicated record'],
							});

							return;
						}

						resolve({});
					})
					.catch(e => {
						console.log(e);
					});
			} else {
				resolve({});
			}

			return 0;
		});
	};

	validateContent = innerHtml => {
		if (innerHtml === '<p><br></p>' || innerHtml === undefined) {
			this.setState({
				isContentEmpty: true,
			});
		} else {
			this.setState({
				isContentEmpty: false,
			});
		}
	};

	checkTypeNotCookBook = record => {
		if (record.type !== 'cookbook') {
			return true;
		}

		return false;
	};

	render() {
		const role = this.props.permissions;
		const { isContentEmpty } = this.state;

		return (
			<Edit undoable={false} title={<LocationTitle />} {...this.props}>
				<TabbedForm
					asyncValidate={this.validateInput}
					asyncBlurFields={['slug']}
					toolbar={<LocationSaveToolbar toggleStatus={this.toggleStatus} role={role} />}
				>
					<FormTab label="summary">
						<TextInput source="slug" options={{ placeholder: 'Leave it blank for auto generation' }} />
						<MultiLanguageInput source="title" validate={required()} />
						<TextInput source="alias" validate={required()} />
						<TextInput source="description" validate={required()} />
						{/* <ImageWithCropInput
							source="portraitImage"
							ratio={9 / 16}
							label="Portrait Image"
							dropzone={{ uniqueId: 'postImagePortrait', width: 700, height: 200 }}
						/> */}
						<PostImageInput
							source="image"
							thumbnailSource="thumbnail"
							label="Image *"
							dropzone={{ uniqueId: 'locationImage', width: 700, height: 200 }}
						/>
						<FormDataConsumer>
							{({ formData, ...rest }) => (
								<ReferenceInput
									label="Country"
									source="countryId"
									reference="countries"
									validate={required()}
									{...rest}
								>
									<SelectInput />
								</ReferenceInput>
							)}
						</FormDataConsumer>
						<SelectInput
							source="language"
							choices={[{ id: 'vi_VN', name: 'Vietnamese' }, { id: 'en_EN', name: 'English' }]}
							validate={required()}
						/>
						<TextField source="status" label="Status" />
						<DateField source="publishedAt" label="Last published at" />
						<Field name="status" component={this.StatusInput} />
					</FormTab>
					<FormTab label="Content *">
						{isContentEmpty && (
							<TextInput
								source="textCloned"
								validate={required()}
								style={{
									display: 'none',
								}}
							/>
						)}
						<RichTextEditor
							source="text"
							validation={{ required: true }}
							sidebarOpen={this.props.sidebarOpen}
							validate={this.validateContent}
						/>
					</FormTab>
					<FormTab label="miscellaneous">
						<TextInput source="metaTitle" label="Meta Title" />
						<TextInput source="metaDescription" label="Meta Description" />
						<NumberInput source="order" label="Order in Popular Places section (Blog)" />
						<BooleanInput source="showInHome" label="Show in Experiences section (Home)" />
						<NumberInput source="mapPosition.lat" label="Latitude" />
						<NumberInput source="mapPosition.lng" label="Longitude" />
					</FormTab>
					{/* <FormTab label="Related Tour">
						<ReferenceManyField perPage={10} reference="highlights" target="locationId">
							<Datagrid>
								<TextField source="id" />
								<TextField source="title" />
								<EditButton />
							</Datagrid>
						</ReferenceManyField>
					</FormTab> */}
				</TabbedForm>
			</Edit>
		);
	}
}

const mapStateToProps = state => ({
	sidebarOpen: state.admin.ui.sidebarOpen,
});

export default compose(connect(mapStateToProps))(LocationEdit);
