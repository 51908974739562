/* © 2018 NauStud.io
 * @author Eric Tran
 */

import React, { Component } from 'react';
import { Create, SimpleForm, required, ArrayInput, SimpleFormIterator, NumberInput } from 'react-admin';

import MultiLanguageInput from '../MultiLanguageInput';

class FaqCreate extends Component {
	render() {
		return (
			<Create {...this.props} undoable={false}>
				<SimpleForm redirect="list">
					<NumberInput source="order" />
					<MultiLanguageInput source="headerText" label="Header Text" validate={required()} />
					<ArrayInput source="items" label="Items">
						<SimpleFormIterator>
							<MultiLanguageInput source="question" label="Question" validate={required()} />
							<MultiLanguageInput source="answer" label="Answer" validate={required()} />
						</SimpleFormIterator>
					</ArrayInput>
				</SimpleForm>
			</Create>
		);
	}
}

export default FaqCreate;
