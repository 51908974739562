/* © 2018 NauStud.io
 * @author Eric Tran
 */

import React, { Component } from 'react';
import { Create, LongTextInput, BooleanInput, SimpleForm, TextInput, minLength } from 'react-admin';
import { slugify, isDuplicateRecord } from '../../helper/utils';

import ImageInput from '../ImageInput';

class TagCreate extends Component {
	checkDuplicateRecord = values => {
		const id = slugify(values.name);

		return new Promise((resolve, reject) => {
			if (id) {
				isDuplicateRecord(id, 'id', 'tags')
					.then(res => {
						if (res.data.length > 0) {
							return reject({
								name: ['Duplicated record'],
							});
						}

						return resolve({});
					})
					.catch(e => {
						console.log(e);
					});
			} else {
				resolve({});
			}
		});
	};

	render() {
		return (
			<Create {...this.props} undoable={false}>
				<SimpleForm asyncValidate={this.checkDuplicateRecord} asyncBlurFields={['name']}>
					<TextInput source="name" />
					<BooleanInput source="hidden" />
					<LongTextInput source="description" validate={minLength(10)} />
					<LongTextInput source="longDescription" validate={minLength(10)} />
					<ImageInput
						source="image"
						dropzone={{
							uniqueId: 'tagEditDrop',
							width: 1400,
							height: 450,
						}}
					/>
					<TextInput source="metaTitle" label="Meta Title" />
					<TextInput source="metaDescription" label="Meta Description" />
				</SimpleForm>
			</Create>
		);
	}
}

export default TagCreate;
