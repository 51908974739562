/* © 2018 NauStud.io
 * @author Thanh + Hai Luu
 */
const convertFileToBase64 = files =>
	new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsDataURL(files[0]);

		reader.onload = () =>
			resolve([
				{
					name: files[0].name,
					type: files[0].type,
					base64: reader.result,
				},
			]);

		reader.onerror = reject;
	});

const uploadableResources = ['media', 'users', 'settings', 'tags', 'categories', 'locations', 'travel-styles', 'services'];

const addUploadCapabilities = requestHandler => (type, resource, params) => {
	const { data } = params;

	if ((type === 'CREATE' || type === 'UPDATE') && uploadableResources.indexOf(resource) !== -1) {
		const uploadFilesKeys = Object.keys(data).filter(key => key.includes('uploadFiles-') && data);

		if (uploadFilesKeys && uploadFilesKeys.length) {
			const promiseList = uploadFilesKeys.map(uploadKey =>
				convertFileToBase64(data[uploadKey]).then(transformedNewImages => {
					const [, field] = uploadKey.split('-');

					delete data[uploadKey];

					return {
						[field]: transformedNewImages,
					};
				})
			);

			return Promise.all(promiseList).then(res => {
				const uploadFiles = res.reduce(
					(prev, current) => ({
						...prev,
						...current,
					}),
					{}
				);

				return requestHandler(type, resource, {
					...params,
					data: {
						...params.data,
						uploadFiles,
					},
				});
			});
		}
		if (data.freeWalkingTourPoints && data.freeWalkingTourPoints.length) {
			const promises = data.freeWalkingTourPoints.map((point) => {
				if (point.audio && point.audio.rawFile) {

					return convertFileToBase64([point.audio.rawFile]).then(transformedNewAudio => ({
						...point,
						audio: transformedNewAudio[0]
					}));
				}

				return Promise.resolve(point);
			});

			return Promise.all(promises).then((freeWalkingTourPoints) => (
				requestHandler(type, resource, {
					...params,
					data: {
						...params.data,
						freeWalkingTourPoints
					}
				})
			));
		}
	}

	return requestHandler(type, resource, params);
};

export default addUploadCapabilities;
