/* © 2018 NauStud.io
 * @author Eric Tran
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Edit, TabbedForm, FormTab, TextInput, required, Toolbar, SaveButton } from 'react-admin';

import MultiLanguageInput from '../MultiLanguageInput';
import RichTextEditor from '../RichTextEditor';
import { slugify, isDuplicateRecord } from '../../helper/utils';

const regexValidSlug = /([^a-zA-Z0-9-])/g;

const PageTitle = ({ record }) => <span>Static Page {record ? `"${record.title.en}"` : ''}</span>;

PageTitle.propTypes = {
	record: PropTypes.shape({
		title: PropTypes.string,
	}),
};

PageTitle.defaultProps = {
	record: null,
};

const EditToolbar = props => (
	<Toolbar {...props}>
		<SaveButton />
	</Toolbar>
);

class PageEdit extends Component {
	componentWillMount() {
		this.availableLanguages = JSON.parse(localStorage.getItem('languages') || []);
	}

	validateInput = values => {
		const slug = slugify(values.slug);

		return new Promise((resolve, reject) => {
			if (slug) {
				if (regexValidSlug.test(values.slug)) {
					return reject({
						slug: ['Invalid slug'],
					});
				}

				isDuplicateRecord(slug, 'slug', 'pages', values.id)
					.then(res => {
						if (res.data.length > 0) {
							return reject({
								slug: ['Duplicated record'],
							});
						}

						return resolve({});
					})
					.catch(e => {
						console.log(e);
					});
			} else {
				resolve({});
			}

			return 0;
		});
	};

	render() {
		return (
			<Edit title={<PageTitle />} {...this.props} undoable={false}>
				<TabbedForm
					redirect="list"
					asyncValidate={this.checkDuplicateRecord}
					asyncBlurFields={['slug']}
					toolbar={<EditToolbar />}
				>
					<FormTab label="summary">
						<TextInput source="slug" validate={required()} label="Url" />
						<MultiLanguageInput source="title" label="Title" validate={required()} />
					</FormTab>
					{this.availableLanguages.map(lang => (
						<FormTab label={lang.name}>
							<RichTextEditor
								source={`text.${lang.code}`}
								toolbarOptions={[
									[{ header: [2, 3, 4, false] }],
									['bold', 'italic', 'underline'],
									[{ list: 'bullet' }],
									[{ indent: '-1' }, { indent: '+1' }],
									[{ align: [] }],
									[{ color: [] }, { background: [] }],
									['clean'], // remove formatting button
									['link'],
								]}
							/>
						</FormTab>
					))}
					<FormTab label="miscellaneous">
						<TextInput source="metaTitle" label="Meta Title" />
						<TextInput source="metaDescription" label="Meta Description" />
					</FormTab>
				</TabbedForm>
			</Edit>
		);
	}
}

export default PageEdit;
