/* © 2018 NauStud.io
 * @author Eric Tran
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Datagrid, List, TextField, EditButton, DeleteButton } from 'react-admin';

import { dataGridStyle } from '../../helper/constants';

class ProductCategoryList extends Component {
	static propTypes = {
		classes: PropTypes.shape({}),
	};

	static defaultProps = {
		classes: {},
	};

	render() {
		const { classes } = this.props;

		return (
			<List {...this.props} bulkActionButtons={false}>
				<Datagrid classes={classes}>
					<TextField source="name.en" label="Name" sortable={false} />
					<TextField source="description.en" label="Description" sortable={false} />
					<EditButton />
					<DeleteButton />
				</Datagrid>
			</List>
		);
	}
}

const styles = {
	...dataGridStyle,
};

export default withStyles(styles)(ProductCategoryList);
