/* © 2018 NauStud.io
 * @author Eric Tran
 */

import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Datagrid, List, TextField, NumberField, EditButton, DeleteButton } from 'react-admin';

import { dataGridStyle, noWrapStyle } from '../../helper/constants';

class FaqList extends Component {
	render() {
		return (
			<List {...this.props} sort={{ field: 'order', order: 'ASC' }} bulkActionButtons={false}>
				<Datagrid styles={dataGridStyle}>
					<TextField source="headerText.en" label="Header Text" />
					<NumberField source="order" label="Order" />
					<EditButton />
					<DeleteButton />
				</Datagrid>
			</List>
		);
	}
}

const styles = {
	...noWrapStyle,
};

export default withStyles(styles)(FaqList);
