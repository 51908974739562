/* © 2018 NauStud.io
 * @author Eric Tran
 */

import React, { Component } from 'react';
import {
	Edit,
	LongTextInput,
	TextInput,
	TabbedForm,
	FormTab,
	GET_LIST,
	Toolbar,
	SaveButton,
	ArrayInput,
	required,
	SimpleFormIterator,
	NumberInput,
	FormDataConsumer,
} from 'react-admin';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import RichTextInput from 'ra-input-rich-text';

import config from '../../config';

import restClient from '../../helper/restClient';
import ImageInput from '../../components/ImageInput';
import MultiLanguageInput from '../../components/MultiLanguageInput';
import LocationInput from '../LocationInput';

const EditToolbar = props => (
	<Toolbar {...props}>
		<SaveButton />
	</Toolbar>
);

class Setting extends Component {
	state = {
		settingId: '',
	};

	componentWillMount() {
		restClient(GET_LIST, 'settings', {
			pagination: {
				perPage: 1,
			},
			sort: {},
			filter: {},
		})
			.then(rs => {
				if (rs.data && rs.data.length) {
					console.log('settingId: ', rs.data[0].id);
					this.setState({ settingId: rs.data[0].id });
				}
			})
			.catch(err => {
				console.log(err);
			});
	}

	onTestEmailChange = e => {
		const value = e.target.value;
		this.setState({ testEmail: value });
	};

	onTestButtonClick = e => {
		const template = e.currentTarget.id;
		fetch(`${config.apiHost}/api/send-test-email`, {
			method: 'post',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				email: this.state.testEmail,
				template: this.emailTemplates[template],
			}),
		})
			.then(res => res.json())
			.then(res => {
				if (res && res.success) {
					/* eslint-disable-next-line */
					alert('Email Sent');
				}
				if (res && !res.success && res.error) {
					/* eslint-disable-next-line */
					alert(res.error);
				}
			});
	};

	emailTemplates = {};

	render() {
		const { settingId, testEmail } = this.state;

		if (!settingId) {
			return <div />;
		}
		const match = {
			params: { id: settingId },
			url: `/settings/${settingId}`,
			isExact: true,
		};

		const toolbarOptions = [
			['bold', 'italic', 'underline', 'strike'], // toggled buttons
			[{ header: [1, 2, 3, 4, 5, 6, false] }],
			[{ list: 'ordered' }, { list: 'bullet' }],
			[{ color: [] }, { background: [] }], // dropdown with defaults from theme
			[{ align: [] }],
			['link'],
			['clean'],
		];

		return (
			<div>
				<Edit
					undoable={false}
					{...this.props}
					resource="settings"
					match={match}
					id={settingId}
					title="Setting"
					actions={null}
				>
					<TabbedForm toolbar={<EditToolbar />}>
						<FormTab label="General">
							<LongTextInput source="metaTitle" label="Meta Title" />
							<LongTextInput source="metaDescription" label="Meta Description" />
							<ImageInput
								source="ogImage"
								label="Open Graph Cover Image"
								dropzone={{ uniqueId: 'settingDrop', width: 700, height: 200 }}
							/>
							<TextInput source="socialAccount.facebook" label="Facebook account url" />
							<TextInput source="socialAccount.google" label="Google account url" />
							<TextInput source="socialAccount.twitter" label="Twitter account url" />
							<TextInput source="socialAccount.youtube" label="Youtube account url" />
							<TextInput source="socialAccount.instagram" label="Instagram account url" />
							<LocationInput source="popularPlaceIds" label="Popular Places" isMultiple />
							<LongTextInput
								source="newBookingNotifyEmails"
								label="New booking notify emails (separate using comma or space)"
							/>
							<LongTextInput
								source="newContactNotifyEmails"
								label="New contact notify emails (separate using comma or space)"
							/>
						</FormTab>
						<FormTab label="Payment Settings">
							<RichTextInput source="bankTranferInfo" label="Bank transfer info" />
							<NumberInput source="creditCardFee" label="Credit card fee (%)" />
						</FormTab>
						<FormTab label="Copyright Info">
							<MultiLanguageInput source="copyright" label="Copyright and License Info" />
						</FormTab>
						<FormTab label="Languages">
							<ArrayInput source="availableLanguages">
								<SimpleFormIterator>
									<TextInput source="code" label="Code" validate={required()} />
									<TextInput source="name" label="Name" validate={required()} />
								</SimpleFormIterator>
							</ArrayInput>
						</FormTab>
						<FormTab label="Offices">
							<MultiLanguageInput source="address" label="Footer Address" />
							<TextInput source="hotline" />
							<TextInput source="contactEmail" />
							<ArrayInput source="offices">
								<SimpleFormIterator>
									<MultiLanguageInput source="name" label="Name" />
									<MultiLanguageInput source="address" label="Address" />
									<TextInput source="phone" label="Phone" />
									<TextInput source="fax" label="Fax" />
									<TextInput source="email" label="Email" />
								</SimpleFormIterator>
							</ArrayInput>
						</FormTab>
						<FormTab label="Custom HTML Injection">
							<LongTextInput source="customScriptHead" label="Head Injection" />
							<LongTextInput source="customScriptBody" label="Body Injection" />
						</FormTab>
						<FormTab label="Website Email">
							<FormDataConsumer>
								{({ formData }) => {
									this.emailTemplates = {
										...formData,
									};
								}}
							</FormDataConsumer>
							<br />
							<TextField placeholder="Test email" onChange={this.onTestEmailChange} value={testEmail} />
							<br />
							<br />
							<div style={{ background: '#f6f6f6', padding: 15, marginBottom: 40, width: '100%' }}>
								<TextInput
									source="welcomeEmailTemplate.subject"
									label="Welcome Email (Variables: {name})"
								/>
								<RichTextInput toolbar={toolbarOptions} source="welcomeEmailTemplate.body" />
								<Button
									style={{ marginTop: 20, background: 'white' }}
									variant="outlined"
									onClick={this.onTestButtonClick}
									id="welcomeEmailTemplate"
								>
									Test
								</Button>
							</div>
							<div style={{ background: '#f6f6f6', padding: 15, marginBottom: 40, width: '100%' }}>
								<TextInput
									source="resetPasswordEmailTemplate.subject"
									label="Reset Password Email (Variables: {name}, {link})"
								/>
								<RichTextInput toolbar={toolbarOptions} source="resetPasswordEmailTemplate.body" />
								<Button
									style={{ marginTop: 20, background: 'white' }}
									variant="outlined"
									onClick={this.onTestButtonClick}
									id="resetPasswordEmailTemplate"
								>
									Test
								</Button>
							</div>
							<div style={{ background: '#f6f6f6', padding: 15, marginBottom: 40, width: '100%' }}>
								<TextInput
									source="subscribeNewsletterEmailTemplate.subject"
									label="Newsletter Subscription Email (Variables: {email})"
								/>
								<RichTextInput
									toolbar={toolbarOptions}
									source="subscribeNewsletterEmailTemplate.body"
								/>
								<Button
									style={{ marginTop: 20, background: 'white' }}
									variant="outlined"
									onClick={this.onTestButtonClick}
									id="subscribeNewsletterEmailTemplate"
								>
									Test
								</Button>
							</div>
							<div style={{ background: '#f6f6f6', padding: 15, marginBottom: 40, width: '100%' }}>
								<TextInput
									source="customTourRequestEmailTemplate.subject"
									label="Custom Tour Request Confirm Email (Variables: {name}, {link})"
								/>
								<RichTextInput toolbar={toolbarOptions} source="customTourRequestEmailTemplate.body" />
								<Button
									style={{ marginTop: 20, background: 'white' }}
									variant="outlined"
									onClick={this.onTestButtonClick}
									id="customTourRequestEmailTemplate"
								>
									Test
								</Button>
							</div>
							<div style={{ background: '#f6f6f6', padding: 15, marginBottom: 40, width: '100%' }}>
								<TextInput
									source="bookingSuccessEmailTemplate.subject"
									label="Booking Success Email (Variables: {name}, {link})"
								/>
								<RichTextInput toolbar={toolbarOptions} source="bookingSuccessEmailTemplate.body" />
								<Button
									style={{ marginTop: 20, background: 'white' }}
									variant="outlined"
									onClick={this.onTestButtonClick}
									id="bookingSuccessEmailTemplate"
								>
									Test
								</Button>
							</div>
						</FormTab>
						<FormTab label="Backoffice Email">
							<br />
							<TextField placeholder="Test email" onChange={this.onTestEmailChange} value={testEmail} />
							<br />
							<br />
							<div style={{ background: '#f6f6f6', padding: 15, marginBottom: 40, width: '100%' }}>
								<TextInput
									source="cmsAccountReadyEmailTemplate.subject"
									label="Account Ready Email (Variables: {name}, {backofficeUrl}, {pass})"
								/>
								<RichTextInput toolbar={toolbarOptions} source="cmsAccountReadyEmailTemplate.body" />
								<Button
									style={{ marginTop: 20, background: 'white' }}
									variant="outlined"
									onClick={this.onTestButtonClick}
									id="cmsAccountReadyEmailTemplate"
								>
									Test
								</Button>
							</div>
							{/* <br />
							<br />
							<br />
							<br />
							<TextInput source="cmsResetPasswordEmailTemplate.subject" label="Reset Password Email" />
							<RichTextInput
								toolbar={toolbarOptions}
								source="cmsResetPasswordEmailTemplate.body"
								label="Reset Password Email Body (Variables: {name}, {pass})"
							/>
							<Button style={{ marginTop: 20, background: 'white' }} variant="outlined" onClick={this.onTestButtonClick} id="cmsResetPasswordEmailTemplate">
								Test
							</Button> */}
							<div style={{ background: '#f6f6f6', padding: 15, marginBottom: 40, width: '100%' }}>
								<TextInput
									source="cmsSendOTPEmailTemplate.subject"
									label="Send OTP Email (Variables: {otp})"
								/>
								<RichTextInput toolbar={toolbarOptions} source="cmsSendOTPEmailTemplate.body" />
								<Button
									style={{ marginTop: 20, background: 'white' }}
									variant="outlined"
									onClick={this.onTestButtonClick}
									id="cmsSendOTPEmailTemplate"
								>
									Test
								</Button>
							</div>
						</FormTab>
					</TabbedForm>
				</Edit>
			</div>
		);
	}
}

export default Setting;
