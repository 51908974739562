/* © 2019 NauStud.io
 * @author Eric Tran
 */

import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Datagrid, List, TextField, DeleteButton, ImageField } from 'react-admin';

import { dataGridStyle, noWrapStyle } from '../../helper/constants';

class GuideLanguageList extends Component {
	render() {
		return (
			<List {...this.props} bulkActionButtons={false}>
				<Datagrid styles={dataGridStyle}>
					<TextField source="id" label="Language Code" />
					<TextField source="name" label="Name" />
					<ImageField source="flag" label="Flag" />
					<DeleteButton />
				</Datagrid>
			</List>
		);
	}
}

const styles = {
	...noWrapStyle,
};

export default withStyles(styles)(GuideLanguageList);

