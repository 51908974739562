/* © 2018 NauStud.io
 * @author Quy Tran
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import {
	Edit,
	TextInput,
	DisabledInput,
	SimpleForm,
	GET_ONE,
} from 'react-admin';

import restClient from '../../helper/restClient';

const CountryName = ({ record }) => <span>Country {record ? `"${record.title}"` : ''}</span>;

const validateCountryCode = (values) => {
	const {id = ''} = values || {};
	const errors = {};

	if (id.length !== 2) {
			errors.id = ['The id must be 2 chars'];
	}

	return errors;
}

CountryName.propTypes = {
	record: PropTypes.shape({
		title: PropTypes.string,
	}),
};

CountryName.defaultProps = {
	record: null,
};

class CountryEdit extends Component {
	static propTypes = {
		match: PropTypes.shape({
			params: PropTypes.shape({
				slug: PropTypes.string,
				id: PropTypes.string,
			}),
		}),
		sidebarOpen: PropTypes.bool,
	};

	static defaultProps = {
		match: {},
		sidebarOpen: false,
	};

	state = {
		status: '',
		isContentEmpty: true,
	};

	componentDidMount() {
		const id = this.props.match.params.id || '';
		restClient(GET_ONE, 'countries', {
			id,
		}).then(res => {
			this.previousSlug = res.data.slug;
			if (res && res.data) {
				this.setState({ status: res.data.status });
			}
		});
	}

	render() {
		return (
			<div>
				<Edit title={<CountryName />} {...this.props} undoable={false}>
					<SimpleForm validate={validateCountryCode}>
						<DisabledInput source="id" label="ISO Country Code (ex: vn, us)"   />
						<a href="https://en.wikipedia.org/wiki/List_of_ISO_3166_country_codes" target="blank">* ISO country code list</a>
						<TextInput source="name" label="Country name" />
					</SimpleForm>
				</Edit>
			</div>
		);
	}
}

const mapStateToProps = state => ({
	sidebarOpen: state.admin.ui.sidebarOpen,
});

export default compose(connect(mapStateToProps))(CountryEdit);
