/* © 2019 NauStud.io
 * @author Eric Tran
 */

import React, { Component } from 'react';
import {
	Edit,
	SimpleForm,
	TextInput,
	required,
	ArrayInput,
	SimpleFormIterator,
	ReferenceInput,
	SelectInput,
	NumberInput,
} from 'react-admin';
import { DateInput } from 'react-admin-date-inputs';

import LocationInput from '../LocationInput';

class CarAllotmentEdit extends Component {
	render() {
		return (
			<Edit {...this.props} undoable={false}>
				<SimpleForm redirect="list">
					<TextInput source="name" label="Car Department Name" validate={required()} />
					<LocationInput source="locationIds" label="Location" validate={required()} isMultiple />
					<ArrayInput source="allotment" label="Base Allotment">
						<SimpleFormIterator>
							<ReferenceInput label="Car Type" source="carTypeId" reference="car-types">
								<SelectInput optionText="name.en" />
							</ReferenceInput>
							<NumberInput source="quantity" label="Quantity" />
						</SimpleFormIterator>
					</ArrayInput>
					<ArrayInput source="variation">
						<SimpleFormIterator>
							<DateInput source="from" label="From" options={{ format: 'dd/MM/yyyy' }} />
							<DateInput source="to" label="To" options={{ format: 'dd/MM/yyyy' }} />
							<ArrayInput source="allotment" label="New Allotment">
								<SimpleFormIterator>
									<ReferenceInput label="Car Type" source="carTypeId" reference="car-types">
										<SelectInput optionText="name.en" />
									</ReferenceInput>
									<NumberInput source="quantity" label="Quantity" />
								</SimpleFormIterator>
							</ArrayInput>
						</SimpleFormIterator>
					</ArrayInput>
				</SimpleForm>
			</Edit>
		);
	}
}

export default CarAllotmentEdit;
