/* © 2018 NauStud.io
 * @author Tu Nguyen
 */

export const countries = [
	{ name: 'Afghanistan', code: '93', isocode: 'AF/AFG', shortisocode: 'AF' },
	{ name: 'Albania', code: '355', isocode: 'AL/ALB', shortisocode: 'AL' },
	{ name: 'Algeria', code: '213', isocode: 'DZ/DZA', shortisocode: 'DZ' },
	{ name: 'American Samoa', code: '1-684', isocode: 'AS/ASM', shortisocode: 'AS' },
	{ name: 'Andorra', code: '376', isocode: 'AD/AND', shortisocode: 'AD' },
	{ name: 'Angola', code: '244', isocode: 'AO/AGO', shortisocode: 'AO' },
	{ name: 'Anguilla', code: '1-264', isocode: 'AI/AIA', shortisocode: 'AI' },
	{ name: 'Antarctica', code: '672', isocode: 'AQ/ATA', shortisocode: 'AQ' },
	{ name: 'Antigua and Barbuda', code: '1-268', isocode: 'AG/ATG', shortisocode: 'AG' },
	{ name: 'Argentina', code: '54', isocode: 'AR/ARG', shortisocode: 'AR' },
	{ name: 'Armenia', code: '374', isocode: 'AM/ARM', shortisocode: 'AM' },
	{ name: 'Aruba', code: '297', isocode: 'AW/ABW', shortisocode: 'AW' },
	{ name: 'Australia', code: '61', isocode: 'AU/AUS', shortisocode: 'AU' },
	{ name: 'Austria', code: '43', isocode: 'AT/AUT', shortisocode: 'AT' },
	{ name: 'Azerbaijan', code: '994', isocode: 'AZ/AZE', shortisocode: 'AZ' },
	{ name: 'Bahamas', code: '1-242', isocode: 'BS/BHS', shortisocode: 'BS' },
	{ name: 'Bahrain', code: '973', isocode: 'BH/BHR', shortisocode: 'BH' },
	{ name: 'Bangladesh', code: '880', isocode: 'BD/BGD', shortisocode: 'BD' },
	{ name: 'Barbados', code: '1-246', isocode: 'BB/BRB', shortisocode: 'BB' },
	{ name: 'Belarus', code: '375', isocode: 'BY/BLR', shortisocode: 'BY' },
	{ name: 'Belgium', code: '32', isocode: 'BE/BEL', shortisocode: 'BE' },
	{ name: 'Belize', code: '501', isocode: 'BZ/BLZ', shortisocode: 'BZ' },
	{ name: 'Benin', code: '229', isocode: 'BJ/BEN', shortisocode: 'BJ' },
	{ name: 'Bermuda', code: '1-441', isocode: 'BM/BMU', shortisocode: 'BM' },
	{ name: 'Bhutan', code: '975', isocode: 'BT/BTN', shortisocode: 'BT' },
	{ name: 'Bolivia', code: '591', isocode: 'BO/BOL', shortisocode: 'BO' },
	{ name: 'Bosnia and Herzegovina', code: '387', isocode: 'BA/BIH', shortisocode: 'BA' },
	{ name: 'Botswana', code: '267', isocode: 'BW/BWA', shortisocode: 'BW' },
	{ name: 'Brazil', code: '55', isocode: 'BR/BRA', shortisocode: 'BR' },
	{ name: 'British Indian Ocean Territory', code: '246', isocode: 'IO/IOT', shortisocode: 'IO' },
	{ name: 'British Virgin Islands', code: '1-284', isocode: 'VG/VGB', shortisocode: 'VG' },
	{ name: 'Brunei', code: '673', isocode: 'BN/BRN', shortisocode: 'BN' },
	{ name: 'Bulgaria', code: '359', isocode: 'BG/BGR', shortisocode: 'BG' },
	{ name: 'Burkina Faso', code: '226', isocode: 'BF/BFA', shortisocode: 'BF' },
	{ name: 'Burundi', code: '257', isocode: 'BI/BDI', shortisocode: 'BI' },
	{ name: 'Cambodia', code: '855', isocode: 'KH/KHM', shortisocode: 'KH' },
	{ name: 'Cameroon', code: '237', isocode: 'CM/CMR', shortisocode: 'CM' },
	{ name: 'Canada', code: '1', isocode: 'CA/CAN', shortisocode: 'CA' },
	{ name: 'Cape Verde', code: '238', isocode: 'CV/CPV', shortisocode: 'CV' },
	{ name: 'Cayman Islands', code: '1-345', isocode: 'KY/CYM', shortisocode: 'KY' },
	{ name: 'Central African Republic', code: '236', isocode: 'CF/CAF', shortisocode: 'CF' },
	{ name: 'Chad', code: '235', isocode: 'TD/TCD', shortisocode: 'TD' },
	{ name: 'Chile', code: '56', isocode: 'CL/CHL', shortisocode: 'CL' },
	{ name: 'China', code: '86', isocode: 'CN/CHN', shortisocode: 'CN' },
	{ name: 'Christmas Island', code: '61', isocode: 'CX/CXR', shortisocode: 'CX' },
	{ name: 'Cocos Islands', code: '61', isocode: 'CC/CCK', shortisocode: 'CC' },
	{ name: 'Colombia', code: '57', isocode: 'CO/COL', shortisocode: 'CO' },
	{ name: 'Comoros', code: '269', isocode: 'KM/COM', shortisocode: 'KM' },
	{ name: 'Cook Islands', code: '682', isocode: 'CK/COK', shortisocode: 'CK' },
	{ name: 'Costa Rica', code: '506', isocode: 'CR/CRI', shortisocode: 'CR' },
	{ name: 'Croatia', code: '385', isocode: 'HR/HRV', shortisocode: 'HR' },
	{ name: 'Cuba', code: '53', isocode: 'CU/CUB', shortisocode: 'CU' },
	{ name: 'Curacao', code: '599', isocode: 'CW/CUW', shortisocode: 'CW' },
	{ name: 'Cyprus', code: '357', isocode: 'CY/CYP', shortisocode: 'CY' },
	{ name: 'Czech Republic', code: '420', isocode: 'CZ/CZE', shortisocode: 'CZ' },
	{ name: 'Democratic Republic of the Congo', code: '243', isocode: 'CD/COD', shortisocode: 'CD' },
	{ name: 'Denmark', code: '45', isocode: 'DK/DNK', shortisocode: 'DK' },
	{ name: 'Djibouti', code: '253', isocode: 'DJ/DJI', shortisocode: 'DJ' },
	{ name: 'Dominica', code: '1-767', isocode: 'DM/DMA', shortisocode: 'DM' },
	{ name: 'Dominican Republic', code: '1-809, 1-829, 1-849', isocode: 'DO/DOM', shortisocode: 'DO' },
	{ name: 'East Timor', code: '670', isocode: 'TL/TLS', shortisocode: 'TL' },
	{ name: 'Ecuador', code: '593', isocode: 'EC/ECU', shortisocode: 'EC' },
	{ name: 'Egypt', code: '20', isocode: 'EG/EGY', shortisocode: 'EG' },
	{ name: 'El Salvador', code: '503', isocode: 'SV/SLV', shortisocode: 'SV' },
	{ name: 'Equatorial Guinea', code: '240', isocode: 'GQ/GNQ', shortisocode: 'GQ' },
	{ name: 'Eritrea', code: '291', isocode: 'ER/ERI', shortisocode: 'ER' },
	{ name: 'Estonia', code: '372', isocode: 'EE/EST', shortisocode: 'EE' },
	{ name: 'Ethiopia', code: '251', isocode: 'ET/ETH', shortisocode: 'ET' },
	{ name: 'Falkland Islands', code: '500', isocode: 'FK/FLK', shortisocode: 'FK' },
	{ name: 'Faroe Islands', code: '298', isocode: 'FO/FRO', shortisocode: 'FO' },
	{ name: 'Fiji', code: '679', isocode: 'FJ/FJI', shortisocode: 'FJ' },
	{ name: 'Finland', code: '358', isocode: 'FI/FIN', shortisocode: 'FI' },
	{ name: 'France', code: '33', isocode: 'FR/FRA', shortisocode: 'FR' },
	{ name: 'French Polynesia', code: '689', isocode: 'PF/PYF', shortisocode: 'PF' },
	{ name: 'Gabon', code: '241', isocode: 'GA/GAB', shortisocode: 'GA' },
	{ name: 'Gambia', code: '220', isocode: 'GM/GMB', shortisocode: 'GM' },
	{ name: 'Georgia', code: '995', isocode: 'GE/GEO', shortisocode: 'GE' },
	{ name: 'Germany', code: '49', isocode: 'DE/DEU', shortisocode: 'DE' },
	{ name: 'Ghana', code: '233', isocode: 'GH/GHA', shortisocode: 'GH' },
	{ name: 'Gibraltar', code: '350', isocode: 'GI/GIB', shortisocode: 'GI' },
	{ name: 'Greece', code: '30', isocode: 'GR/GRC', shortisocode: 'GR' },
	{ name: 'Greenland', code: '299', isocode: 'GL/GRL', shortisocode: 'GL' },
	{ name: 'Grenada', code: '1-473', isocode: 'GD/GRD', shortisocode: 'GD' },
	{ name: 'Guam', code: '1-671', isocode: 'GU/GUM', shortisocode: 'GU' },
	{ name: 'Guatemala', code: '502', isocode: 'GT/GTM', shortisocode: 'GT' },
	{ name: 'Guernsey', code: '44-1481', isocode: 'GG/GGY', shortisocode: 'GG' },
	{ name: 'Guinea', code: '224', isocode: 'GN/GIN', shortisocode: 'GN' },
	{ name: 'Guinea-Bissau', code: '245', isocode: 'GW/GNB', shortisocode: 'GW' },
	{ name: 'Guyana', code: '592', isocode: 'GY/GUY', shortisocode: 'GY' },
	{ name: 'Haiti', code: '509', isocode: 'HT/HTI', shortisocode: 'HT' },
	{ name: 'Honduras', code: '504', isocode: 'HN/HND', shortisocode: 'HN' },
	{ name: 'Hong Kong', code: '852', isocode: 'HK/HKG', shortisocode: 'HK' },
	{ name: 'Hungary', code: '36', isocode: 'HU/HUN', shortisocode: 'HU' },
	{ name: 'Iceland', code: '354', isocode: 'IS/ISL', shortisocode: 'IS' },
	{ name: 'India', code: '91', isocode: 'IN/IND', shortisocode: 'IN' },
	{ name: 'Indonesia', code: '62', isocode: 'ID/IDN', shortisocode: 'ID' },
	{ name: 'Iran', code: '98', isocode: 'IR/IRN', shortisocode: 'IR' },
	{ name: 'Iraq', code: '964', isocode: 'IQ/IRQ', shortisocode: 'IQ' },
	{ name: 'Ireland', code: '353', isocode: 'IE/IRL', shortisocode: 'IE' },
	{ name: 'Isle of Man', code: '44-1624', isocode: 'IM/IMN', shortisocode: 'IM' },
	{ name: 'Israel', code: '972', isocode: 'IL/ISR', shortisocode: 'IL' },
	{ name: 'Italy', code: '39', isocode: 'IT/ITA', shortisocode: 'IT' },
	{ name: 'Ivory Coast', code: '225', isocode: 'CI/CIV', shortisocode: 'CI' },
	{ name: 'Jamaica', code: '1-876', isocode: 'JM/JAM', shortisocode: 'JM' },
	{ name: 'Japan', code: '81', isocode: 'JP/JPN', shortisocode: 'JP' },
	{ name: 'Jersey', code: '44-1534', isocode: 'JE/JEY', shortisocode: 'JE' },
	{ name: 'Jordan', code: '962', isocode: 'JO/JOR', shortisocode: 'JO' },
	{ name: 'Kazakhstan', code: '7', isocode: 'KZ/KAZ', shortisocode: 'KZ' },
	{ name: 'Kenya', code: '254', isocode: 'KE/KEN', shortisocode: 'KE' },
	{ name: 'Kiribati', code: '686', isocode: 'KI/KIR', shortisocode: 'KI' },
	{ name: 'Kosovo', code: '383', isocode: 'XK/XKX', shortisocode: 'XK' },
	{ name: 'Kuwait', code: '965', isocode: 'KW/KWT', shortisocode: 'KW' },
	{ name: 'Kyrgyzstan', code: '996', isocode: 'KG/KGZ', shortisocode: 'KG' },
	{ name: 'Laos', code: '856', isocode: 'LA/LAO', shortisocode: 'LA' },
	{ name: 'Latvia', code: '371', isocode: 'LV/LVA', shortisocode: 'LV' },
	{ name: 'Lebanon', code: '961', isocode: 'LB/LBN', shortisocode: 'LB' },
	{ name: 'Lesotho', code: '266', isocode: 'LS/LSO', shortisocode: 'LS' },
	{ name: 'Liberia', code: '231', isocode: 'LR/LBR', shortisocode: 'LR' },
	{ name: 'Libya', code: '218', isocode: 'LY/LBY', shortisocode: 'LY' },
	{ name: 'Liechtenstein', code: '423', isocode: 'LI/LIE', shortisocode: 'LI' },
	{ name: 'Lithuania', code: '370', isocode: 'LT/LTU', shortisocode: 'LT' },
	{ name: 'Luxembourg', code: '352', isocode: 'LU/LUX', shortisocode: 'LU' },
	{ name: 'Macau', code: '853', isocode: 'MO/MAC', shortisocode: 'MO' },
	{ name: 'Macedonia', code: '389', isocode: 'MK/MKD', shortisocode: 'MK' },
	{ name: 'Madagascar', code: '261', isocode: 'MG/MDG', shortisocode: 'MG' },
	{ name: 'Malawi', code: '265', isocode: 'MW/MWI', shortisocode: 'MW' },
	{ name: 'Malaysia', code: '60', isocode: 'MY/MYS', shortisocode: 'MY' },
	{ name: 'Maldives', code: '960', isocode: 'MV/MDV', shortisocode: 'MV' },
	{ name: 'Mali', code: '223', isocode: 'ML/MLI', shortisocode: 'ML' },
	{ name: 'Malta', code: '356', isocode: 'MT/MLT', shortisocode: 'MT' },
	{ name: 'Marshall Islands', code: '692', isocode: 'MH/MHL', shortisocode: 'MH' },
	{ name: 'Mauritania', code: '222', isocode: 'MR/MRT', shortisocode: 'MR' },
	{ name: 'Mauritius', code: '230', isocode: 'MU/MUS', shortisocode: 'MU' },
	{ name: 'Mayotte', code: '262', isocode: 'YT/MYT', shortisocode: 'YT' },
	{ name: 'Mexico', code: '52', isocode: 'MX/MEX', shortisocode: 'MX' },
	{ name: 'Micronesia', code: '691', isocode: 'FM/FSM', shortisocode: 'FM' },
	{ name: 'Moldova', code: '373', isocode: 'MD/MDA', shortisocode: 'MD' },
	{ name: 'Monaco', code: '377', isocode: 'MC/MCO', shortisocode: 'MC' },
	{ name: 'Mongolia', code: '976', isocode: 'MN/MNG', shortisocode: 'MN' },
	{ name: 'Montenegro', code: '382', isocode: 'ME/MNE', shortisocode: 'ME' },
	{ name: 'Montserrat', code: '1-664', isocode: 'MS/MSR', shortisocode: 'MS' },
	{ name: 'Morocco', code: '212', isocode: 'MA/MAR', shortisocode: 'MA' },
	{ name: 'Mozambique', code: '258', isocode: 'MZ/MOZ', shortisocode: 'MZ' },
	{ name: 'Myanmar', code: '95', isocode: 'MM/MMR', shortisocode: 'MM' },
	{ name: 'Namibia', code: '264', isocode: 'NA/NAM', shortisocode: 'NA' },
	{ name: 'Nauru', code: '674', isocode: 'NR/NRU', shortisocode: 'NR' },
	{ name: 'Nepal', code: '977', isocode: 'NP/NPL', shortisocode: 'NP' },
	{ name: 'Netherlands', code: '31', isocode: 'NL/NLD', shortisocode: 'NL' },
	{ name: 'Netherlands Antilles', code: '599', isocode: 'AN/ANT', shortisocode: 'AN' },
	{ name: 'New Caledonia', code: '687', isocode: 'NC/NCL', shortisocode: 'NC' },
	{ name: 'New Zealand', code: '64', isocode: 'NZ/NZL', shortisocode: 'NZ' },
	{ name: 'Nicaragua', code: '505', isocode: 'NI/NIC', shortisocode: 'NI' },
	{ name: 'Niger', code: '227', isocode: 'NE/NER', shortisocode: 'NE' },
	{ name: 'Nigeria', code: '234', isocode: 'NG/NGA', shortisocode: 'NG' },
	{ name: 'Niue', code: '683', isocode: 'NU/NIU', shortisocode: 'NU' },
	{ name: 'North Korea', code: '850', isocode: 'KP/PRK', shortisocode: 'KP' },
	{ name: 'Northern Mariana Islands', code: '1-670', isocode: 'MP/MNP', shortisocode: 'MP' },
	{ name: 'Norway', code: '47', isocode: 'NO/NOR', shortisocode: 'NO' },
	{ name: 'Oman', code: '968', isocode: 'OM/OMN', shortisocode: 'OM' },
	{ name: 'Pakistan', code: '92', isocode: 'PK/PAK', shortisocode: 'PK' },
	{ name: 'Palau', code: '680', isocode: 'PW/PLW', shortisocode: 'PW' },
	{ name: 'Palestine', code: '970', isocode: 'PS/PSE', shortisocode: 'PS' },
	{ name: 'Panama', code: '507', isocode: 'PA/PAN', shortisocode: 'PA' },
	{ name: 'Papua New Guinea', code: '675', isocode: 'PG/PNG', shortisocode: 'PG' },
	{ name: 'Paraguay', code: '595', isocode: 'PY/PRY', shortisocode: 'PY' },
	{ name: 'Peru', code: '51', isocode: 'PE/PER', shortisocode: 'PE' },
	{ name: 'Philippines', code: '63', isocode: 'PH/PHL', shortisocode: 'PH' },
	{ name: 'Pitcairn', code: '64', isocode: 'PN/PCN', shortisocode: 'PN' },
	{ name: 'Poland', code: '48', isocode: 'PL/POL', shortisocode: 'PL' },
	{ name: 'Portugal', code: '351', isocode: 'PT/PRT', shortisocode: 'PT' },
	{ name: 'Puerto Rico', code: '1-787, 1-939', isocode: 'PR/PRI', shortisocode: 'PR' },
	{ name: 'Qatar', code: '974', isocode: 'QA/QAT', shortisocode: 'QA' },
	{ name: 'Republic of the Congo', code: '242', isocode: 'CG/COG', shortisocode: 'CG' },
	{ name: 'Reunion', code: '262', isocode: 'RE/REU', shortisocode: 'RE' },
	{ name: 'Romania', code: '40', isocode: 'RO/ROU', shortisocode: 'RO' },
	{ name: 'Russia', code: '7', isocode: 'RU/RUS', shortisocode: 'RU' },
	{ name: 'Rwanda', code: '250', isocode: 'RW/RWA', shortisocode: 'RW' },
	{ name: 'Saint Barthelemy', code: '590', isocode: 'BL/BLM', shortisocode: 'BL' },
	{ name: 'Saint Helena', code: '290', isocode: 'SH/SHN', shortisocode: 'SH' },
	{ name: 'Saint Kitts and Nevis', code: '1-869', isocode: 'KN/KNA', shortisocode: 'KN' },
	{ name: 'Saint Lucia', code: '1-758', isocode: 'LC/LCA', shortisocode: 'LC' },
	{ name: 'Saint Martin', code: '590', isocode: 'MF/MAF', shortisocode: 'MF' },
	{ name: 'Saint Pierre and Miquelon', code: '508', isocode: 'PM/SPM', shortisocode: 'PM' },
	{ name: 'Saint Vincent and the Grenadines', code: '1-784', isocode: 'VC/VCT', shortisocode: 'VC' },
	{ name: 'Samoa', code: '685', isocode: 'WS/WSM', shortisocode: 'WS' },
	{ name: 'San Marino', code: '378', isocode: 'SM/SMR', shortisocode: 'SM' },
	{ name: 'Sao Tome and Principe', code: '239', isocode: 'ST/STP', shortisocode: 'ST' },
	{ name: 'Saudi Arabia', code: '966', isocode: 'SA/SAU', shortisocode: 'SA' },
	{ name: 'Senegal', code: '221', isocode: 'SN/SEN', shortisocode: 'SN' },
	{ name: 'Serbia', code: '381', isocode: 'RS/SRB', shortisocode: 'RS' },
	{ name: 'Seychelles', code: '248', isocode: 'SC/SYC', shortisocode: 'SC' },
	{ name: 'Sierra Leone', code: '232', isocode: 'SL/SLE', shortisocode: 'SL' },
	{ name: 'Singapore', code: '65', isocode: 'SG/SGP', shortisocode: 'SG' },
	{ name: 'Sint Maarten', code: '1-721', isocode: 'SX/SXM', shortisocode: 'SX' },
	{ name: 'Slovakia', code: '421', isocode: 'SK/SVK', shortisocode: 'SK' },
	{ name: 'Slovenia', code: '386', isocode: 'SI/SVN', shortisocode: 'SI' },
	{ name: 'Solomon Islands', code: '677', isocode: 'SB/SLB', shortisocode: 'SB' },
	{ name: 'Somalia', code: '252', isocode: 'SO/SOM', shortisocode: 'SO' },
	{ name: 'South Africa', code: '27', isocode: 'ZA/ZAF', shortisocode: 'ZA' },
	{ name: 'South Korea', code: '82', isocode: 'KR/KOR', shortisocode: 'KR' },
	{ name: 'South Sudan', code: '211', isocode: 'SS/SSD', shortisocode: 'SS' },
	{ name: 'Spain', code: '34', isocode: 'ES/ESP', shortisocode: 'ES' },
	{ name: 'Sri Lanka', code: '94', isocode: 'LK/LKA', shortisocode: 'LK' },
	{ name: 'Sudan', code: '249', isocode: 'SD/SDN', shortisocode: 'SD' },
	{ name: 'Suriname', code: '597', isocode: 'SR/SUR', shortisocode: 'SR' },
	{ name: 'Svalbard and Jan Mayen', code: '47', isocode: 'SJ/SJM', shortisocode: 'SJ' },
	{ name: 'Swaziland', code: '268', isocode: 'SZ/SWZ', shortisocode: 'SZ' },
	{ name: 'Sweden', code: '46', isocode: 'SE/SWE', shortisocode: 'SE' },
	{ name: 'Switzerland', code: '41', isocode: 'CH/CHE', shortisocode: 'CH' },
	{ name: 'Syria', code: '963', isocode: 'SY/SYR', shortisocode: 'SY' },
	{ name: 'Taiwan', code: '886', isocode: 'TW/TWN', shortisocode: 'TW' },
	{ name: 'Tajikistan', code: '992', isocode: 'TJ/TJK', shortisocode: 'TJ' },
	{ name: 'Tanzania', code: '255', isocode: 'TZ/TZA', shortisocode: 'TZ' },
	{ name: 'Thailand', code: '66', isocode: 'TH/THA', shortisocode: 'TH' },
	{ name: 'Togo', code: '228', isocode: 'TG/TGO', shortisocode: 'TG' },
	{ name: 'Tokelau', code: '690', isocode: 'TK/TKL', shortisocode: 'TK' },
	{ name: 'Tonga', code: '676', isocode: 'TO/TON', shortisocode: 'TO' },
	{ name: 'Trinidad and Tobago', code: '1-868', isocode: 'TT/TTO', shortisocode: 'TT' },
	{ name: 'Tunisia', code: '216', isocode: 'TN/TUN', shortisocode: 'TN' },
	{ name: 'Turkey', code: '90', isocode: 'TR/TUR', shortisocode: 'TR' },
	{ name: 'Turkmenistan', code: '993', isocode: 'TM/TKM', shortisocode: 'TM' },
	{ name: 'Turks and Caicos Islands', code: '1-649', isocode: 'TC/TCA', shortisocode: 'TC' },
	{ name: 'Tuvalu', code: '688', isocode: 'TV/TUV', shortisocode: 'TV' },
	{ name: 'U.S. Virgin Islands', code: '1-340', isocode: 'VI/VIR', shortisocode: 'VI' },
	{ name: 'Uganda', code: '256', isocode: 'UG/UGA', shortisocode: 'UG' },
	{ name: 'Ukraine', code: '380', isocode: 'UA/UKR', shortisocode: 'UA' },
	{ name: 'United Arab Emirates', code: '971', isocode: 'AE/ARE', shortisocode: 'AE' },
	{ name: 'United Kingdom', code: '44', isocode: 'GB/GBR', shortisocode: 'GB' },
	{ name: 'United States', code: '1', isocode: 'US/USA', shortisocode: 'US' },
	{ name: 'Uruguay', code: '598', isocode: 'UY/URY', shortisocode: 'UY' },
	{ name: 'Uzbekistan', code: '998', isocode: 'UZ/UZB', shortisocode: 'UZ' },
	{ name: 'Vanuatu', code: '678', isocode: 'VU/VUT', shortisocode: 'VU' },
	{ name: 'Vatican', code: '379', isocode: 'VA/VAT', shortisocode: 'VA' },
	{ name: 'Venezuela', code: '58', isocode: 'VE/VEN', shortisocode: 'VE' },
	{ name: 'Vietnam', code: '84', isocode: 'VN/VNM', shortisocode: 'VN' },
	{ name: 'Wallis and Futuna', code: '681', isocode: 'WF/WLF', shortisocode: 'WF' },
	{ name: 'Western Sahara', code: '212', isocode: 'EH/ESH', shortisocode: 'EH' },
	{ name: 'Yemen', code: '967', isocode: 'YE/YEM', shortisocode: 'YE' },
	{ name: 'Zambia', code: '260', isocode: 'ZM/ZMB', shortisocode: 'ZM' },
	{ name: 'Zimbabwe', code: '263', isocode: 'ZW/ZWE', shortisocode: 'ZW' },
];

export const languages = [
	{ code: 'af', name: 'Afrikaans', native: 'Afrikaans' },
	{ code: 'am', name: 'Amharic', native: 'አማርኛ' },
	{ code: 'ar', name: 'Arabic', native: 'العربية"', rtl: 1 },
	{ code: 'ay', name: 'Aymara', native: 'Aymar' },
	{ code: 'az', name: 'Azerbaijani', native: 'Azərbaycanca / آذربايجان' },
	{ code: 'be', name: 'Belarusian', native: 'Беларуская' },
	{ code: 'bg', name: 'Bulgarian', native: 'Български' },
	{ code: 'bi', name: 'Bislama', native: 'Bislama' },
	{ code: 'bn', name: 'Bengali', native: 'বাংলা' },
	{ code: 'bs', name: 'Bosnian', native: 'Bosanski' },
	{ code: 'ca', name: 'Catalan', native: 'Català' },
	{ code: 'ch', name: 'Chamorro', native: 'Chamoru' },
	{ code: 'cs', name: 'Czech', native: 'Česky' },
	{ code: 'da', name: 'Danish', native: 'Dansk' },
	{ code: 'de', name: 'German', native: 'Deutsch' },
	{ code: 'dv', name: 'Divehi', native: 'ދިވެހިބަސް', rtl: 1 },
	{ code: 'dz', name: 'Dzongkha', native: 'ཇོང་ཁ' },
	{ code: 'el', name: 'Greek', native: 'Ελληνικά' },
	{ code: 'en', name: 'English', native: 'English' },
	{ code: 'es', name: 'Spanish', native: 'Español' },
	{ code: 'et', name: 'Estonian', native: 'Eesti' },
	{ code: 'eu', name: 'Basque', native: 'Euskara' },
	{ code: 'fa', name: 'Persian', native: 'فارسی', rtl: 1 },
	{ code: 'ff', name: 'Peul', native: 'Fulfulde' },
	{ code: 'fi', name: 'Finnish', native: 'Suomi' },
	{ code: 'fj', name: 'Fijian', native: 'Na Vosa Vakaviti' },
	{ code: 'fo', name: 'Faroese', native: 'Føroyskt' },
	{ code: 'fr', name: 'French', native: 'Français' },
	{ code: 'ga', name: 'Irish', native: 'Gaeilge' },
	{ code: 'gl', name: 'Galician', native: 'Galego' },
	{ code: 'gn', name: 'Guarani', native: "Avañe'ẽ" },
	{ code: 'gv', name: 'Manx', native: 'Gaelg' },
	{ code: 'he', name: 'Hebrew', native: 'עברית', rtl: 1 },
	{ code: 'hi', name: 'Hindi', native: 'हिन्दी' },
	{ code: 'hr', name: 'Croatian', native: 'Hrvatski' },
	{ code: 'ht', name: 'Haitian', native: 'Krèyol ayisyen' },
	{ code: 'hu', name: 'Hungarian', native: 'Magyar' },
	{ code: 'hy', name: 'Armenian', native: 'Հայերեն' },
	{ code: 'id', name: 'Indonesian', native: 'Bahasa Indonesia' },
	{ code: 'is', name: 'Icelandic', native: 'Íslenska' },
	{ code: 'it', name: 'Italian', native: 'Italiano' },
	{ code: 'ja', name: 'Japanese', native: '日本語' },
	{ code: 'ka', name: 'Georgian', native: 'ქართული' },
	{ code: 'kg', name: 'Kongo', native: 'KiKongo' },
	{ code: 'kk', name: 'Kazakh', native: 'Қазақша' },
	{ code: 'kl', name: 'Greenlandic', native: 'Kalaallisut' },
	{ code: 'km', name: 'Cambodian', native: 'ភាសាខ្មែរ' },
	{ code: 'ko', name: 'Korean', native: '한국어' },
	{ code: 'ku', name: 'Kurdish', native: 'Kurdî / كوردی', rtl: 1 },
	{ code: 'ky', name: 'Kirghiz', native: 'Kırgızca / Кыргызча' },
	{ code: 'la', name: 'Latin', native: 'Latina' },
	{ code: 'lb', name: 'Luxembourgish', native: 'Lëtzebuergesch' },
	{ code: 'ln', name: 'Lingala', native: 'Lingála' },
	{ code: 'lo', name: 'Laotian', native: 'ລາວ / Pha xa lao' },
	{ code: 'lt', name: 'Lithuanian', native: 'Lietuvių' },
	{ code: 'lv', name: 'Latvian', native: 'Latviešu' },
	{ code: 'mg', name: 'Malagasy', native: 'Malagasy' },
	{ code: 'mh', name: 'Marshallese', native: 'Kajin Majel / Ebon' },
	{ code: 'mi', name: 'Maori', native: 'Māori' },
	{ code: 'mk', name: 'Macedonian', native: 'Македонски' },
	{ code: 'mn', name: 'Mongolian', native: 'Монгол' },
	{ code: 'ms', name: 'Malay', native: 'Bahasa Melayu' },
	{ code: 'mt', name: 'Maltese', native: 'bil-Malti' },
	{ code: 'my', name: 'Burmese', native: 'မြန်မာစာ' },
	{ code: 'na', name: 'Nauruan', native: 'Dorerin Naoero' },
	{ code: 'nd', name: 'North Ndebele', native: 'Sindebele' },
	{ code: 'ne', name: 'Nepali', native: 'नेपाली' },
	{ code: 'nl', name: 'Dutch', native: 'Nederlands' },
	{ code: 'nn', name: 'Norwegian Nynorsk', native: 'Norsk (nynorsk)' },
	{ code: 'no', name: 'Norwegian', native: 'Norsk (bokmål / riksmål)' },
	{ code: 'nr', name: 'South Ndebele', native: 'isiNdebele' },
	{ code: 'ny', name: 'Chichewa', native: 'Chi-Chewa' },
	{ code: 'oc', name: 'Occitan', native: 'Occitan' },
	{ code: 'pa', name: 'Panjabi / Punjabi', native: 'ਪੰਜਾਬੀ / पंजाबी / پنجابي' },
	{ code: 'pl', name: 'Polish', native: 'Polski' },
	{ code: 'ps', name: 'Pashto', native: 'پښتو', rtl: 1 },
	{ code: 'pt', name: 'Portuguese', native: 'Português' },
	{ code: 'qu', name: 'Quechua', native: 'Runa Simi' },
	{ code: 'rn', name: 'Kirundi', native: 'Kirundi' },
	{ code: 'ro', name: 'Romanian', native: 'Română' },
	{ code: 'ru', name: 'Russian', native: 'Русский' },
	{ code: 'rw', name: 'Rwandi', native: 'Kinyarwandi' },
	{ code: 'sg', name: 'Sango', native: 'Sängö' },
	{ code: 'si', name: 'Sinhalese', native: 'සිංහල' },
	{ code: 'sk', name: 'Slovak', native: 'Slovenčina' },
	{ code: 'sl', name: 'Slovenian', native: 'Slovenščina' },
	{ code: 'sm', name: 'Samoan', native: 'Gagana Samoa' },
	{ code: 'sn', name: 'Shona', native: 'chiShona' },
	{ code: 'so', name: 'Somalia', native: 'Soomaaliga' },
	{ code: 'sq', name: 'Albanian', native: 'Shqip' },
	{ code: 'sr', name: 'Serbian', native: 'Српски' },
	{ code: 'ss', name: 'Swati', native: 'SiSwati' },
	{ code: 'st', name: 'Southern Sotho', native: 'Sesotho' },
	{ code: 'sv', name: 'Swedish', native: 'Svenska' },
	{ code: 'sw', name: 'Swahili', native: 'Kiswahili' },
	{ code: 'ta', name: 'Tamil', native: 'தமிழ்' },
	{ code: 'tg', name: 'Tajik', native: 'Тоҷикӣ' },
	{ code: 'th', name: 'Thai', native: 'ไทย / Phasa Thai' },
	{ code: 'ti', name: 'Tigrinya', native: 'ትግርኛ' },
	{ code: 'tk', name: 'Turkmen', native: 'Туркмен / تركمن' },
	{ code: 'tn', name: 'Tswana', native: 'Setswana' },
	{ code: 'to', name: 'Tonga', native: 'Lea Faka-Tonga' },
	{ code: 'tr', name: 'Turkish', native: 'Türkçe' },
	{ code: 'ts', name: 'Tsonga', native: 'Xitsonga' },
	{ code: 'uk', name: 'Ukrainian', native: 'Українська' },
	{ code: 'ur', name: 'Urdu', native: 'اردو', rtl: 1 },
	{ code: 'uz', name: 'Uzbek', native: 'Ўзбек' },
	{ code: 've', name: 'Venda', native: 'Tshivenḓa' },
	{ code: 'vi', name: 'Vietnamese', native: 'Tiếng Việt' },
	{ code: 'xh', name: 'Xhosa', native: 'isiXhosa' },
	{ code: 'zh', name: 'Chinese', native: '中文' },
	{ code: 'zu', name: 'Zulu', native: 'isiZulu' },
];

export const noWrapStyle = {
	// nowrap: {
	// 	whiteSpace: 'nowrap',
	// },
};

export const listStyle = {
	maxHeight: '200px',
	overflowY: 'scroll',
};

export const dataGridStyle = {
	table: {
		tableLayout: 'auto',
	},
	tbody: {
		height: 'inherit',
	},
	header: {
		th: {
			padding: 0,
			color: 'rgba(0, 0, 0, 0.87)',
			verticalAlign: 'middle',
		},
		'th:first-child': {
			color: 'rgba(0, 0, 0, 0.87)',
			verticalAlign: 'middle',
			padding: 0,
		},
	},
	cell: {
		td: {
			padding: '0 15px',
			whiteSpace: 'normal',
		},
		'td:first-child': {
			padding: '0 15px 0 16px',
			whiteSpace: 'normal',
		},
	},
};

export const PRODUCT_TYPE = {
	AIRPORT_TRANSPORTATION: 'airport-transfer',
	PRIVATE_INTERCITY_TRANSPORTATION: 'private-bus',
	OPEN_BUS: 'open-bus',
	CAR_RENTAL: 'car-rental',
	PRIVATE_TRIP: 'private-trip',
	PUBLIC_TRIP: 'public-trip',
	SHOW: 'show',
	FREE_WALKING_TOUR: 'free-walking-tour',
	HOTEL: 'hotel',
	TOUR_GUIDE: 'tour-guide',
	PRIVATE_TOUR_PACKAGE: 'private-tour',
	GUARANTEE_TOUR_PACKAGE: 'guarantee-tour',
};

export const PRODUCT_TYPE_NAME_MAPPING = {
	[PRODUCT_TYPE.AIRPORT_TRANSPORTATION]: 'Airport Transportation',
	[PRODUCT_TYPE.PRIVATE_INTERCITY_TRANSPORTATION]: 'Private Intercity Car',
	[PRODUCT_TYPE.OPEN_BUS]: 'Open Bus',
	[PRODUCT_TYPE.CAR_RENTAL]: 'Car Rental',
};

export const PROMOTION_TYPE = {
	GENERATE_DISCOUNT: 'general',
	CAMPAIGN: 'campaign',
	PROMOTION: 'promotion',
};
