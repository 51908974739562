/* © 2019 NauStud.io
 * @author Eric Tran
 */

import React, { Component } from 'react';
import { Datagrid, TextField, List, ReferenceField } from 'react-admin';

class Report extends Component {
	render() {
		const {
			match: {
				params: { tripId },
			},
		} = this.props;

		return (
			<List
				{...this.props}
				resource="customers-list"
				bulkActionButtons={false}
				hasCreate={false}
				hasEdit={false}
				hasShow={false}
				hasList
				filter={{ tripId }}
				basePath={'/'}
				exporter={false}
			>
				<Datagrid>
					<TextField source="id" label="STT" />
					<TextField source="firstName" label="Guest First Name" />
					<TextField source="lastName" label="Guest Last Name" />
					<ReferenceField basePath="/bookings" label="Booking" source="bookingId" reference="bookings">
						<TextField source="id" />
					</ReferenceField>
					<TextField source="bookingStatus" label="Booking Status" />
				</Datagrid>
			</List>
		);
	}
}

export default Report;
