/* © 2018 NauStud.io
 * @author Tu Nguyen
 */
import Quill from 'quill/dist/quill';

const BlockEmbed = Quill.import('blots/block/embed');

export default class CustomBlockquote extends BlockEmbed {
	static blotName = 'customBlockquote';
	static tagName = 'blockquote';

	static create(value) {
		const node = super.create(value);

		const citeContent = value.cite;
		const content = value.content;

		const cite = document.createElement('cite');
		cite.innerHTML = citeContent;
		const contentDiv = document.createElement('div');
		contentDiv.innerHTML = content;
		const icon = document.createElement('i');
		icon.className = 'icon icon--quotes';

		contentDiv.prepend(icon);
		node.appendChild(contentDiv);
		node.appendChild(cite);

		return node;
	}
}
