/* © 2019 NauStud.io
 * @author Eric Tran
 */

import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import startOfMonth from 'date-fns/startOfMonth';
import endOfMonth from 'date-fns/endOfMonth';
import compareAsc from 'date-fns/compareAsc';
import addDays from 'date-fns/addDays';
import addMonths from 'date-fns/addMonths';
import format from 'date-fns/format';
import eachDayOfInterval from 'date-fns/eachDayOfInterval';
import eachWeekOfInterval from 'date-fns/eachWeekOfInterval';
import isSameMonth from 'date-fns/isSameMonth';
import getDay from 'date-fns/getDay';

import { Field } from 'redux-form';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from '@material-ui/core/styles';
import { GET_LIST } from 'react-admin';

import restClient from '../helper/restClient';
import { languages } from '../helper/constants';
import { capitalize } from '../helper/utils';

const styles = {
	textField: {
		width: 80,
	},
	tableCellHead: {
		maxWidth: 140,
	},
	tableCell: {
		minWidth: 110,
		maxWidth: 110,
		paddingLeft: 10,
		paddingRight: 10,
	},
};

class ProductCostTable extends Component {
	static propTypes = {
		formData: PropTypes.object,
		classes: PropTypes.object,
		lookupValue: PropTypes.string,
		changeValues: PropTypes.array,
		lookupData: PropTypes.array,
	};

	static defaultProps = {
		formData: {},
		classes: {},
		lookupValue: 'carTypeId',
		changeValues: ['cost'],
		lookupData: null,
	};

	static getDerivedStateFromProps(props, state) {
		const newState = {
			...state,
			variation: props.formData.variation || [],
		};
		if (props.lookupData !== null) {
			newState.lookupData = props.lookupData;
		}

		return newState;
	}

	constructor(props) {
		super(props);

		let lookupData = props.lookupData || [];
		if (this.props.lookupValue === 'language') {
			lookupData = languages.map(lang => ({
				id: lang.code,
				name: lang.name,
			}));
		}

		this.state = {
			selectedMonth: null,
			lookupData,
			variation: props.formData.variation || [],
		};
	}

	componentDidMount() {
		if (this.props.lookupValue === 'carTypeId') {
			restClient(GET_LIST, 'car-types', {
				pagination: {},
				sort: {},
				filter: {},
			}).then(res => {
				if (res.data && res.data.length) {
					this.setState({
						lookupData: res.data.map(carType => ({
							id: carType.id,
							name: carType.name.en,
						})),
					});
				}
			});
		}
	}

	onTableCostCellChange = (date, item, valueName, cost) => {
		const {
			lookupValue,
			formData: { costInfo = [] },
			changeValues,
		} = this.props;
		const { variation } = this.state;
		let foundVariationIndex = variation.findIndex(v => compareAsc(new Date(v.date), new Date(date)) === 0);
		let foundCostInfoIndex = 0;

		// default costs
		const costs = {};
		const foundCostInfo = costInfo.find(ci => ci[lookupValue] === item[lookupValue]);
		changeValues.forEach(element => {
			costs[element] = foundCostInfo[element] || 0;
		});
		costs[valueName] = cost;

		if (foundVariationIndex < 0) {
			variation.push({
				date,
				costInfo: [
					{
						[lookupValue]: item[lookupValue],
						...costs,
					},
				],
			});
			foundVariationIndex = variation.length - 1;
		} else {
			const costInfoList = variation[foundVariationIndex].costInfo || [];
			foundCostInfoIndex = costInfoList.findIndex(ci => ci[lookupValue] === item[lookupValue]);
			if (foundCostInfoIndex < 0) {
				costInfoList.push({
					[lookupValue]: item[lookupValue],
					...costs,
				});
				foundCostInfoIndex = costInfo.length - 1;
			} else {
				changeValues.forEach(element => {
					costs[element] = costInfoList[foundCostInfoIndex][element] || 0;
				});
				costs[valueName] = cost;
				costInfoList[foundCostInfoIndex][valueName] = cost;
			}
			variation[foundVariationIndex].costInfo = costInfoList;
		}

		this.setState(
			{
				variation,
			},
			() => {
				this[`variation[${foundVariationIndex}].dateOnChange`](date);
				this[`variation[${foundVariationIndex}].costInfo[${foundCostInfoIndex}].${lookupValue}OnChange`](
					item[lookupValue]
				);
				changeValues.forEach(element => {
					this[`variation[${foundVariationIndex}].costInfo[${foundCostInfoIndex}].${element}OnChange`](
						costs[element]
					);
				});
			}
		);
	};

	onTableMarkupTypeCellChange = (date, type) => {
		const { variation } = this.state;
		let foundVariationIndex = variation.findIndex(v => compareAsc(new Date(v.date), new Date(date)) === 0);
		if (foundVariationIndex < 0) {
			variation.push({
				date,
				markupType: type,
			});
			foundVariationIndex = variation.length - 1;
		} else {
			variation[foundVariationIndex].markupType = type;
		}

		this.setState(
			{
				variation,
			},
			() => {
				this[`variation[${foundVariationIndex}].dateOnChange`](date);
				this[`variation[${foundVariationIndex}].markupTypeOnChange`](type);
			}
		);
	};

	onTableMarkupAmountCellChange = (date, amount) => {
		const { variation } = this.state;
		let foundVariationIndex = variation.findIndex(v => compareAsc(new Date(v.date), new Date(date)) === 0);
		if (foundVariationIndex < 0) {
			variation.push({
				date,
				markupAmount: amount,
			});
			foundVariationIndex = variation.length - 1;
		} else {
			variation[foundVariationIndex].markupAmount = amount;
		}

		this.setState(
			{
				variation,
			},
			() => {
				this[`variation[${foundVariationIndex}].dateOnChange`](date);
				this[`variation[${foundVariationIndex}].markupAmountOnChange`](amount);
			}
		);
	};

	onSelectMonth = event => {
		this.setState({ selectedMonth: new Date(event.target.value) });
	};

	getRangeMinMax = () => {
		let min = new Date(0, 0, 0);
		let max = new Date(2100, 0, 0);

		const {
			formData: { supplyTerm, availableDays },
		} = this.props;
		let ranges = [];
		if (supplyTerm && supplyTerm.from && supplyTerm.to) {
			ranges.push({ from: supplyTerm.from, to: supplyTerm.to });
		} else if (availableDays && availableDays.length) {
			ranges = availableDays.filter(range => !!range.from && !!range.to);
		}

		if (!ranges.length) {
			return { min, max };
		}

		min = new Date(2100, 0, 0);
		max = new Date(0, 0, 0);
		ranges.forEach(range => {
			if (compareAsc(min, new Date(range.from)) === 1) {
				min = new Date(range.from);
			}
			if (compareAsc(new Date(range.to), max) === 1) {
				max = new Date(range.to);
			}
		});

		return { min, max };
	};

	checkInRange = (date, isCheckMonth = false) => {
		const {
			formData: { supplyTerm, availableDays },
		} = this.props;
		let ranges = [];
		if (supplyTerm && supplyTerm.from && supplyTerm.to) {
			ranges.push({ from: supplyTerm.from, to: supplyTerm.to, weekdays: [0, 1, 2, 3, 4, 5, 6] });
		} else if (availableDays && availableDays.length) {
			ranges = availableDays.filter(range => !!range.from && !!range.to);
		} else {
			return false;
		}

		console.log(ranges);

		let result = false;
		for (let i = 0; i < ranges.length; i++) {
			const range = ranges[i];
			const matchWeekday = (range.weekdays || []).findIndex(weekday => weekday.toString() === getDay(date).toString()) > -1;
			const from = new Date(range.from);
			const to = new Date(range.to);
			if (compareAsc(date, from) >= 0 && compareAsc(to, date) >= 0 && matchWeekday) {
				result = true;
			}
			if (isCheckMonth && (isSameMonth(date, from) || isSameMonth(date, to))) {
				result = true;
			}
		}

		return result;
	};

	generateTableData = selectedMonth => {
		if (!selectedMonth) {
			return [];
		}
		const data = [];
		const start = startOfMonth(selectedMonth);
		const end = endOfMonth(selectedMonth);
		const weeks = eachWeekOfInterval(
			{
				start,
				end,
			},
			{
				weekStartsOn: 1,
			}
		);

		weeks.forEach(week => {
			const endWeek = addDays(new Date(week), 6);
			const days = eachDayOfInterval({ start: week, end: endWeek }).map(date => ({
				date,
				isInRange: this.checkInRange(date),
			}));
			const findIndex = days.findIndex(day => day.isInRange);
			if (findIndex > -1) {
				data.push(days);
			}
		});

		return data;
	};

	generateMonthMenuItems = () => {
		const data = [];
		const { min, max } = this.getRangeMinMax();
		const start = new Date(min);
		const end = new Date(max);
		start.setDate(15);
		end.setDate(15);
		let cursor = new Date(start);
		while (compareAsc(end, cursor) >= 0) {
			if (this.checkInRange(cursor, true)) {
				data.push(cursor);
			}
			cursor = addMonths(cursor, 1);
		}

		return data;
	};

	findCost = (date, id) => {
		const {
			formData: { variation = [], costInfo = [] },
			changeValues,
			lookupValue,
		} = this.props;
		const result = {};
		for (let i = 0; i < variation.length; i++) {
			const item = variation[i];
			if (compareAsc(new Date(item.date), new Date(date)) === 0) {
				const variationCostInfo = item.costInfo || [];
				const foundCostInfo = variationCostInfo.find(ci => ci[lookupValue] === id);
				if (foundCostInfo) {
					changeValues.forEach(element => {
						result[element] = foundCostInfo[element] || 0;
					});

					return result;
				}
			}
		}

		const foundCostInfo = costInfo.find(ci => ci[lookupValue] === id);
		changeValues.forEach(element => {
			result[element] = foundCostInfo[element] || 0;
		});

		return result;
	};

	findMarkup = date => {
		const {
			formData: { variation = [], markupType, markupAmount },
		} = this.props;
		for (let i = 0; i < variation.length; i++) {
			const item = variation[i];
			if (compareAsc(new Date(item.date), new Date(date)) === 0) {
				return { markupType: item.markupType || markupType, markupAmount: item.markupAmount || markupAmount };
			}
		}

		return {
			markupType,
			markupAmount,
		};
	};

	Input = ({ input: { name, value, onChange, ...inputProps } }) => {
		this[`${name}OnChange`] = onChange;

		return <input onChange={onChange} hidden {...inputProps} />;
	};

	render() {
		const {
			formData: { costInfo = [] },
			classes,
			lookupValue,
			changeValues,
		} = this.props;
		const { selectedMonth, lookupData, variation } = this.state;
		const monthSelectData = this.generateMonthMenuItems();

		const selected = selectedMonth || monthSelectData[0];
		const data = this.generateTableData(selected);

		return (
			<div>
				{variation.map((v, index) => (
					<Fragment key={index.toString()}>
						<Field name={`variation[${index}].date`} component={this.Input} hidden />
						<Field name={`variation[${index}].markupType`} component={this.Input} hidden />
						<Field name={`variation[${index}].markupAmount`} component={this.Input} hidden />
						{(v.costInfo || []).map((ci, i) => (
							<Fragment key={i.toString()}>
								<Field
									name={`variation[${index}].costInfo[${i}].${lookupValue}`}
									component={this.Input}
									hidden
								/>
								{changeValues.map(valueName => (
									<Field
										key={valueName}
										name={`variation[${index}].costInfo[${i}].${valueName}`}
										component={this.Input}
										hidden
									/>
								))}
							</Fragment>
						))}
					</Fragment>
				))}
				<Select value={selected ? format(selected, 'yyyy/MM/dd') : undefined} onChange={this.onSelectMonth}>
					{monthSelectData.map(item => (
						<MenuItem key={item} value={format(item, 'yyyy/MM/dd')}>
							{format(item, 'MMM yyyy')}
						</MenuItem>
					))}
				</Select>
				{data.map((table, tableIndex) => (
					<Table key={tableIndex.toString()}>
						<TableHead>
							<TableRow>
								<TableCell>{selected ? format(selected, 'MMM yyyy') : ''}</TableCell>
								<TableCell>Field Name</TableCell>
								{table.map((cell, index) => (
									<Fragment key={cell.date + index.toString()}>
										<TableCell className={classes.tableCell}>
											{format(cell.date, 'eee - dd/M')}
										</TableCell>
										<TableCell className={classes.tableCell}>&nbsp;</TableCell>
									</Fragment>
								))}
							</TableRow>
						</TableHead>
						<TableBody>
							{costInfo.map(item => {
								const foundItem = lookupData.find(ct => ct.id === item[lookupValue]);

								if (foundItem) {
									return (
										<Fragment key={item[lookupValue]}>
											{changeValues.map((valueName, index) => (
												<TableRow key={item[lookupValue] + valueName}>
													{index === 0 ? (
														<TableCell className={classes.tableCellHead}>
															{foundItem.name}
														</TableCell>
													) : (
														<TableCell className={classes.tableCellHead}>&nbsp;</TableCell>
													)}
													<TableCell className={classes.tableCellHead}>
														{capitalize(valueName)}
													</TableCell>
													{table.map((cell, i) => {
														const costs = this.findCost(cell.date, item[lookupValue]);
														const { markupType, markupAmount } = this.findMarkup(cell.date);
														let price = costs[valueName];
														if (markupType === 'percentage') {
															price += ((markupAmount || 0) * price) / 100;
														} else {
															price += markupAmount || 0;
														}

														return (
															<Fragment key={cell.date + i.toString()}>
																<TableCell className={classes.tableCell}>
																	{cell.isInRange ? (
																		<TextField
																			value={costs[valueName]}
																			disabled={!isSameMonth(cell.date, selected)}
																			onChange={event =>
																				this.onTableCostCellChange(
																					cell.date,
																					item,
																					valueName,
																					parseInt(event.target.value, 10)
																				)
																			}
																			className={classes.textField}
																		/>
																	) : (
																		''
																	)}
																</TableCell>
																<TableCell className={classes.tableCell}>
																	{cell.isInRange ? (
																		<TextField
																			value={price}
																			disabled
																			className={classes.textField}
																		/>
																	) : (
																		''
																	)}
																</TableCell>
															</Fragment>
														);
													})}
												</TableRow>
											))}
										</Fragment>
									);
								}

								return null;
							})}
							<TableRow>
								<TableCell className={classes.tableCellHead}>Markup Type</TableCell>
								<TableCell className={classes.tableCellHead}>&nbsp;</TableCell>
								{table.map((cell, i) => {
									const { markupType } = this.findMarkup(cell.date);

									return (
										<Fragment key={cell.date + i.toString()}>
											<TableCell className={classes.tableCell}>
												{cell.isInRange ? (
													<Select
														value={markupType}
														disabled={!isSameMonth(cell.date, selected)}
														onChange={event => {
															this.onTableMarkupTypeCellChange(
																cell.date,
																event.target.value
															);
														}}
													>
														<MenuItem value="fix">Fix</MenuItem>
														<MenuItem value="percentage">%</MenuItem>
													</Select>
												) : (
													''
												)}
											</TableCell>
											<TableCell className={classes.tableCell}>&nbsp;</TableCell>
										</Fragment>
									);
								})}
							</TableRow>
							<TableRow>
								<TableCell className={classes.tableCellHead}>Markup Amount</TableCell>
								<TableCell className={classes.tableCellHead}>&nbsp;</TableCell>
								{table.map((cell, i) => {
									const { markupAmount } = this.findMarkup(cell.date);

									return (
										<Fragment key={cell.date + i.toString()}>
											<TableCell className={classes.tableCell}>
												{cell.isInRange ? (
													<TextField
														value={markupAmount}
														disabled={!isSameMonth(cell.date, selected)}
														className={classes.textField}
														onChange={event => {
															this.onTableMarkupAmountCellChange(
																cell.date,
																parseInt(event.target.value, 10)
															);
														}}
													/>
												) : (
													''
												)}
											</TableCell>
											<TableCell className={classes.tableCell}>&nbsp;</TableCell>
										</Fragment>
									);
								})}
							</TableRow>
						</TableBody>
					</Table>
				))}
			</div>
		);
	}
}

export default withStyles(styles)(ProductCostTable);
