/* © 2019 NauStud.io
 * @author Eric Tran
 */

import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Datagrid, List, TextField, EditButton } from 'react-admin';

import { dataGridStyle, noWrapStyle } from '../../helper/constants';
import DeleteButtonWithConfirmation from '../DeleteButtonWithConfirmation';
import ListDateField from '../ListDateField';

class OpenBusList extends Component {
	render() {
		return (
			<List {...this.props} bulkActionButtons={false} sort={{ field: 'departureDateTime', order: 'ASC' }}>
				<Datagrid styles={dataGridStyle}>
					<TextField source="name" label="Name" />
					<TextField source="description" label="Description" />
					<ListDateField source="departureDateTime" format="dd/MM/yyyy HH:mm" />
					<TextField source="shortRouteString" label="Route" />
					<EditButton />
					<DeleteButtonWithConfirmation />
				</Datagrid>
			</List>
		);
	}
}

const styles = {
	...noWrapStyle,
};

export default withStyles(styles)(OpenBusList);
