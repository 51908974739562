/* © 2018 NauStud.io
 * @author Eric Tran, Thanh
 */

import storage from './storage';

const authClient = (loginWithTokenApiUrl, noAccessPage = '/login') => (type, params) => {
	if (type === 'AUTH_LOGIN') {
		const { otpToken } = params;
		// write login to login with token
		const request = new Request(`${loginWithTokenApiUrl}?include=user`, {
			method: 'POST',
			body: JSON.stringify({ otpToken }),
			headers: new Headers({ 'Content-Type': 'application/json' }),
		});

		return fetch(request)
			.then(response => {
				if (response.status < 200 || response.status >= 300) {
					return response.text().then(text => ({
						response,
						error: new Error(response.status === 429 ? text : response.statusText),
					}));
				}

				return response.text().then(text => ({ response, data: text }));
			})
			.then(({ error, data }) => {
				if (error) {
					throw error;
				}

				const { ttl, user, ...tokenData } = JSON.parse(data);
				if (!user.role || user.role === 'user' || user.role === 'agency') {
					throw new Error('Not allow to access');
				} else {
					storage.save('lbtoken', tokenData);
					storage.save('currentUser', user);
				}
			});
	}

	if (type === 'AUTH_LOGOUT') {
		storage.remove('lbtoken');
		storage.remove('currentUser');

		return Promise.resolve();
	}
	if (type === 'AUTH_ERROR') {
		const { status } = params;
		if (status === 401 || status === 403) {
			storage.remove('lbtoken');
			storage.remove('currentUser');

			return Promise.reject({ redirectTo: noAccessPage });
		}

		return Promise.resolve();
	}
	if (type === 'AUTH_CHECK') {
		const token = storage.load('lbtoken');
		if (token && token.id) {
			return Promise.resolve();
		}
		storage.remove('lbtoken');
		storage.remove('currentUser');

		return Promise.reject({ redirectTo: noAccessPage });
	}

	if (type === 'AUTH_GET_PERMISSIONS') {
		return Promise.resolve(storage.load('currentUser').role);
	}

	return Promise.reject('Unkown method');
};

const loginWithPassword = (loginWithPasswordApiUrl, { email, password }) => {
	const request = new Request(`${loginWithPasswordApiUrl}?include=user`, {
		method: 'POST',
		body: JSON.stringify({ email, password }),
		headers: new Headers({ 'Content-Type': 'application/json' }),
	});

	return fetch(request)
		.then(response => {
			if (response.status < 200 || response.status >= 300) {
				return response.text().then(text => ({
					response,
					error: new Error(response.status === 429 ? text : response.statusText),
				}));
			}

			return response.text().then(text => ({ response, data: text }));
		})
		.then(({ error, data }) => {
			if (error) {
				throw error;
			}

			return JSON.parse(data);
		});
};

const loginWithoutOTP = (loginWithoutTokenApiUrl, noAccessPage = '/login') => (type, params) => {
	if (type === 'AUTH_LOGIN') {
		const { email, password } = params;
		// write login to login with token
		const request = new Request(`${loginWithoutTokenApiUrl}?include=user`, {
			method: 'POST',
			body: JSON.stringify({ email, password }),
			headers: new Headers({ 'Content-Type': 'application/json' }),
		});

		return fetch(request)
			.then(response => {
				if (response.status < 200 || response.status >= 300) {
					return response.text().then(text => ({
						response,
						error: new Error(response.status === 429 ? text : response.statusText),
					}));
				}

				return response.text().then(text => ({ response, data: text }));
			})
			.then(({ error, data }) => {
				if (error) {
					throw error;
				}

				const { ttl, user, ...tokenData } = JSON.parse(data);
				if (!user.role || user.role === 'user' || user.role === 'agency') {
					throw new Error('Not allow to access');
				} else {
					storage.save('lbtoken', tokenData);
					storage.save('currentUser', user);
				}
			});
	}

	if (type === 'AUTH_LOGOUT') {
		storage.remove('lbtoken');
		storage.remove('currentUser');

		return Promise.resolve();
	}
	if (type === 'AUTH_ERROR') {
		const { status } = params;
		if (status === 401 || status === 403) {
			storage.remove('lbtoken');
			storage.remove('currentUser');

			return Promise.reject({ redirectTo: noAccessPage });
		}

		return Promise.resolve();
	}
	if (type === 'AUTH_CHECK') {
		const token = storage.load('lbtoken');
		if (token && token.id) {
			return Promise.resolve();
		}
		storage.remove('lbtoken');
		storage.remove('currentUser');

		return Promise.reject({ redirectTo: noAccessPage });
	}

	if (type === 'AUTH_GET_PERMISSIONS') {
		return Promise.resolve(storage.load('currentUser').role);
	}

	return Promise.reject('Unkown method');
};

const logout = logoutApiUrl => {
	let token = storage.load('lbtoken');
	token = (token && token.id) || '';
	storage.remove('lbtoken');
	storage.remove('currentUser');

	if (!token) {
		return Promise.resolve(null);
	}

	const request = new Request(`${logoutApiUrl}`, {
		method: 'POST',
		headers: new Headers({
			'Content-Type': 'application/json',
			Authorization: token,
		}),
	});

	return fetch(request).then(response => {
		if (response.status < 200 || response.status >= 300) {
			console.log(response.body);
		}
	});
};

const loginWithOTP = authClient;

export default authClient;

export { loginWithPassword, loginWithOTP, loginWithoutOTP, logout };
