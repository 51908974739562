/* © 2019 NauStud.io
 * @author Eric Tran
 */

import React, { Component } from 'react';
import {
	Edit,
	TabbedForm,
	FormTab,
	TextInput,
	LongTextInput,
	BooleanInput,
	AutocompleteInput,
	SelectInput,
	SelectArrayInput,
	NumberInput,
	FormDataConsumer,
	ArrayInput,
	SimpleFormIterator,
	ReferenceInput,
	ReferenceArrayInput,
	required,
	minValue,
	maxValue,
} from 'react-admin';

import addYears from 'date-fns/addYears';
import { DateInput } from 'react-admin-date-inputs';
import LocationInput from '../LocationInput';
import ProductCostTable from '../ProductCostTable';

class AirportTransferEdit extends Component {
	render() {
		return (
			<Edit {...this.props} undoable={false}>
				<TabbedForm redirect="list">
					<FormTab label="General">
						<TextInput source="vtCode" label="Vietravel Code" validate={required()} />
						<TextInput source="name" label="Name" validate={required()} />
						<LongTextInput source="description" label="Description" />
						<LocationInput source="locationId" label="Location" validate={required()} />
						<ReferenceInput source="airportId" label="Airport" reference="airports" validate={required()}>
							<AutocompleteInput optionText="name.en" />
						</ReferenceInput>
						<NumberInput source="release" label="Release (Hour)" validate={required()} />
						<BooleanInput source="active" label="Active" />
					</FormTab>
					<FormTab label="Guide">
						<ReferenceArrayInput
							source="availableGuideLanguages"
							label="Available Guide Languages"
							reference="guide-languages"
						>
							<SelectArrayInput optionsText="name" />
						</ReferenceArrayInput>
						<SelectArrayInput
							source="availableGuideDuration"
							defaultValue={['halfDay']}
							choices={[
								{ id: 'halfDay', name: 'Half Day' },
								{ id: 'fullDay', name: 'Full Day' },
								{ id: 'multipleDay', name: 'Multiple Day' },
							]}
						/>
					</FormTab>
					<FormTab label="Surcharge">
						<SelectInput
							source="surchargeType"
							defaultValue="fix"
							choices={[{ id: 'fix', name: 'Fix' }, { id: 'percentage', name: '%' }]}
						/>
						<FormDataConsumer>
							{({ formData }) => {
								if (formData.surchargeType === 'percentage') {
									return (
										<NumberInput
											source="surchargeAmount"
											inputProps={{ min: 0, max: 100 }}
											validate={[minValue(0), maxValue(100)]}
											defaultValue={0}
										/>
									);
								}

								return (
									<NumberInput
										source="surchargeAmount"
										inputProps={{ min: 0 }}
										validate={minValue(0)}
										defaultValue={0}
									/>
								);
							}}
						</FormDataConsumer>
					</FormTab>
					<FormTab label="Cost Information">
						<DateInput
							source="supplyTerm.from"
							label="Supply Term (From)"
							validate={required()}
							options={{ format: 'dd/MM/yyyy' }}
							defaultValue={new Date()}
						/>
						<DateInput
							source="supplyTerm.to"
							label="Supply Term (To)"
							validate={required()}
							options={{ format: 'dd/MM/yyyy' }}
							defaultValue={addYears(new Date(), 1)}
						/>
						<ArrayInput source="costInfo" label="Cost Information Array">
							<SimpleFormIterator>
								<ReferenceInput source="carTypeId" label="Car Type" reference="car-types">
									<SelectInput optionText="name.en" />
								</ReferenceInput>
								<NumberInput source="cost" label="Cost (VND)" />
							</SimpleFormIterator>
						</ArrayInput>
						<SelectInput
							source="markupType"
							defaultValue="fix"
							choices={[{ id: 'fix', name: 'Fix' }, { id: 'percentage', name: '%' }]}
						/>
						<FormDataConsumer>
							{({ formData }) => {
								if (formData.markupType === 'percentage') {
									return (
										<NumberInput
											source="markupAmount"
											inputProps={{ min: 0, max: 100 }}
											validate={[minValue(0), maxValue(100)]}
											defaultValue={0}
										/>
									);
								}

								return (
									<NumberInput
										source="markupAmount"
										inputProps={{ min: 0 }}
										validate={minValue(0)}
										defaultValue={0}
									/>
								);
							}}
						</FormDataConsumer>
						<BooleanInput source="fillTheTable" label="Fill the table" />
						<FormDataConsumer>
							{({ formData }) => {
								if (formData.fillTheTable) {
									return <ProductCostTable formData={formData} />;
								}

								return '';
							}}
						</FormDataConsumer>
					</FormTab>
				</TabbedForm>
			</Edit>
		);
	}
}

export default AirportTransferEdit;
