/* © 2018 NauStud.io
 * @author Eric Tran
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import format from 'date-fns/format';
import { Datagrid, List, TextField, NumberField, ReferenceField, Filter, EditButton } from 'react-admin';
import { DateInput } from 'react-admin-date-inputs';

import { dataGridStyle } from '../../helper/constants';

const CustomDateField = ({ record = {}, source }) => {
	let date = record[source] ? new Date(record[source]) : '';

	date = format(date, 'dd/MM/yyyy');

	return <span>{date}</span>;
};

CustomDateField.propTypes = {
	record: PropTypes.shape({
		departureDate: PropTypes.instanceOf(Date),
	}),
	source: PropTypes.string,
};

CustomDateField.defaultProps = { record: null, source: '' };

const BookingsFilter = props => (
	<Filter {...props}>
		<DateInput label="Booking Date From" source="createdAt.gte" alwaysOn options={{ format: 'dd/MM/yyyy' }} />
		<DateInput label="Booking Date To" source="createdAt.lte" alwaysOn options={{ format: 'dd/MM/yyyy' }} />
	</Filter>
);

class BookingList extends Component {
	static propTypes = {
		classes: PropTypes.shape({}),
	};

	static defaultProps = {
		classes: {},
	};

	render() {
		const { classes, resource } = this.props;
		const tourReference = resource === 'custom-tour-bookings' ? 'custom-tours' : 'tours';

		return (
			<List
				{...this.props}
				bulkActionButtons={false}
				filters={<BookingsFilter />}
				sort={{ field: 'createdAt', order: 'DESC' }}
			>
				<Datagrid classes={classes}>
					<TextField source="id" label="Booking ID" sortable={false} />
					<ReferenceField source="tourId" label="Tour ID" reference={tourReference}>
						<TextField source="title.en" />
					</ReferenceField>
					<CustomDateField label="Departure Date" source="departureDate" />
					<CustomDateField label="Booking Date" source="createdAt" />
					<TextField source="tourInfo.tourTitle" label="Tour name" sortable={false} />
					<TextField source="status" label="Status" />
					<NumberField source="totalPrice" label="Total price" />
					<TextField source="customers.length" label="Number of guests" />
					<ReferenceField source="userId" label="User" reference="users">
						<TextField source="fullname" />
					</ReferenceField>
					<ReferenceField source="userId" label="User Role" reference="users">
						<TextField source="role" />
					</ReferenceField>
					<EditButton />
				</Datagrid>
			</List>
		);
	}
}

const styles = {
	postsField: { textAlign: 'center', display: 'block' },
	...dataGridStyle,
};

export default withStyles(styles)(BookingList);
