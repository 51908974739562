/* © 2018 NauStud.io
 * @author Eric Tran
 */

import React, { Component } from 'react';
import { Create, SimpleForm, minLength, required } from 'react-admin';

import MultiLanguageInput from '../MultiLanguageInput';

class ProductCategoryCreate extends Component {
	render() {
		return (
			<Create {...this.props} undoable={false}>
				<SimpleForm redirect="list">
					<MultiLanguageInput source="name" validate={required()} />
					<MultiLanguageInput source="description" validate={minLength(10)} />
				</SimpleForm>
			</Create>
		);
	}
}

export default ProductCategoryCreate;
