/* © 2018 NauStud.io
 * @author Thanh, Eric
 */
/* eslint-disable
	react/default-props-match-prop-types,
	react/no-unused-prop-types,
	react/require-default-props,
	react/forbid-prop-types,
	no-alert,
	no-restricted-globals
*/
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { /* CREATE */ addField } from 'react-admin';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import Toolbar from './toolbar';

// import { getValueNestedObject } from '../../helper/utils';
import Quill, { Block, Delta, FullContentImage, FullTextImage } from './quill';
import ImageTooltip from './tooltips/ImageTooltip';

class RichTextEditor extends PureComponent {
	// These props are required by AOR wrapper that will inject connected data to the component
	static propTypes = {
		source: PropTypes.string,
		isCreate: PropTypes.bool,
		toolbarOptions: PropTypes.oneOfType([PropTypes.array, PropTypes.bool]),
		validate: PropTypes.func,
		input: PropTypes.shape({
			onChange: PropTypes.func,
			value: PropTypes.string,
		}),
		record: PropTypes.shape({
			text: PropTypes.string,
		}),
		isNotBlog: PropTypes.bool,
	};

	static defaultProps = {
		source: 'text',
		record: {
			text: '',
		},
		validate: undefined,
		isCreate: false,
		toolbarOptions: [
			[{ header: [2, 3, 4, false] }],
			['bold', 'italic', 'underline'],
			[{ list: 'bullet' }],
			[{ indent: '-1' }, { indent: '+1' }],
			['clean'], // remove formatting button
			['link'],
		],
		isNotBlog: false,
	};

	constructor(props) {
		super(props);

		this.timer = null;
	}

	state = {
		isToolBarShow: false,
		isFormattingImage: false,
	};

	componentDidMount() {
		this.Quill = Quill;
		this._initQuill();
	}

	componentWillUnmount() {
		if (this.quill) {
			this.quill.off('text-change', this.onTextChange);
			this.quill = null;
		}
		window.removeEventListener('scroll', this.onScroll);
	}

	onTextChange = () => {
		//Call input onChange at Fast-pace will make the editor feels Lag
		clearTimeout(this.timer);
		this.timer = setTimeout(this._onInputChangeHandle, 500);

		if (this.editor.innerHTML.length > 11) {
			this.editor.classList.remove('ql-blank');
		}
	};

	onScroll = () => {
		if (!this.quill) {
			return 0;
		}

		const toolbar = this.quill.getModule('toolbar');
		const scroll = Math.max(document.documentElement.scrollTop, document.body.scrollTop);

		if (Math.abs(this.scroll - scroll) > 280 && scroll < 500) {
			document.documentElement.scrollTop = this.scroll;
			document.body.scrollTop = this.scroll;
		} else {
			this.scroll = scroll;
		}
		if (toolbar && this.editorRef) {
			const scrollTop = this.editorRef.getBoundingClientRect().top;
			if (scrollTop <= 30) {
				const padding = window.innerWidth - document.body.clientWidth;
				toolbar.container.classList.add('rte-toolbar--float');
				toolbar.container.style.paddingRight = `${padding}px`;
				this.rteRef.style.paddingTop = '63px';
				this.floatingToolbar = true;
			} else {
				toolbar.container.classList.remove('rte-toolbar--float');
				toolbar.container.style.paddingRight = 0;
				this.rteRef.style.paddingTop = 0;
				this.floatingToolbar = false;
			}
		}

		return 0;
	};

	onCloseTooltip = () => {
		this.setState({ isFormattingImage: false });
	};

	onFormatImage = ({ hasContainer }) => {
		if (this.selectedImage && this.selectedImage.parentElement) {
			if (hasContainer) {
				this.selectedImage.parentElement.className = 'container';
			} else {
				this.selectedImage.parentElement.className = '';
			}
			this.onTextChange();
		}
		this.onCloseTooltip();
	};

	updateEditorWrapperRef = ref => {
		this.rteRef = ref;
	};

	_onInputChangeHandle = () => {
		const innerHTML = this.editor.innerHTML;
		this.props.input.onChange(innerHTML);
	};

	// internal function

	_initQuill() {
		const { toolbarOptions } = this.props;

		this.quill = new Quill(this.editorRef, {
			modules: {
				toolbar: toolbarOptions,
			},
			placeholder: 'Tell your story...',
			theme: 'snow',
		});
		window.quill = this.quill;

		window.addEventListener('scroll', this.onScroll);

		this.quill.root.addEventListener(
			'paste',
			() => {
				const scroll = Math.max(document.documentElement.scrollTop, document.body.scrollTop);
				setTimeout(() => {
					document.documentElement.scrollTop = scroll;
					document.body.scrollTop = scroll;
				}, 10);
			},
			true
		);

		this.editor = this.editorRef.querySelector('.ql-editor');
		this.editor.innerHTML = this.props.input.value || '';

		this.quill.on('text-change', this.onTextChange);
		this._handleCustomListItem();
		this.editor.innerHTML = this._addExtraLineBreak(this.editor.innerHTML);

		this.quill.clipboard.addMatcher(Node.ELEMENT_NODE, node => {
			const plaintext = node.textContent;

			return new Delta().insert(plaintext);
		});

		//Render toolbar
		this.setState({
			isToolBarShow: true,
		});

		this.quill.on(Quill.events.EDITOR_CHANGE, (eventType, range) => {
			if (eventType !== Quill.events.SELECTION_CHANGE) return;
			if (range === null) return;
			if (range.length === 1) {
				let [block] = this.quill.scroll.descendant(FullContentImage, range.index);
				if (!block) {
					[block] = this.quill.scroll.descendant(FullTextImage, range.index);
				}
				if (!block || !block.domNode) return;
				const firstChild = block.domNode.firstChild;
				if (firstChild instanceof HTMLImageElement && firstChild.parentElement.tagName === 'FIGURE') {
					this.hasContainer = firstChild.parentElement.className.indexOf('container') > -1;
					this.selectedImage = firstChild;
					this.setState({ isFormattingImage: true });
				} else {
					this.setState({ isFormattingImage: false });
				}
			}
		});
	}

	// ref functions
	updateEditorRef = ref => {
		this.editorRef = ref;
	};

	_handleCustomListItem = () => {
		this.editor.addEventListener('keydown', e => {
			if (e.key === 'Enter') {
				const range = this.quill.getSelection();
				const [block] = this.quill.scroll.descendant(Block, range.index);
				if (block && block.prev && block.prev.statics.blotName === 'customListItem') {
					this.quill.insertEmbed(range.index, 'customListItem', true, Quill.sources.USER);
					this.quill.setSelection(range.index + 3, Quill.sources.SILENT);
				}
			}
		});
	};

	_addExtraLineBreak = inputText => {
		let need = false;
		const length = inputText.length;
		const last = inputText.substr(length - 11);

		if (last !== '<p><br></p>') {
			need = true;
		}

		if (need) {
			return `${inputText}<p><br></p>`;
		}

		return inputText;
	};

	// other functions
	showWarning = warning => {
		this.setState({ warning });
	};

	hideWarning = () => {
		this.setState({ warning: '' });
	};

	hasContainer = false;

	render() {
		const { isToolBarShow, isFormattingImage } = this.state;
		const { isNotBlog } = this.props;

		return (
			<div className={`rte ${isNotBlog ? '' : 'blog-content'}`} ref={this.updateEditorWrapperRef}>
				<div ref={this.updateEditorRef} id="editor" className={`rte__editor`} />

				{isToolBarShow && <Toolbar quill={this.quill} showWarning={this.showWarning} />}
				{isFormattingImage && (
					<div className="tooltip-wrap">
						<div className="tooltip-content">
							<ImageTooltip
								isFormatting
								closeTooltip={this.onCloseTooltip}
								onSubmit={this.onFormatImage}
								hasContainer={this.hasContainer}
							/>
						</div>
					</div>
				)}
				<Dialog
					title="Warning"
					actions={[<Button label="OK" primary onClick={this.hideWarning} />]}
					modal={false}
					open={!!this.state.warning}
					onRequestClose={this.hideWarning}
				>
					{this.state.warning}
				</Dialog>
			</div>
		);
	}
}

export default addField(RichTextEditor);
