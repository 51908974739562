/* © 2019 NauStud.io
 * @author Eric Tran
 */

import React, { Component } from 'react';
import {
	Create,
	TabbedForm,
	FormTab,
	TextInput,
	LongTextInput,
	BooleanInput,
	SelectInput,
	SelectArrayInput,
	NumberInput,
	FormDataConsumer,
	ArrayInput,
	SimpleFormIterator,
	ReferenceInput,
	ReferenceArrayInput,
	required,
	minValue,
	maxValue,
	CheckboxGroupInput,
} from 'react-admin';

import { DateInput, TimeInput } from 'react-admin-date-inputs';
import MultiLanguageInput from '../MultiLanguageInput';
import MultiLanguageRichTextInput from '../MultiLanguageRichTextInput';
import LocationInput from '../LocationInput';
import ProductCostTable from '../ProductCostTable';

class IntercityCarCreate extends Component {
	render() {
		return (
			<Create {...this.props} undoable={false}>
				<TabbedForm redirect="list">
					<FormTab label="General">
						<TextInput source="vtCode" label="Vietravel Code" validate={required()} />
						<MultiLanguageInput source="name" label="Name" validate={required()} />
						<LongTextInput source="description" label="Description" />
						<MultiLanguageRichTextInput source="routeInformation" label="Route Information" />
						<LocationInput source="fromLocationId" label="From City" validate={required()} />
						<LocationInput source="toLocationId" label="To City" validate={required()} />
						<ArrayInput source="locationIds" label="Stop Over Locations" validate={required()}>
							<SimpleFormIterator>
								<LocationInput label="City" validate={required()} />
							</SimpleFormIterator>
						</ArrayInput>
						<NumberInput source="release" label="Release (Hour)" validate={required()} />
						<NumberInput source="distance" label="Distance (Km)" validate={required()} />
						<NumberInput source="duration" label="Duration (Minutes)" validate={required()} />
						<ArrayInput source="startTimes">
							<SimpleFormIterator>
								<TimeInput />
							</SimpleFormIterator>
						</ArrayInput>
						<BooleanInput source="active" label="Active" />
					</FormTab>
					<FormTab label="Guide">
						<ReferenceArrayInput
							source="availableGuideLanguages"
							label="Available Guide Languages"
							reference="guide-languages"
						>
							<SelectArrayInput optionsText="name" />
						</ReferenceArrayInput>
						<SelectArrayInput
							source="availableGuideDuration"
							choices={[
								{ id: 'halfDay', name: 'Half Day' },
								{ id: 'fullDay', name: 'Full Day' },
								{ id: 'multipleDay', name: 'Multiple Day' },
							]}
						/>
					</FormTab>
					<FormTab label="Surcharge">
						<SelectInput
							source="surchargeType"
							defaultValue="fix"
							choices={[
								{ id: 'fix', name: 'Fix' },
								{ id: 'percentage', name: '%' },
							]}
						/>
						<FormDataConsumer>
							{({ formData }) => {
								if (formData.surchargeType === 'percentage') {
									return (
										<NumberInput
											source="surchargeAmount"
											inputProps={{ min: 0, max: 100 }}
											validate={[minValue(0), maxValue(100)]}
											defaultValue={0}
										/>
									);
								}

								return (
									<NumberInput
										source="surchargeAmount"
										inputProps={{ min: 0 }}
										validate={minValue(0)}
										defaultValue={0}
									/>
								);
							}}
						</FormDataConsumer>
					</FormTab>
					<FormTab label="Cost Information">
						<ArrayInput style={{ width: '50%' }} source="availableDays" label="Available days">
							<SimpleFormIterator>
								<DateInput label="From" source="from" options={{ format: 'dd/MM/yyyy' }} />
								<DateInput label="To" source="to" options={{ format: 'dd/MM/yyyy' }} />
								<CheckboxGroupInput
									source="weekdays"
									label="Available Weekdays"
									defaultValue={[0, 1, 2, 3, 4, 5, 6]}
									choices={[
										{ id: '1', name: 'Monday' },
										{ id: '2', name: 'Tuesday' },
										{ id: '3', name: 'Wednesday' },
										{ id: '4', name: 'Thursday' },
										{ id: '5', name: 'Friday' },
										{ id: '6', name: 'Saturday' },
										{ id: '0', name: 'Sunday' },
									]}
								/>
							</SimpleFormIterator>
						</ArrayInput>
						<ArrayInput source="costInfo" label="Cost Information Array">
							<SimpleFormIterator>
								<ReferenceInput source="carTypeId" label="Car Type" reference="car-types">
									<SelectInput optionText="name.en" />
								</ReferenceInput>
								<NumberInput source="cost" label="Cost (VND)" />
							</SimpleFormIterator>
						</ArrayInput>
						<SelectInput
							source="markupType"
							defaultValue="fix"
							choices={[
								{ id: 'fix', name: 'Fix' },
								{ id: 'percentage', name: '%' },
							]}
						/>
						<FormDataConsumer>
							{({ formData }) => {
								if (formData.markupType === 'percentage') {
									return (
										<NumberInput
											source="markupAmount"
											inputProps={{ min: 0, max: 100 }}
											validate={[minValue(0), maxValue(100)]}
											defaultValue={0}
										/>
									);
								}

								return (
									<NumberInput
										source="markupAmount"
										inputProps={{ min: 0 }}
										validate={minValue(0)}
										defaultValue={0}
									/>
								);
							}}
						</FormDataConsumer>
						<BooleanInput source="fillTheTable" label="Fill the table" />
						<FormDataConsumer>
							{({ formData }) => {
								if (formData.fillTheTable) {
									return <ProductCostTable formData={formData} />;
								}

								return '';
							}}
						</FormDataConsumer>
					</FormTab>
				</TabbedForm>
			</Create>
		);
	}
}

export default IntercityCarCreate;
