/* © 2017 Goalify
 * @author Thanh
 *
 * This is just a pseudo class which proxy the global APP_CONFIG object
 * @example:
 * import config from './config';
 *
 * console.log(config.GRAPHQL_ENDPOINT);
 */
/* global APP_CONFIG: false*/

if (!('APP_CONFIG' in window)) {
	throw new Error('APP_CONFIG is not defined in global scope.');
}

const config = APP_CONFIG;

export default config;
