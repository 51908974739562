/* © 2018 NauStud.io
 * @author Hai Luu
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
	Edit,
	TextInput,
	LongTextInput,
	SelectInput,
	TabbedForm,
	FormTab,
	ReferenceInput,
	AutocompleteInput,
	required,
} from 'react-admin';

import ImageInput from '../ImageInput';

const MediaTitle = ({ record }) => <span>{`Media ${record ? `#${record.id}` : ''}`}</span>;

MediaTitle.propTypes = { record: PropTypes.shape({}) };

MediaTitle.defaultProps = { record: {} };

class MediaEdit extends Component {
	searchName = searchText => ({ name: { regexp: searchText } });

	render() {
		return (
			<Edit {...this.props} title={<MediaTitle />} undoable={false}>
				<TabbedForm>
					<FormTab label="summary">
						<TextInput source="name" validate={required()} />
						<TextInput source="url" validate={required()} />
						<SelectInput
							validate={required()}
							source="type"
							choices={[{ id: 'image', name: 'Image' }, { id: 'video', name: 'Video' }]}
						/>
						<ImageInput
							source="poster"
							dropzone={{
								uniqueId: 'mediaEdit',
								width: 700,
								height: 200,
							}}
						/>
						<ReferenceInput
							label="Album"
							source="albumId"
							reference="albums"
							filterToQuery={this.searchName}
							allowEmpty
						>
							<AutocompleteInput optionText="name" />
						</ReferenceInput>
					</FormTab>
					<FormTab label="content">
						<LongTextInput source="description" />
					</FormTab>
				</TabbedForm>
			</Edit>
		);
	}
}

export default MediaEdit;
