/* © 2018 NauStud.io
 * @author Eric Tran
 */

import React, { Component } from 'react';
import { Edit, TextInput, GET_LIST, ArrayInput, SimpleFormIterator, required, FormTab, TabbedForm } from 'react-admin';

import restClient from '../../helper/restClient';
import ImageWithCropInput from '../../components/ImageWithCropInput';

// const EditToolbar = props => (
// 	<Toolbar {...props}>
// 		<SaveButton />
// 	</Toolbar>
// );

class InquiryTourQuestion extends Component {
	state = {
		inquiryTourQuestionId: '',
	};

	componentWillMount() {
		restClient(GET_LIST, 'inquiry-tour-questions', {
			pagination: {
				perPage: 1,
			},
			sort: {},
			filter: {},
		})
			.then(rs => {
				if (rs.data && rs.data.length) {
					this.setState({ inquiryTourQuestionId: rs.data[0].id });
				}
			})
			.catch(err => {
				console.log(err);
			});
	}

	render() {
		const { inquiryTourQuestionId } = this.state;

		if (!inquiryTourQuestionId) {
			return <div />;
		}
		const match = {
			params: { id: inquiryTourQuestionId },
			url: `/inquiry-tour-questions/${inquiryTourQuestionId}`,
			isExact: true,
		};

		return (
			<div>
				<Edit
					{...this.props}
					resource="inquiry-tour-questions"
					match={match}
					id={inquiryTourQuestionId}
					title="Inquiry Tour Setting"
					actions={null}
				>
					<TabbedForm redirect="list">
						<FormTab label="Setting">
							<TextInput validate={required()} source="receiveEmail" label="Email to receive user answers" />
						</FormTab>
						<FormTab label="Questions">
							<ArrayInput source="question1" label="Question 1">
								<SimpleFormIterator>
									<ImageWithCropInput source="image" label="Icon" ratio={1} dropzone={{ width: 300, height: 300 }} />
									<TextInput validate={required()} source="answer" label="Question answer" />
								</SimpleFormIterator>
							</ArrayInput>
							<ArrayInput source="question2" label="Question 2">
								<SimpleFormIterator>
									<ImageWithCropInput source="image" label="Icon" ratio={1} dropzone={{ width: 300, height: 300 }} />
									<TextInput validate={required()} source="answer" label="Question answer" />
								</SimpleFormIterator>
							</ArrayInput>
							<ArrayInput source="question3" label="Question 3">
								<SimpleFormIterator>
									<ImageWithCropInput source="image" label="Icon" ratio={1} dropzone={{ width: 300, height: 300 }} />
									<TextInput validate={required()} source="answer" label="Question answer" />
								</SimpleFormIterator>
							</ArrayInput>
							<ArrayInput source="question4" label="Question 4">
								<SimpleFormIterator>
									<ImageWithCropInput source="image" label="Icon" ratio={1} dropzone={{ width: 300, height: 300 }} />
									<TextInput validate={required()} source="answer" label="Question answer" />
								</SimpleFormIterator>
							</ArrayInput>
							<ArrayInput source="question5" label="Question 5">
								<SimpleFormIterator>
									<ImageWithCropInput source="image" label="Icon" ratio={1} dropzone={{ width: 300, height: 300 }} />
									<TextInput validate={required()} source="answer" label="Question answer" />
								</SimpleFormIterator>
							</ArrayInput>
						</FormTab>
						{/* <FormTab label="Setting">
							<TextInput source="Say thank to customer" />
						</FormTab> */}
					</TabbedForm>
				</Edit>
			</div>
		);
	}
}

export default InquiryTourQuestion;
