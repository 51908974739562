/* © 2018 NauStud.io
 * @author Eric Tran
 */

import React, { Component } from 'react';
import { Create, LongTextInput, SimpleForm, TextInput, required } from 'react-admin';

import ImageWithCropInput from '../ImageWithCropInput';

class TravelStyleCreate extends Component {
	render() {
		return (
			<Create {...this.props} undoable={false}>
				<SimpleForm redirect="list">
					<TextInput source="name" validate={required()} />
					<LongTextInput source="description" />
					<ImageWithCropInput
						source="image"
						ratio={125 / 83}
						dropzone={{
							uniqueId: 'travelStyleDrop',
							width: 450,
							height: 250,
						}}
					/>
				</SimpleForm>
			</Create>
		);
	}
}

export default TravelStyleCreate;
